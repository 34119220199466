<section class="dialog">
    <header class="dialog__header">
        <h3 class="dialog__title">{{ title }}</h3>
        <mat-icon class="close" (click)="close()">close</mat-icon>
    </header>

    <div class="dialog__content">

        <app-search-mat (textChange)="onSearchChange($event)"
                        [kitView]="true"
                        placeholderTxt="Поиск задач"
                        class="search"></app-search-mat>

        <div class="inner">
            <app-spinner [isSpinnerOn]="!isLoaded" *ngIf="!isLoaded"></app-spinner>

            <div class="scrollable" *ngIf="isMultiple">
                <ng-container *ngIf="(selectedSorted.length) && (selected.length - hiddenCnt)">
                    <h3 class="content__title">Выбраны ({{(selected.length - hiddenCnt)}})</h3>
                    <div class="list">
                        <ng-container *ngFor="let item of selectedSorted">
                            <app-subtasks-item [task]="item"
                                               *ngIf="item.type == 'task' && selectedVisible(item)"
                                               [title]="item.title"
                                               (click)="isAvailable(item.availability) ? removeTask(item) : false"
                                               [disabled]="!isAvailable(item.availability)"
                                               [remove]="isAvailable(item.availability)"
                                               [searchTerm]="searchTerm"></app-subtasks-item>
                        </ng-container>
                    </div>
                </ng-container>
            </div>

            <h3 class="content__title" *ngIf="sorted.length">
                Можно выбрать
            </h3>
            <cdk-virtual-scroll-viewport appendOnly itemSize="36" class="body _list">
                    <div class="list">
                        <ng-container *cdkVirtualFor="let item of sorted">
                            <app-subtasks-item [task]="item"
                                               [title]="item.title"
                                               (click)="addTask(item)"
                                               [add]="true"
                                               [searchTerm]="searchTerm"></app-subtasks-item>
                        </ng-container>
                    </div>
            </cdk-virtual-scroll-viewport>

<!--            <ng-container *ngIf="sorted.length">-->
<!--                <h3 class="content__title">Можно выбрать</h3>-->
<!--                <div class="list">-->
<!--                    <ng-container *ngFor="let item of sorted">-->
<!--                        <app-subtasks-item [task]="item"-->
<!--                                           [title]="item.title"-->
<!--                                           (click)="addTask(item)"-->
<!--                                           [add]="true"-->
<!--                                           [searchTerm]="searchTerm"></app-subtasks-item>-->
<!--                    </ng-container>-->
<!--                </div>-->
<!--            </ng-container>-->
            <h3 class="content__title _error" *ngIf="!sorted.length && !selectedSorted.length && isLoaded">Нет совпадений</h3>
        </div>
    </div>

    <footer class="dialog__footer">
        <app-button class="button _reset"
                    *ngIf="allowCreate"
                    buttonType="link"
                    [title]="addButton"
                    (click)="add()"
                    [type]="'text'"
                    type="button"></app-button>
        <div class="spacer"></div>
        <div class="main-buttons">
            <app-button class="button _reset"
                        buttonType="outlined"
                        title="Отменить"
                        (click)="close()"
                        [type]="'reset'"
                        type="reset"></app-button>
            <app-button class="button _save"
                        title="Сохранить"
                        (click)="saveForm()"
                        [type]="'submit'"></app-button>
        </div>

    </footer>

</section>

<ng-container *ngIf="confirmEditSubtasks">
    <app-confirm title="Сохранить изменения?"
                 confirmTitle="да"
                 cancelTitle="нет"
                 (confirm)="confirmEditSubtasks = false; saveForm()"
                 (cancel)="confirmEditSubtasks = false; close()">
    </app-confirm>
</ng-container>
