import {JsonElementType, JsonProperty, JsonType} from 'ta-json';
import {UserShort} from '../../../../models/user';
import {Motivation} from './motivation';

export enum EmploymentTypeEnum {
    STAFF_MEMBER = 'STAFF_MEMBER',
    IE = 'IE',
    CIVIL_LAW = 'CIVIL_LAW',
    TRAINEE = 'TRAINEE',
    SELF_EMPLOYED = 'SELF_EMPLOYED',
}

export enum RoleTypeEnum {
    AUTHOR = 'AUTHOR',
    EMPLOYEE = 'EMPLOYEE',
    COORDINATOR = 'COORDINATOR',
}


export const RoleTypeMapping = Object.freeze([
    {
        value: RoleTypeEnum.AUTHOR,
        name: 'Автор'
    },
    {
        value: RoleTypeEnum.EMPLOYEE,
        name: 'Сотрудник'
    },
    {
        value: RoleTypeEnum.COORDINATOR,
        name: 'Согласователь'
    }

]);

export const EmploymentTypeMapping = Object.freeze([
    {
        value: EmploymentTypeEnum.STAFF_MEMBER,
        name: 'В штате'
    },
    {
        value: EmploymentTypeEnum.IE,
        name: 'ИП'
    },
    {
        value: EmploymentTypeEnum.CIVIL_LAW,
        name: 'ГПХ'
    },
    {
        value: EmploymentTypeEnum.TRAINEE,
        name: 'Стажер'
    },
    {
        value: EmploymentTypeEnum.SELF_EMPLOYED,
        name: 'Самозанятый'
    },
]);

export enum AccrualTypeEnum {
    PER_MONTH = 'PER_MONTH',
    PER_HOUR = 'PER_HOUR'
}

export const AccrualTypeMapping = Object.freeze([
    {
        value: AccrualTypeEnum.PER_HOUR,
        name: 'Почасовая'
    },
    {
        value: AccrualTypeEnum.PER_MONTH,
        name: 'Ежемесячная'
    }
]);



export enum MotivationStatusEnum {
    APPROVED = 'approved',
    DRAFT = 'draft',
    ON_APPROVE = 'on_approve',
    CANCELED = 'canceled',
    ACTIVE = 'current',
    NEED_APPROVE = 'need_approve',
    FINISHED = 'completed',
    FAMILIARIZE = 'pending_familiarization'
}

export const MotivationStatusMapping = Object.freeze([
    {
        value: MotivationStatusEnum.APPROVED,
        class: 'green',
        name: 'Согласована'
    },
    {
        value: MotivationStatusEnum.DRAFT,
        class: 'darkgrey',
        name: 'Черновик'
    },
    {
        value: MotivationStatusEnum.ON_APPROVE,
        class: 'yellow',
        name: 'На согласовании'
    },
    {
        value: MotivationStatusEnum.CANCELED,
        class: 'grey',
        name: 'Отменена'
    },
    {
        value: MotivationStatusEnum.FINISHED,
        class: 'grey',
        name: 'Завершена'
    },
    {
        value: MotivationStatusEnum.ACTIVE,
        class: 'green-with-bg',
        name: 'Действующая'
    },
    {
        value: MotivationStatusEnum.NEED_APPROVE,
        class: 'red',
        name: 'Требует согласования'
    },
    {
        value: MotivationStatusEnum.FAMILIARIZE,
        class: 'red',
        name: 'Требует ознакомления'
    },
]);

export class Company {
    id: number;
    name: string;
}

export class LegalEntity {
    id: number;
    company_id: number;
    title: string;
}

export class UsersMotivation {
    @JsonProperty('employment_type')
    @JsonType(String)
    employmentType?: string = null;

    @JsonProperty('employment_date')
    @JsonType(String)
    employmentDate?: string = null;

    @JsonProperty('is_main')
    @JsonType(String)
    isMain?: boolean = false;

    @JsonProperty('periodicity')
    @JsonType(String)
    periodicity?: string = null;

    @JsonProperty('id')
    @JsonType(Number)
    id: number = null;

    @JsonProperty('rate')
    @JsonType(Number)
    rate?: number;

    @JsonProperty('user')
    @JsonElementType(UserShort)
    user: UserShort = null;

    @JsonProperty('motivations')
    @JsonElementType(Motivation)
    motivations: Motivation[] = [];

    @JsonProperty('motivation')
    @JsonElementType(Motivation)
    motivation: Motivation;

    @JsonProperty('legal_entity')
    @JsonElementType(LegalEntity)
    legal: LegalEntity;

    @JsonProperty('legal_entity_id')
    @JsonElementType(Number)
    legalId; number;

    @JsonProperty('company')
    @JsonElementType(Company)
    company: Company;

    currentMots?: Motivation[];
    archiveMots?: Motivation[];

    @JsonProperty('motivation_id')
    @JsonElementType(Number)
    motivation_id?: number;

    @JsonProperty('users_motivation_id')
    @JsonElementType(Number)
    users_motivation_id?: number;

    constructor(
        id?: number,
        employmentType: string = '',
        periodicity: string = '',
        user: UserShort = null,
        motivation: Motivation = null,
        motivations: Motivation[] = []
    ) {
        this.id = id;
        this.employmentType = employmentType;
        this.periodicity = periodicity;
        this.user = user;
        this.motivation = motivation;
        this.motivations = motivations;
    }

    createNewCard(parent: UsersMotivation, motivation: Motivation) {
        return new UsersMotivation(
            parent.id,
            parent.employmentType,
            parent.periodicity,
            parent.user,
            motivation,
            parent.motivations,
        );
    }


    makeClearList(list) {
        const newList = list.filter(el => el.usersMotivations && el.usersMotivations.length);

        newList.forEach(el => {
            el.usersMotivations.forEach(card => {
                card.motivations?.forEach((motivation, index) => {
                    if (index === 0) {
                        card.motivation = motivation;
                    } else {
                        el.usersMotivations.push(UsersMotivation.prototype.createNewCard(card, motivation));
                    }
                });
            });
        });

        return newList;
    }

    makeTreeNode(list) {
        const map = {};
        const roots = [];

        if (list.length === 1) {
            return list;
        }

        for (let i = 0; i < list.length; i += 1) {
            map[list[i].id] = i;
            list[i]['children'] = [];
        }

        for (let i = 0; i < list.length; i += 1) {
            const node = list[i];
            if (node.parentId && node.parentId !== node.id && list[map[node['parentId']]]) {
                list[map[node['parentId']]]['children'] ? list[map[node['parentId']]]['children'].push(node) : list[map[node['parentId']]]['children'] = [node];
            } else {
                roots.push(node);
            }
        }

        return roots;
    }

}
