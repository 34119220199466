<div class="members">
            <div class="header">
                <h2 class="title">
                    {{ title }} <ng-container *ngIf="groups.length">({{ groups.length }})</ng-container>
                </h2>
            </div>
        <app-groups-list [groups]="groups" [editable]="!disabled"></app-groups-list>

        <button mat-flat-button class="button _transparent" type="button" *ngIf="!disabled" (click)="openFormAdd()">
            <mat-icon class="icon">add</mat-icon>
            Добавить группу
        </button>
</div>




