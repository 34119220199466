<label class="label kit _assessment">
                <span class="label__txt"  *ngIf="showLabels">
                    Статус
                </span>
    <mat-select class="select" [formControl]="formGroup.controls['status']">
        <mat-option [value]="0">
            Не выбрано
        </mat-option>
        <mat-option *ngFor="let status of statuses" [value]="status">
            {{ statusName(status) }}
        </mat-option>
    </mat-select>
</label>

<label class="label kit _date" *ngIf="formGroup.controls['status'].value">
                <span class="label__txt"  *ngIf="showLabels">
                    Период
                </span>
    <mat-date-range-input class="input"
                          [ngClass]="{'_error': formGroup.controls['statusStart'].invalid || formGroup.controls['statusEnd'].invalid}"
                          [rangePicker]="statusDate"
                (click)="statusDate.open()"
                [separator]="rangeSeparator">
        <input [formControl]="formGroup.controls['statusStart']"
               placeholder="Выберите период"
               [dateMask]="maskConfig" matStartDate readonly>
        <input [formControl]="formGroup.controls['statusEnd']"
               placeholder=""
               [dateMask]="maskConfig" matEndDate readonly>
    </mat-date-range-input>

    <mat-datepicker-toggle matSuffix [for]="statusDate"></mat-datepicker-toggle>
    <mat-datepicker-toggle matSuffix (click)="clearStartDate($event)" class="clear-btn" *ngIf="formGroup.controls['statusStart'].value">
        <mat-icon matDatepickerToggleIcon>clear</mat-icon>
    </mat-datepicker-toggle>
    <mat-date-range-picker #statusDate (closed)="onClose()"></mat-date-range-picker>
</label>

