<div class="learning-tag-anchor" tourAnchor="learningTagDialog"></div>
<section class="dialog">
    <header class="dialog__header">
        <h3 class="dialog__title">Выбор метки</h3>
        <mat-icon class="close learningTagClose" (click)="close()">close</mat-icon>
    </header>

    <div class="dialog__content">
        <app-search-mat class="search"
                        [kitView]="true"
                        (textChange)="onSearchChange($event)"></app-search-mat>

        <mat-divider class="diviner"></mat-divider>

        <div class="dialog__content-head">
            <h2 class="title">Метки ({{ sorted.length }})</h2>

            <app-button buttonType="add"
                        type="button"
                        class="add-button learningGroupAdd"
                        *ngIf="isCanCreate"
                        (click)="openFormAddNewTag()">
            </app-button>
        </div>

        <div class="list">
            <app-tags-list [tags]="sorted"
                           [selectedTags]="selectedTags"
                           [selected]="true"
                           (addTag)="onAddTag($event)"
                           (removeTag)="onRemoveTag($event)"
            ></app-tags-list>

            <button mat-flat-button class="button _add _transparent learningTagAdd" type="button" *ngIf="isCanCreate" (click)="openFormAddNewTag()">
                <mat-icon>add</mat-icon>
                Добавить метку
            </button>
        </div>

    </div>
</section>
<div *ngIf="isLearningOpen" class="learning-background-overlay"></div>
