import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {SearchInputComponent} from '../search-input/search-input.component';
import {MotivationsService} from '../../../phonebook/services/motivations.service';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit, OnDestroy {

    // Значение введенное
    public inputValue = '';

    // Передает результат ввода наверх
    @Output() public readonly textChange = new EventEmitter();

    /** Нужен ли скрывающийся поиск */
    @Input() public compact = false;
    @Input() public disabled = false;

    @Input() public searchTerm = '';

    /** отображение без рамок */
    @Input() public thin = false;
    /** Подписки живут пока не... */
    destroyed: Subject<void> = new Subject<void>();

    /** Открыт ли поиск */
    public isSearchOpen = false;

    @ViewChild('input') input: SearchInputComponent;

    constructor(
        public motivationService: MotivationsService,
    ) {
    }

    onFocusChange() {
        if (this.isSearchOpen && !this.searchTerm.length && this.compact) {
            this.close();
        }
    }

    public clear() {
        this.input.clear();
    }

    ngOnInit() {
        this.isSearchOpen = !this.compact;

        if (this.searchTerm !== '') {
            this.isSearchOpen = true;
        }

        this.motivationService.closeSearch$
            .pipe(takeUntil(this.destroyed))
            .subscribe(resp => {
                if (resp) {
                    this.searchTerm = '';
                    this.isSearchOpen = false;
                }
            })
    }

    textChangeEvent() {
        this.searchTerm = this.searchTerm.slice(0, 100);
        this.textChange.emit(this.searchTerm)
    }

    searchClick() {
        if (this.disabled) {
            return false;
        }
        this.isSearchOpen = !this.isSearchOpen;
        if (!this.isSearchOpen) {
            this.searchTerm = '';
            this.textChange.emit('');
        } else {
            this.input.focus();
        }
    }

    close() {
        this.isSearchOpen = false;
    }

    ngOnDestroy() {
        this.textChange.emit('');
        this.destroyed.next();
        this.destroyed.complete();
    }

}
