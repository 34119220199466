<div class="search" [ngClass]="size">
  <mat-icon class="icon _search">search</mat-icon>
  <input #searchTerm class="input"
         type="text"
         [autoFocus]=autoFocus
         [ngClass]="{'_outline': outline}"
         [placeholder]="placeholder"
         maxlength="100"
         [(ngModel)]="inputValue"/>
  <button *ngIf="inputValue || closeVisible" (click)="clear()" class="clear-button">
    <mat-icon class="icon _clear">clear</mat-icon>
  </button>
</div>
