
<h1>Соглашение о конфиденциальности персональной информации</h1>
<p>Настоящее Соглашение о конфиденциальности персональной информации (далее &mdash; Соглашение) заключается между Индивидуальным предпринимателем Бучиком Сергеем Александровичем (далее &mdash; Оператор) и любым пользователем Сайта Компании <strong>www.myradius.ru</strong> и размещенного на нем Продукта Компании &mdash; приложения <strong>Radius</strong> (далее &mdash; Приложение). Соглашение действует в отношении всей информации, которую Оператор может получить о пользователе во время использования им Приложения.</p>
<ol>
    <li><strong>Персональная информация пользователей, которую получает Оператор.</strong></li>
    <ol style="list-style-type: lower-alpha;">
        <li>В рамках настоящего Соглашения под &laquo;персональной информацией пользователя&raquo; понимаются:</li>
        <ol style="list-style-type: lower-roman;">
            <li>Персональная информация, которую пользователь предоставляет о себе<br /> самостоятельно, передается при регистрации в Приложении и при заполнении формы обратной связи. Обязательная для заполнения информация помечена специальным образом. Иная информация предоставляется пользователем на его усмотрение.</li>
            <li>Данные, которые автоматически передаются счетчикам на сайте размещения Приложения в процессе его скачивания и использования, в том числе IP-адрес, информация из cookies, информация о браузере пользователя (или иной программе, с помощью которой осуществляется доступ к Приложению), время доступа, адрес запрашиваемой страницы.</li>
        </ol>
        <li>При заполнении форм для получения доступа к Приложению пользователь предоставляет следующую персональную информацию: фамилию, имя, номер мобильного телефона. Оператор исходит из того, что пользователь предоставляет достоверную и достаточную персональную информацию по вопросам, предлагаемым в формах. Ответственность за правильность и достоверность вводимых персональных данных Пользователь несет самостоятельно.&nbsp;</li>
        <li>Предоставление пользователем своих персональных данных означает безоговорочное согласие пользователя с настоящим Соглашением и указанными в нем условиями обработки его персональной информации; в случае несогласия с этими условиями пользователь должен воздержаться от предоставления своих персональных данных.</li>
    </ol>
    <li><strong>Цели сбора и обработки персональной информации пользователей.</strong></li>
    <ol style="list-style-type: lower-alpha;">
        <li>Оператор собирает и хранит только те персональные данные, которые необходимы для обеспечения доступа к Приложению, выполнения запросов пользователя, получения обратной связи.</li>
        <li>Персональную информацию пользователя Оператор может использовать в следующих целях:</li>
        <ol style="list-style-type: lower-roman;">
            <li>Идентификация Пользователя в рамках Соглашения и договоров с Компанией;</li>
            <li>Выполнение запросов пользователя, выяснение обстоятельств негативного опыта обращения в Компанию;</li>
            <li>Связь с пользователем при обработке запросов от пользователя;</li>
        </ol>
    </ol>
    <li><strong>Условия обработки персональной информации пользователя и её передачи третьим лицам.</strong></li>
    <ol style="list-style-type: lower-alpha;">
        <li>Оператор обязуется не разглашать полученную от пользователя информацию. Вне пределов, указанных в пункте 2.b. настоящего Соглашения, информация о пользователях не будет каким-либо образом использована. Доступ к таким сведениям имеют только лица, специально уполномоченные на выполнение данных задач, и предупрежденные об ответственности за случайное или умышленное разглашение, либо несанкционированное использование таких сведений.</li>
        <li>В отношении персональной информации пользователя сохраняется ее конфиденциальность. Если при использовании какой-либо формы определённая часть его персональной информации (например, Имя, Фамилия) становится общедоступной, Оператор размещает соответствующее предупреждение на странице формы.</li>
        <li>При обработке персональных данных пользователей Оператор руководствуется Федеральным законом РФ &laquo;О персональных данных&raquo;.</li>
    </ol>

    <li><strong>Удаление пользователем персональной информации.</strong></li>
    <ol style="list-style-type: lower-alpha;">
        <li>Пользователь в любой момент может удалить предоставленную им в рамках Соглашения персональную информацию, отправив письмо в Компанию по электронной почте info@myradius.ru и указав при этом введенные персональные данные. Оператор обязуется рассмотреть и ответить на письмо в трехдневный срок с момента его получения и предпринять все необходимые меры для безвозвратного удаления персональных данных.<strong>&nbsp;</strong></li>
    </ol>
    <li><strong>Меры, применяемые для защиты персональной информации пользователей.</strong></li>
    <ol style="list-style-type: lower-alpha;">
        <li>Оператор принимает необходимые и достаточные организационные и технические меры для защиты персональной информации пользователя от неправомерного или случайного доступа, блокирования, копирования, распространения, а также от иных неправомерных действий с ней третьих лиц.</li>
    </ol>
    <li><strong>Изменение Соглашения о конфиденциальности персональной информации.</strong></li>
    <ol style="list-style-type: lower-alpha;">
        <li>Оператор оставляет за собой исключительное право в одностороннем порядке вносить изменения и дополнения в настоящее Соглашение. При внесении изменений в актуальной редакции указывается дата последнего обновления. Новая редакция Соглашения вступает в силу с момента ее размещения, если иное не предусмотрено новой редакцией Соглашения.</li>
    </ol>
</ol>
<p>&nbsp;</p>
<p><em>Настоящее Соглашение о конфиденциальности персональной информации было обновлено последний раз 28 декабря 2018 года.&nbsp;</em></p>
<p>&nbsp;</p>
