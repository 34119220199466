import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Subject} from 'rxjs';
import {AuthService} from '../../../../../services/auth.service';
import {takeUntil} from 'rxjs/operators';
import {PositionsService} from '../../../services/positions.service';
import {PositionFolder} from '../../../models/position-folder';
import {GlobalNotificationCenterService} from '../../../../../services/global-notification-center.service';
import {ApiCompanyService} from '../../../services/api-company.service';
import {Position} from '../../../models/position';
import {CompanyService} from '../../../services/company.service';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {FlatTreeControl} from '@angular/cdk/tree';

/** Flat node with expandable and level information */
interface ExampleFlatNode {
    expandable: boolean;
    name: string;
    level: number;
}

@Component({
  selector: 'app-position-list',
  templateUrl: './position-list.component.html',
  styleUrls: ['./position-list.component.scss']
})

export class PositionListComponent implements OnInit, OnDestroy, OnChanges {
    @Output() public readonly onSelectedChanges = new EventEmitter();
    @Input() public positionFolders: PositionFolder[] = [];
    @Input() public selectedPositions = [];
    @Input() public multiple = true;
    @Input() public selectedFolder: PositionFolder = null;
    @Input() public sortedPositions = [];
    @Input() public sortedFolders = [];
    @Input() public searchStr = '';
    @Input() public folders = false;
    @Input() public canAdd = true;
    private userId = null;

    private destroyed = new Subject<void>();
    isAdmin = false;
    public editMode = false;

    private _transformer = (node: PositionFolder, level: number) => ({
            expandable: (!!node.children && node.children.length > 0) || (node.positions && node.positions.length > 0) ,
            name: node.title,
            positions: node.positions,
            level,
            id: node.id,
        });

    treeControl = new FlatTreeControl<ExampleFlatNode>(
        node => node.level,
        node => node.expandable,
    );

    treeFlattener = new MatTreeFlattener(
        this._transformer,
        node => node.level,
        node => node.expandable,
        node => node.children,
    );

    dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    constructor(
        private positionsService: PositionsService,
        private notiService: GlobalNotificationCenterService,
        private auth: AuthService,
        private api: ApiCompanyService,
        private company: CompanyService,
    ) {
    }

    hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

    positive = (_: number, node: ExampleFlatNode) => node.name !== '-';

    onFolderClick(fol: PositionFolder, evt) {
        evt.preventDefault();
        if (this.selectedFolder && this.selectedFolder.id === fol.id) {
            this.selectedFolder = null;
        } else {
            this.selectedFolder = fol;
        }

        this.onSelectedChanges.emit(this.selectedFolder);
    }

    onPositionClick(pos: Position, evt) {
        evt.stopPropagation();
        if (this.multiple) {
            const index = this.selectedPositions.findIndex(el => el.id === pos.id);
            if (index >= 0) {
                this.selectedPositions = this.selectedPositions.filter(el => el.id !== pos.id);
            } else {
                pos.slots.forEach(el => el['is_active'] = true);
                this.selectedPositions.push(pos);
            }
        } else {
            pos.slots.forEach((s, index) => {
                s['is_active'] = index === 0;
            });

            this.selectedPositions = [pos];
        }


        this.onSelectedChanges.emit(this.selectedPositions);
    }

    isSelected(item) {
        if (this.folders) {
            return this.selectedFolder && this.selectedFolder.id === item.id;
        } else {
            return this.selectedPositions.some(el => el.id === item.id);
        }
    }

    ngOnInit(): void {
        this.userId = this.auth.currentUser$.value.id;
        this.company.editMode$
            .pipe(takeUntil(this.destroyed))
            .subscribe(res => {
                this.editMode = res;
            });

        this.company.isAdminPositions$
            .pipe(takeUntil(this.destroyed))
            .subscribe(res => {
                this.isAdmin = res;
            });
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }

    onCreateClick() {
        this.company.onCreatePositionClick$.next(true);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedPositions && this.folders) {
            this.selectedPositions = changes.selectedPositions.currentValue;
            this.selectedFolder = this.selectedPositions[0] ? this.selectedPositions[0] : null;
        }
        if (changes.positionFolders) {
            this.positionFolders = changes.positionFolders.currentValue;
            this.dataSource.data = this.positionFolders;
        }

        if (changes.sortedPositions) {
            this.sortedPositions = changes.sortedPositions.currentValue;
        }

        if (changes.sortedFolders) {
            this.sortedFolders = changes.sortedFolders.currentValue;
        }
    }

    getCounterFolder(node) {
        const cnt = node.positions.length;
        return cnt;
    }

}
