<ng-container [ngSwitch]="buttonType">
    <button *ngSwitchCase="Types.FIELD" mat-flat-button [type]="type" [disabled]="disabled" (click)="onClick()"
            [autofocus]="focus"
            class="button default">{{title}}
    </button>
    <button *ngSwitchCase="Types.OUTLINED" mat-flat-button [type]="type" [disabled]="disabled" (click)="onClick()"
            [autofocus]="focus"
            class="button outlined">{{title}}
    </button>
    <button *ngSwitchCase="Types.TEXT" mat-flat-button [type]="type" [disabled]="disabled" (click)="onClick()"
            [autofocus]="focus"
            class="button text">{{title}}
    </button>
    <button *ngSwitchCase="Types.LINK" mat-flat-button [type]="type" [disabled]="disabled" (click)="onClick()"
            [autofocus]="focus"
            class="button link">{{title}}
    </button>
    <button *ngSwitchCase="Types.FIELD_ICON" mat-flat-button [type]="type" [disabled]="disabled" (click)="onClick()"
            [autofocus]="focus"
            class="button default field-icon">
        <mat-icon>{{icon}}</mat-icon>
        {{title}}
    </button>
    <button *ngSwitchCase="Types.OUTLINED_ICON" [type]="type" mat-flat-button [disabled]="disabled" (click)="onClick()"
            [autofocus]="focus"
            class="button outlined-icon">
        <!--<mat-icon class="icon" *ngIf="radiusIcon" [svgIcon]="radiusIcon"></mat-icon>-->
        <span class="txt">{{title}}</span>
    </button>
    <button *ngSwitchCase="Types.CLOSE" [type]="type" [disabled]="disabled" (click)="onClick()"
            [autofocus]="focus" class="clear-btn-wrapper">
        <mat-icon class="close-btn-icon">close</mat-icon>
    </button>
    <button mat-icon-button *ngSwitchCase="Types.EDIT" [disabled]="disabled"
            [autofocus]="focus" (click)="onClick()" class="edit-button">
        <mat-icon class="icon">edit</mat-icon>
    </button>
    <button *ngSwitchCase="Types.ADD" [type]="type" [disabled]="disabled"
            [autofocus]="focus" (click)="onClick()" class="add-button">
        <mat-icon class="add-btn-icon">add</mat-icon>
    </button>
    <button *ngSwitchDefault mat-flat-button [type]="type"
            [autofocus]="focus" [disabled]="disabled" (click)="onClick()"
            class="button default">
        <!--<mat-icon class="icon" *ngIf="radiusIcon" [svgIcon]="radiusIcon"></mat-icon>-->
        <span class="txt">{{title}} <ng-content></ng-content></span>
    </button>
</ng-container>
