import {JsonProperty, JsonType} from 'ta-json';
import {MotivationsCustomField} from './motivations-custom-field';

export class MotivationsSettingsCustomField {
    @JsonProperty('id')
    @JsonType(Number)
    id: number = null;

    @JsonProperty('is_display')
    @JsonType(Boolean)
    isDisplay: boolean;

    @JsonProperty('field')
    @JsonType(MotivationsCustomField)
    customField: MotivationsCustomField = null;
}
