<section class="dialog">
    <header class="dialog__header">
        <h3 class="dialog__title">{{ title }}</h3>
        <mat-icon class="close" (click)="close()">close</mat-icon>
    </header>


    <div class="content kit">
        <div class="selected" *ngIf="selectedPositions.length">
            <h3 class="selected__title">Выбраны</h3>
            <mat-chip-list #chipList>
                <mat-chip *ngFor="let item of selectedPositions"
                          [removable]="true"
                          (removed)="onDeletePosition(item)">
                    <span class="txt">
                    <ng-container>{{ item.name ? item.name : item.title }}</ng-container>
                    </span>

                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
            </mat-chip-list>
        </div>

        <app-search-mat class="search" (textChange)="onSearchInputPosition($event)"></app-search-mat>
        <app-position-list [searchStr]='searchStr'
                           [canAdd]="false"
                           [positionFolders]="positionFolders"
                           [folders]="folders"
                           [selectedPositions]="selectedPositions"
                           [sortedPositions]="sortedPositions"
                           [sortedFolders]="sortedFolders"
                           (onSelectedChanges)="onSelectedChanges($event)"></app-position-list>

    </div>


    <footer class="dialog__footer">
        <div class="position _root">
            <a class="link" (click)="onCreateClick()"><mat-icon class="icon">add</mat-icon>Создать должность</a>
        </div>
        <div class="save-buttons">
            <app-button class="button" type="reset" buttonType="outlined" title="Отменить" (click)="close()"></app-button>
            <app-button class="button" type="submit" (click)="submit()" title="Сохранить"></app-button>
        </div>
    </footer>

    <app-spinner class="loader" [isSpinnerOn]="true" *ngIf="!isLoaded"></app-spinner>
</section>
