import {Component, HostListener, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {OnboardingService} from '../../../../services/onboarding.service';
import {DeviceDetectorService} from 'ngx-device-detector';

export interface SlideInfo {
    src: string;
    src2x: string;
}

@Component({
    selector: 'app-onboarding-wnd',
    templateUrl: './onboardind-wnd.component.html',
    styleUrls: ['./onboarding-wnd.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class OnboardingWndComponent implements OnInit {
    constructor(
        private onboarding: OnboardingService,
        private deviceDetector: DeviceDetectorService,
    ) {
        this.isMobile = deviceDetector.isMobile();
    }

    @Input() mainSlider = false;
    @Input() isVisible = false;

    owlOptions = {
        loop: false,
        autoplay: false,
        dots: true,
        navSpeed: 700,
        items: 1,
        center: true,
        autoWidth: true,
        autoHeight: false,
        responsive: false,
        navText: [
            '<img src=\'/assets/icons/arrow.svg\'/>',
            '<img src=\'/assets/icons/arrow.svg\'/>'
        ],
        nav: true
    } as any;
    @Input() key: string;
    @Input() slides: SlideInfo[];
    isMobile = false;

    @ViewChild('owlCarousel', { static: false }) owlCarousel: any;

    // Управление через клавиатуру
    @HostListener('document:keydown.escape', ['$event']) onLEscapeClick() {
        if (this.isVisible) {
            this.closeFullScreen();
        }
    }

    @HostListener('document:keydown.arrowRight', ['$event']) onLeftArrowClick(event: KeyboardEvent) {
        if (this.isVisible) {
            this.owlCarousel.next();
        }
    }

    @HostListener('document:keydown.arrowLeft', ['$event']) onRightArrowClick(event: KeyboardEvent) {
        if (this.isVisible) {
            this.owlCarousel.prev();
        }
    }

    ngOnInit() {
        if (this.mainSlider) {
            this.owlOptions = {...this.owlOptions, items: 2};
        } else {
            this.owlOptions = {...this.owlOptions, autoHeight: false};
        }
    }

    closeFullScreen() {
        if (this.key) {
            this.onboarding.setViewed(this.key, true);
        }
    }

    stopPropagation($event: MouseEvent) {
        $event.stopPropagation();
    }


    backToCommonHint(){
        this.onboarding.setViewed(this.key, true);
        this.onboarding.setViewed('common', false);

    }
    getSlideId(i: number) {
        return `slide-${i}`;
    }

    getSrcSet(slide: SlideInfo) {
        if (!slide.src2x) {
            return undefined;
        }

        return slide.src + ', ' + slide.src2x + ' 2x';
    }
}
