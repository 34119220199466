import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'SortById'
})
export class SortByIdPipe implements PipeTransform {
    transform(value: any[]): any[] {
        return value?.sort((n1,n2) =>
        {
            return n1?.id - n2?.id;
        });
    }

}
