import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-link-edit-form',
  templateUrl: './link-edit-form.component.html',
  styleUrls: ['./link-edit-form.component.scss']
})
export class LinkEditFormComponent implements OnInit {

  public formGroup: UntypedFormGroup = null;
  public title = 'Редактирование ссылки';

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit(): void {
    this.formGroup = new UntypedFormGroup({
      name: new UntypedFormControl(this.data.name),
        url: new UntypedFormControl(this.data.url)
    });
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    if (this.formGroup.controls['url'].value.trim() !== '') {
      this.dialogRef.close({name:this.formGroup.controls['name'].value, url:this.formGroup.controls['url'].value});
    }
  }

}
