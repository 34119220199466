import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular";
import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

import packageJson from '../package.json';

if (environment.production) {
    Sentry.init({
        dsn: "https://bf19b43cc37e6990d2a156a411f521f4@sentry.myradius.ru/2",
        integrations: [
            // Registers and configures the Tracing integration,
            // which automatically instruments your application to monitor its
            // performance, including custom Angular routing instrumentation
            Sentry.browserTracingIntegration(),

            // Registers the Replay integration,
            // which automatically captures Session Replays
            //Sentry.replayIntegration(),
        ],

        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/myradius\.ru\/api/, /^https:\/\/development\.myradius\.ru\/api/],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: environment.production ? 0.1 : 1.0,

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,

        // указываем какой инвайромент слать
        environment: environment.production ? 'production' : 'dev',

        // debug: !environment.production,

        // сюда можно трекать версию
        release: "portal@" + packageJson.version,
    });
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then(success => console.log(`Bootstrap success`))
    .catch(err => console.error(err));
