import {JsonObject, JsonProperty, JsonType} from 'ta-json';

@JsonObject()
export class File {
    @JsonProperty('id')
    @JsonType(Number)
    id?: number;

    @JsonProperty('name')
    @JsonType(String)
    name?: string;

    @JsonProperty('photo')
    @JsonType(String)
    photo?: string;

    @JsonProperty('as_file')
    @JsonType(Boolean)
    asFile?: boolean;

    @JsonProperty('file_name')
    @JsonType(String)
    fileName?: string;

    @JsonProperty('created_by')
    @JsonType(String)
    createdBy?: string;

    @JsonProperty('is_deletable')
    @JsonType(Boolean)
    isDeletable?: boolean;

    @JsonProperty('type')
    @JsonType(String)
    type?: FileType;

    @JsonProperty('mime_type')
    @JsonType(String)
    mime?: string;

    @JsonProperty('size')
    @JsonType(Number)
    size?: number;

    @JsonProperty('entity_id')
    @JsonType(String)
    entityId?: string;

    constructor(
        id?: number,
        name?: string,
        createdBy?: string,
        isDeletable?: boolean,
        type?: FileType,
        fileName?: string,
        mime?: string,
        entityId?: string,
        size?: number
    ) {
        this.id = id;
        this.name = name;
        this.createdBy = createdBy;
        this.isDeletable = isDeletable;
        this.type = type;
        this.fileName = fileName;
        this.mime = mime;
        this.entityId = entityId;
        this.size = size;
    }
}

/**
 * Список статусов
 */
export enum FileType {
    IMAGE = 'IMAGE',
    FILE = 'FILE'
}

export function getExtension(name: string) {
    let parts;
    return (parts = name.split('/').pop().split('.')).length > 1 ? parts.pop() : '';
}

export function getFileExtensionWidget(name) {
    let extension;

    switch (getExtension(name)) {
        case 'ai':
            extension = 'ai';
            break;
        case 'avi':
            extension = 'avi';
            break;
        case 'bmp':
            extension = 'bmp';
            break;
        case 'bmp':
            extension = 'bmp';
            break;
        case 'css':
            extension = 'css';
            break;
        case 'doc':
            extension = 'doc';
            break;
        case 'docx':
            extension = 'doc';
            break;
        case 'gif':
            extension = 'gif';
            break;
        case 'html':
            extension = 'html';
            break;
        case 'jpg':
            extension = 'jpg';
            break;
        case 'js':
            extension = 'js';
            break;
        case 'midi':
            extension = 'midi';
            break;
        case 'mov':
            extension = 'mov';
            break;
        case 'mp3':
            extension = 'mp3';
            break;
        case 'mpg':
            extension = 'mpg';
            break;
        case 'pdf':
            extension = 'pdf';
            break;
        case 'png':
            extension = 'png';
            break;
        case 'ppt':
            extension = 'ppt';
            break;
        case 'psd':
            extension = 'psd';
            break;
        case 'svg':
            extension = 'svg';
            break;
        case 'tif':
            extension = 'tif';
            break;
        case 'txt':
            extension = 'txt';
            break;
        case 'wmv':
            extension = 'wmv';
            break;
        case 'xls':
            extension = 'xls';
            break;
        case 'xlsx':
            extension = 'xls';
            break;
        case 'xml':
            extension = 'xml';
            break;
        case 'zip':
            extension = 'zip';
            break;
        default:
            extension = 'file';
            break;
    }

    return extension;
}
