export function maskedBackspaceFix($event: KeyboardEvent) {
    const key = $event.key;
    const input = $event.target as HTMLInputElement;
    const pos = input.selectionStart;

    if (key === 'Backspace' && pos === 2) {
        input.selectionStart = 1;
        input.selectionEnd = 1;
    }
}
