import {JsonProperty, JsonType} from 'ta-json';
import {MotivationsSettingsCustomField} from './motivations-settings-custom-field';

export class MotivationsSettings {
    @JsonProperty('employment_type')
    @JsonType(Boolean)
    employmentType: boolean;

    @JsonProperty('periodicity')
    @JsonType(Boolean)
    periodicity: boolean;

    @JsonProperty('accrual_type')
    @JsonType(Boolean)
    accrualType: boolean;

    @JsonProperty('date_finish')
    @JsonType(Boolean)
    dateFinish: boolean;

    @JsonProperty('salary')
    @JsonType(Boolean)
    salary: boolean;

    @JsonProperty('variable_path')
    @JsonType(Boolean)
    variablePath: boolean;

    @JsonProperty('note')
    @JsonType(Boolean)
    note: boolean;

    @JsonProperty('custom_fields_settings')
    @JsonType(MotivationsSettingsCustomField)
    customFieldsSettings: MotivationsSettingsCustomField[] = [];
}
