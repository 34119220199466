import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {JSON} from 'ta-json';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {
  viewedHints$ = new BehaviorSubject({});
  loadHints$ = new BehaviorSubject(false);
  currentScreen$ = new BehaviorSubject(null);

    constructor() {}

  loadViewedHints() {
    const json = localStorage.getItem('hints');

    if (json) {
      this.viewedHints$.next(JSON.parse(json));
    }
  }

  setViewed(key, val) {
    const n = this.viewedHints$.getValue();
    n[key] = val;
    this.viewedHints$.next(n);
  }
    // introJS = introJs();
    showBtns = false;

   /* startTour(){
       // const IntroJs = require('../../../../../../../node_modules/intro.js/intro.js');
        const IntroJs = require('../../../node_modules/intro.js/intro.js');
        let intro = IntroJs();
        intro.setOptions({
            steps: [
                /!*{
                    intro: "Welcome!",
                    position: 'centre'
                },*!/
                {

                    title: 'Раздел задачи',
                    element: document.querySelector('.tasksFirstStep'),
                    intro: 'Click to view the buttons',
                    position: 'В разделе задачи вы можете создать, редактировать и просмотреть свои задачи и задачи своих сотрудников'
                },
                {

                    title: 'Доски',
                    element: document.querySelector('.firstStep'),
                    intro: 'В разделе “Доски” вы можете смотреть или редактировать ранее созданные доски или создать свою. Также вы можете импортировать задачи из Trello и Jira.',
                    position: 'right'
                },
                {

                    title: 'Быстрый фильтр по своим задачам',
                    element: document.querySelector('.secondStep'),
                    intro: 'Быстрый фильтр по задачам, в которых вы являетесь исполнителем или наблюдателем'
                }/!*,
                {

                    title: 'Режим отображения задач',
                    element: document.querySelector('.secondStep'),
                    intro: 'Здесь вы можете выбрать в каком режиме будут отображаться задачи: \n' +
                    'В виде иерархии\n' +
                    'В виде списка\n' +
                    'В виде канбан доски\n' +
                    'В виде спринта\n' +
                    '\n' +
                    'Также, последним пунктом, вы можете открыть статистику по задачам в разрезе сотрудников'
                },
                {

                    title: 'Архив',
                    element: document.querySelector('.secondStep'),
                    intro: 'В архиве вы можете посмотреть выполненные задачи '
                },
                {

                    title: 'Поиск и фильтрация',
                    element: document.querySelector('.secondStep'),
                    intro: 'С помощью поиска и фильтрации вы можете найти любую из действующих задач'
                },
                {

                    title: 'Создание задач',
                    element: document.querySelector('.secondStep'),
                    intro: 'При нажатии кнопки “Добавить задачу” вы сможете создать новую задачу. Подробнее мы расскажем вам в обучении по разделу “Задачи”'
                },
                {

                    title: 'Настройки',
                    element: document.querySelector('.secondStep'),
                    intro: 'В настройках вы можете изменить отображение задач и выбрать нужные режимы отображения задач'
                }*!/

            ],
            showProgress: true,
            disableInteraction: false
        });

        intro.start();
        intro.onbeforechange((targetElement) => {
            console.log("new step");
            // this.intro.refresh();

        });

    }

    getElement(){
        console.log('.....', document.querySelector('.first'));
        return document.querySelector('.first');
    }*/



}
