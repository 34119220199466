import {Directive, ElementRef, Input} from '@angular/core';

@Directive({
  selector: '[autoFocus]'
})
export class AutoFocusDirective {

    private autofocus;
    constructor(private el: ElementRef) {
    }

    ngOnInit() {
        if (this.autofocus || typeof this.autofocus === 'undefined') {
this.el.nativeElement.focus();
}
    }

    @Input() set autoFocus(condition) {
        this.autofocus = condition != false;
    }

}
