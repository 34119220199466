<div class="inner">
    <div class="head">

        <app-user-avatar class="user-img"  *ngIf="user" [matTooltip]="position ? position.title : user.shortName" [user]="user">
        </app-user-avatar>

        <span class="role" *ngIf="isRole">
            <mat-icon class="icon" [svgIcon]="'radius_mask'"></mat-icon>
        </span>

        <span class="user-img _empty" *ngIf="!user" [matTooltip]="position?.title">
            <mat-icon class="icon">person_add</mat-icon>
        </span>
    </div>

    <div class="remove" *ngIf="!disabled"  [matTooltip]="removeTooltip" (click)="onRemoveClick($event)">
        <mat-icon class="icon">close</mat-icon>
    </div>

    <div class="body" *ngIf="subdivision || isPersonalInfo">
        <span class="title" *ngIf="user">
            {{ user.name }}
        </span>

        <span class="title" *ngIf="!user">
            Вакансия
        </span>

        <span class="subtitle"  *ngIf="subdivision">
            {{ subdivision.name }}
        </span>
        <span class="subtitle" *ngIf="position">
            {{ position.title }}
        </span>
    </div>

    <div class="body" *ngIf="position && !isPersonalInfo">
        <span class="title">
            {{ position.title }}
        </span>

        <span class="subtitle" *ngIf="user">
            {{ user.name }}
        </span>
    </div>

    <app-rating-number *ngIf="isNpm && rating" [rating]="rating" class="rating"></app-rating-number>
    <mat-icon class="star" matTooltip="Административное подчинение">star</mat-icon>

</div>