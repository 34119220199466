<ng-container *ngFor="let messenger of messengers, let i = index">
    <div class="messenger-container">
        <label class="label kit">
            <div class="email-title">
                <span class="label__txt">
                    Мессенджер
                </span>
            <mat-icon class="icon"  (click)="remove(i)" *ngIf="i != messengers.length">delete_outline</mat-icon>
            </div>
            <mat-select class="select"
                        (selectionChange)="onTypeSelected($event, i)"
                        [(ngModel)]="messenger.type">
                <mat-select-trigger class="select-trigger">
                    <mat-icon class="icon" [svgIcon]="messengerIcon(messenger.type)"></mat-icon>
                    <span> {{ messengerName(messenger.type) }}</span>
                </mat-select-trigger>
                <mat-option *ngFor="let messengerType of allMessengers" [value]="messengerType">
                    {{ messengerName(messengerType) }}
                </mat-option>
            </mat-select>
        </label>

        <label class="label kit" *ngIf="messenger.type">
            <span class="label__txt">
                Логин
            </span>
            <input class="input"
                   [(ngModel)]="messenger.login"
                   (keyup)="onLoginChange($event, i)">
        </label>
    </div>
</ng-container>
<label class="label kit" *ngIf="!messengers.length || (messengers[messengers.length - 1].type && messengers[messengers.length - 1].login)">
    <span class="label__txt"></span>
    <button class="add-phone" (click)="add()">Добавить мессенджер
        <mat-icon class="icon">add</mat-icon>
    </button>
</label>
