import {Component, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {OnboardingService} from '../../../../../services/onboarding.service';
import {Subject} from 'rxjs';
import {AuthService} from '../../../../../services/auth.service';
import IntroJs from 'intro.js/intro.js';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {SkipConfirmDialogComponent} from '../skip-confirm-dialog/skip-confirm-dialog.component';
import {SharedService} from '../../../services/shared.service';


@Component({
    selector: 'app-onboarding-tasks',
    templateUrl: './onboarding-tasks.component.html',
    styleUrls: ['./onboarding-tasks.component.scss']
})
export class OnboardingTasksComponent implements OnInit, OnDestroy {
    private destroyed = new Subject<void>();
    isVisible = false;
    compliteIntro = false;
    ONBOARDING_KEY = 'tasks';

    slides = [];
    isAdmin = false;
    inited = false;

    constructor(
        private onboarding: OnboardingService,
        private auth: AuthService,
        private shared: SharedService,
        public dialog: MatDialog
    ) {
    }

    ngOnInit(): void {
        this.onboarding.currentScreen$.next(this.ONBOARDING_KEY);

        this.onboarding.viewedHints$.pipe(takeUntil(this.destroyed))
            .subscribe(val => {
                if (val.hasOwnProperty(this.ONBOARDING_KEY)) {
                    this.isVisible = val[this.ONBOARDING_KEY] === false;
                } else {
                    this.isVisible = false;
                }
            });

        this.auth.rights$.pipe(takeUntil(this.destroyed))
            .subscribe(val => {
                this.updateRights(val);
                this.setSlides();
            });
    }

    startTour() {
        let intro = IntroJs();
        intro.setOptions({
            steps: [
                {

                    title: 'Раздел задачи',
                    element: document.querySelector('.tasksFirstStep'),
                    intro: 'В разделе задачи вы можете создать, редактировать и просмотреть свои задачи и задачи своих коллег',
                    position: 'right'
                },
                {

                    title: 'Доски',
                    element: document.querySelector('.tasksSecondStep'),
                    intro: 'В разделе “Доски” вы можете смотреть и редактировать ранее созданные доски или создать новую.  Также здесь находится ваша “Личная доска”, на которой дублируются ваши задачи, где вы исполнитель и наблюдатель.',
                    position: 'right'
                },
                {

                    title: 'Быстрый фильтр по своим задачам',
                    element: document.querySelector('.tasksThirdStep'),
                    intro: 'Быстрый фильтр по задачам, в которых вы являетесь исполнителем или наблюдателем'
                },
                {

                    title: 'Режим отображения задач',
                    element: document.querySelector('.tasksFourthStep'),
                    intro: 'Здесь вы можете выбрать в каком режиме будут отображаться задачи:' +
                    '<br><ul>' +
                    '<li>В виде иерархии</li>' +
                    '<li>В виде списка</li>' +
                    '<li>В виде канбан доски</li>' +
                    '<li>В виде спринта</li>' +
                    '</ul>' +
                    'Также, последним пунктом, вы можете открыть статистику по задачам в разрезе сотрудников'
                },
                {

                    title: 'Архив',
                    element: document.querySelector('.tasksFifthStep'),
                    intro: 'В архиве вы можете посмотреть выполненные задачи, которые были перенесены в архив.'
                },
                {

                    title: 'Поиск и фильтрация',
                    element: document.querySelector('.tasksSixthStep'),
                    intro: 'С помощью поиска и фильтрации вы можете найти любую необходимую информацию и отфильтровать по задачам.'
                },
                {

                    title: 'Создание задач',
                    element: document.querySelector('.tasksSeventhStep'),
                    intro: 'При нажатии кнопки “Добавить задачу” вы сможете создать новую задачу. ',
                    // 'Подробнее мы расскажем вам в обучении по разделу “Задачи”',
                    position: 'left'
                },
                {

                    title: 'Настройки',
                    element: document.querySelector('.tasksEighthStep'),
                    intro: 'В настройках вы можете изменить отображение задач и выбрать нужные режимы отображения задач',
                    position: 'left'
                }

            ],
            showStepNumbers: true,
            showBullets: false,
            nextLabel: 'Далее',
            prevLabel: 'Назад',
            skipLabel: 'Пропустить введение',
            stepNumbersOfLabel: '/',
            doneLabel: 'Перейти к обучению',
            overlayOpacity: 0
        });

        intro.start();

        intro.oncomplete(el => {
            this.compliteIntro = true;
        });

        intro.onbeforeexit((targetElement) => {
            if (!this.compliteIntro) {
                const dialogRef = this.dialog.open(SkipConfirmDialogComponent,{data: {title: 'введение'}});

                return dialogRef.afterClosed().toPromise()
                    .then(result => {
                        return result;
                    });
            }
        });
    }

    openLearning() {
        this.shared.openTaskLearning$.next(true);
    }

    openAnotherIntro(name = '') {

        if (name) {
            name = 'Вы можете вернуться к ' + name + 'в любой момент нажав на знак вопроса, находясь в разделе “Задачи”';
        }

        if (this.compliteIntro) {
            this.compliteIntro = false;
        }

        let introSkip = IntroJs();
        introSkip.setOptions({
            steps:[
                {
                    element: document.querySelector('.skipOnboarding'),
                    intro: name ? name : 'Вы можете вернуться к введению в любой момент нажав на знак вопроса, находясь в разделе “Задачи”',
                    title: name ? 'Пропустить обучение' : 'Пропустить введение',
                    position: 'right'
                }
            ],
            buttonClass: 'skip',
            highlightClass: 'skip',
            showStepNumbers: false,
            showBullets: false,
            skipLabel: null,
            hidePrev: true,
            doneLabel: 'Завершить'
        });

        introSkip.start();

        introSkip.oncomplete(el => {
            introSkip = null;
        });
    }

    closeFullScreen() {
        this.onboarding.setViewed('tasks', true);
    }

    ngOnDestroy() {
    }

    updateRights(rights) {
        this.isAdmin = rights?.isAdministrator;
        this.inited = true;
    }


    private setSlides() {
        const slidesAdmin = [
            {
                src: '/assets/images/onboarding/tasks-1.png',
                src2x: '/assets/images/onboarding/tasks-1-2x.png',
            },
            {
                src: '/assets/images/onboarding/tasks-2.png',
                src2x: '/assets/images/onboarding/tasks-2-2x.png',
            },
            {
                src: '/assets/images/onboarding/tasks-3.png',
                src2x: '/assets/images/onboarding/tasks-3-2x.png',
            },
            {
                src: '/assets/images/onboarding/tasks-4.png',
                src2x: '/assets/images/onboarding/tasks-4-2x.png',
            },
            {
                src: '/assets/images/onboarding/tasks-5.png',
                src2x: '/assets/images/onboarding/tasks-5-2x.png',
            },
            {
                src: '/assets/images/onboarding/tasks-6.png',
                src2x: '/assets/images/onboarding/tasks-6-2x.png',
            },
            {
                src: '/assets/images/onboarding/tasks-7.png',
                src2x: '/assets/images/onboarding/tasks-7-2x.png',
            },
            {
                src: '/assets/images/onboarding/tasks-8.png',
                src2x: '/assets/images/onboarding/tasks-8-2x.png',
            },
        ];

        const slidesUser = [
            {
                src: '/assets/images/onboarding/tasks-4.png',
                src2x: '/assets/images/onboarding/tasks-4-2x.png',
            },
            {
                src: '/assets/images/onboarding/tasks-5.png',
                src2x: '/assets/images/onboarding/tasks-5-2x.png',
            },
            {
                src: '/assets/images/onboarding/tasks-6.png',
                src2x: '/assets/images/onboarding/tasks-6-2x.png',
            },
            {
                src: '/assets/images/onboarding/tasks-7.png',
                src2x: '/assets/images/onboarding/tasks-7-2x.png',
            },
            {
                src: '/assets/images/onboarding/tasks-8.png',
                src2x: '/assets/images/onboarding/tasks-8-2x.png',
            },
        ];

        if (this.isAdmin) {
            this.slides = slidesAdmin;
        } else {
            this.slides = slidesUser;
        }
    }
}
