import {JsonProperty, JsonType} from 'ta-json';
import {UserShort} from '../../../../models/user';

export class MotivationsPositionsCoordinators {
    @JsonProperty('id')
    @JsonType(Number)
    id: number;

    @JsonProperty('title')
    @JsonType(String)
    title: string;

    @JsonProperty('users')
    @JsonType(UserShort)
    users: UserShort[] = [];

    constructor(
        id: number,
        title: string,
        users: UserShort[],
    ) {
        this.id = id;
        this.title = title;
        this.users = users;
    }
}
