import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Rating} from '../../models/rating';
import {CompanyService} from '../../../phonebook/services/company.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-rating-number',
    templateUrl: './rating-number.component.html',
    styleUrls: ['./rating-number.component.scss']
})
export class RatingNumberComponent implements OnInit, OnDestroy {
    @Input() rating: Rating = null;
    public settings = null;
    private destroyed = new Subject<void>();

    constructor(
        private company: CompanyService) {
    }

    ngOnInit() {
        this.company.$settings
            .pipe(takeUntil(this.destroyed))
            .subscribe(res => {
                if (res) {
                    this.settings = res;
                }
            });
    }

    get green() {
        const min = this.settings?.green_from > 10 ? this.settings.green_from / 10 : this.settings?.green_from;
        return min <= this.rating.value;
    }

    get orange() {
        const min = this.settings?.orange_from > 10 ? this.settings.orange_from / 10 : this.settings?.orange_from;
        const max = this.settings?.orange_to > 10 ?this.settings.orange_to / 10 : this.settings?.orange_to;
        return min <= this.rating.value && max >= this.rating.value;
    }

    get red() {
        const max = this.settings?.red_to > 10 ? this.settings.red_to / 10 : this.settings?.red_to;
        return this.rating.value <= max;
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }
}
