import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Task} from '../../../../../models/task';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-subtasks-item',
    templateUrl: './subtasks-item.component.html',
    styleUrls: ['./subtasks-item.component.scss']
})
export class SubtasksItemComponent implements OnInit, OnDestroy {

    // Модель задачи
    @Input() public task: Task = null;
    @Input() public disabled = false;
    @Input() public remove = false;
    @Input() public add = false;
    @Input() public searchTerm = '';
    @Input() public canRemove = false;
    @Input() public isTemplate = false;
    @Output() public onTaskClick = new EventEmitter();
    // Триггер смерти компонента
    private destroyed = new Subject<void>();

    constructor() {
    }

    onTaskInEditClick(event, action: String) {
        if (this.canRemove) {
            event.stopPropagation();
            this.onTaskClick.emit({action, task: this.task});
        }
    }

    ngOnInit(): void {
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }
}
