import {Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {ApiService} from '../../services/api.service';
import {combineLatest, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ChatsService} from '../../services/chats.service';
import {GlobalNotificationCenterService} from '../../services/global-notification-center.service';
import {Location} from '@angular/common';
import {AppsService} from '../../services/apps.service';

@Component({
    selector: 'app-apps',
    templateUrl: './apps.component.html',
    styleUrls: ['./apps.component.scss']
})
export class AppsComponent implements OnInit, OnDestroy {

    appUrl: string;
    dataLoaded = false;
    private destroyed = new Subject<void>();
    companyId = null;
    hideFloatingMenu = false;

    constructor(
        private auth: AuthService,
        private api: ApiService,
        private sanitizer: DomSanitizer,
        private route: ActivatedRoute,
        private router: Router,
        private hostElement: ElementRef,
        private chatsApi: ChatsService,
        private ncService: GlobalNotificationCenterService,
        private location: Location,
        private appsService: AppsService,
    ) {
    }

    async ngOnInit() {
        this.auth.currentUser$.pipe(
            takeUntil(this.destroyed)
        ).subscribe(user => {
            this.companyId = user.company?.id;
        });

        combineLatest(this.api.appList$, this.route.params, this.route.queryParams)
            .pipe(takeUntil(this.destroyed))
            .subscribe(([appList, params, queryParams]) => {
                if (appList.length > 0) {
                            const appId = params['appId'];
                            if (appId) {
                                const app = appList.find(el => el.name === appId);
                                if (app) {
                                    this.appUrl = app.url + `/?app=${app.id}`;

                                    if (app.name === 'finance-1c') {
                                        this.hideFloatingMenu = true;
                                    }
                                } else {
                                    this.router.navigate(['404']);
                                }
                                this.dataLoaded = true;
                                this.setSrc(queryParams);
                                const params = new URLSearchParams(queryParams);
                                if (Object.keys(params).length) {
                                    this.location.go('apps/' + appId);
                                }
                            }
                }
            });

    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }

    setSrc(queryParams) {
        this.hostElement.nativeElement.querySelector('iframe').src = this.sanitizeUrl(queryParams);
        this.appsService.currentFrame = this.hostElement.nativeElement.querySelector('iframe');
    }

    public sanitizeUrl(queryParamsOld) {
        if (this.auth.auth.apiKey) {
            const queryParams = new URLSearchParams(queryParamsOld);
            // смарт-контракт
            if (queryParams.get('sc')) {
                return `${this.appUrl}&api_key=${this.auth.auth.apiKey}&sc=${queryParams.get('sc')}`;
            }
            // заявка на оплату
            if (queryParams.get('pa')) {
                return `${this.appUrl}&api_key=${this.auth.auth.apiKey}&pa=${queryParams.get('pa')}`;
            }
            // счет
            if (queryParams.get('inv')) {
                return `${this.appUrl}&api_key=${this.auth.auth.apiKey}&inv=${queryParams.get('inv')}`;
            }
            if (queryParams.get('task')) {
                return `${this.appUrl}&api_key=${this.auth.auth.apiKey}&task=${queryParams.get('task')}`;
            }
            if (queryParams.get('guidebook')) {
                return `${this.appUrl}&api_key=${this.auth.auth.apiKey}&guidebook=${queryParams.get('guidebook')}`;
            }
            return `${this.appUrl}&api_key=${this.auth.auth.apiKey}`;
        } else {
            return null;
        }
    }

}
