import {User} from '../models/user';

export function sortUsers(a: User, b: User) {
    const an = a?.name || '1';
    const bn = b?.name || '2';

    const biggest = an.length > bn.length ? an : bn;

    if (an.length === 0 && bn.length === 0) return 0;
    if (an.length === 0) return 1;
    if (bn.length === 0) return -1;

    const isCyrillyc = (char) => {
        return char?.match(/[\p{sc=Cyrillic}]+/ug);
    };

    const cmp = (achar, bchar) => {
        if (isCyrillyc(achar) && !isCyrillyc(bchar)) {
            return -1;
        }

        if (!isCyrillyc(achar) && isCyrillyc(bchar)) {
            return 1;
        }

        if (achar == bchar) return 0;
        return achar < bchar ? -1 : 1;
    };

    let cmpResult = 0;

    for (let i = 0; i < biggest.length; ++i) {
        const achar = an.length < i ? '' : an[i];
        const bchar = bn.length < i ? '' : bn[i];
        cmpResult = cmp(achar, bchar);

        if (cmpResult !== 0) return cmpResult;
    }

    return  cmpResult;
}
