import {JsonProperty, JsonType} from 'ta-json';

export class Schedule {
    @JsonProperty('date')
    @JsonType(Date)
    date: Date;

    @JsonProperty('index')
    @JsonType(Number)
    index: number;

    @JsonProperty('status')
    @JsonType(Number)
    status: number;
}
