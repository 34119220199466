import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from '@angular/core';

@Component({
    selector: 'app-assessment',
    templateUrl: './assessment.component.html',
    styleUrls: ['./assessment.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssessmentComponent{

    public value = null;
    @Input() public assessment_type = 'hour';

    constructor(
        private cdr: ChangeDetectorRef
    ) {
    }

    @Input() set data(param: any) {
        this.value = param;
        this.cdr.detectChanges();
    }

    getUserPlaningPoints(points) {
        if (this.assessment_type !== 'hour') {
            return points
        } else {
            return this.generateTimeFormat(points);
        }
    }

    generateTimeFormat(points) {
        let m = points % 60;
        let h = (points - m) / 60;
        let str = ''
        let min = ''

        min = m < 10 && h > 0 ? '0' + m : '' + m;

        str = (h ? h + 'ч ' : '') + (m ? min + 'м' : '');

        return str;
    }
}
