import {JsonProperty, JsonType} from 'ta-json';

export class Tag {
    @JsonProperty('id')
    @JsonType(String)
    @JsonType(Number)
    id?: number;

    @JsonProperty('board_id')
    @JsonType(Number)
    boardId?: number;

    @JsonProperty('name')
    @JsonType(String)
    name?: string;

    @JsonProperty('color')
    @JsonType(String)
    color?: string;

    @JsonProperty('visible')
    @JsonType(Boolean)
    visible?: boolean;

    @JsonProperty('on_board_default')
    @JsonType(Boolean)
    on_board_default?: boolean;

    @JsonProperty('task_group')
    @JsonType(Boolean)
    taskGroup?: boolean;

    constructor(id: number,
                boardId?: number,
                name: string = '',
                color: string = '_grey',
                visible: boolean = true,
                on_board_default: boolean = false,
                taskGroup: boolean = false
    ) {
        this.id = id;
        this.boardId = boardId;
        this.name = name;
        this.color = color;
        this.visible = visible;
        this.on_board_default = on_board_default;
        this.taskGroup = taskGroup;
    }
}

/**
 * Список статусов
 */
export enum TagsColor {
    RED = '#FF0000',
    PINK = '#EA1A65',
    BLUE = '#1985FF',
    PURPLE = '#913E98',
    CYAN = '#1BBDD4',
    DARK_GREEN = '#14988B',
    GREEN = '#69BF46',
    YELLOW = '#d49300',
    ORANGE = '#FFA726',
    GREY = '#ABB6BB'
}

export enum TagsBackground {
    RED = '#FFE0E0',
    PINK = '#FFE0EB',
    BLUE = '#DBE4FF',
    PURPLE = '#E5DBFF',
    CYAN = '#C3F9FD',
    DARK_GREEN = '#B0F8DE',
    GREEN = '#D3F9D8',
    YELLOW = '#FFF3BF',
    ORANGE = '#FFE6C8',
    GREY = '#E9EFF4'
}

/**
 * Маппинг статусов
 */
export const tagsMapping = Object.freeze([
    {
        background: TagsBackground.RED,
        color: TagsColor.RED,
        name: '_red',
    },
    {
        background: TagsBackground.PINK,
        color: TagsColor.PINK,
        name: '_pink',
    },
    {
        background: TagsBackground.PURPLE,
        color: TagsColor.PURPLE,
        name: '_purple',
    },
    {
        background: TagsBackground.BLUE,
        color: TagsColor.BLUE,
        name: '_blue',
    },
    {
        background: TagsBackground.CYAN,
        color: TagsColor.CYAN,
        name: '_cyan',
    },
    {
        background: TagsBackground.DARK_GREEN,
        color: TagsColor.DARK_GREEN,
        name: '_dark_green',
    },
    {
        background: TagsBackground.GREEN,
        color: TagsColor.GREEN,
        name: '_green',
    },
    {
        background: TagsBackground.YELLOW,
        color: TagsColor.YELLOW,
        name: '_yellow',
    },
    {
        background: TagsBackground.ORANGE,
        color: TagsColor.ORANGE,
        name: '_orange',
    },
    {
        background: TagsBackground.GREY,
        color: TagsColor.GREY,
        name: '_grey',
    },
]);
