import {JsonElementType, JsonProperty, JsonType} from 'ta-json';
import {Rating} from '../../shared/models/rating';
import {FunctionInfo} from './functions/function-info';
import {UserPosition} from './user-position';
import {SlotSubdivision} from './slot-subdivision';
import {FunctionQuestion} from './functions/function-question';

export class PositionFolderShort  {
    @JsonProperty('id')
    @JsonType(Number)
    id: number = null;

    @JsonProperty('title')
    @JsonType(String)
    title: string = null;
}


export class Position {
    @JsonProperty('id')
    @JsonType(Number)
    id: number = null;

    @JsonProperty('title')
    @JsonType(String)
    title: string = null;

    @JsonProperty('description')
    @JsonType(String)
    description: string = null;

    @JsonProperty('destiny')
    @JsonType(String)
    destiny: string = null;

    @JsonProperty('tags')
    @JsonType(Array)
    tags = [];

    @JsonProperty('is_delete')
    @JsonType(Boolean)
    isDelete = false;

    @JsonProperty('users')
    @JsonElementType(UserPosition)
    users: UserPosition[] = [];

    @JsonProperty('slots')
    @JsonElementType(SlotSubdivision)
    slots: SlotSubdivision[] = [];

    @JsonProperty('rating')
    @JsonElementType(Rating)
    rating: Rating = null;

    @JsonProperty('functions')
    @JsonElementType(FunctionInfo)
    functions: FunctionInfo[] = [];

    @JsonProperty('group')
    @JsonElementType(PositionFolderShort)
    group: PositionFolderShort = null;

    @JsonProperty('group_id')
    @JsonElementType(Number)
    group_id = 0;

    @JsonProperty('chart_data')
    @JsonType(Array)
    chart_data = [];

    @JsonProperty('questions')
    @JsonElementType(FunctionQuestion)
    questions: FunctionQuestion[];

    constructor(
        id?: number,
        title?: string,
        description?: string,
        destiny?: string,
        isDelete?: boolean,
        users: UserPosition[] = [],
        functions: FunctionInfo[] = []
    ) {
        this.id = id;
        this.title = title;
        this.description = description;
        this.destiny = destiny;
        this.isDelete = isDelete;
        this.users = users;
        this.functions = functions;
    }

    sortPositions(list) {
        list.sort(function (a, b) {
            if (a.title.compare < b.title) {
                return -1;
            }
            if (a.title > b.title) {
                return 1;
            }
            return 0;
        });

        list.sort((a, b) => a.title.localeCompare(b.title, 'ru'));

        list.sort(function (a, b) {
            if (b.id < 0) {
                return -1;
            }

            return 1;
        });
    }

    makeTreeNode(list) {
        const map = {};
        const roots = [];

        if (list.length === 1) {
            return list;
        }

        for (let i = 0; i < list.length; i += 1) {
            map[list[i].id] = i;
            list[i]['children'] = [];
        }

        for (let i = 0; i < list.length; i += 1) {
            const node = list[i];
            if (node.parent_id && node.parent_id !== node.id && list[map[node['parent_id']]]) {
                list[map[node['parent_id']]]['children'] ?
                    list[map[node['parent_id']]]['children'].push(node) :
                    list[map[node['parent_id']]]['children'] = [node];
            } else {
                roots.push(node);
            }
        }

        return roots;
    }

}
