import {UserShort} from '../../../../models/user';
import {JsonObject, JsonProperty, JsonType} from 'ta-json';
import {UsersMotivation} from '../motivations/users-motivation';

@JsonObject()
export class ExecutiveMatrix extends UserShort {
    @JsonProperty('photo')
    @JsonType(String)
    photo?: string;

    @JsonProperty('user_motivation')
    @JsonType(UsersMotivation)
    userMotivation?: UsersMotivation[] = [];

    constructor(
        userShort?: UserShort,
        photo: string = '',
        userMotivation: UsersMotivation[] = []
    ) {
        super(userShort.id, userShort.firstName, userShort.middleName, userShort.lastName, userShort.position, userShort.currentPosition);
        this.userMotivation = userMotivation;
    }
}
