import {Component, inject, OnInit} from '@angular/core';
import {TourService} from 'ngx-ui-tour-md-menu';

// import {default as defaultTemplate} from '!!raw-loader!./examples/default-template.txt';
// import {DELAY_AFTER_NAVIGATION} from '../app.providers';
// import {TuiButtonModule, TuiLinkModule, TuiNotificationModule} from '@taiga-ui/core';
// import {TuiDocPageModule} from '@taiga-ui/addon-doc';

@Component({
  selector: 'app-learning',
  templateUrl: './learning.component.html',
  styleUrls: ['./learning.component.scss']
})
export class LearningComponent implements OnInit {


    public readonly tourService = inject(TourService);

    ngOnInit() {

    }
}
