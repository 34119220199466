import {Component, EventEmitter, Input, Output} from '@angular/core';
import {User, UserSlot} from '../../../../../models/user';
import {ConfigService} from '../../../../../services/config.service';
import {AuthService} from '../../../../../services/auth.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {SubdivisionEditComponent} from '../personal-card-edit/subdivision-edit/subdivision-edit.component';
import {takeUntil} from 'rxjs/operators';
import {ConfirmSmartComponent} from '../../confirm-smart/confirm-smart.component';
import {ApiCompanyService} from '../../../../phonebook/services/api-company.service';
import {GlobalNotificationCenterService} from '../../../../../services/global-notification-center.service';
import {Subdivision} from '../../../../phonebook/models/subdivision';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-personal-position-slot',
    templateUrl: './personal-position-slot.component.html',
    styleUrls: ['./personal-position-slot.component.scss']
})
export class PersonalPositionSlotComponent {

    @Input() public slot: UserSlot = null;
    @Input() public subdivision: Subdivision = null;
    @Input() public showManager: boolean = false;
    @Input() public user: User = null;
    @Input() public isEdit: boolean = false;
    @Input() public isAdmin: boolean = false;
    @Input() public added: boolean = false;
    @Input() public canOpen: boolean = true;
    @Output() public change: EventEmitter<any> = new EventEmitter();
    @Output() public createSlot: EventEmitter<any> = new EventEmitter();
    @Output() public openSlot: EventEmitter<any> = new EventEmitter();

    private destroyed = new Subject<void>();

    public parentManager = null;


    constructor(
        private config: ConfigService,
        private auth: AuthService,
        private dialog: MatDialog,
        private api: ApiCompanyService,
        private notiService: GlobalNotificationCenterService,
    ) {
    }

    ngOnInit(): void {
        if (this.slot && !this.slot.isRole && this.user) {
            this.getUserManager();
        }
    }

    get isNpm() {
        return this.auth.isNpm$.value;
    }

    clickOpen() {
        this.openSlot.emit(this.slot);
    }

    getAvatar() {
        const subdivision = this.subdivision ? this.subdivision : this.slot.subdivision;
        if (subdivision) {
            return this.config.getLogoSrc(subdivision.image, 'small');
        } else {
            return this.config.photoAvatarUser + '/default/small';
        }
    }

    getManagerAvatar() {
        if (this.parentManager) {
            return this.config.getUserAvatarByUser(this.parentManager, 'small');
        } else {
            return this.config.photoAvatarUser + '/default/small';
        }
    }


    addSubdivision() {
        const dialogForm = this.dialog.open(SubdivisionEditComponent, {
            width: '500px',
            height: '680px',
            data: {user: this.user}
        });

        dialogForm.afterClosed().subscribe(result => {
            if (result) {
                this.createSlot.emit(result);
            }
        });
    }

    get removeuserTxt() {
        if (this.slot) {
            return this.slot.isRole ? 'Удалить сотрудника с роли' :
                'Удалить сотрудника с должности';
        }
    }

    removeUser() {
        this.getSubdivisionById(this.slot.subdivision.id);
        const dialogData = {
            title: this.removeuserTxt + '?'
            ,
            buttons: [
                {
                    color: '_grey',
                    name: 'Отмена',
                    action: 'exit',
                    autofocus: false
                },
                {
                    color: '_blue',
                    name: 'Удалить',
                    action: 'delete',
                    autofocus: true
                }]

        };

        const dialogRef = this.dialog.open(ConfirmSmartComponent, {
            data: dialogData
        });

        dialogRef.afterClosed()
            .pipe(takeUntil(this.destroyed))
            .subscribe(result => {
                if (result === 'delete') {
                    const currentSlotIndex = this.subdivision.slots.findIndex(el => el.id === this.slot.id);
                    this.subdivision.slots[currentSlotIndex].user = null;

                    const data = {
                        struct: {
                            id: this.slot.subdivision.id,
                            slots: this.subdivision.slots
                        }
                    };
                    this.edit(data, this.slot.subdivision.id);
                } else if (result === 'exit') {
                    return false;
                } else if (!result) {
                    return false;
                }
            });
    }

    changeToPosition() {
        this.getSubdivisionById(this.slot.subdivision.id);
        const dialogData = {
            title: 'Вы хотите изменить роль на должность?'
            ,
            buttons: [
                {
                    color: '_grey',
                    name: 'Отмена',
                    action: 'exit',
                    autofocus: false
                },
                {
                    color: '_blue',
                    name: 'Заменить на должность',
                    action: 'delete',
                    autofocus: true
                }]

        };

        const dialogRef = this.dialog.open(ConfirmSmartComponent, {
            data: dialogData
        });

        dialogRef.afterClosed()
            .pipe(takeUntil(this.destroyed))
            .subscribe(result => {
                if (result === 'delete') {
                    const currentSlotIndex = this.subdivision.slots.findIndex(el => el.id === this.slot.id);
                    this.subdivision.slots[currentSlotIndex].is_role = false;

                    const data = {
                        struct: {
                            id: this.slot.subdivision.id,
                            slots: this.subdivision.slots
                        }
                    };
                    this.edit(data, this.slot.subdivision.id);
                } else if (result === 'exit') {
                    return false;
                } else if (!result) {
                    return false;
                }
            });
    }

    edit(data, id) {
        this.api.editSubdivision(data, id)
            .pipe(takeUntil(this.destroyed))
            .subscribe(res => {
                this.notiService.handleSuccess('Подразделение изменено');
                this.change.emit(true);
            }, (err) => {
                this.notiService.handleFullError(err);
            });
    }

    getUserManager() {
        if (this.user.subdivision.executives.length > 1) {
            this.parentManager = this.user.subdivision.executives[0].id !== this.user.id ? this.user.subdivision.executives[0] : this.user.subdivision.executives[1];
        } else {
            this.parentManager = this.user.subdivision.executives[0];
        }
    }

    getSubdivisionById(id) {
        this.api.getSubdivisionById(id)
            .pipe(takeUntil(this.destroyed))
            .subscribe(data => {
                this.subdivision = data.subdivision;
            }, (err) => {
                this.notiService.handleFullError(err);
            });
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }

}
