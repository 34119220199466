import {ErrorHandler, Injectable} from '@angular/core';
import * as Sentry from '@sentry/angular';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    sentryErrorHandler = Sentry.createErrorHandler();

    handleError(error: any): void {
        this.sentryErrorHandler.handleError(error);

        const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        console.error(error);

        if (chunkFailedMessage.test(error.message)) {
            window.location.reload();
        }
    }
}
