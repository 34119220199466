<ng-container *ngFor="let account of accounts, let i = index">
    <label class="label kit">
        <div class="label-box">
            <span class="label__txt">
               {{(account.isNotifiable || i === 0) ? 'Email' : 'Дополнительный email'}}
            <mat-icon *ngIf="account.isGmail" class="google" svgIcon="radius_main_gmail"></mat-icon>
            </span>
            <mat-icon class="icon" *ngIf="i > 0 || canDeleteFirst()" (click)="showModalEmail('delete', account, i)">delete_outline</mat-icon>
        </div>
        <ng-container *ngIf="userId">
            <div class="input"
                 disabled
                 (click)="showModalEmail('edit', account)"
                 [placeholder]="i === 0 ? 'example@npmgroup.ru' : 'example@mail.ru'">
                {{account.account}}
            </div>
           <div class="verified-block" *ngIf="!account.isVerified">
                <span class="error">Почта не подтверждена</span>
               <div class="txt" *ngIf="timer[i] > 0">
                   Пригласить повторно через 0:{{timer[i].toString().padStart(2, '0')}}
               </div>
                <a class="invite-again" *ngIf="timer[i] === 0" (click)="sendInvite(account, i)">
                    <mat-icon class="icon">sync</mat-icon>
                    Пригласить  повторно</a>
            </div>
        </ng-container>
      </label>
</ng-container>

<label class="label kit">
    <span class="label__txt"></span>
    <button class="add-phone" (click)="showModalEmail('create')">Добавить почту
        <mat-icon class="icon">add</mat-icon>
    </button>
</label>
<div class="gmail-select" *ngIf="isNpm">
    <label class="label kit">
            <span class="label__txt">
                Google-аккаунт для работы с документами
                <mat-icon class="google" svgIcon="radius_main_gmail"></mat-icon>
            </span>
        <mat-select class="select"
                    placeholder="Выберите почту"
                    (selectionChange)="isGmailChange($event)"
                    [(ngModel)]="gmailAccount">
            <mat-option [value]="null">
                Не выбрано
            </mat-option>
            <mat-option  *ngFor="let account of allGmails, let i = index" [value]="account.account">
                {{ account.account }}
            </mat-option>
        </mat-select>

    </label>
</div>