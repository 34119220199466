<section class="dialog">
    <header class="dialog__header __modal">
        <h2 class="dialog__title">
            {{ title }}
        </h2>

        <mat-icon aria-label="close" class="button _close" (click)="closeForm()">close</mat-icon>
    </header>

    <div class="dialog__content">

        <app-search-mat
            #search
            (textChange)="searchChanges($event)"
            [kitView]="true"
            placeholderTxt="Поиск сотрудников"
            class="search"
        ></app-search-mat>


            <section class="selected-people-section" *ngIf="selectedSorted && selectedSorted.length">
                <h3 class="selected__title">Выбраны ({{ selectedSorted.length }})</h3>

                <div class="items">
                    <ul class="people__selected">
                        <ng-container *ngFor="let item of selectedSorted; let i = index">
                            <li class="people__list-item"
                                (click)="removeUser(item)"
                                [matTooltip]="item['removable'] == false ? 'Участника задачи удалить нельзя': ''"
                                *ngIf="showAll || i < 8"
                                [ngClass]="{ _top: isSelf(item) }">
                                <app-person-item [user]="item" [shortView]="true"
                                                 [disabled]="item['removable'] == false"></app-person-item>
                            </li>

                            <span class="divider"
                                  *ngIf="item['removable'] == false && selectedSorted[i + 1] && selectedSorted[i + 1]['removable'] == true"></span>
                        </ng-container>
                        <li class="people__list-item more"
                            *ngIf="selectedSorted.length > 8"
                            (click)="showAll = !showAll">
                              <span class="img">
                                <mat-icon class="icon">{{
                                    showAll ? "expand_less" : "expand_more"
                                    }}</mat-icon>
                              </span>
                            <span class="name">
                                {{ showAll ? "Свернуть" : "Ещё" }}
                              </span>
                        </li>
                    </ul>
                </div>
            </section>
            <mat-divider class="diviner" *ngIf="selectedSorted"></mat-divider>

        <app-spinner *ngIf="!isLoaded"
                     [isSpinnerOn]="true"
                     class="loader"
        ></app-spinner>

        <div class="persons__adds" *ngIf="isLoaded && users && users.length">
            <h3 class="search__title" *ngIf="!usersSorted.length">
                Нет совпадений
            </h3>

            <h3 class="people__title" *ngIf="usersSorted.length">
                Можно выбрать
            </h3>

            <ul class="persons__list">
                <ng-container *ngFor="let item of usersSorted">
                    <li class="person persons__list-item"
                        (click)="addUser(item)">

                        <app-person-item
                                class="item"
                                [user]="item"
                                action="add"
                        ></app-person-item>
                    </li>
                </ng-container>
            </ul>
        </div>


        <footer class="footer">
            <app-button
                    *ngIf="data.flag !== 'addAllFlag'"
                    class="button _reset"
                    buttonType="outlined"
                    title="Отмена"
                    (click)="closeForm()"
                    [type]="'reset'"
                    type="reset"
            ></app-button>
            <app-button
                    *ngIf="data.flag !== 'addAllFlag'"
                    class="button _save"
                    title="Сохранить"
                    (action)="saveForm()"
                    [disabled]="!changedMembers"
                    [type]="'submit'"
            ></app-button>


        </footer>
    </div>
</section>
