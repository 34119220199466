import {Component, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef,} from '@angular/material/legacy-dialog';

export class ConfirmActionButton {
    action: string;
    name: string;
    color: string;
    autofocus: boolean;
}
@Component({
    selector: 'app-confirm-action',
    templateUrl: './confirm-smart.component.html',
    styleUrls: ['./confirm-smart.component.scss'],
})
export class ConfirmSmartComponent {
    buttons: ConfirmActionButton[] = null;
    link: string = null;
    title = '';
    description = null;
    checkbox = null;
    checked = false;

    constructor(
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data,
    ) {
        this.buttons = data.buttons;
        this.title = data.title;
        this.description = data.description;
        this.checkbox = data.checkbox ? data.checkbox : null;
        this.link = data.link ? data.link : null;
    }

    close() {
        this.dialogRef.close();
    }

    onLinkClick(evt) {
        evt.preventDefault();
        const action = 'link';
        this.dialogRef.close(action);
    }

    action(buttonAction) {
        if (this.checkbox) {
            const dataActions = {
                isChecked: this.checked,
                buttonAction,
            };
            this.dialogRef.close(dataActions);
        } else {
            this.dialogRef.close(buttonAction);
        }
    }
}
