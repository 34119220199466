import {Component, Input, OnInit} from '@angular/core';
import {Tag} from '../../../task-manager/models/tag';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {JSON} from 'ta-json';
import {GroupsFormComponent} from './groups-form/groups-form.component';
import {GroupsService} from '../../../task-manager/services/groups.service';

@Component({
    selector: 'app-groups',
    templateUrl: './groups.component.html',
    styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit {

    @Input() public groups: Tag[] = [];
    @Input() public disabled = false;
    @Input() public isOpened = false;


    constructor(
        private dialog: MatDialog,
        private groupsService: GroupsService
    ) {
    }

    get title() {
        return 'Группы';
    }

    get buttonTitle() {
        return '+ Добавить группу';
    }

    ngOnInit() {
        const opened = localStorage.getItem('taskManagerGroupsExpanded');
        this.isOpened = opened ? JSON.parse(opened) : false;
    }

    onExpand(evt) {
        localStorage.setItem('taskManagerGroupsExpanded', evt);
        this.isOpened = evt;
    }

    openFormAdd() {
        const dialogForm = this.dialog.open(GroupsFormComponent);
    }

    onGroupSelect($event) {
        console.log('selected', $event);
        this.groupsService.onGroupSelect$.next($event);
    }
}
