import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

export enum ButtonsType {
  FIELD = 'field',
  OUTLINED = 'outlined',
  TEXT = 'text',
  LINK = 'link',
  FIELD_ICON = 'field_icon',
  CLOSE = 'close',
  EDIT = 'edit',
  ADD = 'add',
  OUTLINED_ICON = 'outlined_icon'
}

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

  public readonly Types = ButtonsType;

  @Input() title = '';
  @Input() icon = '';
  @Input() radiusIcon = null;
  @Input() color = '';
  @Input() type = 'button';
  @Input() focus = false;
  @Input() disabled = false;
  /** Тип кнопки по KIT (field, outlined, text, field_icon, outlined_icon) */
  @Input() buttonType: ButtonsType;
  @Output() action = new EventEmitter<any>();


  constructor() { }

  ngOnInit(): void {
  }

  onClick() {
    if (this.disabled) {
return;
}

    this.action.emit(true);
  }

}
