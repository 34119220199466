<section class="dialog">
    <header class="dialog__header">
        <h3 class="dialog__title">{{ title }}</h3>
        <mat-icon class="close" (click)="close()">close</mat-icon>
    </header>


    <div class="content">
    <div class="form">
        <section class="section" *ngIf="data['performers'] && data['performers'].length">
            <h4 class="section__title">Исполнители</h4>
            <label class="label" *ngFor="let user of data['performers']">
                <mat-checkbox class="filter-helper" [(ngModel)]="user['selected']"
                              (change)="onUsersChange($event, user)"
                              labelPosition="before">
                    <app-members-item [user]="user" class="_filter-helper"
                                      [responsible]="data['responsible']?.id === user.id"
                                      [performer]="data['responsible']?.id !== user.id"></app-members-item>
                </mat-checkbox>
                <span class="resp-button"
                      [ngClass]="{'_disabled': !user['selected'],
                      '_selected': user.id === data['responsible']?.id}">
                     <mat-icon class="responsive"
                               svgIcon="radius_star-border"
                               (click)="onResponsibleClick($event, user)"
                               [matTooltip]="user['selected'] ? 'Назначить ответственным' : ''"></mat-icon>
                </span>
            </label>

        </section>

        <section class="section" *ngIf="data['observers'] && data['observers'].length">
            <h4 class="section__title">Наблюдатели</h4>
            <label class="label" *ngFor="let user of data['observers']">
                <mat-checkbox class="filter-helper" [(ngModel)]="user['selected']" labelPosition="before">
                    <app-members-item class="_filter-helper" [user]="user"></app-members-item>
                </mat-checkbox>
            </label>
        </section>

        <section class="section" *ngIf="data['adds']">
            <label class="label">
                <mat-checkbox class="filter-helper" [(ngModel)]="data['adds']['selected']" labelPosition="before">
                    <h4 class="section__title">Вложения</h4>
                </mat-checkbox>
            </label>
        </section>

        <section class="section" *ngIf="data['groups'] && data['groups'].length">
            <h4 class="section__title">Группа</h4>
            <label class="label" *ngFor="let group of data['groups']">
                <mat-checkbox class="filter-helper" [(ngModel)]="group['selected']"
                              (change)="changeGroup($event, group)" labelPosition="before">
                    <app-selected-filter-button type="group" [data]="group"
                                                [disabled]="true"></app-selected-filter-button>
                </mat-checkbox>
            </label>
        </section>

        <section class="section" *ngIf="data['importancePriority'] && data['importancePriority'].length">
            <h4 class="section__title">Приоритет</h4>
            <label class="label" *ngFor="let item of data['importancePriority']">
                <mat-checkbox class="filter-helper" [(ngModel)]="item['selected']"
                              (change)="changeImportance($event, item)" labelPosition="before">
                    <app-selected-filter-button [data]="item" type="importance"
                                                [disabled]="true"></app-selected-filter-button>
                </mat-checkbox>
            </label>
        </section>

        <section class="section" *ngIf="data['sprints'] && data['sprints'].length">
            <h4 class="section__title">Спринт</h4>
            <label class="label" *ngFor="let sprint of data['sprints']">
                <mat-checkbox class="filter-helper" [(ngModel)]="sprint['selected']"
                              (change)="changeSprint($event, sprint)" labelPosition="before">
                    <app-selected-filter-button type="sprint" [data]="sprint"
                                                [disabled]="true"></app-selected-filter-button>
                </mat-checkbox>
            </label>
        </section>

        <section class="section" *ngIf="data['tags'] && data['tags'].length">
            <h4 class="section__title">Метки</h4>
            <label class="label" *ngFor="let tag of data['tags']">
                <mat-checkbox class="filter-helper" [(ngModel)]="tag['selected']" labelPosition="before">
                    <app-selected-filter-button type="tag" [data]="tag"
                                                [disabled]="true"></app-selected-filter-button>
                </mat-checkbox>
            </label>
        </section>
    </div>
    </div>

    <footer class="dialog__footer">
        <app-button class="button" type="reset" buttonType="outlined"
                    [title]="sharedBoard ? 'Отменить' : 'Не применять'"
                    (click)="close()"></app-button>
        <app-button class="button" type="submit" (click)="onSubmit()"
                    [disabled]="!isSelected"
                    [title]="sharedBoard ? 'Добавить' : 'Применить выбранные'"></app-button>
    </footer>

</section>


