import {JsonProperty, JsonType} from 'ta-json';


export class Phone {
    @JsonProperty('type')
    @JsonType(String)
    type: string;

    @JsonProperty('phone')
    @JsonType(String)
    phone: string;

    constructor(
        type: string,
        phone: string
    ) {
        this.type = type;
        this.phone = phone;
    }
}
