import {JsonObject, JsonProperty, JsonType} from 'ta-json';

@JsonObject()
export default class History {
    @JsonProperty()
    @JsonType(Number)
    id?: number;

    @JsonProperty('user_id')
    @JsonType(Number)
    userId: number;

    @JsonProperty('full_name')
    @JsonType(String)
    fullName: string;

    @JsonProperty('created_at')
    @JsonType(Date)
    createdAt: Date;

    @JsonProperty('description')
    @JsonType(String)
    description: string;

    constructor(id: number, userId: number, fullName: string, createdAt: string, description: string) {
        this.id = id;
        this.userId = userId;
        this.fullName = fullName;
        this.createdAt = new Date(this.createdAt);
        this.description = description;
    }

}
