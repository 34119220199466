import {Component, EventEmitter, OnInit, Output,} from '@angular/core';

@Component({
    selector: 'app-top-message',
    templateUrl: './top-message.component.html',
    styleUrls: ['./top-message.component.scss'],
})
export class TopMessageComponent implements OnInit {
    @Output() close = new EventEmitter();

    ngOnInit() {

    }

    onClose() {
        this.close.emit();
    }
}
