<ng-container *ngIf="isVisible && !compliteIntro && inited">
    <div class="learning-overlay" (click)="closeLearningAfterError($event)"></div>
</ng-container>
<ng-container *ngIf="compliteIntro && inited">
    <div class="back-layer" *ngIf="!isMobile">
        <div class="wnd" [ngClass]="{'_small': mainSlider}">
            <button mat-icon-button (click)="closeFullScreen()" class="close-btn">
                <mat-icon>close</mat-icon>
            </button>

            <div class="dialog__content">
                <img src="../../../../../../assets/images/onboarding/tasks-learning-done.png">
                <h2>Поздравляем!<br>  Вы прошли обучение в разделе «Задачи»</h2>
                <span class="description"> Больше о возможностях раздела задачи Вы можете узнать<br> в <a href="https://myradius.ru/apps/knowledge-base?guidebook=313b2c1f7aaa8fc297952deae1051853" target="_blank">базе знаний.</a></span>
            </div>
            <footer class="dialog__footer done">
                <app-button class="button"
                            (click)="compliteIntro = false"
                            title="Завершить"></app-button>
            </footer>
        </div>
    </div>
</ng-container>