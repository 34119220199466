import {Component, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {OnboardingService} from '../../../../../services/onboarding.service';
import {Subject} from 'rxjs';
import {AuthService} from '../../../../../services/auth.service';

@Component({
  selector: 'app-onboarding-company',
  templateUrl: './onboarding-company.component.html',
  styleUrls: ['./onboarding-company.component.scss'],
})
export class OnboardingCompanyComponent implements OnInit, OnDestroy {
  private destroyed = new Subject<void>();
  isVisible = true;
  ONBOARDING_KEY = 'company';

  slides = [];
  isAdmin = false;
  inited = false;

  constructor(
      private onboarding: OnboardingService,
      private auth: AuthService,
  ) { }

  ngOnInit(): void {
    this.onboarding.currentScreen$.next(this.ONBOARDING_KEY);

    this.onboarding.viewedHints$.pipe(takeUntil(this.destroyed))
        .subscribe(val => {
            if (val.hasOwnProperty(this.ONBOARDING_KEY)) {
              this.isVisible = val[this.ONBOARDING_KEY] === false;
            } else {
              this.isVisible = false;
            }
        });

    this.auth.rights$.pipe(takeUntil(this.destroyed))
        .subscribe(val => {
          this.updateRights(val);
          this.setSlides();
        });


  }

  updateRights(rights) {
    this.isAdmin = rights?.isAdministrator;
    this.inited = true;
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  private setSlides() {
    const slidesAdmin = [
      { src: '/assets/images/onboarding/company-1.png',
        src2x: '/assets/images/onboarding/company-1-2x.png',
      },
      { src: '/assets/images/onboarding/company-2.png',
        src2x: '/assets/images/onboarding/company-2-2x.png',
      },
      { src: '/assets/images/onboarding/company-3.png',
        src2x: '/assets/images/onboarding/company-3-2x.png',
      },
      { src: '/assets/images/onboarding/company-4.png',
        src2x: '/assets/images/onboarding/company-4-2x.png',
      },
      { src: '/assets/images/onboarding/company-5.png',
        src2x: '/assets/images/onboarding/company-5-2x.png',
      },
      { src: '/assets/images/onboarding/company-6.png',
        src2x: '/assets/images/onboarding/company-6-2x.png',
      },
    ];

    const slidesUser = [
      { src: '/assets/images/onboarding/company-u-1.png',
        src2x: '/assets/images/onboarding/company-u-1-2x.png',
      },
      { src: '/assets/images/onboarding/company-u-2.png',
        src2x: '/assets/images/onboarding/company-u-2-2x.png',
      },
    ];

    if (this.isAdmin) {
      this.slides = slidesAdmin;
    } else {
      this.slides = slidesUser;
    }
  }
}
