import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import Status from '../../../task-manager/models/status';
import {getStatusName, ShortTask, Task, TaskStatuses, taskStatusesActualMapping} from '../../../task-manager/models/task';
import {AuthService} from '../../../../services/auth.service';
import {takeUntil} from 'rxjs/operators';
import {BoardsService} from '../../../task-manager/services/boards.service';

@Component({
    selector: 'app-task-manager-status',
    templateUrl: './task-manager-status.component.html',
    styleUrls: ['./task-manager-status.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskManagerStatusComponent implements OnInit, OnDestroy {
    public status: Status = null;
    public task: Task = null;
    public taskStatusName = null;
    public taskStatusIcon = null;
    public isTextStatus = true;
    public isSvgIcon = false;
    public membership = false;
    @Input() public disabled = false;
    // Отображать статус несмотря на настройкич
    @Input() public statusOnly = false;
    // Триггер смерти компонента
    private destroyed = new Subject<void>();
    public isStatusColumnSwap = null;
    @Input() isScheduleTemplate = false;

    constructor(
        private auth: AuthService,
        private boardsService: BoardsService,
        private cdr: ChangeDetectorRef
    ) {
    }

    @Input() set data(data) {
        this.status = data.status;
        this.task = data;

        if (this.isScheduleTemplate) {
            const status = taskStatusesActualMapping.find(el => el.actionId === this.status.id);
            // console.log(status);
            // this.status = status;
            this.taskStatusIcon = status?.icon;
            this.membership = false;
            this.isSvgIcon = false;
            this.isTextStatus = false;
        }

       this.reload(data);

        this.cdr.detectChanges();
    }

    @Input() set isText(isTextStatus: boolean) {
        this.isTextStatus = isTextStatus;
        if (this.task) {
            this.reload(this.task);
        }
        this.cdr.detectChanges();
    }

    getStatusName() {
        if (!this.isTextStatus) {
            return getStatusName(this.task, this.auth?.auth.id);
        }

        if (this.task.status.type === TaskStatuses.PAUSE
            || this.task.status.type === TaskStatuses.TO_ACCEPT
            || this.task.status.type === TaskStatuses.CHANGE_APPROVAL
            || this.task.status.type === TaskStatuses.CANCELLATION_APPROVAL || this.isStatusColumnSwap) {
            return getStatusName(this.task, this.auth?.auth.id);
        } else {
            return this.task.column && this.task.type !== 'template' ?
                this.task.column.tag && !this.statusOnly  ? this.task.column.tag : this.task.column.name :
                getStatusName(this.task, this.auth.auth.id);
        }
    }

    ngOnInit(): void {
        this.boardsService.boardSelected$
            .pipe(takeUntil(this.destroyed))
            .subscribe(res => {
                if (res) {
                    this.isStatusColumnSwap = res.statusColumnSwap;
                    this.cdr.detectChanges();
                }
            });
    }

    reload(data) {
        if ((data instanceof Task) || (data instanceof ShortTask)) {
            this.taskStatusName = this.getStatusName();
            this.taskStatusIcon = this.task.getActualStatusIcon();
            this.membership = this.task?.membership(this.auth.auth.id);
            this.isSvgIcon = !this.isTextStatus && this.task.hasSvgIcon();
        }
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }

}
