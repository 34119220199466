<ng-container>
  <button [matMenuTriggerFor]="supportMenu" class="company-logo">
    <img [src]="companyLogo" alt="" class="company-logo-img" />
  </button>

  <mat-menu #supportMenu="matMenu" class="company-menu" xPosition="after" yPosition="below">
    <a mat-menu-item  href="/cabinet-company" class="menu-link" *ngIf="companyId">
      <img [src]="companyLogo" alt="" class="company-logo-img-menu" />
      <div class="menu-item-column">
        <div class="user-menu__name">{{company?.name}}</div>
        <div class="user-menu__isadmin">{{isAdmin ? 'Администратор' : 'Пользователь'}}</div>
      </div>
    </a>
    <div class="separator" *ngIf="companyId"></div>
    <a mat-menu-item class="menu-link" *ngIf="isAdmin && companyId && isNpm"
       href="/cabinet-radius/invoices"
    >
      <img src="/assets/images/favicon.png" alt="" class="menu-link-img" />
      <div class="menu-item-column wide">
        <div>Управление радиусом</div>
      </div>
    </a>
<!--    <a mat-menu-item class="menu-link" href="cabinet-company/pricing" *ngIf="isAdmin && companyId">-->
<!--      <img src="/assets/icons/currency_ruble_black_24dp.svg" alt="" class="menu-link-img" />-->
<!--      <div class="menu-item-column wide">-->
<!--        <div>Баланс</div>-->
<!--        <strong>{{balance}} ₽</strong>-->
<!--      </div>-->
<!--    </a>-->
    <!--a mat-menu-item class="menu-link" *ngIf="isAdmin && companyId">
      <img src="/assets/icons/cloud.svg" alt="" class="menu-link-img" />
      <div class="menu-item-column wide">
        <div>Дисковое пространство</div>
        <strong>{{usedSpace}} из {{totalSpace}}</strong>
      </div>
    </a-->
    <a mat-menu-item href="/cabinet-company" class="menu-link" *ngIf="companyId">
      <img src="/assets/icons/account.svg" alt="" class="menu-link-img" />
      <div class="menu-item-column">
        Аккаунт компании
      </div>
    </a>
    <a mat-menu-item routerLink="/auth/sign-out" class="menu-link">
      <mat-icon class="icon menu-link-img" svgIcon="radius_exit"></mat-icon>
      <div class="menu-item-column">
        Выйти из аккаунта
      </div>
    </a>
  </mat-menu>
</ng-container>
