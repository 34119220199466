import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';

@Directive({
    selector: '[appEmailValidatorValue]',
    providers: [{ provide: NG_VALIDATORS, useExisting: EmailValidatorValueDirective, multi: true }]
})
export class EmailValidatorValueDirective implements Validator {

    private regexp = '[a-zA-Z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?';

    validate(control: AbstractControl): ValidationErrors | null  {
        if (!control.value) {
            return null;
        }

        const result = control.value.match('[a-zA-Z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?');
        return result ? null : { emailFail: true };
    }

    match(value: String) {
        if (!value) {
            return false;
        }

        return value.match(this.regexp);
    }

}
