import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {CompanyService} from '../../../../../../phonebook/services/company.service';
import {ApiCompanyService} from '../../../../../../phonebook/services/api-company.service';
import {PositionsService} from '../../../../../../phonebook/services/positions.service';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialog as MatDialog,
    MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import {GlobalNotificationCenterService} from '../../../../../../../services/global-notification-center.service';
import {ConfigService} from '../../../../../../../services/config.service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {Subdivision} from '../../../../../../../models/subdivision';
import {Subject} from 'rxjs';
import {SharedService} from '../../../../../services/shared.service';

@Component({
  selector: 'app-subdivision-add-modal',
  templateUrl: './subdivision-add-modal.component.html',
  styleUrls: ['./subdivision-add-modal.component.scss']
})
export class SubdivisionAddModalComponent implements OnInit, OnDestroy  {


    public subdivision: Subdivision = null;
    public formGroup: UntypedFormGroup = null;

    public isSubmitClicked = false;
    /*
    public avatar = null;
    public user: User = null;
    */
    public isButtonsDisabled = false;
    public isLoaded = false;
    private destroyed = new Subject<void>();


    constructor(
        public config: ConfigService,
        private company: CompanyService,
        private sharedService: SharedService,
        private api: ApiCompanyService,
        private dialog: MatDialog,
        public dialogRef: MatDialogRef<any>,
        private positionsService: PositionsService,
        private notiService: GlobalNotificationCenterService,
        @Inject(MAT_DIALOG_DATA) public data = null,
    ) {
    }

    ngOnInit(): void {
        this.formGroup = new UntypedFormGroup({
            name: new UntypedFormControl(
                '',
                [Validators.required]),
            parent: new UntypedFormControl(
                this.getParentSubdivision(),
                [Validators.required]),
        });
        this.isLoaded = true;

    }

    close(data = null) {
        this.dialogRef.close(data);
    }


    getAvatar(item: Subdivision) {
        return this.config.getLogoSrc(item.image);
    }

    getParentSubdivision() {
        return this.data.subdivisions[0];
    }

    submit() {
        this.isButtonsDisabled = true;
        if (!this.formGroup) {
            this.isButtonsDisabled = false;
            return false;
        }

        if (this.formGroup.invalid) {
            this.formGroup.markAllAsTouched();
            this.notiService.handleError(this.sharedService.FORM_INVALID);
            this.isButtonsDisabled = false;
            return false;
        }

        const formData = this.formGroup.getRawValue();
          const data = {
                struct: formData
            };
           this.create(data);
    }

    create(data) {
        this.api.createSubdivision(data)
            .pipe(takeUntil(this.destroyed))
            .subscribe(res => {
                this.company.canExitSubdivisionCard$.next(true);
                this.company.reloadList$.next(true);
                this.isButtonsDisabled = false;
                this.close(res);
            }, (err) => {
                this.notiService.handleFullError(err);
                this.isButtonsDisabled = false;
            });
    }


    hasError(elem) {
        return (this.isSubmitClicked && elem.invalid) || (elem.invalid && (elem.dirty || elem.touched));
    }

    ngOnDestroy() {
        this.company.openedSubdivisionId$.next(null);
        this.company.openedSubdivision$.next(null);
        this.destroyed.next();
        this.destroyed.complete();
    }

}
