import {TasksService} from '../../../task-manager/services/tasks.service';
import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {ConfigService} from '../../../../services/config.service';
import {takeUntil} from 'rxjs/operators';
import {User} from '../../../../models/user';
import {ChatsService} from '../../../../services/chats.service';
import {ApiPhonebookService} from '../../../phonebook/services/api-phonebook.service';
import {StorageService} from '../../../../services/storage.service';

@Component({
    selector: 'app-person-card',
    templateUrl: './person-card.component.html',
    styleUrls: ['./person-card.component.scss']
})
export class PersonCardComponent implements OnInit, OnDestroy {

    public person: User;
    public avatar;
    public loaded = false;
    public position = null;
    @Input() public userId = null;
    @Output() public readonly close: EventEmitter<any> = new EventEmitter();
    /** Trigger that causes all registered subscriptions to be terminated. */
    private destroyed = new Subject<void>();

    constructor(
        public config: ConfigService,
        private chats: ChatsService,
        private phonebookApiService: ApiPhonebookService,
        private cdr: ChangeDetectorRef,
    ) {
    }

    get name() {
        return this.person ? this.person.name : null;
    }

    ngOnInit() {
        this.loaded = false;
        this.phonebookApiService.getUserById(this.userId)
            .pipe(takeUntil(this.destroyed))
            .subscribe(res => {
                this.person = res.user;
                this.position = this.person.slots?.find(el => !el.isRole);
                this.avatar = this.person ?
                    this.config.getUserAvatarByUser(this.person, 'small') :
                    this.config.getUserAvatar(this.userId, 'small');
                this.loaded = true;
                this.cdr.detectChanges();
            });
    }

    openChat() {
        const data = {
            id: this.person.id,
            name: this.person.shortName
        };
        this.chats.openChat$.next(data);
        this.onClose();
    }

    onClose() {
        this.close.emit(true);
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }
}
