import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

    /** Текущий пользователь */
    learningBoardName$ = new BehaviorSubject(null);
    openTaskLearning$ = new BehaviorSubject(false);
    allowCloseModalLearning$ = new BehaviorSubject(true);
    stepDone$ = new BehaviorSubject(null);
    viewPart$ = new BehaviorSubject(null);
    hasChanges$ = new BehaviorSubject(null);
    taskLoaded$ = new Subject();
    closeBoardsMenu$ = new Subject();
    learningSetSelected$ = new Subject();

    readonly FORM_INVALID = 'Все обязательные поля должны быть заполнены';
    readonly FORM_INVALID_REQUIRED = 'Поле обязательно для заполнения';
    readonly FORM_INVALID_SYMBOLS = 'Вы ввели недопустимые символы';
    readonly FORM_INVALID_MAIL_PHONE = 'Вы ввели неверный E-mail или телефон';
    readonly FORM_INVALID_PASSWORD = 'Поле не может быть пустым или содержать меньше 6 символов';
    // readonly FORM_INVALID_PASSWORD = 'Максимальная длина названия не должна содержать более 150 символов';
    readonly FORM_INVALID_1500 = 'Максимальная длина названия не должна содержать более 1500 символов';
    // readonly FORM_INVALID_MAXLENGTH = 'Максимальная длина не должна превышать'
    readonly FORM_INVALID_100 = 'Максимальная длина названия не должна содержать более 100 символов';
    readonly FORM_INVALID_TEXT_MAX_VALUE = 'Превышено количество символов';


    constructor() { }
}
