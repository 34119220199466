<label class="label kit pull-up">
    <div class="label-box">
        <span class="label__txt">
            Мобильный телефон
        </span>
        <span class="label__txt check" *ngIf="mobile?.isVerified">
            <mat-icon class="icon">check_circle_outline</mat-icon>
            Номер подтвержден
        </span>
        <!--<span class="label__txt _delete" *ngIf="mobile?.isVerified" (click)="onDeleteMobilePhoneBtnClick()">удалить</span>-->
    </div>
    <div class="_work-phone">
        <input class="input"
               [disabled]="mobile?.isVerified"
               [(ngModel)]="mobileInput"
               (keyup)="onMobilePhoneKeyUp($event)"
               (paste)="onMobilePhonePaste($event)"
               [ngClass]="{'verified': (mobile?.isVerified)}"
               autocomplete="off" mask="+ 0 (000) 000 00 00">
        <mat-icon *ngIf="mobile?.isVerified"
                  (click)="onDeleteMobilePhoneBtnClick()"
                  class="icon delete-mobile">close
        </mat-icon>
    </div>
    <div *ngIf="mobile && !isSended && !mobile.isVerified" class="verify-link-block">
        <span *ngIf="isMobilePhoneUsed === null && mobileVerify" class="hint ">Выполняется проверка...</span>
        <a *ngIf="isMobilePhoneUsed === false && currentUser.id === setUserId" class="label__txt verified" href="#" disabled="true"
           (click)="sendCipher($event)">
            <mat-icon class="icon" svgIcon="radius_phone_connect"></mat-icon>
            Подтвердить</a>
        <span *ngIf="isMobilePhoneUsed" class="hint _error">Этот номер уже используется</span>
        <span *ngIf="mobileError" class="hint _error">{{ mobileError }}</span>
    </div>
</label>
<label class="label kit _internal">
        <span class="label__txt">
            Внутренний номер
        </span>
    <input class="input"
           [(ngModel)]="internalInput"
           maxlength="6"
           (keyup)="onInternalPhoneChange($event)"
           autocomplete="off">
</label>
<label class="label kit" *ngIf="workInput">
    <div class="mobile-title">
        <span class="label__txt">
            Дополнительный телефон
        </span>
        <mat-icon class="icon"  (click)="removeWorkPhone(i)" *ngIf="editMode">delete_outline</mat-icon>
    </div>
    <input class="input _work-phone"
           [(ngModel)]="workInput"
           (keyup)="onWorkPhoneChange($event)">
</label>
<ng-container *ngFor="let phone of phones, let i = index">
    <label class="label kit">
        <div class="mobile-title">
            <span class="label__txt">
                Дополнительный телефон
            </span>
            <mat-icon class="icon" (click)="removePhone(i)">delete_outline</mat-icon>
        </div>
        <input class="input"
               [(ngModel)]="phone.phone"
               (keyup)="onPhoneChange($event, i)"
               (paste)="onPhoneChange($event, i)">
    </label>
</ng-container>
<label class="label kit">
    <span class="label__txt"></span>
    <button class="add-phone" (click)="addPhone()">Добавить телефон
        <mat-icon class="icon">add</mat-icon>
    </button>
</label>
