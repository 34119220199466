import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {OnboardingService} from '../../../../../services/onboarding.service';
import {Subject} from 'rxjs';
import {environment} from '../../../../../../environments/environment';
import {ApiService} from '../../../../../services/api.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-onboarding-wnd-common',
  templateUrl: './onboarding-wnd-common.component.html',
  styleUrls: ['./onboarding-wnd-common.component.scss']
})
export class OnboardingWndCommonComponent implements OnInit, OnDestroy  {

    private destroyed = new Subject<void>();
    isVisible = false;
    isLoaded = false;
    ONBOARDING_KEY = 'common';

    slides = [];
    isAdmin = false;
    inited = false;

    public fileStorage = environment.fileStorageURL;


    // Управление через клавиатуру
    @HostListener('document:keydown.escape', ['$event']) onLEscapeClick() {
       if (this.isVisible) {
           this.closeFullScreen();
       }
    }


    constructor(
        private onboarding: OnboardingService,
        public api: ApiService,
        public router: Router
) { }


    ngOnInit(): void {
        this.isLoaded = false;
        this.onboarding.viewedHints$.pipe(takeUntil(this.destroyed))
            .subscribe(val => {
                if (val.hasOwnProperty(this.ONBOARDING_KEY)) {
                    this.isVisible = val[this.ONBOARDING_KEY] === false;
                } else {
                    this.isVisible = false;
                }
                this.isLoaded = true;
            });
    }

    closeFullScreen() {
        this.onboarding.setViewed(this.ONBOARDING_KEY, true);
    }

    toHint(key) {
        if (key === 'tasks') {
            this.onboarding.setViewed(key, false);
            this.onboarding.setViewed('common', true);
            this.router.navigate(['/apps/boards']);
        } else {
            this.onboarding.setViewed(key, false);
            this.onboarding.setViewed('common', true);
        }
    }

    stopPropagation($event: MouseEvent) {
        $event.stopPropagation();
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }
}
