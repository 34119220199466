<section class="dial og">
    <header class="dialog__header">
        <h2 class="dialog__title">
            {{ title }}
        </h2>

        <mat-icon aria-label="close" class="close" (click)="closeForm()">close</mat-icon>

    </header>

    <div class="dialog__content">

        <app-search-mat (textChange)="searchChanges($event)"
                        #search
                        [kitView]="true"
                        placeholderTxt="Поиск сотрудников"
                        class="search"></app-search-mat>

        <div class="peoples__top" *ngIf="selectedSorted && selectedSorted.length">
            <h3 class="selected__title">Выбраны ({{selectedSorted.length}})</h3>

            <div class="items">
                <ul class="people__selected">
                    <ng-container *ngFor="let item of selectedSorted; let i = index">
                        <li class="people__list-item"
                            (click)="removeUser(item)"
                            *ngIf="showAll || i < 7"
                            [ngClass]="{'_top' : isSelf(item)}">
                            <app-person-item [user]="item"
                                             [action]="item['disabled'] ? null : 'delete'"
                                             [shortView]="true"></app-person-item>

                        </li>
                    </ng-container>
                    <li class="people__list-item more" *ngIf="selectedSorted.length > 7"
                        (click)="showAll = !showAll">
                            <span class="img">
                                <mat-icon class="icon">{{showAll ? 'expand_less' : 'expand_more'}}</mat-icon>
                            </span>
                        <span class="name">
                                {{showAll ? 'Свернуть' : 'Ещё'}}
                            </span>
                    </li>
                </ul>
            </div>
            <mat-divider></mat-divider>
        </div>

        <app-spinner *ngIf="!isLoaded" [isSpinnerOn]="true" class="loader"></app-spinner>

        <cdk-virtual-scroll-viewport *ngIf="selectedSorted && !meetUsers" appendOnly itemSize="50" class="body"
                                     [ngClass]="{'max-height-body' : !showAll || selectedSorted.length < 8,
        'min-height-body' : showAll && selectedSorted.length > 15, 'height-without-search' : !selectedSorted.length}">
            <section class="peoples">
                <div class="persons__adds" *ngIf="isLoaded && users && users.length">
                    <h3 class="search__title" *ngIf="!availableUsersCurrentSorted.length">
                        Нет совпадений
                    </h3>

                    <h3 class="people__title" *ngIf="availableUsersCurrentSorted.length">Сотрудники</h3>

                    <app-empty-list-helper *ngIf="tariffsFilter"
                                           class="helper"
                                           radius_icon="radius_info"
                                           [text]="tariffsText"></app-empty-list-helper>

                    <ul class="persons__list">
                        <ng-container *cdkVirtualFor="let item of availableUsersCurrentSorted">
                            <li class="person persons__list-item"
                                [ngClass]="{'_top' : isSelf(item)}"
                                (click)="addUser(item)">
                                <app-person-item class="item"
                                                 [title]="false"
                                                 [user]="item"
                                                 action="add"></app-person-item>
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </section>
        </cdk-virtual-scroll-viewport>

        <!-- TODO: maybe make separate component for this -->
        <cdk-virtual-scroll-viewport *ngIf="selectedSorted && meetUsers" appendOnly itemSize="50" class="body _percent"
                                     [ngClass]="{'max-height-body' : !showAll || selectedSorted.length < 8,
        'min-height-body' : showAll && selectedSorted.length > 15, 'height-without-search' : !selectedSorted.length}">
            <section class="peoples">
                <div class="persons__adds" *ngIf="isLoaded && users && users.length">
                    <h3 class="search__title" *ngIf="!availableUsersCurrentSorted.length && !availableUsersCurrentMeetSorted.length">
                        Нет совпадений
                    </h3>

                    <app-empty-list-helper *ngIf="tariffsFilter"
                                           class="helper"
                                           radius_icon="radius_info"
                                           [text]="tariffsText"></app-empty-list-helper>

                    <h3 class="people__title _bold" *ngIf="availableUsersCurrentSorted.length">Можно выбрать</h3>


                    <ng-container *ngIf="availableUsersCurrentMeetSorted.length > 0">
                        <h3 class="people__title _small-grey">Участники встречи</h3>

                        <ul class="persons__list">
                            <ng-container *ngFor="let item of availableUsersCurrentMeetSorted">
                                <li class="person persons__list-item"
                                    [ngClass]="{'_top' : isSelf(item)}"
                                    (click)="addUser(item)">
                                    <app-person-item class="item"
                                                     [title]="false"
                                                     [user]="item"
                                                     action="add"></app-person-item>
                                </li>
                            </ng-container>
                        </ul>
                    </ng-container>

                    <ng-container *ngIf="availableUsersCurrentMeetSorted.length === 0 && !searchParams">
                        <h3 class="people__title _small-grey">Участники встречи</h3>
                        <div class="grey-box">Выбраны все доступные участники встречи</div>
                    </ng-container>

                    <h3 class="people__title _small-grey" *ngIf="availableUsersCurrentSorted.length">Остальные</h3>

                    <ul class="persons__list">
                        <ng-container *cdkVirtualFor="let item of availableUsersCurrentSorted">
                            <li class="person persons__list-item"
                                [ngClass]="{'_top' : isSelf(item)}"
                                (click)="addUser(item)">
                                <app-person-item class="item"
                                                 [title]="false"
                                                 [user]="item"
                                                 action="add"></app-person-item>
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </section>
        </cdk-virtual-scroll-viewport>

        <footer class="user__footer">
            <app-button class="button _reset"
                        buttonType="outlined"
                        title="Отмена"
                        (click)="closeForm()"
                        [type]="'reset'"
                        type="reset"></app-button>
            <app-button class="button _save"
                        title="Сохранить"
                        (click)="saveForm()"
                        [disabled]="OnlyUserCondition"
                        [type]="'submit'"></app-button>
        </footer>

    </div>
</section>
