import {JsonElementType, JsonProperty, JsonType} from 'ta-json';
import {Rating} from '../../../shared/models/rating';
import {Slot} from '../slot';
import {UserFunction} from '../user-function';

export class FunctionPosition {
    @JsonProperty('id')
    @JsonType(Number)
    id: number = null;

    @JsonProperty('title')
    @JsonType(String)
    title: string = null;

    @JsonProperty('name')
    @JsonType(String)
    name: string = null;

    @JsonProperty('slots')
    @JsonElementType(Slot)
    slots: Slot[] = [];

    @JsonProperty('users')
    @JsonElementType(UserFunction)
    users: UserFunction[] = [];

    @JsonProperty('weight')
    @JsonType(Number)
    weight: number;

    constructor(id: number, title: string, users: UserFunction[], weight: number) {
        this.id = id;
        this.title = title;
        this.users = users;
        this.weight = weight;
    }
}
export class FunctionSubdivision {
    @JsonProperty('id')
    @JsonType(Number)
    id: number = null;

    @JsonProperty('image')
    @JsonType(String)
    image: string = null;

    @JsonProperty('name')
    @JsonType(String)
    name: string = null;

    @JsonProperty('slots')
    @JsonElementType(Slot)
    slots: Slot[] = [];

    @JsonProperty('users')
    @JsonElementType(UserFunction)
    users: UserFunction[] = [];

    @JsonProperty('weight')
    @JsonType(Number)
    weight: number;

    constructor(id: number, name: string, slots: Slot[]) {
        this.id = id;
        this.name = name;
        this.slots = slots;
    }
}

export class FunctionShort {
    @JsonProperty('id')
    @JsonType(Number)
    id: number;

    @JsonProperty('title')
    @JsonType(String)
    title = '';

    @JsonProperty('path')
    @JsonType(Array)
    path = [];

    @JsonProperty('rating')
    @JsonElementType(Rating)
    rating: Rating;

    @JsonProperty('children')
    @JsonElementType(FunctionShort)
    children: FunctionShort[] = [];

    @JsonProperty('performers_positions')
    @JsonElementType(FunctionPosition)
    performersPositions: FunctionPosition[] = [];

    @JsonProperty('delegates_positions')
    @JsonElementType(FunctionPosition)
    delegatesPositions: FunctionPosition[] = [];

    @JsonProperty('performers_subdivisions')
    @JsonElementType(FunctionSubdivision)
    performersSubdivisions: FunctionSubdivision[] = [];

    @JsonProperty('responsibles_positions')
    @JsonElementType(FunctionPosition)
    responsiblesPositions: FunctionPosition[] = [];


    @JsonProperty('unit_id')
    @JsonType(Number)
    unitId = 0;

    @JsonProperty('is_folder')
    @JsonType(Boolean)
    is_folder = false;

    @JsonProperty('parent_id')
    @JsonType(Number)
    parent_id = null;

    makeTreeNode(list) {
        const map = {};
        const roots = [];

        if (list.length === 1) {
            return list;
        }

        for (let i = 0; i < list.length; i += 1) {
            map[list[i].id] = i;
            list[i]['children'] = [];
            list[i]['parent_id'] = null;
            list[i]['parent_id'] = list[i].path && list[i].path.length > 1 ?
                list[i]['parent_id'] = list[i].path[list[i].path.length - 2] :
                null;

        }

        for (let i = 0; i < list.length; i += 1) {
            const node = list[i];
            if (node.parent_id && node.parent_id !== node.id && list[map[node['parent_id']]]) {
                list[map[node['parent_id']]]['children'] ?
                    list[map[node['parent_id']]]['children'].push(node) :
                    list[map[node['parent_id']]]['children'] = [node];
            } else {
                roots.push(node);
            }
        }

        return roots;
    }
}
