import {JsonProperty, JsonType} from 'ta-json';

export class Group {
    @JsonProperty('id')
    @JsonType(Number)
    id?: number;

    @JsonProperty('board_id')
    @JsonType(Number)
    boardId?: number;

    @JsonProperty('name')
    @JsonType(String)
    name?: string;

    @JsonProperty('color')
    @JsonType(String)
    color?: string;

    @JsonProperty('visible')
    @JsonType(Boolean)
    visible?: boolean;

    @JsonProperty('on_board_default')
    @JsonType(Boolean)
    on_board_default?: boolean;

    @JsonProperty('task_group')
    @JsonType(Boolean)
    taskGroup?: boolean;

    static readonly colorDisabled = '#CBCED2';

    constructor(id: number,
                boardId?: number,
                name: string = '',
                color: string = '_grey',
                visible: boolean = true,
                on_board_default: boolean = false,
                taskGroup: boolean = false
    ) {
        this.id = id;
        this.boardId = boardId;
        this.name = name;
        this.color = color;
        this.visible = visible;
        this.on_board_default = on_board_default;
        this.taskGroup = taskGroup;
    }
}

export enum GroupsColor {       // colors before
    COLOR0 = '#D7DFE9',
    COLOR1 = '#7950F2',         // 1985FF
    COLOR2 = '#934BDB',         // FF0000
    COLOR3 = '#BE4BDB',         // 913E98
    COLOR4 = '#E64980',         // 69BF46
    COLOR5 = '#DB4B4B',         // 1BBDD4
    COLOR6 = '#FA5252',         // 6300FF
    COLOR7 = '#FD7E14',         // 9A19FF
    COLOR8 = '#FAB005',         // 757575
    COLOR9 = '#DB904B',         // 009950
    COLOR10 = '#15AABF',        // 1930FF
    COLOR11 = '#4BB8DB',        // C10000
    COLOR12 = '#12B886',        // FFA726
    COLOR13 = '#40C057',        // FF6C19
    COLOR14 = '#82C91E',        // 5C6570
}

export const groupsMapping = Object.freeze([
    {
        color: GroupsColor.COLOR0,
        name: '_color0',
    },
    {
        color: GroupsColor.COLOR1,
        name: '_color1',
    },
    {
        color: GroupsColor.COLOR2,
        name: '_color2',
    },
    {
        color: GroupsColor.COLOR3,
        name: '_color3',
    },
    {
        color: GroupsColor.COLOR4,
        name: '_color4',
    },
    {
        color: GroupsColor.COLOR5,
        name: '_color5',
    },
    {
        color: GroupsColor.COLOR6,
        name: '_color6',
    },
    {
        color: GroupsColor.COLOR7,
        name: '_color7',
    },
    {
        color: GroupsColor.COLOR8,
        name: '_color8',
    },
    {
        color: GroupsColor.COLOR9,
        name: '_color9',
    },
    {
        color: GroupsColor.COLOR10,
        name: '_color10',
    },
    {
        color: GroupsColor.COLOR11,
        name: '_color11',
    },
    {
        color: GroupsColor.COLOR12,
        name: '_color12',
    },
    {
        color: GroupsColor.COLOR13,
        name: '_color13',
    },
    {
        color: GroupsColor.COLOR14,
        name: '_color14',
    },
    {
        color: Group.colorDisabled,
        name: '_colorDisabled'
    }
]);
