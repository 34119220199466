import { JsonProperty, JsonType } from 'ta-json';

import { Slot } from '../../phonebook/models/slot';
import { QuestionResponce } from './question-responce';

export class QuestionShort {
    @JsonProperty('id')
    @JsonType(Number)
    id: number;

    @JsonProperty('answer')
    @JsonType(QuestionResponce)
    answer: QuestionResponce;

    @JsonProperty('type')
    @JsonType(String)
    type: string;

    @JsonProperty('survey_type')
    @JsonType(String)
    survey_type: string;

    @JsonProperty('slot')
    @JsonType(Slot)
    slot: Slot;

    @JsonProperty('created_at')
    @JsonType(String)
    createdAt: string;

    @JsonProperty('inserted_at')
    @JsonType(String)
    insertedAt: string;

    @JsonProperty('is_new')
    @JsonType(Boolean)
    isNew = false;

    @JsonProperty('is_checked')
    @JsonType(Boolean)
    isChecked = false;

    @JsonProperty('question')
    @JsonType(String)
    question = '';

    @JsonProperty('answer_id')
    @JsonType(Number)
    answerId: number;

    @JsonProperty('is_disabled')
    @JsonType(Boolean)
    disabled: boolean;

    @JsonProperty('topic_title')
    @JsonType(String)
    topicTitle: string;

    constructor(
        id: number,
        type: string,
        createdAt: string,
        insertedAt: string,
        isNew: boolean,
        isChecked: boolean,
        question: string,
        answerId: number,
        topicTitle: string,
        disabled: boolean,
    ) {
        this.id = id;
        this.type = type;
        this.createdAt = createdAt;
        this.insertedAt = insertedAt;
        this.isNew = isNew;
        this.isChecked = isChecked;
        this.question = question;
        this.answerId = answerId;
        this.topicTitle = topicTitle;
        this.disabled = disabled;
    }
}
