import {Component, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {ConfigService} from '../../../../services/config.service';
import {takeUntil} from 'rxjs/operators';
import {PhotoGalleryService} from '../../../../services/photo-gallery.service';
import {saveAs} from 'file-saver';


@Component({
    selector: 'app-photo-gallery',
    templateUrl: './photo-gallery.component.html',
    styleUrls: ['./photo-gallery.component.scss']
})
export class PhotoGalleryComponent implements OnInit, OnDestroy {


    @Input() entityId = null;
    // Активная картинка
    openedId = null;
    photoList = [];
    private destroyed = new Subject<void>();
    public loaded = false;

    constructor(
        private galleryService: PhotoGalleryService,
        private config: ConfigService) {
    }

    // Управление галереей через клавиатура
    @HostListener('document:keydown.arrowLeft', ['$event']) onLeftArrowClick(event: KeyboardEvent) {
        if (this.photoList && this.photoList.length) {
            this.prevImage(event);
        }
    }

    @HostListener('document:keydown.arrowRight', ['$event']) onRightArrowClick(event: KeyboardEvent) {
        if (this.photoList && this.photoList.length) {
            this.nextImage(event);
        }
    }

    @HostListener('document:keydown.escape', ['$event']) onLEscapeClick() {
        if (this.photoList && this.photoList.length) {
            this.closeFullScreen();
        }
    }

    ngOnInit() {
        this.galleryService.photos$
            .pipe(takeUntil(this.destroyed))
            .subscribe(res => {
                if (res && this.photoList !== res) {
                    this.photoList = res;
                }
                this.openedId = this.photoList[0];

                setTimeout(() => {
                    this.loaded = true;
                }, 1000)
            });

        this.galleryService.entityId$
            .pipe(takeUntil(this.destroyed))
            .subscribe(id => {
                if (id) {
                    this.entityId = id;
                }
            });

        this.galleryService.currentPhotoId$
            .pipe(takeUntil(this.destroyed))
            .subscribe(res => {
                if (res && this.openedId) {
                    this.openedId = res;
                }
            });
    }

    closeFullScreen() {
        this.openedId = null;
        this.photoList = [];
        this.galleryService.currentPhotoId$.next(null);
        this.galleryService.photos$.next(null);
    }

    nextImage(evt) {
        evt.stopPropagation();
        const openedIndex = this.photoList.findIndex((el) => el.id === this.openedId);
        this.openedId = this.photoList[openedIndex + 1] ? this.photoList[openedIndex + 1].id : this.photoList[0].id;
    }

    prevImage(evt) {
        evt.stopPropagation();
        const openedIndex = this.photoList.findIndex((el) => el.id === this.openedId);
        this.openedId = this.photoList[openedIndex - 1] ? this.photoList[openedIndex - 1].id : this.photoList[this.photoList.length - 1].id;
    }

    getImageUrl(file) {
        return `${this.config.fileStoragePicsUrl}${file.name}`;
    }

    getCurrentFile() {
        return this.photoList.find(f => f.id === this.openedId);
    }

    getDownloadLink() {
        const file = this.getCurrentFile();
        return `${this.config.fileStoragePicsUrl}${file.name}`;
    }

    ngOnDestroy() {
        this.galleryService.currentPhotoId$.next(null);
        this.galleryService.photos$.next(null);
        this.destroyed.next();
        this.destroyed.complete();
    }

    downloadImage($event) {
        $event.preventDefault();
        $event.stopPropagation();
        $event.stopImmediatePropagation();

        const item = this.getCurrentFile();
        saveAs(this.getDownloadLink(), item.name);

        return false;
    }
}
