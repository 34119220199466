import {JsonElementType, JsonProperty, JsonType} from 'ta-json';
import {Rating} from '../../shared/models/rating';
import {FunctionInfo} from './functions/function-info';
import {User} from '../../../models/user';
import {SubdivisionShortSlot} from './subdivision-short-slot';

export class SlotSubdivision {
    @JsonProperty('id')
    @JsonType(Number)
    id: number = null;

    @JsonProperty('title')
    @JsonType(String)
    title: string = null;

    @JsonProperty('is_role')
    @JsonType(Boolean)
    is_role = true;

    @JsonProperty('description')
    @JsonType(String)
    description: string = null;

    @JsonProperty('destiny')
    @JsonType(String)
    destiny: string = null;

    @JsonProperty('rating')
    @JsonElementType(Rating)
    rating: Rating = null;

    @JsonProperty('functions')
    @JsonElementType(FunctionInfo)
    functions: FunctionInfo[] = null;

    @JsonProperty('user')
    @JsonElementType(User)
    user: User = null;

    @JsonProperty('struct')
    @JsonElementType(SubdivisionShortSlot)
    struct: SubdivisionShortSlot = null;

    @JsonProperty('is_active')
    @JsonType(Boolean)
    is_active = false;

    constructor(
        id?: number,
        title?: string,
        description?: string,
        destiny?: string,
        functions: FunctionInfo[] = []
    ) {
        this.id = id;
        this.title = title;
        this.description = description;
        this.destiny = destiny;
        this.functions = functions;
    }

}
