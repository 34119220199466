<div class="nb_buttons">
    <button mat-icon-button class="nb_button" [ngClass]="{'_active': buttonKBActive}" (click)="saveInKB(index)" matTooltip="Сохранить в Базу Знаний">
        <mat-icon class="icon"  [ngClass]="{'_active-book': buttonKBActive}" svgIcon="radius_book-open"></mat-icon>
    </button>
    <button mat-icon-button *ngIf="buttonKBActive" class="nb_button _second" [ngClass]="{'_active': buttonConversionGDActive}"
     (click)="transformInGD(index)" matTooltip="Преобразовать в GoogleDoc">
        <mat-icon class="icon" [ngClass]="{'_active': buttonConversionGDActive}" svgIcon="radius_add_to_drive"></mat-icon>
    </button>
    <!-- <button mat-icon-button *ngIf="buttonActive" class="nb_button">
        <mat-icon class="icon" svgIcon="radius_lock-open"></mat-icon>
    </button> -->
</div>
