import {JsonProperty, JsonType} from 'ta-json';
import {User} from '../../../models/user';

export class HistoryPhonebook {
    @JsonProperty('event')
    @JsonType(String)
    event: string;

    @JsonProperty('user')
    @JsonType(User)
    user: User;

    @JsonProperty('created_at')
    @JsonType(Date)
    createdAt: Date;

    constructor(event: string, user: User, createdAt: string) {
        this.event = event;
        this.createdAt = new Date(this.createdAt);
        this.user = user;
    }

}
