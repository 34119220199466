import {Component, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-skip-confirm-dialog',
  templateUrl: './skip-confirm-dialog.component.html',
  styleUrls: ['./skip-confirm-dialog.component.scss']
})
export class SkipConfirmDialogComponent {

  constructor(public dialogRef: MatDialogRef<SkipConfirmDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data,) {}

    close(result): void {
        this.dialogRef.close(result);
    }

    getName() {
    return this.data.title === 'обучение' ? 'обучению' : 'введению';
    }
}
