<div class="backdrop" (click)="onClose()"></div>


<section class="form">
    <div class="sidebar-card kit">
        <header class="sidebar-card__header">
            <h1 class="sidebar-card__title title">Показатели</h1>
            <div class="sidebar-card__toolbar">
                <button type="button" class="button _icon" (click)="onClose()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </header>
        <section class="content-container" *ngIf="!isLoaded">
            <app-spinner [isSpinnerOn]="true"></app-spinner>
        </section>
        <section class="content-container" *ngIf="isLoaded">
<!--            <div class="sidebar-card__section">-->
<!--                <strong class="subheader">Оценки удовлетворенности</strong>-->
<!--                <div *ngFor="let m of metrics">-->
<!--                    <div (click)="onAddMetricClick($event, m)" class="metric-item">-->
<!--                        <mat-checkbox *ngIf="notAdded(m)" class="checkbox-18px" [checked]="metricSubmit?.includes(m.id)"></mat-checkbox>-->
<!--                        <mat-checkbox *ngIf="!notAdded(m)" class="checkbox-18px" [disabled]="true" [checked]="true"></mat-checkbox>-->


<!--                        <div class="txt">-->
<!--                            {{m.title}}-->
<!--                        </div>-->

<!--                        <div class="rating _green">-->
<!--                            <div class="txt">{{m.rating.toFixed(1)}}</div>-->
<!--                            <mat-icon class="arrow" svgIcon="radius_arrow_up"></mat-icon>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
            <div class="sidebar-card__section">
                <strong class="subheader">Шаблоны KPI</strong>
                <div>
                    <div class="search-block">
                        <input class="input" placeholder="Поиск" [formControl]="searchQuery"/>
                        <button *ngIf="searchStr" (click)="clear()" class="clear-button">
                            <mat-icon class="icon _clear">clear</mat-icon>
                        </button>
                    </div>
                    <div class="selected-templates" *ngIf="selectedTemplates.length > 0">
                        <div class="heading">
                            <div class="txt">Выбраны</div>
                            <a href="#" class="link" (click)="clearSelectedTemplated($event)">Очистить все</a>
                        </div>
                        <mat-chip-list class="chips grey-chips">
                            <mat-chip removable="true" *ngFor="let t of selectedTemplates"
                                (removed)="onDeselectKPITemplate(t)"
                            >
                                {{t.title}}
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>
                        </mat-chip-list>
                    </div>
                    <div *ngFor="let m of kpiTemplatesList">
                        <div (click)="onAddKPIClick($event, m)" class="metric-item">
                            <mat-checkbox *ngIf="notAddedKPI(m)" class="checkbox-18px" [checked]="kpiSubmit?.includes(m.id)"></mat-checkbox>
                            <mat-checkbox *ngIf="!notAddedKPI(m)" class="checkbox-18px" [disabled]="true" [checked]="true"></mat-checkbox>


                            <div class="txt clickable" [innerHTML]="m.title | highlight: searchStr" (click)="openKPITemplateCard($event, m)"></div>
                        </div>
                    </div>

                    <button class="link-button add-btn" (click)="addNewKPITemplate()">
                        <mat-icon class="icon">add</mat-icon>
                        <span class="txt">Создать шаблон KPI</span>
                    </button>

                </div>
            </div>
        </section>
        <footer class="footer" *ngIf="metricSubmit?.length > 0 || kpiSubmit?.length > 0">
            <span class="title">Выбрано: {{metricSubmit.length + kpiSubmit.length}} {{pluralize(metricSubmit.length + kpiSubmit.length, 'показатель', 'показатели', 'показателей')}}</span>
            <div class="buttons">
                <button mat-flat-button class="button _white _cancel"
                        (click)="onClose()">Отменить
                </button>
                <button mat-flat-button class="button _blue _save"
                        (click)="onSave()">Добавить
                </button>
            </div>
        </footer>
    </div>
</section>
