<div class="reply">
    <span class="border"></span>
    <img class="photo" *ngIf="hasAttachments && isImage()"
          [src]="getImageUrl(reply.attachments[0])">

    <img class="file__img" *ngIf="hasAttachments && reply.attachments[0] && !isImage() && !isAudio()"
         [src]="getFileUrl(reply.attachments[0])">

    <div class="reply__msg" [ngClass]="{'_attachment': hasAttachments && reply.attachments[0]}">
        <span class="name" *ngIf="!edit">{{ reply.lastName + ' ' + reply.firstName }}</span>
        <span class="name" *ngIf="edit">Редактирование</span>
        <span class="message" *ngIf="reply.message && reply.message.length">{{ reply.message }}</span>

        <span class="message _grey" *ngIf="hasAttachments && !reply.message || !reply.message.length">{{ type() }}</span>
    </div>
</div>