import * as moment from 'moment';
import {JSON, JsonElementType, JsonObject, JsonProperty, JsonType,} from 'ta-json';

import {PositionShort} from '../modules/phonebook/models/position-short';
import {SubdivisionShortSlot} from '../modules/phonebook/models/subdivision-short-slot';
import {Rating} from '../modules/shared/models/rating';
import {Account} from './account';
import {Messenger} from './messenger';
import {Phone} from './phone';
import {Status} from './status';
import {Subdivision} from './subdivision';

@JsonObject()
export class MobilePhone {
    @JsonProperty('id')
    @JsonType(Number)
    id: number;

    @JsonProperty('is_verified')
    @JsonType(Boolean)
    isVerified: boolean;

    @JsonProperty('phone')
    @JsonType(String)
    phone: string;
}

export class PlaningUserStatsNew {
    @JsonProperty('coef')
    @JsonType(Number)
    coef?: number = 0;

    @JsonProperty('exp')
    @JsonType(Number)
    exp?: number = 0;

    @JsonProperty('sign')
    @JsonType(Number)
    sign?: number = 0;
}

export class PlaningUserStats {
    @JsonProperty('assessment_done')
    @JsonElementType(PlaningUserStatsNew)
    doneNew?: PlaningUserStatsNew = null;

    @JsonProperty('assessment_total')
    @JsonElementType(PlaningUserStatsNew)
    totalNew?: PlaningUserStatsNew = null;

    @JsonProperty('number_of_tasks')
    @JsonType(Number)
    count?: number = 0;

    @JsonProperty('assessment_planing')
    @JsonElementType(Number)
    plan?: number = null;
}

/**
 * Модель краткого представления пользователя
 */
@JsonObject()
export class UserShort {
    /** Уникальный идентификатор */
    @JsonProperty()
    @JsonType(String)
    id: string;
    /** Имя */
    @JsonProperty('first_name')
    @JsonType(String)
    firstName: string;
    /** Отчество, если есть */
    @JsonProperty('middle_name')
    @JsonType(String)
    middleName?: string;
    /** Фамилия */
    @JsonProperty('last_name')
    @JsonType(String)
    lastName: string;
    /** Должность */
    @JsonProperty('position')
    @JsonType(String)
    position?: string;
    /** Должность */
    @JsonProperty('current_position')
    @JsonElementType(PositionShort)
    currentPosition?: PositionShort;
    /** Вес */
    @JsonProperty('weight')
    @JsonType(Number)
    weight?: number;

    @JsonProperty('photo')
    @JsonType(String)
    photo?: string;

    @JsonProperty('subdivisions')
    @JsonType(Subdivision)
    subdivisions?: Subdivision[];

    @JsonProperty('subdivision')
    @JsonType(Subdivision)
    subdivision?: Subdivision;

    @JsonProperty('planing_points')
    @JsonType(Number)
    planing_points: number;

    @JsonProperty('stat')
    @JsonElementType(PlaningUserStats)
    stats: PlaningUserStats = null;

    @JsonProperty('is_blocked')
    @JsonElementType(Boolean)
    isBlocked?: boolean = false;

    @JsonProperty('is_hidden')
    @JsonElementType(Boolean)
    isHidden?: boolean = false;

    unread?;

    get shortestName() {
        let name = this.lastName;
        if (this.firstName?.length) {
            name += ' ' + this.firstName[0].toUpperCase() + '.';
        }
        if (this.middleName?.length) {
            name += ' ' + this.middleName[0].toUpperCase() + '.';
        }

        return name;
    }

    constructor(
        id?: any,
        firstName?: string,
        middleName?: string,
        lastName?: string,
        position?: string,
        currentPosition?: PositionShort,
        weight?: number,
        photo?: string,
    ) {
        this.id = id;
        this.firstName = firstName;
        this.middleName = middleName;
        this.lastName = lastName;
        this.position = position;
        this.currentPosition = currentPosition;
        this.weight = weight;
        this.photo = photo;
    }

    get shortName() {
        let fullName = this.lastName;
        if (this.firstName) {
            fullName += ' ' + this.firstName;
        }
        return fullName;
    }

    get name() {
        let fullName = this.lastName;
        if (this.firstName) {
            fullName += ' ' + this.firstName;
            if (this.middleName) {
                fullName += ' ' + this.middleName;
            }
        }
        return fullName;
    }
}

export class CheckedUser extends UserShort {
    public checked: boolean;

    constructor(user, checked) {
        super( user.id, user.firstName, user.middleName, user.lastName,
            user.position, user.currentPosition, user.weight, user.photo);

        this.checked = checked;
    }
}

/**
 * Модель сверх-краткого представления пользователя, только одним айдишником
 */
@JsonObject()
export class UserId {
    /** Уникальный идентификатор */
    @JsonProperty()
    @JsonType(Number)
    id: number;
}

export class UserPosition {
    @JsonProperty('id')
    @JsonType(Number)
    id: number = null;

    @JsonProperty('title')
    @JsonType(String)
    title: string = null;
}

@JsonObject()
export class UserSlot {
    @JsonProperty('id')
    @JsonType(Number)
    id: number;

    @JsonProperty('is_role')
    @JsonType(Boolean)
    isRole: boolean;

    @JsonProperty('position')
    @JsonType(UserPosition)
    position: UserPosition = null;

    @JsonProperty('position_id')
    @JsonType(Number)
    positionId: number;

    @JsonProperty('role')
    @JsonType(String)
    role: string = 'employee' || 'manager';

    @JsonProperty('struct_adm_id')
    @JsonType(Number)
    structAdmId: number;

    @JsonProperty('subdivision')
    @JsonElementType(SubdivisionShortSlot)
    subdivision: SubdivisionShortSlot = null;

    @JsonProperty('user_id')
    @JsonType(Number)
    userId: number;
}

@JsonObject()
export class Rights {
    @JsonProperty('can_edit')
    @JsonType(Boolean)
    canEdit: boolean;

    @JsonProperty('is_administrator')
    @JsonType(Boolean)
    isAdministrator: boolean;

    @JsonProperty('member_of_boards')
    @JsonType(Boolean)
    memberOfBoards: boolean;

    @JsonProperty('member_of_task')
    @JsonType(Boolean)
    memberOfTask: boolean;
}

export class AcademyUserRights {
    @JsonProperty('is_academy_administrator')
    @JsonType(Boolean)
    is_academy_administrator: boolean;

    @JsonProperty('is_company_administrator')
    @JsonType(Boolean)
    is_company_administrator: boolean;

    @JsonProperty('is_manager')
    @JsonType(Boolean)
    is_manager: boolean;

    @JsonProperty('is_platform_owner_administrator')
    @JsonType(Boolean)
    is_platform_owner_administrator: boolean;

    @JsonProperty('managed_departments_ids')
    @JsonType(Array)
    managed_departments_ids: number[];
}

@JsonObject()
export class User {
    @JsonProperty('api_key')
    @JsonType(String)
    public apiKey;

    @JsonProperty('birthday')
    @JsonType(String)
    public birthday;

    @JsonProperty('current_position')
    @JsonType(String)
    current_position;

    @JsonProperty('company')
    @JsonType(String)
    company;

    @JsonProperty('created_at')
    @JsonType(String)
    createdAt: string;

    @JsonProperty('created_by')
    @JsonType(String)
    createdBy: number;

    @JsonProperty('first_name')
    @JsonType(String)
    firstName: string;

    @JsonProperty('last_name')
    @JsonType(String)
    lastName: string;

    @JsonProperty('middle_name')
    @JsonType(String)
    middleName: string;

    @JsonProperty('gender')
    @JsonType(String)
    gender: string;

    @JsonProperty('id')
    @JsonType(Number)
    id: number;

    @JsonProperty('additional_info')
    @JsonType(String)
    additionalInfo: string;

    @JsonProperty('internal_phone')
    @JsonType(String)
    internalPhone: string;

    @JsonProperty('is_blocked')
    @JsonType(Boolean)
    isBlocked: boolean;

    @JsonProperty('location')
    @JsonType(String)
    location: string;

    @JsonProperty('photo')
    @JsonType(String)
    photo: string;

    @JsonProperty('updated_at')
    @JsonType(String)
    updatedAt: string;

    @JsonProperty('updated_by')
    @JsonType(String)
    updatedBy: number;

    @JsonProperty('division_id')
    @JsonType(Number)
    divisionId: number;

    @JsonProperty('work_phone')
    @JsonType(String)
    workPhone: number;

    @JsonProperty('mobile_phone')
    @JsonElementType(MobilePhone)
    mobilePhone: MobilePhone;

    @JsonProperty('accounts')
    @JsonElementType(Account)
    accounts: Account[];

    @JsonProperty('subdivision')
    @JsonElementType(Subdivision)
    subdivision: Subdivision;

    @JsonProperty('status')
    @JsonElementType(Status)
    status: Status;

    @JsonProperty('messenger')
    @JsonElementType(Messenger)
    messenger: Messenger[];

    @JsonProperty('phones')
    @JsonElementType(Phone)
    phones: Phone[];

    @JsonProperty('current_position')
    @JsonElementType(PositionShort)
    currentPosition: PositionShort;

    @JsonProperty()
    @JsonType(String)
    c_circle_id: string;
    @JsonProperty()
    @JsonType(String)
    c_user_id: string;
    @JsonProperty()
    @JsonType(String)
    removable: boolean;
    isMoreFoundInDetailedCard: boolean;

    @JsonProperty('is_favorite')
    @JsonType(Boolean)
    isFavorite: boolean;

    @JsonProperty('hidden')
    @JsonType(Boolean)
    hidden: boolean;

    @JsonProperty('rating')
    @JsonElementType(Rating)
    rating: Rating = null;

    @JsonProperty('slots')
    @JsonElementType(UserSlot)
    slots: UserSlot[];

    @JsonProperty('timezone')
    @JsonType(String)
    timezone: string;

    @JsonProperty('is_auto_timezone')
    @JsonType(Boolean)
    is_auto_timezone: boolean;

    constructor(
        id: number = null,
        apiKey: string = null,
        position: string = null,
        birthday: string = null,
        createdAt: string = null,
        createdBy: number = null,
        firstName: string = null,
        lastName: string = null,
        middleName: string = null,
        gender: string = null,
        additionalInfo: string = null,
        internalPhone: string = null,
        isBlocked: boolean = null,
        location: string = null,
        photo: string = null,
        updatedAt: string = null,
        updatedBy: number = null,
        workPhone: number = null,
        accounts: Account[] = [],
        subdivision: Subdivision = null,
        status: Status = null,
        messenger: Messenger[] = [],
        phones: Phone[] = [],
        isFavorite: boolean = false,
        hidden: boolean = false,
        currentPosition: PositionShort = null,
        slots: UserSlot[] = [],
    ) {
        this.id = id;
        this.apiKey = apiKey;
        this.current_position = position;
        this.birthday = birthday;
        this.createdAt = createdAt;
        this.createdBy = createdBy;
        this.firstName = firstName;
        this.lastName = lastName;
        this.middleName = middleName;
        this.gender = gender;
        this.additionalInfo = additionalInfo;
        this.internalPhone = internalPhone;
        this.isBlocked = isBlocked;
        this.location = location;
        this.photo = photo;
        this.subdivision = subdivision;
        this.updatedAt = updatedAt;
        this.updatedBy = updatedBy;
        this.workPhone = workPhone;
        this.accounts = accounts;
        this.status = status;
        this.messenger = messenger;
        this.phones = phones;
        this.isFavorite = isFavorite;
        this.hidden = hidden;
        this.currentPosition = currentPosition;
        this.slots = slots;
    }

    get name() {
        let fullName = this.lastName;
        if (this.firstName) {
            fullName += ' ' + this.firstName;
            if (this.middleName) {
                fullName += ' ' + this.middleName;
            }
        }
        return fullName;
    }

    get shortName() {
        let fullName = this.lastName;
        if (this.firstName) {
            fullName += ' ' + this.firstName;
        }
        return fullName;
    }

    birthdayColor() {
        const now = moment(new Date());
        const date = moment(new Date(this.birthday));
        const dateCurrentYear = moment(
            new Date(
                date.month() === 0 && date.date() < 7
                    ? now.year() + 1
                    : now.year(),
                date.month(),
                date.date(),
            ),
        );
        const diff = now.diff(dateCurrentYear, 'days');

        if (date.month() === now.month() && date.date() === now.date()) {
            return 'green';
        } else if (diff <= 0 && diff >= -7) {
            return 'yellow';
        } else {
            return false;
        }
    }

    /**
     * Сортирует по алфавиту переданный список юзеров
     *
     * @param persons
     * @sortType = name / birthday
     */
    sortPersons(persons: User[], isABC = true) {
        switch (isABC) {
            case true:
                persons.sort((a, b) => {
                    if (!a.lastName && !a.firstName) {
                        return 1;
                    }
                    return (a.lastName + a.firstName).localeCompare(
                        b.lastName + b.firstName,
                    );
                });
                break;
            case false:
                persons.sort((a, b) =>
                    this.compareBirthdays(a.birthday, b.birthday),
                );
                break;
            default:
                persons.sort((a, b) =>
                    (a.lastName + a.firstName).localeCompare(
                        b.lastName + b.firstName,
                    ),
                );
                break;
        }
    }

    compareBirthdays(a: string, b: string) {
        if (!a && !b) {
            return 0;
        }
        if (!a && b) {
            return 1;
        }
        if (a && !b) {
            return -1;
        }
        const aDate = new Date(a);
        const bDate = new Date(b);
        const now = new Date();
        const aWithoutYear = new Date(
            new Date(
                aDate.getFullYear(),
                aDate.getMonth(),
                aDate.getDate(),
            ).getTime() <
            new Date(
                aDate.getFullYear(),
                now.getMonth(),
                now.getDate(),
            ).getTime()
                ? 1
                : 0,
            aDate.getMonth(),
            aDate.getDate(),
        );
        const bWithoutYear = new Date(
            new Date(
                bDate.getFullYear(),
                bDate.getMonth(),
                bDate.getDate(),
            ).getTime() <
            new Date(
                bDate.getFullYear(),
                now.getMonth(),
                now.getDate(),
            ).getTime()
                ? 1
                : 0,
            bDate.getMonth(),
            bDate.getDate(),
        );
        return aWithoutYear.getTime() - bWithoutYear.getTime();
    }

    checkStatusDay() {
        if (!this.status) {
            return false;
        }

        if (this.status.dateEnd) {
            const statusEnd = moment(this.status.dateEnd);
            return statusEnd.isAfter(moment().subtract(1, 'days'));
        }

        return true;
    }

    /** Бэк выдает ошибку, если вместо null отправлять '' */
    transformBeforeHTTP(): User {
        const newObj = JSON.serialize(this);
        newObj.memberships.forEach((element) => {
            if (!element.position) {
                element.position = null;
            }
        });

        const describer = new Describer(User);
        const keys = describer.describeClass();
        for (const key of keys) {
            if (key === 'photo') {
                continue;
            }
            if (newObj.hasOwnProperty(key)) {
                const value = newObj[key];
                if (!value && value !== 0) {
                    newObj[key] = null;
                }
            }
            /** добавляет ключ/значение из класса к объекту, если их еще нет */
            if (!newObj.hasOwnProperty(key)) {
                newObj[key] = describer.getProperty(key);
            }
        }
        return newObj;
    }

    getUserShort() {
        const result = new UserShort(
            this.id,
            this.firstName,
            this.middleName,
            this.lastName,
            this.currentPosition?.title,
            this.currentPosition,
            1,
            this.photo,
        );
        return result;
    }
}

/** Вспомогательный класс описатель передаваемого класса
 * Умеет возвращать список ключей и значение по-умолчанию для ключа
 * В передаваемом классе поля должны быть инициализированы
 */
class Describer {
    obj: any;

    constructor(typeOfClass: any) {
        this.obj = new typeOfClass();
    }

    describeClass() {
        return Object.getOwnPropertyNames(this.obj);
    }

    getProperty(key) {
        return this.obj[key];
    }
}
