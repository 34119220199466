<div class="learning-anchor"></div>
<div class="board learningOpenCreateBoard _create" tourAnchor="LearningAddBoard" (click)="openShortForm()" [ngClass]="{'_hide': isShortFormOpened, '_disabled': disabledContent}">

    <mat-icon class="status">add</mat-icon>

    <div class="txt">Создать доску</div>

</div>

<div class="board _form _opened" tourAnchor="LearningAddBoardForm" [ngClass]="{'_hide': !isShortFormOpened}">

    <form class="form" [formGroup]="formGroupShort" (ngSubmit)="onSubmit()">
        <textarea #taskName
                  id="taskName"
                  class="input "
                  formControlName="name"
                  autocomplete="off"
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="0"
                  cdkAutosizeMaxRows="100"
                  (blur)="onFocusChange()"
                  (keypress)="onKeyPress($event)"
                  placeholder="Введите название"></textarea>
    </form>

    <div class="toolbar">
        <button mat-icon-button type="button" *ngIf="formGroupShort.controls['name'].value"
                class="button submit"
                (click)="onSubmit()">
            <mat-icon>done</mat-icon>
        </button>
        <button mat-icon-button type="button" class="button close learningCloseBoardCreate" (click)="closeShortForm()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
