<div class="inner" [ngClass]="{'edit-mode': isEdit}" *ngIf="slot && !added">
    <div class="head" (click)="canOpen ? clickOpen() : ''">
        <img class="user-img" [src]="getAvatar()">
    </div>
    <div class="body" (click)="canOpen ? clickOpen() : ''">
        <span class="title" *ngIf="slot.subdivision || subdivision">
            {{ slot.subdivision ? slot.subdivision.name : subdivision.name }}
        </span>

        <div class="subtitle" *ngIf="slot.position">
          <span>
            {{ slot.position.title }}
          </span>
            <mat-icon class="role"
                      *ngIf="(slot.isRole || slot.is_role) && (!isEdit || (isEdit && !isAdmin))" [svgIcon]="'radius_mask'"></mat-icon>
            <!--<app-rating-number *ngIf="isNpm && slot.rating && !isEdit" [rating]="slot.rating"
                               class="rating"></app-rating-number>-->
        </div>

    </div>
    <footer *ngIf="showManager && !slot.isRole && (!isEdit || (isEdit && !isAdmin))">
        <ng-container>
            <mat-menu #menu="matMenu" class="header__settings-menu">
                <div class="manager-txt">Административное подчинение:</div>
                <div class="manager-empty" *ngIf="!parentManager">
                    <div class="head">
                        <span *ngIf="!parentManager" class="user-img _empty">
                            <mat-icon class="icon">person_add</mat-icon>
                        </span>
                    </div>
                    <div class="body">
                        <span class="title">
                            Не назначен сотрудник
                        </span>
                        <!--
                        <div class="subtitle" *ngIf="managerSlot && managerSlot.position">
                            <span>
                                {{ managerSlot.position.title }}
                            </span>
                        </div>-->
                    </div>
                </div>
                <app-person-card *ngIf="parentManager" [userId]="parentManager.id">
                </app-person-card>
            </mat-menu>

            <img *ngIf="parentManager" class="user-img" [src]="getManagerAvatar()" [matMenuTriggerFor]="menu">
            <span *ngIf="!parentManager" class="user-img _empty" [matMenuTriggerFor]="menu">
                <mat-icon class="icon">person_add</mat-icon>
            </span>
        </ng-container>
    </footer>

    <footer *ngIf="isEdit && isAdmin" class="edit-buttons">
        <mat-icon class="icon role"
                  matTooltip="Сделать должностью"
                  (click)="changeToPosition()"
                  [ngClass]="{'edit-mode': isEdit && isAdmin}"
                  *ngIf="slot.isRole" [svgIcon]="'radius_mask'"></mat-icon>
        <mat-icon class="icon delete"
                  matTooltip="Удалить из подразделения"
                  (click)="removeUser()"
                  [ngClass]="{'edit-mode': isEdit && isAdmin}"
                  [svgIcon]="'radius_delete'"></mat-icon>

    </footer>
</div>
<button *ngIf="added" class="add-subdivision" (click)="addSubdivision()" [disabled]="!isAdmin">
    <mat-icon class="icon">add</mat-icon>
    Назначить на должность в подразделении
    <mat-icon *ngIf="!isAdmin" class="icon info" matTooltip="Для внесения изменений обратитесь к администратору">
        info_outline
    </mat-icon>
</button>
