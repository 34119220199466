import {Component, OnInit,} from '@angular/core';

@Component({
    selector: 'app-beta',
    templateUrl: './beta.component.html',
    styleUrls: ['./beta.component.scss'],
})
export class BetaComponent implements OnInit {
    ngOnInit() {

    }
}
