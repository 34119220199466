import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {GlobalNotificationCenterService} from '../../../../../../services/global-notification-center.service';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialog as MatDialog,
    MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import {CompanyService} from 'src/app/modules/phonebook/services/company.service';
import {ApiCompanyService} from 'src/app/modules/phonebook/services/api-company.service';
import {Subdivision} from 'src/app/modules/phonebook/models/subdivision';
import {PositionsService} from '../../../../../phonebook/services/positions.service';
import {Position} from '../../../../../phonebook/models/position';
import {ConfigService} from '../../../../../../services/config.service';
import {PositionsDialogComponent} from '../../../../../phonebook/components/shared-components/positions-dialog/positions-dialog.component';
import {Slot} from '../../../../../phonebook/models/slot';
import {SubdivisionUserRole} from '../../../../../../models/subdivision';
import {UserSlot} from '../../../../../../models/user';
import {PositionShortFormComponent} from '../../../../../phonebook/components/positions/position-short-form/position-short-form.component';
import {SubdivisionAddModalComponent} from './subdivision-add-modal/subdivision-add-modal.component';

@Component({
    selector: 'app-subdivision-edit',
    templateUrl: './subdivision-edit.component.html',
    styleUrls: ['./subdivision-edit.component.scss']
})
export class SubdivisionEditComponent implements OnInit, OnDestroy {
    public title = '';
    public step: number;
    public isLoaded = false;
    private destroyed = new Subject<void>();

    public searchStr = '';

    public subdivisionsTree: Subdivision[] = null;
    public subdivisions: Subdivision[] = null;
    public sortedSubdivisions: Subdivision[] = [];
    public selectedSubdivisions: Subdivision[] = [];
    public selectedSubdivision: Subdivision;

    public chosenSlot;
    public managers = [];
    public employees = [];


    constructor(
        public config: ConfigService,
        private company: CompanyService,
        private api: ApiCompanyService,
        private dialog: MatDialog,
        public dialogRef: MatDialogRef<any>,
        private positionsService: PositionsService,
        private notiService: GlobalNotificationCenterService,
        @Inject(MAT_DIALOG_DATA) public data = null,
    ) {
        this.title = this.data && this.data.title ? this.data.title : 'Выберите подразделение';
        this.step = 1;
    }

    ngOnInit(): void {
        this.getSubdivisions();
        this.company.onCreatePositionClick$
            .pipe(takeUntil(this.destroyed))
            .subscribe((res) => {
                const groups = this.positionsService.groups$.value;
                if (groups && groups.length) {
                    this.openFormPositionsCreate();
                } else {
                    this.getPositions();
                }
            });

    }

    addSubdivision() {
        const dialogForm = this.dialog.open(SubdivisionAddModalComponent, {
            width: '500px',
            data: {subdivisions: this.subdivisions}
        });

        dialogForm.afterClosed().subscribe(result => {
            if (result) {
                this.isLoaded = false;
                this.getSubdivisions();
                this.nextStep([result]);
            }
        });
    }

    getPositions() {
        this.api.getFolderPositionsTree()
            .pipe(takeUntil(this.destroyed))
            .subscribe((data) => {
                // Отсортируем все по названиям
                Position.prototype.sortPositions(data.folders);
                data.folders.forEach(folder => {
                    Position.prototype.sortPositions(folder.positions);
                });
                this.positionsService.groups$.next(data.folders);
                this.openFormPositionsCreate();
            }, (err) => {
                this.notiService.handleFullError(err);
            });
    }

    openFormPositionsCreate() {
        const data = {
            title: 'Создать должность',
            folders: this.positionsService.groups$.value,
            buttons: [
                {
                    color: '_grey',
                    name: 'Отменить',
                    action: 'exit',
                    autofocus: false
                },
                {
                    color: '_blue',
                    name: 'Сохранить',
                    action: 'send',
                    autofocus: true
                }]
        };
        const dialogForm = this.dialog.open(PositionShortFormComponent, {data});
    }


    getSubdivisionImage(item) {
        if (item) {
            return this.config.getLogoSrc(item.image, 'small');
        } else {
            return this.config.photoAvatarUser + '/default/small';
        }
    }

    nextStep(item) {
        this.selectedSubdivision = item[0];
        this.step = 2;
        this.title = 'Выберите штатную единицу';
        this.prepareSlots();
    }

    prevStep() {
        this.selectedSubdivision = null;
        this.selectedSubdivisions = [];
        this.chosenSlot = null;
        this.step = 1;
        this.title = 'Выберите подразделение';
    }

    prepareSlots() {
        this.employees = this.selectedSubdivision.slots.filter(el => (el.role === SubdivisionUserRole.EMPLOYEE) && (!el.user));
        this.managers = this.selectedSubdivision.slots.filter(el => (el.role === SubdivisionUserRole.MANAGER) && (!el.user));
        this.isLoaded = true;
    }

    getSubdivisions() {
        this.api.getSubdivisions()
            .pipe(takeUntil(this.destroyed))
            .subscribe((data) => {
                this.subdivisions = [...data.subdivision];
                this.subdivisionsTree = Subdivision.prototype.makeTreeNode(this.subdivisions);
                this.isLoaded = true;
            });
    }


    onSearchInput(res) {
        this.searchStr = res;
        if (res && res.length) {
            this.sortedSubdivisions = this.arrayFilter(this.subdivisions, res);
        } else {
            this.sortedSubdivisions = null;
        }
    }

    arrayFilter(arr: Subdivision[], term: string) {
        return arr.filter(i => i.name.toString().toLowerCase().includes(term.toLowerCase()));
    }

    chooseSlot(slot) {
        this.chosenSlot = slot;
    }

    addMaster() {
        const dialogForm = this.dialog.open(PositionsDialogComponent, {
            width: '500px',
            data: {createFromPhonebook: true}
        });

        dialogForm.afterClosed().subscribe(result => {
            if (result && result.length) {
                result.forEach(pos => {
                    this.createSlot(pos, 'manager');
                });
            }
        });
    }

    addSlave() {
        const dialogForm = this.dialog.open(PositionsDialogComponent, {
            width: '500px',
            data: {createFromPhonebook: true}
        });

        dialogForm.afterClosed().subscribe(result => {
            if (result && result.length) {
                result.forEach(pos => {
                    this.createSlot(pos, 'employee');
                });
            }
        });
    }

    createSlot(pos, type) {
        this.api.getPositionById(pos.id)
            .pipe(takeUntil(this.destroyed))
            .subscribe(data => {
                const position = data.position;
                delete position.functions;
                const param = new Slot(
                    false,
                    type,
                    position,
                );

                this.selectedSubdivision.slots.push(param);

                this.prepareSlots();

                const saveData = {
                    struct: {
                        id: this.selectedSubdivision.id,
                        slots: this.selectedSubdivision.slots
                    }
                };
                this.chosenSlot = param;
              //  this.edit(saveData);

            }, (err) => {
                this.notiService.handleFullError(err);
            });
    }

    edit(data) {
        this.api.editSubdivision(data, this.selectedSubdivision.id)
            .pipe(takeUntil(this.destroyed))
            .subscribe(res => {
                this.notiService.handleSuccess('Подразделение измененено');
                if (this.chosenSlot) {
                    const newSlot: UserSlot = this.chosenSlot;
                    newSlot.subdivision = this.selectedSubdivision;
                    this.chosenSlot = null;
                    this.dialogRef.close(newSlot);
                }
            }, (err) => {
                this.chosenSlot = null;
                this.notiService.handleFullError(err);
            });
    }


    close() {
        this.dialogRef.close();
    }

    submit() {
        if (this.chosenSlot) {
            this.chosenSlot.user = this.data.user;
            const index = this.selectedSubdivision.slots.findIndex(el =>  el.id === this.chosenSlot.id);
            this.selectedSubdivision.slots[index] = this.chosenSlot;

            const data = {
                struct: {
                    id: this.selectedSubdivision.id,
                    slots: this.selectedSubdivision.slots
                }
            };

            this.edit(data);
        } else {
            this.notiService.handleError('Не выбрана штатная единица');
        }
    }

    ngOnDestroy() {
        this.chosenSlot = null;
        this.destroyed.next();
        this.destroyed.complete();
    }

}
