<div class="sign-in">
  <div class="sign-in__promo">
    <span class="back kenburns-top-right"></span>
    <figure class="sign-in__promo-logo">
      <img class="logo _white" src="assets/images/logo-white.svg">
      <figcaption>
        <h1 class="title">Радиус</h1>
        <span class="subtitle">Корпоративная платформа</span>
      </figcaption>
    </figure>
  </div>

  <section class="section">
    <h2 class="title-error">404</h2>
    <span class="txt">Запрашиваемая страница не существует
    <br>
    <a class="link" [routerLink]="'/'">вернуться на главную</a>
    </span>

  </section>
</div>
