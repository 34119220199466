<section class="dialog" [formGroup]="formGroup">
    <header class="dialog__header">
            <div class="title">
                <h3 class="dialog__title">Добавить подразделение</h3>
            </div>

        <mat-icon class="close" (click)="close()">close</mat-icon>
    </header>


    <div class="dialog__content kit" *ngIf="isLoaded">
        <label class="label kit">
                    <span class="textarea-wrap">
                        <textarea [placeholder]="'Введите название'"
                                  formControlName="name"
                                  #titleElem
                                  class="textarea _name"
                                  [ngClass]="{'_error' : hasError(formGroup.controls['name'])}"
                                  autocomplete="off"
                                  cdkTextareaAutosize
                                  #autosize="cdkTextareaAutosize"
                                  cdkAutosizeMinRows="0"
                                  cdkAutosizeMaxRows="100"></textarea>
                        <span class="textarea-wrap__emul"></span>
                    </span>
            <mat-error class="error" *ngIf="hasError(formGroup.controls['name'])">
                {{ sharedService.FORM_INVALID_REQUIRED }}
            </mat-error>
        </label>


        <label class="label _parent kit"  *ngIf="isLoaded">
            <span class="label__txt">
                 Входит в
            </span>
            <mat-select class="select"
                        formControlName="parent">
                <mat-select-trigger *ngIf="formGroup.controls['parent'].value">
                        <span class="option-txt">
                            <img class="option-logo" [src]="getAvatar(formGroup.controls['parent'].value)">
                            {{ formGroup.controls['parent'].value.name }}
                        </span>
                </mat-select-trigger>
                <ng-container *ngFor="let item of data.subdivisions">
                    <mat-option [value]="item">
                            <span class="option-txt">
                                <img class="option-logo" [src]="getAvatar(item)"> {{ item.name }}
                            </span>
                    </mat-option>
                </ng-container>

            </mat-select>
        </label>
    </div>
    <footer class="dialog__footer"  *ngIf="isLoaded">
        <app-button class="button" type="reset" buttonType="outlined" title="Отменить" (click)="close()"></app-button>
        <app-button class="button" type="submit"
                    (click)="submit()" title="Сохранить"
                    [disabled]="isButtonsDisabled"></app-button>
    </footer>

    <app-spinner class="loader" [isSpinnerOn]="true" *ngIf="!isLoaded"></app-spinner>
</section>