import {Injectable} from '@angular/core';
import {ConfigService} from '../../../services/config.service';
import {NotifierService} from 'angular-notifier';
import {AuthService} from '../../../services/auth.service';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigFunctionsService extends ConfigService {

    constructor(
        notifierService: NotifierService,
        authService: AuthService
    ) {
        super(notifierService);
    }

    readonly functionsQuestion = environment.functionsQuestion;
    readonly functionsSettings = environment.functionsSettings;
    /** API URL фото организации */
    readonly logoOrgUnitURL: string = environment.logoOrgUnitURL;

    /**
     * Возвращает URL фото логотипа (если нет - дефолтное)
     *
     * @params {string} orgUnitId
     * @return string
     */
    getLogo(orgUnitId) {
        return this.logoOrgUnitURL + '/' + orgUnitId + '/small';
    }
}
