<h1>Test Sentry</h1>

<ul>
  <li>
    <button (click)="unhandledException()">Unhandled exception</button>
  </li>
  <li>
    <button (click)="handledException()">Handled exception</button>
  </li>
  <li>
    <button (click)="sentryMsg()">Sentry message</button>
  </li>

  <li>
    <button (click)="undefinedObjectMethod()">null.doThings()</button>
  </li>
</ul>
