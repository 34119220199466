import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {fromEvent, Subject} from 'rxjs';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';

@Component({
    selector: 'app-search-mat',
    templateUrl: './search-mat.component.html',
    styleUrls: ['./search-mat.component.scss']
})
export class SearchMatComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() kitView = false;
    @Input() placeholderTxt = 'Поиск';

    // Значение введенное
    public inputValue = '';
    /** Поле поиска */
    private searchTermElRef: ElementRef;
    @ViewChild('searchTerm') set content(content: ElementRef) {
        this.searchTermElRef = content;
    }
     // Передает результат ввода наверх
    @Output()
    public readonly textChange = new EventEmitter();
    /** Подписки живут пока не... */
    destroyed: Subject<void> = new Subject<void>();
    // Модель формы редактирования вопроса
    public formGroup: UntypedFormGroup;

    constructor() {
    }

    ngOnInit() {
        this.formGroup = new UntypedFormGroup({
            inputValue: new UntypedFormControl('')
        });
    }

    ngAfterViewInit() {
        fromEvent(this.searchTermElRef.nativeElement, 'keyup')
            .pipe(
                takeUntil(this.destroyed),
                debounceTime(300),
                distinctUntilChanged())
            .subscribe((keyboardEvent: any) => {
                this.textChange.emit(keyboardEvent.target.value);
                this.inputValue = this.formGroup.controls['inputValue'].value;
            });
    }

    onClear() {
        this.inputValue = '';
        this.formGroup.controls['inputValue'].setValue('');
        this.textChange.emit('');
        this.searchTermElRef.nativeElement.focus();
    }

    ngOnDestroy() {
        this.textChange.emit('');
        this.destroyed.next();
        this.destroyed.complete();
    }

}
