<ng-container *ngIf="isVisible && inited">
    <div class="back-layer" (click)="closeFullScreen()" *ngIf="!isMobile">
        <div class="wnd" [ngClass]="{'_small': mainSlider}">
            <!--<button mat-icon-button (click)="backToCommonHint()" class="back-btn">
                <mat-icon>arrow_back</mat-icon>
            </button>-->
            <button mat-icon-button (click)="closeFullScreen(); openAnotherIntro()" class="close-btn">
                <mat-icon>close</mat-icon>
            </button>

            <div class="dialog__content">
                <img src="../../../../../../assets/images/onboarding/tasks-start.png">
                <h2>Добро пожаловать в раздел «Задачи»</h2>
                <span class="description">Мы подготовили небольшой обзор о возможностях данного раздела, чтобы его использование было для вас понятным и удобным</span>
            </div>
            <footer class="dialog__footer">
                <app-button class="button _reset" id="skipBtn"
                            buttonType="link"
                            title="Пропустить"
                            (click)="closeFullScreen(); openAnotherIntro()"></app-button>

                <app-button class="button"
                            (click)="startTour()"
                            title="Начать введение"></app-button>
            </footer>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="compliteIntro && inited">
    <div class="back-layer" (click)="compliteIntro = false" *ngIf="!isMobile">
        <div class="wnd" [ngClass]="{'_small': mainSlider}">
            <button mat-icon-button (click)="closeFullScreen()" class="close-btn">
                <mat-icon>close</mat-icon>
            </button>

            <div class="dialog__content">
                <img src="../../../../../../assets/images/onboarding/tasks-onboarding-done.png">
                <h2>Поздравляем!<br>  Вы прошли введение в разделе «Задачи»</h2>
            </div>
            <footer class="dialog__footer">
                 <app-button class="button _reset"
                             buttonType="link"
                             (click)="openAnotherIntro('обучению')"
                            title="Завершить"></app-button>
                 <app-button class="button"
                            (click)="openLearning()"
                            title="Пройти обучение"></app-button>
            </footer>
        </div>
    </div>
</ng-container>