import {JsonElementType, JsonProperty, JsonType} from 'ta-json';
import {UserShort} from '../../../models/user';

export class HistoryRescheduling {
    @JsonProperty('approver')
    @JsonElementType(UserShort)
    approver: UserShort = null;

    @JsonProperty('approver_comment')
    @JsonType(String)
    approver_comment: String = null;

    @JsonProperty('requestor')
    @JsonElementType(UserShort)
    requestor: UserShort = null;

    @JsonProperty('requestor_comment')
    @JsonType(String)
    requestor_comment: String = null;

    @JsonProperty('quality_comment')
    @JsonType(String)
    quality_comment: String = null;

    @JsonProperty('rating_duration')
    @JsonType(Number)
    rating_duration: number = null;

    @JsonProperty('rating_quality')
    @JsonType(Number)
    rating_quality: number = null;

    @JsonProperty('count')
    @JsonType(Number)
    count: number = null;

    @JsonProperty('date_from')
    @JsonType(Date)
    date_from: Date = null;

    @JsonProperty('date_to')
    @JsonType(Date)
    date_to: Date = null;

    @JsonProperty('inserted_at')
    @JsonType(Date)
    inserted_at: Date = null;

    @JsonProperty('status')
    @JsonType(String)
    status: string = null;

    @JsonProperty('updated_at')
    @JsonType(Date)
    updated_at: Date = null;
}
