import {JsonProperty, JsonType} from 'ta-json';
import {MotivationsCustomField} from './motivations-custom-field';

export class MotivationsPeriodCustomField {
    @JsonProperty('id')
    @JsonType(Number)
    id: number;

    @JsonProperty('filling')
    @JsonType(String)
    filling: string;

    @JsonProperty('field')
    @JsonType(MotivationsCustomField)
    field: MotivationsCustomField;

    constructor(
        id?: number,
        filling: string = '',
        field: MotivationsCustomField = null,
    ) {
        this.id = id;
        this.filling = filling;
        this.field = field;
    }

}
