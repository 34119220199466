import {UserShort} from '../../../../models/user';
import {JsonObject, JsonProperty, JsonType} from 'ta-json';

@JsonObject()
export class MotivationsUserShort extends UserShort {
    @JsonProperty('role')
    @JsonType(String)
    role?: string;

    @JsonProperty('is_approved')
    @JsonType(Boolean)
    isApproved?: boolean = false;

    is_readed?: boolean;

    constructor(
        userShort?: UserShort,
        role: string = '',
        isApproved: boolean = false,
        isNotReaded: boolean = true,
    ) {
        super(userShort.id, userShort.firstName, userShort.middleName, userShort.lastName, userShort.position, userShort.currentPosition, userShort.weight, userShort.photo);
        this.role = role;
        this.isApproved = isApproved;
        this.is_readed = !isNotReaded;
        this['isNotReaded'] = isNotReaded;
    }
}
