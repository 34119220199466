import {JsonObject, JsonProperty, JsonType} from 'ta-json';

@JsonObject()
export class Link {
    @JsonProperty('name')
    @JsonType(String)
    name?: string;

    @JsonProperty('url')
    @JsonType(String)
    url?: string;

    constructor(
        name?: string,
        url?: string,
    ) {
        this.name = name;
        this.url = url;
    }

    isGDoc?() {
        return this.url.indexOf('docs.google') >= 0;
    }
}
