<div class="exit-blur-box">
    <div class="exit">
        <h3>{{ title }}</h3>

        <mat-form-field *ngIf="textarea"
                        appearance="standard"
                        class="text">
            <textarea matInput
                      class="textarea"
                      #textInput
                      [(ngModel)]="text"
                      autocomplete="off"
                      autofocus
                      [required]="true"
                      cdkTextareaAutosize
                      #autosize="cdkTextareaAutosize"
                      cdkAutosizeMinRows="0"
                      cdkAutosizeMaxRows="100"
                      [placeholder]="textarea"></textarea>
        </mat-form-field>

        <div class="confirm-buttons">
            <button mat-flat-button
                    type="button"
                    class="button _blue"
                    (click)="onConfirm()">
                {{ confirmTitle }}
            </button>
            <button mat-flat-button
                    type="reset"
                    class="button"
                    (click)="cancel.emit(true);">
                {{ cancelTitle }}
            </button>
        </div>
    </div>
</div>
