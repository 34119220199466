import {UserShort} from '../../../models/user';
import {JsonElementType, JsonObject, JsonProperty, JsonType} from 'ta-json';

@JsonObject()
export class PlaningUserStats {
    @JsonProperty('manual_points')
    @JsonType(Number)
    manual_points?: number = 0;

    @JsonProperty('manual_points_done')
    @JsonType(Number)
    done?: number = 0;

    // @JsonProperty('assessment_done')
    // @JsonType(Number)
    // done?: number = 0;

    @JsonProperty('assessment_total')
    @JsonType(Number)
    total?: number = 0;

    @JsonProperty('number_of_tasks')
    @JsonType(Number)
    count?: number = 0;

    @JsonProperty('planing_points')
    @JsonType(Number)
    planing_points?: number;

    @JsonProperty('sprint_points')
    @JsonType(Number)
    sprint_points?: number;
}


@JsonObject()
export class PlaningUser extends UserShort {
    @JsonProperty('planing_points')
    @JsonType(Number)
    planing_points = 0;

    @JsonProperty('stats')
    @JsonElementType(PlaningUserStats)
    stats: PlaningUserStats = null;

    @JsonProperty('stat')
    @JsonElementType(PlaningUserStats)
    stat?: PlaningUserStats;
}
