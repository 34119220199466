import * as moment from 'moment';
import { JsonProperty, JsonType } from 'ta-json';

import { FunctionFull } from '../../phonebook/models/functions/function';
import { Rating } from '../../shared/models/rating';
import { QuestionResponce } from './question-responce';
import { QuestionShort } from './question-short';

export class Question extends QuestionShort {
    @JsonProperty('comment')
    @JsonType(String)
    comment = '';

    @JsonProperty('is_anonymous')
    @JsonType(Boolean)
    isAnonymous = false;

    @JsonProperty('last_answer')
    @JsonType(Question)
    lastAnswer: Question;

    @JsonProperty('answers')
    @JsonType(QuestionResponce)
    answers: QuestionResponce[];

    @JsonProperty('answer')
    @JsonType(QuestionResponce)
    answer: QuestionResponce;

    @JsonProperty('function')
    @JsonType(FunctionFull)
    functionFull: FunctionFull;

    constructor(
        questionShort: QuestionShort,
        comment: string,
        isAnonymous: boolean,
        lastAnswer: Question,
        answers: QuestionResponce[],
        answer: QuestionResponce,
    ) {
        super(
            questionShort.id,
            questionShort.createdAt,
            questionShort.insertedAt,
            questionShort.type,
            questionShort.isNew,
            questionShort.isChecked,
            questionShort.question,
            questionShort.answerId,
            questionShort.topicTitle,
            questionShort.disabled,
        );
        this.comment = comment;
        this.isAnonymous = isAnonymous;
        this.lastAnswer = lastAnswer;
        this.answers = answers;
        this.answer = answer;
    }
}

export function getAnswersWithMonths(chart, list) {
    let months = [];
    moment.locale('ru');
    const keys = [
        ...Object.keys(chart).map((el) => {
            return +el;
        }),
    ];

    list.forEach((el) => {
        let begin = moment(el.updated_at).startOf('month').startOf('day');
        const offset = begin.utcOffset();
        const currentDate =
            (begin.toDate().getTime() + offset * 60 * 1000) / 1000;

        const mIndex = months.findIndex((m) => {
            return m.date === currentDate;
        });
        if (mIndex >= 0) {
            months[mIndex]['answers'].push(el);
        } else {
            const currentIndex = keys.findIndex((el) => el === currentDate);

            let direction = 'positive';
            const currentRating = chart[currentDate];
            const prevRating = chart[keys[currentIndex - 1]];

            if (currentRating < prevRating) {
                direction = 'negative';
            }

            let rating = new Rating();
            rating.value = currentRating;
            rating.direction = direction;

            const month = {
                date: currentDate,
                name: moment(el.updated_at).format('MMMM'),
                rating: rating,
                answers: [el],
            };

            months.push(month);
        }
    });

    return months;
}
