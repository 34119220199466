import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {Subdivision} from '../../../../phonebook/models/subdivision';
import {CompanyService} from '../../../../phonebook/services/company.service';
import {AuthService} from '../../../../../services/auth.service';
import {NavService} from '../../../../phonebook/services/nav.service';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';

/** Flat node with expandable and level information */
interface ExampleFlatNode {
    expandable: boolean;
    name: string;
    level: number;
}

@Component({
    selector: 'app-subdivision-list',
    templateUrl: './subdivision-list.component.html',
    styleUrls: ['./subdivision-list.component.scss']
})
export class SubdivisionListComponent implements OnInit, OnDestroy, OnChanges {

    @Output() public readonly onSelectedChanges = new EventEmitter();
    private userId = null;
    @Input() public subdivisions: Subdivision[] = null;
    @Input() public singleSelection = false;
    @Input() public sortedSubdivisions: Subdivision[] = null;
    @Input() public selectedSubdivisions = null;
    @Input() public disabled = false;
    @Input() public searchStr = '';
    @Output() public click: EventEmitter<Subdivision> = new EventEmitter();
    public opened = [];
    public selected = null;
    private destroyed = new Subject<void>();

    @ViewChild('tree') tree;


    private _transformer = (node: Subdivision, level: number) => ({
            expandable: (!!node.children && node.children.length > 0) ,
            name: node.name,
            children: node.children,
            level,
            id: node.id,
            slots: node.slots,
            image: node.image
        });

    treeControl = new FlatTreeControl<ExampleFlatNode>(
        node => node.level,
        node => node.expandable,
    );

    treeFlattener = new MatTreeFlattener(
        this._transformer,
        node => node.level,
        node => node.expandable,
        node => node.children,
    );

    dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    constructor(
        private company: CompanyService,
        private auth: AuthService,
        private nav: NavService,
    ) {
    }

    hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
    firstChild = (_: number, node: ExampleFlatNode) => node.level === 0;

    positive = (_: number, node: ExampleFlatNode) => node.name !== '-';

    onSubdivisionClick(sub: Subdivision, evt) {
        if (this.singleSelection) {
            this.selectedSubdivisions = [sub];
        } else {
            evt.stopPropagation();
            const index = this.selectedSubdivisions?.findIndex(el => el.id === sub.id);
            if (index >= 0) {
                this.selectedSubdivisions = this.selectedSubdivisions.filter(el => el.id !== sub.id);
            } else {
                this.selectedSubdivisions.push(sub);
            }
        }

        this.onSelectedChanges.emit(this.selectedSubdivisions);

    }

    isSelected(item) {
        return this.selectedSubdivisions?.some(el => el.id === item.id);
    }

    ngOnInit(): void {
        this.userId = this.auth.currentUser$.value.id;
        this.dataSource.data = this.subdivisions;
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }

    getCounterFolder(node) {
        const cnt = node.children.length;
        return cnt;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedSubdivisions) {
            this.selectedSubdivisions = changes.selectedSubdivisions.currentValue;
        }

        if (changes.sortedSubdivisions) {
            this.sortedSubdivisions = changes.sortedSubdivisions.currentValue;
        }
    }


    ngAfterViewInit() {
        this.tree.treeControl.expandAll();
    }

}
