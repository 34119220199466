<section class="dialog kit" *ngIf="data">
    <header class="dialog__header">
        <h3 class="dialog__title">Обрезать изображение</h3>
        <mat-icon class="close" (click)="close()">close</mat-icon>
    </header>

    <div class="dialog__content form">
        <image-cropper class="cropper"
                [imageChangedEvent]="data"
                [maintainAspectRatio]="true"
                format="png"
                (imageCropped)="cropImg($event)"
        >
        </image-cropper>
    </div>

    <footer class="dialog__footer">
        <button type="reset" mat-flat-button class="button _transparent" (click)="close()">Отменить</button>
        <button type="button"
                mat-flat-button
                class="button _white"
                (click)="onSave()">Добавить без изменений</button>
        <button type="button"
                mat-flat-button
                class="button _blue"
                (click)="onSubmit()">Обрезать и добавить</button>
    </footer>
</section>
