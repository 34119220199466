import {Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {ChatsService} from '../../../../../services/chats.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ConfigService} from '../../../../../services/config.service';

@Component({
  selector: 'app-chat-users-link',
  templateUrl: './chat-users-link.component.html',
  styleUrls: ['./chat-users-link.component.scss']
})
export class ChatUsersLinkComponent implements OnInit, OnDestroy, OnChanges {
    private destroyed = new Subject<void>();

    @Input() public text = '';
    @Output() membersLength = new EventEmitter();
    @Output() addUserLink = new EventEmitter();
    public members = [];
    public result = [];
    public focusedIndex = null;

    public dogIndex = null;
    public lastSpace = null;
    public long = null;

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (this.result && this.result.length) {
            if (event.keyCode == 40) {
                this.moveDown()
            }

            if (event.keyCode == 38) {
                this.moveUp();
            }

            if (event.keyCode == 13) {
                this.onItemClick(this.result[this.focusedIndex]);

            }
        }
    }

    moveDown() {
        if (this.focusedIndex !== null) {
            if (this.focusedIndex !== this.result.length - 1) {
                this.focusedIndex += 1;
            } else {
                this.focusedIndex = 0;
            }

        } else {
            this.focusedIndex = 0;
        }
    }

    moveUp() {
        if (this.focusedIndex !== null) {
            if (this.focusedIndex !== 0) {
                this.focusedIndex -= 1;
            } else {
                this.focusedIndex = this.result.length - 1;
            }
        } else {
            this.focusedIndex = this.result.length;
        }
    }

    constructor(
      private chatService: ChatsService,
      private config: ConfigService
    ) {
    }

    ngOnInit(): void {
    this.chatService.members$
        .pipe(takeUntil(this.destroyed))
        .subscribe(res => {
          this.members = res;
        });
    }

    name(user) {
        return user.last_name + ' ' + user.first_name;
    }


    getUserAvatar(user) {
        return this.config.getUserAvatarByUser(user, 'small');
    }

    onItemHover(item, index) {
        this.focusedIndex = index;
    }

    filterUsers() {
        this.result = [];

        if (this.text && this.text.length && this.text.search('@') >= 0) {
            this.dogIndex = this.text.lastIndexOf('@');
            this.lastSpace = this.text.lastIndexOf(' ');
            this.long = this.text.length;

          if (this.dogIndex < this.long && this.dogIndex > this.lastSpace) {
              const perhapsLink = this.text.slice(this.dogIndex + 1, this.long).toLocaleLowerCase();

              this.result = this.members.filter(el => {
                  const lower = el.link.toLocaleLowerCase();
                  if (lower.search(perhapsLink) >= 0) {
                      return el;
                  }
              })
          } else {
              this.result = [];
          }

          this.membersLength.emit(this.result.length);


          // console.log(dogIndex);
          // console.log(lastSpace);
          // console.log(long);

        }
    }

    onItemClick(item) {
        this.text = this.text.slice(0,this.dogIndex) + item.link + ' ';
        this.addUserLink.emit(this.text);
    }

    ngOnChanges(changes: SimpleChanges): void {
      if (changes.text) {
          this.focusedIndex = null;
          this.text = changes.text.currentValue;
          this.filterUsers();
      }
    }


    ngOnDestroy() {

    this.destroyed.next();
    this.destroyed.complete();
  }

}
