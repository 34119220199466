<section class="dialog">
  <header class="dialog__header">
    <h3 class="dialog__title">Выбор доски</h3>

    <mat-icon aria-label="close" class="close" (click)="close()">close</mat-icon>

  </header>
  <ul class="boards-list" *ngIf="boards.length">
      <li *ngIf="showNull" class="boards-item boards-item--null-board" (click)="onBoardSelect(null)">
        <span class="board-inner">
          <mat-icon class="icon" >bookmarks</mat-icon>
          <span class="txt">{{ nullBoard.name}}</span>
        </span>
      </li>
    <ng-container *ngFor="let item of boards">
      <li *ngIf="item.id > 0 && !itemSelected(item) && !item.template_board" class="boards-item" (click)="onBoardSelect(item)">
        <span class="board-inner">
          <mat-icon class="icon" [ngStyle]="{'color' : getItemColor(item.color)}">bookmark</mat-icon>
          <span class="txt">{{ getBoardName(item) }}</span>
        </span>

        <mat-icon *ngIf="item.sharedBoard" class="icon _shared">shared</mat-icon>
      </li>
    </ng-container>
  </ul>


  <div class="boards-list no-data-filters"
       *ngIf="!boards.length">
    <img class="empty-pic" src="../../../../../../assets/images/empty.svg">
    <span class="txt">В вашей компании еще не создано
      <br>ни одной доски (кроме "Личной"),<br>
      либо нет досок, <br>
      которые можно добавить к задаче </span>
    <app-button class="button" title="Создать доску" (click)="createBoard()" *ngIf="canCreate" focus="false"></app-button>
  </div>
</section>
