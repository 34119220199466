import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PhotoGalleryService {
  /** Массив фотографий */
  public photos$ = new BehaviorSubject<any[]>([]);
  /** Выбранная фотография */
  public currentPhotoId$ = new BehaviorSubject<string>(null);
  public entityId$ = new BehaviorSubject<string>(null);

  constructor() { }
}
