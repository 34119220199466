<section class="boards">

      <header class="header">
        <h2 class="title">
          {{ title }}
          <ng-container *ngIf="boards.length && boardsLength()">

            <span *ngIf="!isPersonalBoardIsset">({{ boards.length }})</span>
            <span *ngIf="isPersonalBoardIsset" class="total">({{ boardsLength() }})</span>
          </ng-container>
        </h2>

        <app-button buttonType="add"
                    type="button"
                    class="add-button"
                    *ngIf="!disabled && (!onlyOne || boards.length < 1)"
                    (click)="openBoardsAdd()">
        </app-button>

      </header>

    <app-boards-list [disabled]="disabled"
                     [isTemplate]="isTemplate"
                     (groupAdd)="onGroupAdd($event)"
                     (groupDel)="onGroupDel($event)"
                     (boardDel)="onBoardDel($event)"
                     (boardsUpdate)="onBoardsUpdate($event)"
                     [onlyOne]="onlyOne"
                     [tagsDisabled]="tagsDisabled" [boards]="boards"></app-boards-list>
</section>



