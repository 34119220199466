import {JsonProperty, JsonType} from 'ta-json';

export class QuestionResponce {
    @JsonProperty('id')
    @JsonType(Number)
    id: number;

    @JsonProperty('title')
    @JsonType(String)
    title = '';

    @JsonProperty('points')
    @JsonType(Number)
    points: number;

    constructor(id: number,
                title: string,
                points: number) {
        this.id = id;
        this.title = title;
        this.points = points;
    }
}
