import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-chat-menu',
    templateUrl: './chat-menu.component.html',
    styleUrls: ['./chat-menu.component.scss']
})
export class ChatMenuComponent implements OnInit {

    @Output() public readonly change: EventEmitter<any> = new EventEmitter();
    @Input() public isPrivate = true;

    constructor() {
    }

    ngOnInit(): void {
    }

    onChangeChat(evt, val) {
        evt.preventDefault();
        if (this.isPrivate !== val) {
            this.isPrivate = val;
            this.change.emit(val);
        }
    }

}
