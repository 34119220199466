import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Subject} from 'rxjs';
import {NavService} from '../../../phonebook/services/nav.service';
import {takeUntil} from 'rxjs/operators';
import {CompanyService} from '../../../phonebook/services/company.service';
import {PhonebookService} from '../../../phonebook/services/phonebook.service';
import {PeopleAddComponent} from '../people-add/people-add.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {UserSubdivision} from '../../../phonebook/models/user-subdivision';
import {MotivationsUserShort} from '../../../phonebook/models/motivations/motivations-user-short';

@Component({
    selector: 'app-users-list',
    templateUrl: './users-list.component.html',
    styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit, OnDestroy, OnChanges {

    //Режим для раздела компания
    @Input() public companyMode = false;
    @Input() public users = null;
    @Input() public role = null;
    // Нужно ли возвращать должность
    @Input() public position = false;
    @Input() public title: string = null;
    @Input() public buttonTitle: string = null;
    @Input() public disabled = false;
    @Input() public expansive = true;
    @Input() public isExpandedDefault = false;
    @Output() public readonly usersChange: EventEmitter<UserSubdivision[]> = new EventEmitter();
    @Output() public readonly onOpen: EventEmitter<UserSubdivision[]> = new EventEmitter();
    @Output() public readonly onClose: EventEmitter<UserSubdivision[]> = new EventEmitter();
    public openedUserId = null;

    @Input() public approvers = false;
    @Input() public usersEditable = null;

    public isMotivationsUsers = false;

    @Input() public flag = null;

    private destroyed = new Subject<void>();
    @Input() employee;

    constructor(
        private nav: NavService,
        private company: CompanyService,
        private phonebook: PhonebookService,
        private dialog: MatDialog
    ) {
    }

    ngOnInit(): void {
        this.phonebook.openedUser$
            .pipe(takeUntil(this.destroyed))
            .subscribe(res => {
                this.openedUserId = res;
            });

        if (this.users[0] instanceof MotivationsUserShort) {
            this.isMotivationsUsers = true;
        }
    }

    openUser(user) {
        if (this.approvers) {
            return false;
        }

        if (this.companyMode) {
            this.nav.openRoleById(user.id);
        } else {
            this.nav.openUserById(user.id);
        }

    }

    openUsersForm(evt) {
        evt.stopPropagation();

        const dialogData = {
            users: null,
            selected: this.users,
            title: 'Выбор сотрудников',
            type: 'userShort',
            position: this.position,
            noBlocked: true,
            noHidden: true,
        };
        if (this.flag) {
            dialogData['flag'] = this.flag;
        }
        const dialogRef = this.dialog.open(PeopleAddComponent, {
            minWidth: '500px',
            maxWidth: '500px',
            data: dialogData
        });

        dialogRef.afterClosed()
            .pipe(takeUntil(this.destroyed))
            .subscribe(result => {
                if (result && !this.approvers) {
                    const users = [];
                    result.forEach(el => {
                        users.push(
                            new UserSubdivision (
                                el,
                                this.role,
                                true
                            )
                        );
                    });

                    this.usersChange.emit(users);
                }

                if (result && this.approvers) {
                    this.usersChange.emit(result);
                }
            });
    }


    removeUser(user) {
        // Магия для согласователей
        if (user && this.approvers) {
            const users = this.users.filter(el => el.id !== user.id);
            this.usersChange.emit(users);
            return false;
        }

        this.users = this.users.filter(el => el.id !== user.id);
        this.usersChange.emit(this.users);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.position) {
            this.position = changes.position.currentValue;
        }

        if (changes.users) {
            this.users = changes.users.currentValue;
        }

        if (changes.disabled) {
            this.disabled = changes.disabled.currentValue;
        }
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }
}
