import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {TagsService} from '../../../../task-manager/services/tags.service';
import {Subject} from 'rxjs';
import {Tag} from '../../../../task-manager/models/tag';
import {SharedService} from '../../../services/shared.service';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-tags-select',
    templateUrl: './tags-select.component.html',
    styleUrls: ['./tags-select.component.scss']
})
export class TagsSelectComponent implements OnInit, OnDestroy {
    // Триггер смерти компонента
    private destroyed = new Subject<void>();
    public sorted: Tag[] = [];
    public searchTerm = '';
    public selectedTags: Tag[] = [];
    public isCanCreate = true;
    public tags: Tag[] = [];
    private srcId;

    constructor(
        public dialogRef: MatDialogRef<any>,
        private tagsService: TagsService,
        private sharedService: SharedService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.tags = data.tags;
        this.sorted = this.tags;
        this.selectedTags = data.selectedTags;
        if (data['isCanCreate'] === false) {
            this.isCanCreate = data['isCanCreate'];
        }
        this.srcId = data.srcId;
    }


    get isLearningOpen() {
        return this.sharedService.openTaskLearning$.value;
    }

    ngOnInit() {
        this.sharedService.openTaskLearning$
            .pipe(takeUntil(this.destroyed))
            .subscribe(val => {
                if (val && this.tags.length) {
                    this.tagsService.onTagRemove$.next(this.tags[0]);
                }
            });
    }

    openFormAddNewTag() {
        this.tagsService.searchedName$.next(this.searchTerm);

        if (this.data.obs) {
            // открыли из встечи
            // TODO: надо их как-то адекватно различать
            console.log(this.srcId);
            this.tagsService.openCreateTagFormMeet$.next(this.srcId);
        } else {
            // открыли из задачи
            this.tagsService.openCreateTagForm$.next(true);
        }
        this.close();
    }

    onSearchChange(search) {
        this.searchTerm = search;
        this.sorted = this.filterValues<Tag>(search, this.tags);
    }

    filterValues<Type>(searchTerm, baseList: Type[]): Type[] {
        let newList;

        if (searchTerm) {
            const terms_str = searchTerm.toLowerCase()
                .split(' ')
                .map(i => i.trim())
                .filter(i => i);
            newList = baseList.filter(
                item => terms_str.every(
                    term => this.testItem<Type>(item, term)
                )
            );
        } else {
            newList = baseList;
        }

        return newList;
    }

    testItem<Type>(item: Type, term: string) {
        return item && (this.testString(item['name'], term)) ;
    }

    testString(value: string, term: string) {
        if (!!value) {
            return value.toString().toLowerCase().includes(term);
        }
        return false;
    }

    close() {
        this.dialogRef.close(this.selectedTags);
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }

    onAddTag($event: Tag) {
        // добавляется автоматом в списке

        // передаём в то место откуда вызвали
        if (this.data.obs) {
            this.data.obs.next(this.selectedTags);
        }
    }

    onRemoveTag($event: Tag) {
        this.selectedTags = this.selectedTags.filter(el => el.id !== $event.id);

        // передаём в то место откуда вызвали
        if (this.data.obs) {
            this.data.obs.next(this.selectedTags);
        }
    }
}
