import {JsonElementType, JsonProperty, JsonType} from 'ta-json';

export const VIEW = {
    table: 'plan',
    kanban: 'column',
    hierarchy: 'hierarchy',
    planing: 'planing',
    statistics: 'planing',
    gantt: 'gantt',
    archive: 'plan'
};

export class ViewSettingsTask {
    @JsonProperty('isMessagesVisible')
    @JsonType(Boolean)
    isMessagesVisible: boolean;

    @JsonProperty('isNumbersVisible')
    @JsonType(Boolean)
    isNumbersVisible: boolean;

    @JsonProperty('isTextStatus')
    @JsonType(Boolean)
    isTextStatus?: boolean;

    @JsonProperty('isPerformersVisible')
    @JsonType(Boolean)
    isPerformersVisible?: boolean;

    @JsonProperty('isResponsibleVisible')
    @JsonType(Boolean)
    isResponsibleVisible?: boolean;

    @JsonProperty('isDateVisible')
    @JsonType(Boolean)
    isDateVisible?: boolean;

    @JsonProperty('isAssessmentVisible')
    @JsonType(Boolean)
    isAssessmentVisible?: boolean;

    @JsonProperty('isImportanceVisible')
    @JsonType(Boolean)
    isImportanceVisible?: boolean;

    @JsonProperty('isGroupsVisible')
    @JsonType(Boolean)
    isGroupsVisible?: boolean;

    @JsonProperty('isTagsVisible')
    @JsonType(Boolean)
    isTagsVisible?: boolean;

    @JsonProperty('isBoardsVisible')
    @JsonType(Boolean)
    isBoardsVisible?: boolean;

    @JsonProperty('isShortTaskForm')
    @JsonType(Boolean)
    isShortTaskForm?: boolean;

    @JsonProperty('isSprintVisible')
    @JsonType(Boolean)
    isSprintVisible?: boolean;


    constructor(isNumbersVisible: boolean = true,
                isTextStatus: boolean = true,
                isPerformersVisible: boolean = true,
                isResponsibleVisible: boolean = true,
                isDateVisible: boolean = true,
                isAssessmentVisible: boolean = true,
                isGroupsVisible: boolean = true,
                isTagsVisible: boolean = true,
                isBoardsVisible: boolean = true,
                isShortTaskForm: boolean = false,
                isSprintVisible: boolean = true,
    ) {
        this.isNumbersVisible = isNumbersVisible;
        this.isTextStatus = isTextStatus;
        this.isPerformersVisible = isPerformersVisible;
        this.isResponsibleVisible = isResponsibleVisible;
        this.isDateVisible = isDateVisible;
        this.isAssessmentVisible = isAssessmentVisible;
        this.isGroupsVisible = isGroupsVisible;
        this.isTagsVisible = isTagsVisible;
        this.isBoardsVisible = isBoardsVisible;
        this.isShortTaskForm = isShortTaskForm;
        this.isSprintVisible = isSprintVisible;
    }
}

export class ViewSettingsMenu {
    @JsonProperty('isFiltersVisible')
    @JsonType(Boolean)
    isFiltersVisible?: boolean;
}

export class ViewSettingsScreen {
    @JsonProperty('isTableVisible')
    @JsonType(Boolean)
    isTableVisible?: boolean;

    @JsonProperty('isKanbanVisible')
    @JsonType(Boolean)
    isKanbanVisible?: boolean;

    @JsonProperty('isPlaningVisible')
    @JsonType(Boolean)
    isPlaningVisible?: boolean;

    @JsonProperty('isHierarchyVisible')
    @JsonType(Boolean)
    isHierarchyVisible?: boolean;

    @JsonProperty('isGanttVisible')
    @JsonType(Boolean)
    isGanttVisible?: boolean;

    @JsonProperty('isStatisticsVisible')
    @JsonType(Boolean)
    isStatisticsVisible?: boolean;
}

export class ViewSettingsDone {
    @JsonProperty('isDoneTableHide')
    @JsonType(Boolean)
    isDoneTableHide?: boolean;

    @JsonProperty('isDoneKanbanHide')
    @JsonType(Boolean)
    isDoneKanbanHide?: boolean;

    @JsonProperty('isDonePlaningHide')
    @JsonType(Boolean)
    isDonePlaningHide?: boolean;

    @JsonProperty('isDoneHierarchyHide')
    @JsonType(Boolean)
    isDoneHierarchyHide?: boolean;

    @JsonProperty('isDoneGanttHide')
    @JsonType(Boolean)
    isDoneGanttHide?: boolean;
}

export class ViewSettings {
    @JsonProperty('done')
    @JsonElementType(ViewSettingsDone)
    done: ViewSettingsDone;

    @JsonProperty('task')
    @JsonElementType(ViewSettingsTask)
    task: ViewSettingsTask;

    @JsonProperty('menu')
    @JsonElementType(ViewSettingsMenu)
    menu: ViewSettingsMenu;

    @JsonProperty('screen')
    @JsonElementType(ViewSettingsScreen)
    screen: ViewSettingsScreen;
}

export class ViewSettingsGantt {
    @JsonProperty('isAlwaysDateVisible')
    @JsonType(Boolean)
    isAlwaysDateVisible?: boolean;

    @JsonProperty('isWeekendsVisible')
    @JsonType(Boolean)
    isWeekendsVisible?: boolean;
}
