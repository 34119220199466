import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-empty-list-helper',
  templateUrl: './empty-list-helper.component.html',
  styleUrls: ['./empty-list-helper.component.scss']
})
export class EmptyListHelperComponent implements OnInit {

  @Input() public text = null;
  @Input() public icon = null;
  @Input() public radius_icon = null;
  @Input() public linkText = null;
  @Input() public flat_text = false;
  @Output() public onClick = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
