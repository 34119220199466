<ul class="boards" *ngIf="boards?.length > 0">
    <ng-container *ngFor="let item of boards">
        <li class="board" *ngIf="!item.name">
            <div class="board__inner">

                    <mat-icon class="icon"
                              *ngIf="item.is_main || onlyOne"
                              [matTooltip]="'Нет доступа'"
                              [ngStyle]="{'color' : getBoardColor(item.color)}">bookmark
                    </mat-icon>

                <span class="txt">Нет доступа к прикрепленной доске</span>
                <div class="toolbar" *ngIf="!disabled && canDelete">
                    <button type="button" class="button" *ngIf="item.id > 0" mat-icon-button (click)="editBoard(item)">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button type="button" class="button" *ngIf="item.id > 0" mat-icon-button (click)="deleteBoard(item)">
                        <mat-icon class="big-icon">close</mat-icon>
                    </button>
                </div>
            </div>

        </li>
        <li class="board" *ngIf="item.visible" [ngClass]="{'_personal': item.id < 0, '_selected' : isBoardSelected(item)}">
            <div class="decor"></div>

            <div class="board__inner">
                <ng-container *ngIf="item.id >= 0">
                    <mat-icon class="icon _flag"
                              *ngIf="!item.is_main && !onlyOne"
                              [matTooltip]="'Назначить доску основной'"
                              (click)="onBoardMainChange(item)"
                              [ngClass]="{'_disabled': disabled}"
                              [ngStyle]="{'color' : getBoardColor(item.color)}">bookmark_border
                    </mat-icon>
                    <mat-icon class="icon"
                              *ngIf="item.is_main || onlyOne"
                              [matTooltip]="'Основная доска'"
                              [ngStyle]="{'color' : getBoardColor(item.color)}">bookmark
                    </mat-icon>
                </ng-container>

                <ng-container *ngIf="item.id < 0">
                    <mat-icon class="icon"
                              *ngIf="!item.is_main"
                              [ngStyle]="{'color' : getBoardColor(item.color)}">bookmark_border
                    </mat-icon>

                    <mat-icon class="icon"
                              *ngIf="item.is_main"
                              [matTooltip]="'Основная доска'"
                              [ngStyle]="{'color' : getBoardColor(item.color)}">bookmark
                    </mat-icon>
                </ng-container>

                <span class="txt">{{ getboardName(item) }}</span>
                <div class="toolbar" *ngIf="!disabled && canDelete">
                    <button type="button" class="button" *ngIf="item.id > 0" mat-icon-button (click)="editBoard(item)">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button type="button" class="button" *ngIf="item.id > 0" mat-icon-button (click)="deleteBoard(item)">
                        <mat-icon class="big-icon">close</mat-icon>
                    </button>
                </div>
            </div>

            <div class="tags" *ngIf="!isTemplate">
                <app-tags-list-short [tags]="getTags(item)"
                               [disabled]="tagsDisabled"
                               [boardId]="item.id" [isTemplateGroup]="true"
                               [removable]="true"></app-tags-list-short>

                <button mat-flat-button class="button _add _transparent"
                        *ngIf="!tagsDisabled"
                        type="button"
                        (click)="openFormAdd(item.id, item.tags)">
                    <mat-icon>add</mat-icon> Добавить метку</button>
            </div>

            <div class="tags" *ngIf="isTemplate">
                <app-group-list-item *ngIf="getGroup(item)" [group]="getGroup(item)" [disabled]="tagsDisabled"
                                     (onEdit)="openGroupAdd(item)"
                                     (onDelete)="onBoardGroupRemove($event, item.id)"></app-group-list-item>


                <button mat-flat-button class="button _add-group"
                        *ngIf="!tagsDisabled && !getGroup(item)"
                        type="button"
                        (click)="openGroupAdd(item)">
                    <mat-icon class="icon">add</mat-icon> Выбрать группу доски</button>
            </div>

        </li>
    </ng-container>
</ul>


