import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {tagsMapping} from '../../../../task-manager/models/tag';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {BoardsService} from '../../../../task-manager/services/boards.service';
import {takeUntil} from 'rxjs/operators';
import {ApiBoardsService} from '../../../../task-manager/services/api-boards.service';
import {GlobalNotificationCenterService} from '../../../../../services/global-notification-center.service';
import {Subject} from 'rxjs';
import {ConfigBoardsService} from '../../../../task-manager/services/config-boards.service';
import {TagsService} from '../../../../task-manager/services/tags.service';
import {SharedService} from '../../../services/shared.service';

@Component({
    selector: 'app-tags-form',
    templateUrl: './tags-form.component.html',
    styleUrls: ['./tags-form.component.scss']
})
export class TagsFormComponent implements OnInit, OnDestroy {

    public colors = tagsMapping;
    public formGroup: UntypedFormGroup = null;
    public selected = null;
    public isButtonsDisabled = false;
    public isLoaded = false;
    public tagId: string = null;
    private boardId = null;
    // Триггер смерти компонента
    private destroyed = new Subject<void>();

    constructor(
        public dialogRef: MatDialogRef<any>,
        public boardsService: BoardsService,
        private api: ApiBoardsService,
        private notiService: GlobalNotificationCenterService,
        private config: ConfigBoardsService,
        private tagsService: TagsService,
        private sharedService: SharedService,
        @Inject(MAT_DIALOG_DATA) public data,
    ) {
    }

    get title() {
        const title = !this.tagId ? 'Создать метку' : 'Редактировать метку';
        return title;
    }

    get isLearningOpen() {
        return this.sharedService.openTaskLearning$.value;
    }

    ngOnInit() {
        this.tagId = this.data && this.data.tagId ? this.data.tagId : null;
        this.boardId = this.data && this.data.boardId ? this.data.boardId : null;

        // noinspection JSAnnotator
        this.formGroup = new UntypedFormGroup({
            board_id: new UntypedFormControl(this.boardId ? this.boardId : this.boardsService.openedBoardId$.getValue()),
            name: new UntypedFormControl(this.tagsService.searchedName$.getValue(),
                [Validators.required, Validators.maxLength(100)]),
            color: new UntypedFormControl('_red'),
            task_group: new UntypedFormControl(false)
        });

        this.tagsService.searchedName$.next('');

        this.selected = '_red';

        if (this.tagId) {
            this.getTagById();
        } else {
            this.isLoaded = true;
        }

        this.sharedService.learningBoardName$
            .pipe(takeUntil(this.destroyed))
            .subscribe(res => {
                if (res && res === 'Новая метка') {
                    this.formGroup.controls['name'].setValue(res);
                }
            });
    }

    getTagById() {
        this.api.getTagById(this.tagId)
            .pipe(takeUntil(this.destroyed))
            .subscribe(res => {
                this.formGroup.controls['name'].setValue(res.name);
                this.formGroup.controls['color'].setValue(res.color);
                this.selected = res.color;
                this.isLoaded = true;
            }, (err) => {
                this.notiService.handleFullError(err);
                this.dialogRef.close();
            });
    }

    submit() {
        const name = this.formGroup.controls['name'].value.trim();
        this.formGroup.controls['name'].setValue(name);

        if (this.formGroup.valid) {
            this.isButtonsDisabled = true;
            const data = {tag: this.formGroup.getRawValue()};

            if (this.tagId) {
                this.update(data);
            } else {
                this.create(data);
            }
        } else {
            if (this.formGroup.controls['name'].errors && this.formGroup.controls['name'].errors['maxlength']) {
                this.notiService.handleError(this.config.FORM_INVALID_100);
            } else {
                this.notiService.handleError(this.config.FORM_INVALID);
            }
        }
    }

    create(data) {
        this.api.createTag(data)
            .pipe(takeUntil(this.destroyed))
            .subscribe(tag => {
                this.tagsService.onTagCreate$.next(tag);
                this.dialogRef.close();
            }, err => {
                this.notiService.handleFullError(err);
            });
    }

    update(data) {
        this.api.updateTag(data, this.tagId)
            .pipe(takeUntil(this.destroyed))
            .subscribe(tag => {
                this.tagsService.onTagUpdate$.next(tag);
                this.dialogRef.close();
            }, err => {
                this.notiService.handleFullError(err);
            });
    }

    close() {
        this.dialogRef.close();
    }

    colorChange(evt) {
        this.selected = evt.target.value;
        this.formGroup.controls['color'].setValue(this.selected);
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }

}
