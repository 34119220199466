import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {AuthService} from '../../../../services/auth.service';
import {ChatsService} from '../../../../services/chats.service';
import {environment} from '../../../../../environments/environment';

@Component({
    selector: 'app-tasks-tariffs-dialog-admins-info',
    templateUrl: './tasks-tariffs-dialog-admins-info.component.html',
    styleUrls: ['./tasks-tariffs-dialog-admins-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TasksTariffsDialogAdminsInfoComponent implements OnInit {
    public title = null;
    public admins = null;
    LINK = environment.production ? '/apps/knowledge-base?guidebook=33e368dde70f32f49e383e947ab87c7f'
        : '/apps/knowledge-base?guidebook=273cb5735b0ce0604d9961098323efae';

    constructor(
        private auth: AuthService,
        private chats: ChatsService,
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data
    ) {
        this.title = data.title;
    }

    ngOnInit() {
        this.admins = this.auth.company$.value?.admins;
    }

    close() {
        this.dialogRef.close();
    }

    openChat(user) {
        let name = user.last_name +  ' ' + user.first_name;
        const data = {
            id: user.id,
            name: name
        };
        this.chats.openChat$.next(data);
        this.close();
    }
}
