<div class="dialog">
    <div class="header">
        <h1 class="heading">{{title}}</h1>
        <mat-icon class="close" (click)="close()">close</mat-icon>
    </div>
    <div class="content">
        <p *ngIf="description" class="subtitle" [innerHTML]="description"></p>
        <app-user-list-item *ngFor="let user of admins" class="admin"
                            [disabled]="false"
                            (click)="onClick(user)"
                            [userSubdivision]="user" [position]="getPosition(user)"></app-user-list-item>
    </div>
</div>


