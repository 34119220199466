import {Component, Input, OnInit} from '@angular/core';
import {QuestionResponce} from '../../../questions/models/question-responce';

@Component({
    selector: 'app-question-answer',
    templateUrl: './question-answer.component.html',
    styleUrls: ['./question-answer.component.scss']
})
export class QuestionAnswerComponent implements OnInit {

    @Input() public data: QuestionResponce = null;
    @Input() public isPreviousResponse = false;

    constructor() {
    }

    ngOnInit(): void {
    }

}
