import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {statusMapping} from '../../../../../models/status';
import {defaultDateMask} from '../../../../../utils/commons';

@Component({
  selector: 'app-status-select-tpl',
  templateUrl: './status-select-tpl.component.html',
  styleUrls: ['./status-select-tpl.component.scss']
})
export class StatusSelectTplComponent implements OnInit {
  /** конфиг для маски дат */
  public maskConfig = defaultDateMask();
  @Input() formGroup;
  @Input() showLabels = true;
  @Output() onClear: EventEmitter<any> = new EventEmitter();
  @Output() onCalendarClose: EventEmitter<any> = new EventEmitter();


  public statuses = statusMapping.map(el => el.type);

  constructor(
  ) {

  }

  ngOnInit() {

  }

  statusName(statusType) {
    const status = statusMapping.find(el => el.type === statusType);
    return status ? status.name : '';
  }

  clearStartDate($event) {
    $event.preventDefault();

    this.formGroup.controls['statusStart'].setValue(null);
    this.formGroup.controls['statusEnd'].setValue(null);

    this.onClear.emit();
  }

    onClose() {
      if (!this.formGroup.controls['statusEnd'].value) {
        this.formGroup.controls['statusStart'].setValue(null);
        this.formGroup.controls['statusEnd'].setValue(null);
      }
      this.onCalendarClose.emit();
    }

    get rangeSeparator() {
      return '–';
    }
}
