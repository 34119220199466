<div class="member"
     *ngIf="userSubdivision && !companyMode" (click)="onUserClick($event)"
     [ngClass]="{'_active': removable || addable, '_small': small, '_disabled': disabled}">

    <app-user-avatar class="member__img"
                     *ngIf="userSubdivision.id > 0"
                     [user]="userSubdivision"
                     [matTooltip]="disabled ? null :
            approved ? 'Согласовал: ' + userSubdivision.shortName : userSubdivision.shortName"></app-user-avatar>

    <span class="approver _not_readed" *ngIf="notReaded">
        <mat-icon>visibility_off</mat-icon>
    </span>

    <span class="approver" *ngIf="approved">
        <mat-icon>done</mat-icon>
    </span>

    <span class="member__title txt" *ngIf="!shortView">
        <ng-container *ngIf="fullName">
            <span [innerHTML]="userSubdivision.name  | highlight : searchStr"></span>
        </ng-container>

        <ng-container *ngIf="!fullName">
             <ng-container *ngIf="filtersMode">
              {{ userId === userSubdivision.id ? 'Я' : userSubdivision.firstName }}
            </ng-container>
            <ng-container *ngIf="!filtersMode">
                <span [innerHTML]="(userSubdivision.shortName ? userSubdivision.shortName:
                userSubdivision['last_name'] +' '+ userSubdivision['first_name'])  | highlight : searchStr"></span>
            </ng-container>

        </ng-container>

        <span class="member__position" *ngIf="position && userSubdivision.position && positionIsStr">
            <span [innerHTML]="userSubdivision.position | highlight : searchStr"></span>
        </span>

        <span class="member__position" *ngIf="approvers">
            {{ getRole(userSubdivision.role) }}
        </span>

        <span class="member__position" *ngIf="userSubdivision.currentPosition && position && !positionIsStr">
            <span [innerHTML]="userSubdivision.currentPosition?.title | highlight : searchStr"></span>
        </span>

<!--        <span class="member__position" *ngIf="position">-->
<!--            <span [innerHTML]="position | highlight : searchStr"></span>-->
<!--        </span>-->
    </span>

    <ng-container *ngIf="removable">
        <mat-icon class="icon remove-icon" (click)="onRemoveClick($event)">close</mat-icon>
    </ng-container>
    <ng-container *ngIf="addable">
        <mat-icon class="icon add-icon">add</mat-icon>
    </ng-container>

    <div class="close" [matTooltip]="userSubdivision.shortName">
        <mat-icon class="icon">close</mat-icon>
    </div>
</div>

<ng-container *ngIf="userPosition && companyMode">
    <div class="member" *ngFor="let item of userPosition.structs"
          (click)="onUserClick($event)">
        <img class="member__img"
             *ngIf="userPosition.id > 0"
             [src]="getUserAvatar()">

        <span class="member__title txt">
            <span [innerHTML]="userPosition.name  | highlight : searchStr"></span>

            <span class="member__position" *ngIf="item.name">
                <span [innerHTML]="item.name | highlight : searchStr"></span>
            </span>

        </span>

        <app-rating-number [rating]="userPosition.rating"></app-rating-number>

    </div>
</ng-container>
