import {Component, Inject, OnInit} from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialog as MatDialog,
    MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import {Subject} from 'rxjs';
import {MobilePhone} from '../../../../models/user';
import {ApiPhonebookService} from '../../../phonebook/services/api-phonebook.service';
import {GlobalNotificationCenterService} from '../../../../services/global-notification-center.service';
import {AuthService} from '../../../../services/auth.service';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-phone-verification',
  templateUrl: './phone-verification.component.html',
  styleUrls: ['./phone-verification.component.scss']
})
export class PhoneVerificationComponent implements OnInit {

    private destroyed = new Subject<void>();

    mobile: MobilePhone = null;
    isSended = false;

    cipher = '';
    // CHIPPER_ERROR = '';
    timerStart = 20;
    timer = this.timerStart;
    timerHandler = null;

    constructor(
        public api: ApiPhonebookService,
        public auth: AuthService,
        public notiService: GlobalNotificationCenterService,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<any>,
      @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit(): void {
        this.sendVerificationMobilePhone(this.data.phone);
  }

  sendVerificationMobilePhone(phone) {
      this.api.addPhone(phone, this.data.userId)
          .pipe(takeUntil(this.destroyed))
          .subscribe(resp => {
              // phone added
              this.mobile = {
                  id: 0,
                  phone,
                  isVerified: false,
              };

              this.isSended = true;
              this.startTimer();
          }, err => {
              this.notiService.handleFullError(err);
          });
  }

    onSendAgainClick($event: MouseEvent) {
        $event.preventDefault();

        this.sendVerificationMobilePhone(this.data.phone);
    }

    onCipherCheckClick() {
        if (this.cipher?.length >= 4) {
            this.checkMobilePhone(this.data.phone, this.cipher);
        }
    }

    checkMobilePhone(number: string, cipher: string) {
        // check
        this.api.checkPhone(number, cipher)
            .pipe(takeUntil(this.destroyed))
            .subscribe(resp => {
                const res = {
                    isVerified: true,
                    isSended: false
                };
                this.close(res);
            }, err => {
                this.notiService.handleFullError(err);

            });
    }

    startTimer() {
        this.timer = this.timerStart;
        this.timerHandler = setInterval(() => {
            this.timerTick();
        }, 1000);
    }

    timerTick() {
        if (this.timer > 0) {
            this.timer -= 1;
        } else {
            clearInterval(this.timerHandler);
        }
    }

    close(res = null) {
        this.dialogRef.close(res);
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }
}
