import {UserShort} from '../../../models/user';
import {JsonElementType, JsonProperty, JsonType} from 'ta-json';
import {Rating} from '../../shared/models/rating';

export class UserPosition extends UserShort {
    @JsonProperty('is_active')
    @JsonType(Boolean)
    is_active = true;

    @JsonProperty('rating')
    @JsonElementType(Rating)
    rating: Rating = null;

    // @JsonProperty('structs')
    // @JsonElementType(Subdivision)
    // structs: Subdivision[] = [];
}
