import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {Rights} from '../../../models/user';
import {FunctionShort} from '../models/functions/function-short';
import {FunctionFull} from '../models/functions/function';
import {FunctionsQuestion} from '../models/functions/functions-question';

@Injectable({
    providedIn: 'root'
})
export class FunctionsService {
    public functionFolders$ = new BehaviorSubject<FunctionShort[]>(null);
    public redirectAfterSavingUrl$ = new BehaviorSubject(null);
    public functionHasChanges$ = new BehaviorSubject(null);
    public onSubmit$ = new Subject();
    public controlFrequencyChanged$ = new Subject<any>();
    public usersActiveChange$ = new Subject();
    public openedQuestionId$ = new BehaviorSubject(null);

    public reloadList$ = new Subject();
    public resetFunction$ = new Subject();
    public functions$ = new BehaviorSubject<FunctionShort[]>(null);
    public functionsBaseList$ = new BehaviorSubject<FunctionShort[]>(null);
    public rightsList$ = new BehaviorSubject<Rights>(null);
    public searchTerm$ = new BehaviorSubject('');

    public editQuestionId$ = new BehaviorSubject(null);

    public openFunctionUsersDialog$ = new Subject();

    public openedUsersType$ = new BehaviorSubject(null);
    public openedQuestionType$ = new BehaviorSubject(null);
    public sideFunctionQuestionsOpened$ = new BehaviorSubject(null);

    public dataFunctionResp$ = new BehaviorSubject(null);
    public dataFunctionPerformers$ = new BehaviorSubject(null);
    public dataFunctionCustomers$ = new BehaviorSubject(null);
    public dataFunctionDelegates$ = new BehaviorSubject(null);

    public functionUpdated$ = new Subject<FunctionFull>();
    public functionCreated$ = new Subject<FunctionFull>();
    public functionRemoved$ = new Subject<number>();

    public questionUpdated$ = new Subject<FunctionsQuestion>();
    public questionCreated$ = new Subject<FunctionsQuestion>();
    public questionRemoved$ = new Subject<number>();

    public removeSubdivision$ = new Subject();
    public removePosition$ = new Subject();
    public removeResp$ = new Subject();

    public chatRoomsFlag$ = new BehaviorSubject(false);
    public openFunction$ = new Subject();

    constructor() {
    }
}
