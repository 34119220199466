import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserShort} from '../../../../models/user';
import {ConfigBoardsService} from '../../../task-manager/services/config-boards.service';
import {AuthService} from '../../../../services/auth.service';
import {UserSubdivision} from '../../../phonebook/models/user-subdivision';
import {RoleTypeEnum, RoleTypeMapping} from '../../../phonebook/models/motivations/users-motivation';
import {UserPosition} from '../../../phonebook/models/user-position';

@Component({
  selector: 'app-users-list-item',
  templateUrl: './users-list-item.component.html',
  styleUrls: ['./users-list-item.component.scss']
})
export class UsersListItemComponent implements OnInit {

    // для раздела компаний
    @Input() companyMode = false;
    @Input() public userPosition: UserPosition = null;

    @Output() onRemove = new EventEmitter<UserShort>();
    @Output() onUser = new EventEmitter<UserShort>();
    @Input() public userSubdivision: UserSubdivision = null;
    @Input() public shortView = false;
    @Input() public removable = false;
    @Input() public addable = false;
    // Маленькая аватарка
    @Input() public small = false;
    // Параметр для отображения галочек согласователей
    @Input() public approvers = false;
    @Input() public approved = false;
    // Полное фио
    @Input() public fullName = false;
    // Нужна ли должность
    @Input() public position = null;
    @Input() public disabled = false;
    @Input() public searchStr = null;
    public userId = null;
    @Input() notReaded: boolean;

    constructor(
        private config: ConfigBoardsService,
        private auth: AuthService
    ) {
    }

    get positionIsStr() {
        return typeof this.position === 'string';
    }

    getUserAvatar() {
        return this.config.getUserAvatarByUser(this.userSubdivision || this.userPosition, 'small');
    }

    onUserClick(evt) {
      this.onUser.emit(this.userSubdivision || this.userPosition);
    }

    onRemoveClick(evt) {
      evt.stopPropagation();
      this.onRemove.emit(this.userSubdivision);
    }

    getRole(role) {
        if (role) {
            return RoleTypeMapping.find(el => el.value === role)?.name;
        }

        if (this.approvers && !role) {
            return RoleTypeMapping.find(el => el.value === RoleTypeEnum.COORDINATOR)?.name;
        }
    }

    ngOnInit() {
        this.userId = this.auth.currentUser$.value.id;
    }
}
