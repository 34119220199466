import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {ConfigService} from '../../../../../services/config.service';
import {Subdivision} from '../../../../phonebook/models/subdivision';

@Component({
  selector: 'app-subdivision-list-item',
  templateUrl: './subdivision-list-item.component.html',
  styleUrls: ['./subdivision-list-item.component.scss']
})
export class SubdivisionListItemComponent implements  OnInit, OnDestroy {

    public subdivision: Subdivision = null;
    private destroyed = new Subject<void>();
    @Input() public searchStr = null;
    public isAdmin = false;
    @Input() public rating = true;
    @Input() public selectable = false;

    constructor(
        private config: ConfigService,
    ) {
    }

    getAvatar() {
        return this.config.getLogoSrc(this.subdivision.image, 'small');
    }

    @Input() set data(params: Subdivision) {
        this.subdivision = params;
    }

    onSubdivisionClick() {

    }

    ngOnInit(): void {
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }
}
