import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Task} from '../../../task-manager/models/task';
import {ViewSettingsTask} from '../../../task-manager/models/view-settings';
import {ApiBoardsService} from '../../../task-manager/services/api-boards.service';
import {AuthService} from '../../../../services/auth.service';
import {TasksService} from '../../../task-manager/services/tasks.service';
import {BoardsService} from '../../../task-manager/services/boards.service';
import {TagsService} from '../../../task-manager/services/tags.service';
import {NavService} from '../../../task-manager/services/nav.service';
import {Router} from '@angular/router';
import {ConfigBoardsService} from '../../../task-manager/services/config-boards.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {Board} from '../../../task-manager/models/board';

@Component({
    selector: 'app-kanban-item',
    templateUrl: './kanban-item-component.html',
    styleUrls: ['./kanban-item-component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class KanbanItemComponent implements OnInit {

    @Input() embed = false;
    @Input() public task: Task = null;
    @Input() public shortView = false;
    @Input() archiveOpen = true;
    @Input() global = false;
    public viewSettingsTask: ViewSettingsTask = null;
    public openedId = null;
    private destroyed = new Subject<void>();
    public searchTerm = '';
    @Input() disabled = false;
    public IMPORTANCE_TYPE = this.config.IMPORTANCE_TYPE;

    public isToolbarVisible = false;
    public isDateVisible = null;
    public boardGroup = null;
    public mainBoard = null;
    public isDateFinishVisible = null;
    public cover = null;
    public importanceText = null;
    public importanceIcon = null;

    @Output() opened = new EventEmitter<boolean>();

    @Input() set viewTask(param: ViewSettingsTask) {
        this.viewSettingsTask = param;

        if (this.viewSettingsTask == null) {
            this.viewSettingsTask = new ViewSettingsTask();
        }

        this.isDateVisible = this.task.dateExpiration && this.viewSettingsTask.isDateVisible &&
            !this.task.isTaskDone && !this.task.isTaskArchive && !this.task.isTaskClose;

        this.isToolbarVisible = (this.task.sprint && this.viewSettingsTask.isSprintVisible)
            || (this.task.performers && this.task.performers.length && this.viewSettingsTask.isPerformersVisible)
            || (this.task.responsible && this.viewSettingsTask.isResponsibleVisible);

        this.isDateFinishVisible =  this.task.dateFinish && this.viewSettingsTask.isDateVisible &&
            (this.task.isTaskDone || this.task.isTaskArchive || this.task.isTaskClose);

        this.cdr.detectChanges();
    }

    constructor(
        private api: ApiBoardsService,
        private auth: AuthService,
        private tasksService: TasksService,
        private boardsService: BoardsService,
        private tagsService: TagsService,
        private nav: NavService,
        private router: Router,
        private config: ConfigBoardsService,
        private cdr: ChangeDetectorRef
    ) {
    }

    ngOnInit() {
        this.searchTerm = this.tasksService.searchTerm$.value;

        this.tasksService.searchTerm$
            .pipe(takeUntil(this.destroyed))
            .subscribe(query => {
                this.searchTerm = query;
                this.cdr.detectChanges();
            });

        if (this.task.importance) {
            this.importanceText = this.config.getImportanceTooltip(this.task.importance);
            this.importanceIcon = this.config.getImportanceById(this.task.importance).icon;
        }

        if (this.task.cover) {
            this.cover = `${this.config.fileStoragePicsUrl}${this.task.cover.name}/medium`;
        }

        this.getBoardGroup();

        this.tasksService.lastOpenedTask$
            .pipe(takeUntil(this.destroyed))
            .subscribe(task => {
                if (task) {
                    this.openedId = task.id;
                    this.cdr.detectChanges();
                }
            });

        this.tasksService.openedTask$
            .pipe(takeUntil(this.destroyed))
            .subscribe(task => {
                if (task && this.task.id == task.id) {
                    this.opened.emit(true);
                }
            });

        this.tasksService.updateChatCnt$
            .pipe(takeUntil(this.destroyed))
            .subscribe(res => {
                if (res && this.task.id === +res.id) {
                    this.task.messages = res.counter ? this.task.messages + 1 : 0;
                    this.cdr.detectChanges();
                }
            });
    }

    isBoardSelected(board: Board) {
        let selected = this.boardsService.boardSelected$.getValue();
        if (this.global) {
            selected = board.is_main ? board : +board.id == -(+this.auth.auth.id) ? board : null;
        }
        if (selected) {
            return board.id === selected.id;
        } else {
            return false;
        }
    }

    getBoardColor(colorName) {
        return this.boardsService.getBoardColor(colorName);
    }

    getTagBackground(colorName) {
        return this.tagsService.getTagBackground(colorName);
    }

    getBoardTags(board: Board) {
        return board.tags.filter(el => !el.taskGroup);
    }

    getBoardGroup() {
        if (!this.task.boards) {
            return false;
        }

        const currentTaskBoard = this.task.boards.find(el => this.isBoardSelected(el));

        if (currentTaskBoard && currentTaskBoard.tags && currentTaskBoard.tags.some(el => el.taskGroup)) {
            if (this.task.onCurrentBoard) {
                this.boardGroup = currentTaskBoard.tags.find(el => el.taskGroup);
            }

            this.mainBoard = currentTaskBoard;
            this.boardGroup = this.mainBoard?.tags.find(el => el.taskGroup);
            this.cdr.detectChanges();
            return false;
        }

        const mainBoard = this.task.boards.find(b => b.is_main);

        this.boardGroup = mainBoard?.tags.find(el => el.taskGroup);
        this.cdr.detectChanges();
    }

    openChat() {
        this.tasksService.chatIsOpened$.next(true);
    }

}
