import {Observable} from 'rxjs';
import {CanDeactivate} from '@angular/router';
import {Injectable} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';

export interface ComponentCanDeactivate {
    canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
    constructor(
        public dialog: MatDialog,
    ) {
    }


    canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
        console.log(component);

        // if there are no pending changes, just allow deactivation; else confirm first
        const resp = component.canDeactivate();

        return resp;
    }
}
