<ul class="tags">
    <ng-container *ngFor="let item of tags">
        <li class="tag"
            [ngClass]="{'_editable' : editable, '_selected' : selected, '_removable' : removable, '_disabled': disabled}"
            (click)="clickTag(item)">
            <span class="inner"
                  [ngClass]="{'_selectedItem': isTagSelected(item)}"
                  [ngStyle]="{'background': getItemColor(item.color)}">
                <span class="txt">{{ item.name }}</span>
                <mat-icon [ngStyle]="{'color': getFgColor(item.color)}">done</mat-icon>
            </span>
            <div class="toolbar" *ngIf="(editable || removable) && !disabled">
                <button type="button" class="button" *ngIf="editable && !disabled" mat-icon-button
                        (click)="editTag(item.id)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button type="button" class="button" *ngIf="editable && !disabled" mat-icon-button
                        (click)="isDeleteConfirm = true; tagToDelete = item.id">
                    <mat-icon>close</mat-icon>
                </button>
                <button type="button" class="button" *ngIf="removable && !disabled"
                        mat-icon-button
                        (click)="remove(item)">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </li>
    </ng-container>
</ul>

<ng-container *ngIf="isDeleteConfirm">
    <app-confirm title="Удалить метку?"
                 confirmTitle="Удалить"
                 cancelTitle="Отмена"
                 (confirm)="deleteTag()"
                 (cancel)="isDeleteConfirm = false;">
    </app-confirm>
</ng-container>

