import {JsonElementType, JsonProperty, JsonType} from 'ta-json';
import {Tag} from './tag';
import {TaskView} from './task-view';
import {Member} from './member';

export class Board {
    @JsonProperty('id')
    @JsonType(Number)
    id?: number;

    @JsonProperty('name')
    @JsonType(String)
    name?: string;

    @JsonProperty('description')
    @JsonType(String)
    description: string;

    @JsonProperty('color')
    @JsonType(String)
    color: string;

    @JsonProperty('visible')
    @JsonType(Boolean)
    visible: boolean;

    @JsonProperty('members')
    @JsonElementType(Member)
    members: Member[] = [];

    @JsonProperty('tags')
    @JsonElementType(Tag)
    tags: Tag[] = [];

    @JsonProperty('shared_board')
    @JsonType(Boolean)
    sharedBoard: boolean;

    @JsonProperty('shared_board_title')
    @JsonType(String)
    sharedBoardTitle: string;

    @JsonProperty('view')
    @JsonElementType(TaskView)
    view: TaskView;

    @JsonProperty('view_members')
    @JsonElementType(TaskView)
    viewMembers: TaskView;

    @JsonProperty('required_field_date')
    @JsonType(Boolean)
    requiredFieldDate: boolean;

    @JsonProperty('rescheduling_coordinate')
    @JsonType(Boolean)
    reschedulingCoordinate: boolean = false;

    @JsonProperty('task_acceptance_assessment')
    @JsonType(Boolean)
    taskAcceptanceAssessment: boolean = false;

    @JsonProperty('task_acceptance_required')
    @JsonType(Boolean)
    task_acceptance_required: boolean = false;

    @JsonProperty('tasks_counter')
    @JsonType(Number)
    tasksCounter?: number;

    @JsonProperty('movement_freedom')
    @JsonType(Boolean)
    movementFreedom?: boolean;

    @JsonProperty('status_column_swap')
    @JsonType(Boolean)
    statusColumnSwap?: boolean;

    @JsonProperty('assessment_type')
    @JsonType(String)
    assessmentType?: string;

    @JsonProperty('time_tracking_type')
    @JsonType(String)
    time_tracking_type?: string;

    @JsonProperty('archive_timeout')
    @JsonType(Number)
    archiveTimeout?: number;

    @JsonProperty('pboard_incl_performer')
    @JsonType(Boolean)
    pboardInclPerformer?: boolean;

    @JsonProperty('pboard_incl_observer')
    @JsonType(Boolean)
    pboardInclObserver?: boolean;

    @JsonProperty('sync_boards')
    @JsonType(Array)
    syncBoards = [];

    @JsonProperty('type_availability')
    @JsonType(Boolean)
    typeAvailability: boolean[] = [];

    @JsonProperty('type_title')
    @JsonType(String)
    typeTitle: string[] = [];

    @JsonProperty('is_main')
    @JsonType(Boolean)
    is_main = false;

    @JsonProperty('is_archive')
    @JsonType(Boolean)
    is_archive = false;

    @JsonProperty('template_board')
    @JsonType(Boolean)
    template_board = false;

    tagsDisabled = false;

    @JsonProperty('active_tasks_num')
    @JsonType(Number)
    active_tasks_num = 0;

    @JsonProperty('archive_tasks_num')
    @JsonType(Number)
    archive_tasks_num = 0;

    @JsonProperty('last_activity')
    @JsonType(Date)
    last_activity = null;

    @JsonProperty('is_sprint_auto_date')
    @JsonType(Boolean)
    isSprintAutoDate = false;

    @JsonProperty('mail_sender_role')
    @JsonType(Boolean)
    mail_sender_role = false;

    constructor(id?: number,
                name: string = '',
                description: string = '',
                color: string = '',
                members: Member[] = [],
                tags: Tag[] = [],
                visible: boolean = true,
                sharedBoard: boolean = false,
                sharedBoardTitle: string = '',
                view: TaskView = null,
                viewMembers: TaskView = null,
                requiredFieldDate: boolean = false,
                tasksCounter?: number,
                movementFreedom: boolean = true,
                statusColumnSwap: boolean = true,
                archiveTimeout: number = null,
                pboardInclPerformer: boolean = false,
                pboardInclObserver: boolean = false,
                syncBoards = [],
                isSprintAutoDate: boolean = false
    ) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.color = color;
        this.members = members;
        this.tags = tags;
        this.visible = visible;
        this.sharedBoard = sharedBoard;
        this.sharedBoardTitle = sharedBoardTitle;
        this.view = view;
        this.viewMembers = viewMembers;
        this.requiredFieldDate = requiredFieldDate;
        this.tasksCounter = tasksCounter;
        this.movementFreedom = movementFreedom;
        this.statusColumnSwap = statusColumnSwap;
        this.archiveTimeout = archiveTimeout;
        this.pboardInclPerformer = pboardInclPerformer;
        this.pboardInclObserver = pboardInclObserver;
        this.syncBoards = syncBoards;
        this.isSprintAutoDate = isSprintAutoDate;
    }
}
