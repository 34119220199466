import {JsonProperty, JsonType} from 'ta-json';
import {UserShort} from '../../../models/user';

export class Tracking {
    @JsonProperty('id')
    @JsonType(Number)
    id: number = null;

    @JsonProperty('date')
    @JsonType(Date)
    date: Date = null;

    @JsonProperty('comment')
    @JsonType(String)
    comment: string = null;

    @JsonProperty('assessment')
    @JsonType(Number)
    assessment: number = null;

    @JsonProperty('assessment_type')
    @JsonType(String)
    assessment_type: string = null;

    @JsonProperty('user')
    @JsonType(UserShort)
    user: UserShort = null;
}

