<div class="learning-group-anchor" tourAnchor="learningGroupDialog"></div>
<section class="dialog">
    <header class="dialog__header">
        <h3 class="dialog__title">Выбор группы</h3>
        <mat-icon class="close learningGroupSelectClose" (click)="close()">close</mat-icon>
    </header>

    <div class="dialog__content">
        <app-search-mat class="search"
                        [kitView]="true"
                        (textChange)="onSearchChange($event)"></app-search-mat>

        <mat-divider class="diviner"></mat-divider>

        <div class="dialog__content-head">
            <h2 class="title">Группы задач ({{ sorted.length }})</h2>

            <app-button buttonType="add"
                        type="button"
                        class="add-button learningGroupAdd"
                        *ngIf="canAdd"
                        (click)="openFormAdd()">
            </app-button>
        </div>


        <div class="list">
            <app-groups-list [groups]="sorted"
                             (onSelect)="close($event)"
                             (removeGroup)="remove()"
                             [groupSelected]="groupSelected"
                             [boardId]="currentBoard.id"
                             [selected]="true"
            ></app-groups-list>
            <button *ngIf="canAdd" mat-flat-button class="button _transparent" type="button" (click)="openFormAdd()">
                + Создать группу
            </button>
        </div>
    </div>
</section>
<div *ngIf="isLearningOpen" class="learning-background-overlay"></div>
