import {JsonProperty, JsonType} from 'ta-json';

export class TaskView {
    @JsonProperty('assessment')
    @JsonType(Boolean)
    assessment: boolean;

    @JsonProperty('author')
    @JsonType(Boolean)
    author: boolean;
    @JsonProperty('author_id')
    @JsonType(Boolean)
    authorId: boolean;
    @JsonProperty('boards')
    @JsonType(Boolean)
    boards: boolean;
    @JsonProperty('tags')
    @JsonType(Boolean)
    tags: boolean;
    @JsonProperty('sprint')
    @JsonType(Boolean)
    sprint: boolean;
    @JsonProperty('group')
    @JsonType(Boolean)
    group: boolean;
    @JsonProperty('subtasks')
    @JsonType(Boolean)
    subtasks: boolean;
    @JsonProperty('children')
    @JsonType(Boolean)
    children: boolean;
    @JsonProperty('ancestors')
    @JsonType(Boolean)
    ancestors: boolean;
    @JsonProperty('date_start')
    @JsonType(Boolean)
    dateStart: boolean;
    @JsonProperty('date_expiration')
    @JsonType(Boolean)
    dateExpiration: boolean;
    @JsonProperty('description')
    @JsonType(Boolean)
    description: boolean;
    @JsonProperty('dod')
    @JsonType(Boolean)
    dod: boolean;
    @JsonProperty('actual_result')
    @JsonType(Boolean)
    actual_result: boolean;
    @JsonProperty('files')
    @JsonType(Boolean)
    files: boolean;
    @JsonProperty('flag_importance')
    @JsonType(Boolean)
    flagImportance: boolean;
    @JsonProperty('flag_new')
    @JsonType(Boolean)
    flagNew: boolean;
    @JsonProperty('id')
    @JsonType(Boolean)
    id: boolean;
    @JsonProperty('link_id')
    @JsonType(Boolean)
    linkId: boolean;
    @JsonProperty('links')
    @JsonType(Boolean)
    links: boolean;
    @JsonProperty('members_approver')
    @JsonType(Boolean)
    membersApprover: boolean;
    @JsonProperty('members_observer')
    @JsonType(Boolean)
    membersObserver: boolean;
    @JsonProperty('members_performer')
    @JsonType(Boolean)
    membersPerformer: boolean;
    @JsonProperty('parent')
    @JsonType(Boolean)
    parent: boolean;
    @JsonProperty('path')
    @JsonType(Boolean)
    path: boolean;
    @JsonProperty('status')
    @JsonType(Boolean)
    status: boolean;
    @JsonProperty('title')
    @JsonType(Boolean)
    title: boolean;
    @JsonProperty('unread_messages')
    @JsonType(Boolean)
    unreadMessages: boolean;
    @JsonProperty('movement_freedom')
    @JsonType(Boolean)
    movementFreedom: boolean;
    @JsonProperty('archive_timeout')
    @JsonType(Boolean)
    archiveTimeout: number;
    @JsonProperty('pboard_incl_performer')
    @JsonType(Boolean)
    pboardInclPerformer: boolean;

    @JsonProperty('pboard_incl_observer')
    @JsonType(Boolean)
    pboardInclObserver: boolean;
}
