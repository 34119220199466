import {JsonProperty, JsonType} from 'ta-json';

export class Executive {
    @JsonProperty('id')
    @JsonType(Number)
    id: number;

    @JsonProperty('photo')
    @JsonType(String)
    photo: string;
}

export class Subdivision {
    @JsonProperty('id')
    @JsonType(Number)
    id: number;

    @JsonProperty('name')
    @JsonType(String)
    name: string;

    @JsonProperty('image')
    @JsonType(String)
    image: string;

    @JsonProperty('executives')
    @JsonType(Executive)
    executives?: Executive[];

    constructor(
        name: string = '',
        id: number = null
    ) {
        this.name = name;
        this.id = id;
    }
}

export enum SubdivisionUserRole {
    EXECUTIVE = 'executive',
    EMPLOYEE = 'employee',
    MANAGER = 'manager'
}
