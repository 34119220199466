import {JsonElementType, JsonProperty, JsonType} from 'ta-json';
import {UserId, UserShort} from '../../../../models/user';
import {UsersMotivation} from './users-motivation';
import {Motivation} from './motivation';


export class UserCardMotiv {
    @JsonProperty('user')
    @JsonType(UserShort)
    user?: UserShort;

    @JsonProperty('user_motivations')
    @JsonType(UsersMotivation)
    employments?: UsersMotivation[];

    @JsonProperty('motivations')
    @JsonType(Motivation)
    motivations?: Motivation[];
}

export class MotivationSubdivision {
    @JsonProperty('id')
    @JsonType(Number)
    id: number;

    @JsonProperty('image')
    @JsonType(String)
    image: string;

    @JsonProperty('name')
    @JsonType(String)
    name: string;

    @JsonProperty('parent_id')
    @JsonType(Number)
    parentId: number;

    @JsonProperty('path')
    @JsonType(Array)
    path = [];

    @JsonProperty('children')
    @JsonElementType(MotivationSubdivision)
    children: MotivationSubdivision[];

    @JsonProperty('users')
    @JsonElementType(UserCardMotiv)
    users: UserCardMotiv[];

    @JsonProperty('executives')
    @JsonElementType( UserId)
    executives: UserId[];
}
