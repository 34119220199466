import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Messenger, messengerMapping} from '../../../../../../models/messenger';

@Component({
    selector: 'app-messengers',
    templateUrl: './messengers.component.html',
    styleUrls: ['./messengers.component.scss']
})
export class MessengersComponent implements OnInit {

    public messengers: Messenger[] = [];
    public allMessengers = messengerMapping.map(el => el.type);
    @Output() public readonly onChange: EventEmitter<any> = new EventEmitter();

    constructor() {
    }

    @Input() set data(params: Messenger[]) {
        this.messengers = [...params];
    }

    ngOnInit(): void {
    }

    onLoginChange(evt, index) {
        this.messengers[index].login = evt.target.value;
        this.onChange.emit(this.messengers);
    }

    onTypeSelected(evt, index) {
        if (!evt.value) {
            this.messengers.length > 1
                ? this.messengers.splice(index, 1) : this.messengers[index].login = '';
        }
        this.messengers[index].type = evt.value;
        this.onChange.emit(this.messengers);
    }

    add() {
        const messenger = new Messenger(null, '');
        this.messengers.push(messenger);
    }

    remove(index) {
        this.messengers.splice(index, 1);
        this.onChange.emit(this.messengers);
    }

    messengerName(messengerType) {
        const messenger = messengerMapping.find(el => el.type === messengerType);
        return messenger ? messenger.name : '';
    }

    messengerIcon(messengerType) {
        const messenger = messengerMapping.find(el => el.type === messengerType);
        return messenger ? messenger.icon : '';
    }
}
