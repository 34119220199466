import {JsonObject, JsonProperty, JsonType} from 'ta-json';
import {UserShort} from '../../../../../models/user';


@JsonObject()
export class PricingPlanModule {
    @JsonProperty('id')
    @JsonType(Number)
    id: number;

    /** машиночитаемое название */
    @JsonProperty('name')
    @JsonType(String)
    name: string;

    /** человекочитаемое название */
    @JsonProperty('title')
    @JsonType(String)
    title: string;

    /** цена модуля */
    @JsonProperty('price')
    @JsonType(Number)
    price: number;

    /** TODO: бесполезный флаг, показывает является ли модуль модулем пользователя
     * или компании всегда должен быть false */
    @JsonProperty('is_additional')
    @JsonType(Number)
    is_additional: boolean;
}

@JsonObject()
export class PricingPlanOption {
    @JsonProperty('id')
    @JsonType(Number)
    id: number;

    /** машиночитаемое название */
    @JsonProperty('name')
    @JsonType(String)
    name: string;

    /** человекочитаемое название */
    @JsonProperty('title')
    @JsonType(String)
    title: string;

    /** цена опции */
    @JsonProperty('price')
    @JsonType(Number)
    price: number;

    /** TODO: не реализовано, должно быть колво пользователей на этой опции
     * возможно стоит реализовать на фронте
     */
    @JsonProperty('count')
    @JsonType(Number)
    count: number;
}

@JsonObject()
export class PricingPlanUser {
    /** если установлено, то дата когда можно будет убрать из подарочных платных */
    @JsonProperty('assignment_expiration')
    @JsonType(String)
    assignment_expiration?: string;

    @JsonProperty('created_at')
    @JsonType(String)
    created_at?: string;

    @JsonProperty('created_by')
    @JsonType(Number)
    created_by?: number;

    /** айди колонки в которой пользователь находится */
    @JsonProperty('group_id')
    @JsonType(Number)
    group_id: number;

    /** булевое свойство - является ли пользователь подарочным платным */
    @JsonProperty('is_favorite')
    @JsonType(Boolean)
    is_favorite: boolean;

    /** булевое свойство - является ли пользователь админом */
    @JsonProperty('is_admin')
    @JsonType(Boolean)
    is_admin: boolean;

    /** массив подключенных у пользователя модулей */
    @JsonProperty('modules')
    @JsonType(PricingPlanModule)
    modules: PricingPlanModule[];

    /** объект с пользователем */
    @JsonProperty('user')
    @JsonType(UserShort)
    user: UserShort;

    /** айди пользователя */
    @JsonProperty('user_id')
    @JsonType(Number)
    user_id: number;

    @JsonProperty('is_partner')
    @JsonType(Boolean)
    is_partner?: boolean;
}


/** "Тип" колонки на странице тарифов, включает описание и возможные опции */
@JsonObject()
export class PricingPlanType {
    @JsonProperty('id')
    @JsonType(Number)
    id: number;

    /** список всех подключаемых у тарифа модулей для гибкого тарифа */
    @JsonProperty('modules')
    @JsonType(PricingPlanModule)
    modules: PricingPlanModule[];

    /** машинное название: FREE, PAID, FLEXIBLE */
    @JsonProperty('name')
    @JsonType(String)
    name: string;

    /** список всех поключаемых опций тарифа (кол-во людей на платном) */
    @JsonProperty('options')
    @JsonType(PricingPlanOption)
    options: PricingPlanOption[];

    /** человекочитаемое название */
    @JsonProperty('title')
    @JsonType(String)
    title: string;
}

/** инстанс колонки на странице тарифов, включает установленные значения */
@JsonObject()
export class PricingPlanGroup {
    @JsonProperty('company_tariff_id')
    @JsonType(Number)
    company_tariff_id: number;

    @JsonProperty('expiration_date')
    @JsonType(String)
    expirationDate?: string;

    @JsonProperty('id')
    @JsonType(Number)
    id: number;

    /** массив подключенных опций */
    @JsonProperty('options')
    @JsonType(Number)
    options: PricingPlanOption[];

    /** TODO: не знаю зачем нужно, надо удалить */
    @JsonProperty('modules')
    @JsonType(Number)
    modules: number[];

    /** тип колонки с её возможными опциями и модулями */
    @JsonProperty('type')
    @JsonType(PricingPlanType)
    type: PricingPlanType;

    /** список людей в этой колонке */
    @JsonProperty('users')
    @JsonType(PricingPlanUser)
    users: PricingPlanUser[];
}

export class CompanyModule {
    @JsonProperty('id')
    @JsonType(Number)
    id: number;

    @JsonProperty('is_additional')
    @JsonType(Boolean)
    is_additional: boolean;

    @JsonProperty('name')
    @JsonType(String)
    name: "knowledge_base";

    @JsonProperty('price')
    @JsonType(Number)
    price: number;

    @JsonProperty('title')
    @JsonType(String)
    title: string;
}

export class CompanyModuleWrap {
    @JsonProperty('is_enabled')
    @JsonType(Boolean)
    is_enabled: boolean;

    @JsonProperty('module')
    @JsonType(CompanyModule)
    module: CompanyModule;
}

/** инфа о балансе */
@JsonObject()
export class PricingBalanceInfo {
    /** Баланс */
    @JsonProperty('amount')
    @JsonType(Number)
    amount: number;
}

/** инфа о компании, например баланс */
@JsonObject()
export class PricingCompanyInfo {
    /** Айди компании */
    @JsonProperty('id')
    @JsonType(Number)
    id: number;

    /** инфа о балансе */
    @JsonProperty('balance')
    @JsonType(PricingBalanceInfo)
    balance: PricingBalanceInfo;
}

@JsonObject()
export class Slot {
    @JsonProperty('module_id')
    @JsonType(Number)
    module_id: number;

    @JsonProperty('count')
    @JsonType(Number)
    count: number;
}

/** Тариф компании, который приходит от бека */
@JsonObject()
export class PricingPlanCompany {
    /** slots */
    @JsonProperty('empty_slots')
    @JsonType(Slot)
    empty_slots: Slot[];

    used_slots: Slot[] = [];

    @JsonProperty('empty_slots')
    @JsonType(Slot)
    buyed_slots: Slot[] = [];

    /** Модули компании подключены */
    @JsonProperty('additional_modules')
    @JsonType(CompanyModuleWrap)
    additional_modules: CompanyModuleWrap[];

    @JsonProperty('company')
    @JsonType(PricingCompanyInfo)
    company: PricingCompanyInfo;

    /** Айди компании */
    @JsonProperty('company_id')
    @JsonType(Number)
    company_id: number;

    /** Скидка */
    @JsonProperty('discount')
    @JsonType(Number)
    discount: number;

    /** Дата когда тариф закончится */
    @JsonProperty('end')
    @JsonType(String)
    end: string;

    @JsonProperty('favorite_users')
    @JsonType(PricingPlanUser)
    favorite_users: PricingPlanUser[];

    @JsonProperty('partners')
    @JsonType(UserShort)
    partners: UserShort[];

    /** Массив колонок таблицы с группами платный-беслпатный-гибкий */
    @JsonProperty('groups')
    @JsonType(PricingPlanGroup)
    groups: PricingPlanGroup[];

    @JsonProperty('id')
    @JsonType(Number)
    id: number;

    /** Дата когда тариф начал действовать */
    @JsonProperty('start')
    @JsonType(String)
    start: string;

    /** Текстовый статус: deafult, draft */
    @JsonProperty('status')
    @JsonType(String)
    status: string;

    @JsonProperty('period')
    @JsonType(Number)
    period?: number;

    @JsonProperty('paid_sum')
    @JsonType(Number)
    paid: number;

    @JsonProperty('remaining_balance')
    @JsonType(Number)
    remainSum: number;

    @JsonProperty('is_payed')
    @JsonType(Boolean)
    isPaid: boolean;

    @JsonProperty('is_default')
    @JsonType(Boolean)
    isDefault: boolean;

    @JsonProperty('is_promo')
    @JsonType(Boolean)
    isPromo: boolean;

}
