import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {GlobalNotificationCenterService} from '../../../../../services/global-notification-center.service';
import {Rights, User} from '../../../../../models/user';
import {PersonalCardFormComponent} from './personal-card-form/personal-card-form.component';
import {AuthService} from '../../../../../services/auth.service';
import {ApiPhonebookService} from 'src/app/modules/phonebook/services/api-phonebook.service';
import {ConfigPhonebookService} from 'src/app/modules/phonebook/services/config-phonebook.service';
import {NavService} from 'src/app/modules/phonebook/services/nav.service';
import {PhonebookService} from 'src/app/modules/phonebook/services/phonebook.service';
import {CompanyService} from 'src/app/modules/phonebook/services/company.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {PersonalCardSaverService} from '../../../../../services/personal-card-saver.service';

@Component({
    selector: 'app-personal-card-edit',
    templateUrl: './personal-card-edit.component.html',
    styleUrls: ['./personal-card-edit.component.scss', '../personal-card/personal-card.component.scss']
})
export class PersonalCardEditComponent implements OnInit, OnDestroy {

    public userId = null;
    public user: User = null;
    public rights: Rights = null;
    public isLoaded = false;
    public isAboutCompany = false;
    @ViewChild('form') form: PersonalCardFormComponent;
    private destroyed = new Subject<void>();
    public currentUser = null;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private notiService: GlobalNotificationCenterService,
        private api: ApiPhonebookService,
        private config: ConfigPhonebookService,
        private nav: NavService,
        private phonebook: PhonebookService,
        private auth: AuthService,
        private company: CompanyService,
        public dialog: MatDialog,
        private saver: PersonalCardSaverService,
    ) {
    }

    ngOnInit(): void {
        this.route.paramMap
            .pipe(takeUntil(this.destroyed))
            .subscribe(res => {
                if (res) {
                    this.userId = +res.get('number');
                    if (this.userId) {
                        this.getUserById(this.userId);
                    } else {
                        this.user = new User();
                        this.isLoaded = true;
                    }

                    this.phonebook.openedUser$.next(this.userId ? this.userId : -1);
                }
            }, (err) => {
                this.notiService.handleFullError(err);
            });
        if (this.route.snapshot.data?.cabinet === true) {
            this.isAboutCompany = true;
        }
    }

    onSubmit(formData) {
        if (this.user.id) {
            formData['id'] = this.user.id;
            this.api.editUser(formData, this.user.id)
                .pipe(takeUntil(this.destroyed))
                .subscribe(res => {
                    this.saver.canExit$.next(true);
                    this.updateUser(res['user']);
                    this.company.reloadList$.next(true);
                }, (err) => {
                    this.notiService.handleFullError(err);
                    this.form.isButtonsDisabled = false;
                });
        } else {
            this.api.createUser(formData)
                .pipe(takeUntil(this.destroyed))
                .subscribe(res => {
                    this.saver.canExit$.next(true);
                    this.phonebook.createdUser$.next(true);
                    this.company.reloadList$.next(true);
                    this.refresh(res['user'].id);
                }, (err) => {
                    this.notiService.handleFullError(err);
                    this.form.isButtonsDisabled = false;
                });
        }
    }

    updateUser(user: User) {
        if (user.id === this.auth.currentUser$.value.id) {
            this.auth.setNewAvatar(user.photo);
        }
        this.phonebook.updateUser$.next(user);
        this.nav.openUserById(user.id);
    }

    refresh(userId) {
        this.phonebook.getUsers$.next(userId);
        this.nav.openUserById(userId);
    }

    getUserById(userId) {
        this.user = null;
        this.isLoaded = false;

        this.api.getUserById(userId)
            .pipe(takeUntil(this.destroyed))
            .subscribe(res => {
                this.user = res['user'];
                this.rights = res['rights'];
                if (!this.rights.canEdit) {
                    this.reset();
                }
                this.isLoaded = true;
            }, (err) => {
                this.notiService.handleFullError(err);
                this.isLoaded = true;
            });
    }

    reset() {
        if (this.userId) {
            this.saver.canExit$.next(true);
            this.nav.closeUser();
        } else {
            this.nav.open(this.config.USERS_URL);
        }

    }

    ngOnDestroy() {
        this.phonebook.openedUser$.next(null);
        this.destroyed.next();
        this.destroyed.complete();
    }
}
