import {Component, OnInit} from '@angular/core';

import * as Sentry from '@sentry/angular';

@Component({
  selector: 'app-test-sentry',
  templateUrl: './test-sentry.component.html',
  styleUrls: ['./test-sentry.component.scss']
})
export class TestSentryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  unhandledException() {
    throw new Error('Test Sentry: Unhandled exception!');
  }

  handledException() {
    try {
      throw new Error('Test Sentry: Handled exception!');
    } catch (e) {
      Sentry.captureException(e, {
        tags: {
          something: "woohoo",
        },
      });
    }
  }

  sentryMsg() {
    Sentry.captureMessage('Test Sentry: message!', 'debug');
  }


  undefinedObjectMethod() {
    const obj = null;

    obj.doThings();
  }
}
