import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {FiltersAvailable} from '../models/filters-available';
import {Filter} from '../models/filter';

@Injectable({
    providedIn: 'root'
})
export class FiltersService {

    public filtersAvailable = new BehaviorSubject<FiltersAvailable>(null);
    public tasksFilters$ = new BehaviorSubject<Filter>(null);
    public taskCreateHelperOpened$ = new Subject();
    public selectedGroup$ = new BehaviorSubject(null);

    constructor() {
    }

    getPerformerById(id) {
        const filter = this.filtersAvailable.value;
        if (filter) {
            const users = filter.performers.concat(filter.performersArchive);
            return users.find(el => el.id === id);
        }
    }

    getObserversById(id) {
        const filter = this.filtersAvailable.value;
        if (filter) {
            const users = filter.observers.concat(filter.observersArchive);
            return users.find(el => el.id === id);
        }
    }

    getAuthorsById(id) {
        const filter = this.filtersAvailable.value;
        if (filter) {
            const users = filter.authors.concat(filter.authorsArchive);
            return users.find(el => el['id'] === id);
        }
    }

    getTagById(id) {
        const filter = this.filtersAvailable.value;
        if (filter) {
            const tags = filter.tags.concat(filter.tagsArchive);
            return tags.find(el => el['id'] === id);
        }
    }

    getStatusById(id) {
        const filter = this.filtersAvailable.value;
        if (filter) {
            const statuses = filter.statuses.concat();
            return statuses.find(el => el['id'] === id);
        }
    }

    getGroupById(id) {
        const filter = this.filtersAvailable.value;
        if (filter) {
            const groups = filter.groups.concat(filter.groupsArchive);
            const group = groups.find(el => el['id'] === id);
            if (group) {
                return group;
            }
        }
    }

    getSprintById(id) {
        const filter = this.filtersAvailable.value;
        if (filter) {
            const sprints = filter.sprints.concat(filter.sprintsArchive);
            return sprints.find(el => el['id'] === id);
        }
    }
}
