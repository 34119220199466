<article class="person-card">
    <header class="person-header" *ngIf="loaded">
        <figure class="figure">
            <app-user-avatar class="avatar" [user]="person"></app-user-avatar>
            <figcaption class="figcaption">
        <span class="user-name txt">
            {{name}}</span>
                <div class="tags user-positions">
                     <span class="tag user-position"
                           *ngIf="position"
                           [innerHTML]="position.position?.title"></span>
                </div>
            </figcaption>
        </figure>

        <div class="phones" *ngIf="person">
            <div class="phone" *ngIf="person.mobilePhone">
                <mat-icon class="phone-img icon">local_phone</mat-icon>
                <span [innerHTML]="person.mobilePhone.phone | phoneNumber"></span>
            </div>
            <div class="phone" *ngIf="!person.mobilePhone && (person.phones && person.phones.length || person.workPhone)">
                <mat-icon class="phone-img icon">local_phone</mat-icon>
                <span [innerHTML]="person.phones[0] ? (person.phones[0].phone | phoneNumber) : (person.workPhone | phoneNumber)"></span>
            </div>
            <div class="phone" *ngIf="person.internalPhone">
                <mat-icon class="phone-img icon">local_phone</mat-icon>
                <span [innerHTML]="person.internalPhone"></span>
            </div>
            <div class="phone _chat">
                <mat-icon class="icon _chat" (click)="openChat()" [svgIcon]="'radius_chat'"></mat-icon>
            </div>
        </div>
    </header>
</article>
