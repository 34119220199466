<div class="container" [ngClass]="{'_auth': isAuth}">
    <div class="circle-block">
        <div class="outside-circle">
            <div class="title-block" *ngIf="!isAuth">
                <h2>Работайте в <em>единой</em> системе со множеством сервисов</h2>
                <span>Мы создали инструменты, которыми вы можете пользоваться как
                        по отдельности, так и объединяя их  в единый Радиус контроля
                        вашего предприятия</span>
            </div>
            <div class="title-block" *ngIf="isAuth">
                <img class="logo _white" src="assets/images/logo.svg">
                <span>Управление эффективностью<br> сотрудников</span>
            </div>
            <div class="service-block">
                <ng-container *ngFor="let block of blocks">
                    <div class="transparent-block" [ngClass]="block.color">
                        <div class="service">
                            <div class="section" [ngClass]="block.color">{{ block.upTitle }}</div>
                            <h2 class="service-title">{{block.title}}</h2>
                            <span class="service-subtitle" *ngIf="block.subtitle">{{block.subtitle}}</span>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>

    </div>
</div>
