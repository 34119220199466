import {JsonProperty, JsonType} from 'ta-json';

export enum TypeEnum {
    STRING = 'STRING',
    INTEGER = 'INTEGER',
}

export const TypeMapping = Object.freeze([
    {
        value: TypeEnum.STRING,
        name: 'Текст'
    },
    {
        value: TypeEnum.INTEGER,
        name: 'Число'
    }
]);

export const TypesCustomField = {
    INTEGER: 'number',
    STRING: 'string'
};


export class MotivationsCustomField {
    @JsonProperty('id')
    @JsonType(Number)
    id: number;

    @JsonProperty('name')
    @JsonType(String)
    name: string;

    @JsonProperty('is_active')
    @JsonType(Boolean)
    isActive: boolean;

    @JsonProperty('type')
    @JsonType(String)
    type: string;

    constructor(
        id?: number,
        name?: string,
        isActive?: boolean,
        type?: string
    ) {
        this.id = id;
        this.name = name;
        this.isActive = isActive;
        this.type = type;
    }

}
