<ng-container *ngIf="photoList.length && loaded">
  <div class="back-layer" (click)="closeFullScreen()">
    <ng-container *ngFor="let item of photoList">
      <img class="fullscreen-img" *ngIf="item.id === openedId" [src]="getImageUrl(item)">
    </ng-container>

    <div *ngIf="photoList.length > 1" class="arrow _left" (click)="prevImage($event)">
      <mat-icon class="_large-img">keyboard_arrow_left
      </mat-icon>
    </div>

    <div class="arrow _right" *ngIf="photoList.length > 1" (click)="nextImage($event)">
      <mat-icon class="_large-img">keyboard_arrow_right
      </mat-icon>
    </div>

    <a [href]="getDownloadLink()" mat-icon-button class="download"
            (click)="downloadImage($event)">
      <mat-icon [svgIcon]="'radius_download'" class="icon"></mat-icon>
    </a>

    <button mat-icon-button class="close"
            (click)="closeFullScreen()">
      <mat-icon aria-label="close" class="_large-img">close</mat-icon>
    </button>
  </div>
</ng-container>
