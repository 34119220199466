import {JsonObject, JsonProperty, JsonType} from 'ta-json';

@JsonObject()
export class Application {
    @JsonProperty('created_at')
    @JsonType(String)
    createdAt: string;

    @JsonProperty('created_by')
    @JsonType(Number)
    createdBy: number;

    @JsonProperty('id')
    @JsonType(Number)
    id: number;

    @JsonProperty('is_active')
    @JsonType(Boolean)
    isActive: boolean;

    @JsonProperty('is_platform_owner_company')
    @JsonType(Boolean)
    isPlatformOwnerCompany: boolean;

    @JsonProperty('logo')
    @JsonType(String)
    logo: string;

    @JsonProperty('name')
    @JsonType(String)
    name: string;

    @JsonProperty('parent_id')
    @JsonType(Number)
    parentId: number;

    @JsonProperty('priority')
    @JsonType(Number)
    priority: number;

    @JsonProperty('updated_at')
    @JsonType(String)
    updatedAt: string;

    @JsonProperty('updated_by')
    @JsonType(Number)
    updatedBy: number;

    @JsonProperty('url')
    @JsonType(String)
    url: string;

    @JsonProperty('view_name')
    @JsonType(String)
    viewName: string;
}
