<div class="position"
     (click)="onSubdivisionClick()">
    <img class="logo" *ngIf="subdivision" [src]="getAvatar()">
    <span class="title" [innerHTML]="subdivision.name | highlight : searchStr"></span>

    <div class="position__toolbar">
        <mat-icon *ngIf="selectable" class="icon _done" [svgIcon]="'radius_done'"></mat-icon>
        <!--<app-user-list-item *ngFor="let user of position.users"-->
                            <!--[shortView]="true"-->
                            <!--[small]="true"-->
                            <!--[userSubdivision]="user"-->
                            <!--class="user"></app-user-list-item>-->
        <!--<app-rating-number *ngIf="rating" [rating]="position.rating"></app-rating-number>-->
    </div>
</div>
