<section class="dialog" *ngIf="!isSuccessful">
    <header class="dialog__header">
        <h3 class="dialog__title">{{titleModal}}</h3>
        <mat-icon class="close" (click)="close('reset')">close</mat-icon>
    </header>


    <form class="form" *ngIf="data.flag!=='delete'" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
        <label class="label kit">
                <span class="label__txt">
                    Новый адрес почты
                </span>
            <input class="input" (blur)="isSubmited = true"
                   (focus)="isSubmited = false"
                   type="text" [ngClass]="{'_error': hasError()}"
                   formControlName="email"
                   autocomplete="off">
            <mat-error class="error" *ngIf="hasError()">{{errorModal}}</mat-error>
        </label>
        <!--<mat-form-field class="input" appearance="fill">
            <mat-label >Новый адрес почты</mat-label>
            <input type="text"
                matInput
                   [pattern]="NAME_REGEXP"
                formControlName="email">
            <mat-error class="error" *ngIf="formGroup.invalid">{{errorModal}}</mat-error>
        </mat-form-field>-->
    </form>

    <h3  class="dialog__delete" *ngIf="data.flag==='delete'">Вы действительно хотите удалить email?</h3>

    <footer class="dialog__footer" *ngIf="data.flag!=='delete'">
        <button type="submit" [disabled]="formGroup.invalid"
                mat-flat-button
                (click)="onSubmit()"
                class="button _blue">{{ buttonModal }}</button>
    </footer>

    <footer class="dialog__footer__delete" *ngIf="data.flag==='delete'">
        <button mat-flat-button
            type="reset"
            class="button _reset"
            (click)="close('reset')">
            Отмена
        </button>
        <button mat-flat-button
                type="button"
                class="button _blue"
                (click)="sendEmail()">
            {{ buttonModal }}
        </button>
    </footer>

    <app-spinner class="loader" [isSpinnerOn]="true" *ngIf="!isLoaded"></app-spinner>
</section>

<section class="dialog successful" *ngIf="isSuccessful">
    <header class="dialog__header">
        <mat-icon class="close" (click)="close()">close</mat-icon>
    </header>
    <div class="successful-box">
        <h3 class="dialog__title">Ссылка для подтверждения почты отправлена на: </h3>
        <span *ngIf="data.flag !== 'delete'" class="comment">{{formGroup.controls['email'].value}}</span>
    </div>
</section>
