import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MobilePhone} from '../../../../../../models/user';
import {Phone} from '../../../../../../models/phone';
import {maskedBackspaceFix} from '../../../../../../utils/masked-backspace-fix';
import {ConfirmSmartComponent} from '../../../confirm-smart/confirm-smart.component';
import {takeUntil} from 'rxjs/operators';
import {ApiPhonebookService} from '../../../../../phonebook/services/api-phonebook.service';
import {AuthService} from '../../../../../../services/auth.service';
import {GlobalNotificationCenterService} from '../../../../../../services/global-notification-center.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {Subject} from 'rxjs';
import {PhoneVerificationComponent} from '../../../../../cabinet/components/phone-verification/phone-verification.component';
import {BAD_PHONE_MSG, PHONE_REGEXP} from '../../../../../../utils/regexps';

@Component({
    selector: 'app-phones',
    templateUrl: './phones.component.html',
    styleUrls: ['./phones.component.scss']
})
export class PhonesComponent implements OnInit, OnDestroy {
    private destroyed = new Subject<void>();

    public phones: Phone[] = [];
    @Input() mobile: MobilePhone = null;
    @Input() internal = '';
    @Input() work = '';
    @Output() public readonly changeWorkPhone: EventEmitter<any> = new EventEmitter();
    @Output() public readonly changeInternalPhone: EventEmitter<any> = new EventEmitter();
    @Output() public readonly changePhones: EventEmitter<any> = new EventEmitter();
    @Output() public readonly changeMobile: EventEmitter<any> = new EventEmitter();
    isSended = false;
    cipher = '';
    timerStart = 20;
    timer = this.timerStart;
    timerHandler = null;
    mobileInput: string;
    internalInput: string;
    workInput: string;
    isMobilePhoneUsed = null;
    @Input() setUserId: number;
    currentUser;
    mobileError = '';
    mobileVerify = false;

    constructor(
        public api: ApiPhonebookService,
        public auth: AuthService,
        public notiService: GlobalNotificationCenterService,
        public dialog: MatDialog,
    ) {
    }

    @Input() set data(params: Phone[]) {
        this.phones = [...params];
    }

    ngOnInit(): void {
        this.auth.currentUser$.pipe(takeUntil(this.destroyed))
            .subscribe(res => {
                this.currentUser = res;
            });

        this.mobileInput = this.mobile?.phone || '';
        this.internalInput = this.internal || '';
        this.workInput = this.work || '';
        this.checkPhoneAvaliability();
    }

    onPhoneChange(evt, index) {
        maskedBackspaceFix(evt);
        this.changePhones.emit(this.phones);
    }

    onWorkPhoneChange(evt) {
        maskedBackspaceFix(evt);
        this.changeWorkPhone.emit(this.workInput);
    }

    onInternalPhoneChange(evt) {
        maskedBackspaceFix(evt);
        this.changeInternalPhone.emit(this.internalInput);
    }

    addPhone() {
        const newPhone = new Phone('home_phone', '');
        this.phones.push(newPhone);
    }

    removePhone(index) {
        this.phones.splice(index, 1);
        this.changePhones.emit(this.phones);
    }

    onDeleteMobilePhoneBtnClick() {
        // delete unconfirmed phone
        if (!this.mobile?.isVerified) {
            this.deleteMobilePhone();
            return;
        }

        // ask if you want to delete confirmed phone
        const dialogForm = this.dialog.open(ConfirmSmartComponent, {
            data: {
                title: 'Вы точно хотите удалить телефон, который используется для входа в аккаунт?',
                buttons: [
                    {
                        color: '_grey',
                        name: 'Отменить',
                        action: 'exit',
                        autofocus: false
                    },
                    {
                        color: '_blue',
                        name: 'Удалить',
                        action: 'delete',
                        autofocus: true,
                        inputRequired: true,
                    }]
            }
        });
        dialogForm.afterClosed().subscribe(result => {
            if (result === 'delete') {
                this.deleteMobilePhone();
            }
        });
    }

    deleteMobilePhone() {
        // remove
        this.api.deletePhone(this.mobile, this.auth.auth.id)
            .pipe(takeUntil(this.destroyed))
            .subscribe(resp => {
                // phone deleted
                this.phones = this.phones.filter(el => el.phone !== this.mobile.phone);
                this.mobile = null;
                this.mobileInput = '';
                this.changePhones.emit(this.phones);
                this.changeMobile.emit(this.mobileInput);
            }, err => {
                this.notiService.handleFullError(err);
            });
    }

    sendVerificationMobilePhone(phone: string) {
        const dialogForm = this.dialog.open(PhoneVerificationComponent, {
            width: '377px',
            data: {phone, userId: this.auth.auth.id}
        });
        dialogForm.afterClosed().subscribe(result => {
            if (result) {
                this.mobile.isVerified = result.isVerified;
                this.isSended = result.isSended;
            }
        });
    }


    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }

    sendCipher($event) {
        $event.preventDefault();
        if (this.mobile) {
            this.sendVerificationMobilePhone(this.mobile.phone);
        } else {
            this.sendVerificationMobilePhone(this.mobileInput);
        }
    }

    onMobilePhoneKeyUp($event: KeyboardEvent) {
        maskedBackspaceFix($event);
        // only russian phones
        if (this.mobileInput.length >= 1) {
            this.mobileInput = this.mobileInput.replace(this.mobileInput[0],'7');
        }

        this.changeMobile.emit(this.mobileInput);

        this.checkPhoneAvaliability();
    }

    onMobilePhonePaste($event: ClipboardEvent) {
        // only russian phones
        this.mobileInput = $event.clipboardData.getData("text");

        if (this.mobileInput.length === 11 && this.mobileInput[0] !== '7') {
            this.mobileInput = this.mobileInput.replace(this.mobileInput[0],'7');
        }

        if (this.mobileInput.length >= 1 && this.mobileInput.length !== 11 && this.mobileInput[0] !== '7') {
            const seven = '7';
            this.mobileInput = seven.concat(this.mobileInput);
        }

        this.changeMobile.emit(this.mobileInput);

        this.checkPhoneAvaliability();
    }

    checkPhoneAvaliability() {
        // do not check if verified
        if (this.mobile?.isVerified) {
            return;
        }

        this.mobileError = '';

        if (this.mobileInput?.length >= 11) {
            if (!this.mobileInput.match(PHONE_REGEXP)) {
                this.mobileError = BAD_PHONE_MSG;
                return;
            }

            this.mobileVerify = true;

            this.api.checkAvailableEmail(this.mobileInput)
                .pipe(takeUntil(this.destroyed))
                .subscribe(resp => {
                    this.isMobilePhoneUsed = false;
                    this.mobileVerify = false;
                }, err => {
                    this.isMobilePhoneUsed = true;
                    this.mobileVerify = false;
                });
        } else  {
            this.isMobilePhoneUsed = null;
            this.mobileVerify = false;
        }
    }
}
