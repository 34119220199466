<section class="dialog">
    <header class="dialog__header">
        <h3 class="dialog__title">{{ title }}</h3>
        <mat-icon class="close" (click)="close()">close</mat-icon>
    </header>


    <div class="content">
        {{ content }}
    </div>


    <footer class="dialog__footer">
        <app-button class="button" type="submit" title="Продолжить" (click)="close()"></app-button>
    </footer>

    <app-spinner class="loader" [isSpinnerOn]="true" *ngIf="!isLoaded"></app-spinner>
</section>
