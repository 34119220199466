import {JsonProperty, JsonType} from 'ta-json';

export enum MessengerType {
    SKYPE = 'skype',
    TELEGRAM = 'telegram',
}

/**
 * Маппинг мессенджеров
 */
export const messengerMapping = Object.freeze([
    {
        type: MessengerType.TELEGRAM,
        name: 'Telegram',
        icon: 'radius_telegram',
    },
    {
        type: MessengerType.SKYPE,
        name: 'Skype',
        icon: 'radius_skype',
    }
]);

export class Messenger {
    @JsonProperty('type')
    @JsonType(String)
    type: string;

    @JsonProperty('login')
    @JsonType(String)
    login: string;

    constructor(
        type: string,
        login: string
    ) {
        this.type = type;
        this.login = login;
    }
}
