import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '../../../services/config.service';
import {AuthService} from '../../../services/auth.service';
import {map} from 'rxjs/operators';
import {JSON} from 'ta-json';
import {Account} from 'src/app/models/account';
import {BehaviorSubject, Subject} from 'rxjs';
import {PricingPlanCompany} from '../components/pricing/models/pricing-plan-company';
import {UserShort} from '../../../models/user';

@Injectable({
  providedIn: 'root'
})
export class ApiCabinetService {
  public updateCompanyAvatar$: Subject<any> = new Subject<any>();
  tariffInfo$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
      private http: HttpClient,
      public config: ConfigService,
      public auth: AuthService
  ) { }

  /* Выбор почты для уведомления*/
  public setNotifiableEmail(data) {
    return this.http.put(
        `${this.config.setNotificationEmail}`,
        data,
        this.auth.authorize()
    ).pipe(map(res => JSON.deserialize<Account>(res['payload'], Account)));
  }

  /* Запрос профиля компании*/
  public getCompanyProfile(companyId) {
    return this.http.get(
      `${this.config.companyProfile}${companyId}/`,
      this.auth.authorize()
  ).pipe(
      map(res => res['payload'])
  );
  }

  /* Обновление профиля компании*/
  public updateCompanyProfile(data, companyId) {
    return this.http.put(
      `${this.config.companyProfile}${companyId}/`,
      data,
      this.auth.authorize()
  ).pipe(
      map(res => res['payload'])
  );
  }

  getCurrentPricingPlan() {
    return this.http.get(
        `${this.config.tariffsService}/api/v1/tariffs/company`,
        this.auth.authorize()
    ).pipe(
        map(res => JSON.deserialize<PricingPlanCompany>(res['payload'], PricingPlanCompany))
    );
  }

  getUserInfo() {
    return this.http.get(
        `${this.config.tariffsService}/api/v1/tariffs/user/info`,
        this.auth.authorize()
    ).pipe(
        map(res => res['payload'])
    );
  }

  getScheduledPricingPlan(next = false) {
    const args = next ? '?to_next_period=true' : '';

    return this.http.get(
        `${this.config.tariffsService}/api/v1/tariffs/company/draft${args}`,
        this.auth.authorize()
    ).pipe(
        map(res => res['payload'] ? JSON.deserialize<PricingPlanCompany>(res['payload'], PricingPlanCompany) : null)
    );
  }

  changePricingPlan(payload: any) {
    return this.http.put(
        `${this.config.tariffsService}/api/v1/tariffs/company`,
        payload,
        this.auth.authorize()
    ).pipe(
        map(res => res['payload'] ? JSON.deserialize<PricingPlanCompany>(res['payload'], PricingPlanCompany) : null)
    );
  }

  setFavs(favUsers: UserShort[]) {
    const payload = {users: favUsers.map(u => u.id)};

    return this.http.put(
        `${this.config.tariffsService}/api/v1/tariffs/company/users/favorite`,
        payload,
        this.auth.authorize()
    ).pipe(
        map(res => res)
    );
  }

    cancelPricingPlanDraft(id) {
      return this.http.delete(
          `${this.config.tariffsService}/api/v1/tariffs/company/draft/${id}`,
          this.auth.authorize()
      ).pipe(
          map(res => res['payload'] ? JSON.deserialize<PricingPlanCompany>(res['payload'], PricingPlanCompany) : null)
      );
    }

    activatePromo(code: string) {
      return this.http.post(
          `${this.config.tariffsService}/api/v1/tariffs/active_promo`,
          {code},
          this.auth.authorize()
      ).pipe(
          map(res => res['payload'] ? JSON.deserialize<PricingPlanCompany>(res['payload'], PricingPlanCompany) : null)
      );
    }

  resetDefault() {
    return this.http.delete(
        `${this.config.tariffsService}/api/v1/tariffs/company/drop_to_default`,
        this.auth.authorize()
    ).pipe(
        map(res => res)
    );
  }

    updateCompanyLegal(data, companyId: number) {
      return this.http.put(
          `${this.config.companyProfile}${companyId}/requisites`,
          data,
          this.auth.authorize()
      ).pipe(
          map(res => res['payload'])
      );
    }

    activatePromoMod(tariffId, name, dateStr) {
      const data = {
         "tariff_id": tariffId,
          "name": name,
          "date_to": dateStr,
      };

      return this.http.post(
          `${this.config.tariffsService}/api/v1/tariffs/add_promo_group`,
          data,
          this.auth.authorize()
      ).pipe(
          map(res => res['payload'])
      );
    }
}
