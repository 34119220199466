<audio class="audio" [src]="src" #audio (timeupdate)="timeUpdate($event)"></audio>
<ng-container *ngIf="audio">

    <button class="button" *ngIf="audio.paused && audio.duration" (click)="play(audio)">
        <mat-icon class="icon">play_arrow</mat-icon>
    </button>

    <button class="button" *ngIf="!audio.paused && audio.duration" (click)="audio.pause()">
        <mat-icon class="icon" >close</mat-icon>
    </button>

    <button class="button" *ngIf="!audio.duration">
        <mat-icon class="icon _loading" svgIcon="radius_progress_activity"></mat-icon>
    </button>

    <input class="range" type="range" #slider value="0"
           (input)="sliderMove($event)">

    <span class="range-new">
        <span class="range-new-fill" *ngIf="audio.duration" [style.width.%]="audio.currentTime / audio.duration * 100"></span>
    </span>

    <span class="txt" *ngIf="audio.duration">
        <span class="time">{{ calculateTime(audio.currentTime) }}</span>/
        <span class="time">{{ calculateTime(audio.duration) }}</span>
    </span>

</ng-container>

