import {Component, Input, OnDestroy, OnInit,} from '@angular/core';
import {AuthService} from '../../../../services/auth.service';
import {ChatsService} from '../../../../services/chats.service';
import {takeUntil} from 'rxjs/operators';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {Subject} from 'rxjs';
import {AdminsDialogComponent} from '../admins-dialog/admins-dialog.component';
import {ApiPhonebookService} from '../../../phonebook/services/api-phonebook.service';


@Component({
    selector: 'app-chat-with-admin',
    templateUrl: './chat-with-admin.component.html',
    styleUrls: ['./chat-with-admin.component.scss'],
})
export class ChatWithAdminComponent implements OnInit, OnDestroy {
    @Input() text = 'обратитесь к администратору';

    users = []; // TODO надо сделать какое-то центарльное хранилище юзеров

    /** Тригерит завершение подписок */
    private destroyed = new Subject<void>();

    constructor(
        private auth: AuthService,
        private chats: ChatsService,
        private dialog: MatDialog,
        private api: ApiPhonebookService,
    ) {

    }

    ngOnInit() {
        const user = this.auth.auth;

        console.log(user);

        const company = user.company;

        company.admins.forEach(u => {
            this.api.getUserById(u.id)
                .pipe(takeUntil(this.destroyed))
                .subscribe(resp => {
                   this.users.push(resp.user);
                });
        });
    }

    openChat(id, name) {
        const data = {
            id: id,
            name: name
        };
        this.chats.openChat$.next(data);
    }

    onClick(event) {
        event.preventDefault();
        event.stopPropagation();


        let admins = [];

        for (let adm of this.auth.company$.value?.admins) {
            const u = this.users.find(usr => usr.id === adm.id);
            admins.push(u ? u : adm);
        }

        if (admins.length === 1) {
            const admin = admins[0];
            this.openChat(admin.id, admin.shortName);
        } else {
            const dialogForm = this.dialog.open(AdminsDialogComponent, {
                data: {
                    admins: admins,
                }, maxWidth: 400, minWidth: 400});

            dialogForm.afterClosed()
                .pipe(takeUntil(this.destroyed))
                .subscribe(result => {
                    // do nothing
                });
        }
    }

    ngOnDestroy(): void {
        this.destroyed.next();
        this.destroyed.complete();
    }
}
