<ng-container>
    <!--<div class="back-layer" (click)="closeFullScreen()" *ngIf="!isMobile">-->
        <div class="wnd confirm">
            <header class="header"></header>

            <div class="dialog__content">
                <h3>Вы уверены, что хотите пропустить {{ data.title }}?</h3>
                <span>Вы можете вернуться к {{ getName() }} в любой момент, нажав на знак вопроса, находясь в разделе “Задачи”</span>
                <!--<span>Вы всегда можете вернуться к введению позднее</span>-->
            </div>
            <footer class="dialog__footer done">
                <app-button class="button"
                            buttonType="outlined"
                            (click)="close(true)"
                            title="Пропустить"></app-button>

                <app-button class="button"
                            buttonType="field"
                            (click)="close(false)"
                            [title]="'Вернуться к ' + getName()"></app-button>
            </footer>
        </div>
    <!--</div>-->
</ng-container>