<div class="position"
     [ngClass]="{'_has-user': position['find_user']}"
     (click)="onPositionsClick()">
    <span class="title" [innerHTML]="position.title | highlight : searchStr"></span>

    <span class="count" *ngIf="main && position.slots.length">({{ position.slots.length }})</span>

    <div class="position__toolbar">
        <mat-icon *ngIf="selectable" class="icon _done" [svgIcon]="'radius_done'"></mat-icon>
        <mat-icon *ngIf="selectable" class="icon _add" [svgIcon]="'radius_plus'"></mat-icon>

        <div class="slots" *ngIf="main && position.slots.length">
            <ng-container *ngFor="let slot of position.slots, let index = index">
                <app-slots-list-item class="avatar _linear _small" *ngIf="index < 5"
                                     [shortView]="true"
                                     [matTooltip]="slot.user?.name" [slot]="slot"></app-slots-list-item>
            </ng-container>

            <span class="over" *ngIf="position.slots.length > 5">
                + {{ position.slots.length - 5}}
            </span>
        </div>

        <app-user-list-item *ngFor="let user of position.users"
                            [shortView]="true"
                            [small]="true"
                            [fullName]="true"
                            [userSubdivision]="user"
                            class="user"></app-user-list-item>
        <app-rating-number class="rating" *ngIf="rating" [rating]="position.rating"></app-rating-number>
    </div>
</div>

<div class="users" *ngIf="position['find_user']">
    <ng-container *ngFor="let slot of position.slots">
        <app-user-list-item *ngIf="slot.user"
                            [small]="true"
                            [searchStr]="searchStr"
                            [fullName]="true"
                            [userSubdivision]="slot.user"
                            class="user"></app-user-list-item>
        <app-rating-number *ngIf="rating" [rating]="position.rating"></app-rating-number>
    </ng-container>
</div>

