import {JsonElementType, JsonProperty, JsonType} from 'ta-json';
import {Rating} from '../../../shared/models/rating';
import {UserShort} from '../../../../models/user';
import {QuestionResponce} from '../../../questions/models/question-responce';

export class UserAnswer {
    @JsonProperty('comment')
    @JsonType(String)
    comment: string;

    @JsonProperty('updated_at')
    @JsonType(String)
    updated_at: string;

    @JsonProperty('user')
    @JsonElementType(UserShort)
    user: UserShort;

    @JsonProperty('answer')
    @JsonElementType(QuestionResponce)
    answer: QuestionResponce;
}

export class FunctionQuestion {
    @JsonProperty('id')
    @JsonType(Number)
    id: number;

    @JsonProperty('question')
    @JsonType(String)
    question = '';

    @JsonProperty('is_active')
    @JsonType(Boolean)
    isActive = false;

    @JsonProperty('rating')
    @JsonElementType(Rating)
    rating: Rating = null;

    @JsonProperty('user_answers')
    @JsonElementType(UserAnswer)
    user_answers: UserAnswer[];
}
