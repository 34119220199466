<div class="back-layer" (click)="closeFullScreen()" *ngIf="isVisible && isLoaded">
    <div class="wnd _small" (click)="stopPropagation($event)">
        <button mat-icon-button (click)="closeFullScreen()" class="close-btn">
            <mat-icon>close</mat-icon>
        </button>

        <div class="content">
            <div class="title-wrap-onb">
                <h2>По данному разделу пока нет описания</h2>
                <p>Вы можете посмотреть подсказки по другим разделам:</p>
            </div>
            <div class="slider-wrap">
                <div class="hint-button"  (click)="toHint('main')">
                    <span class="hint-title">
                        <img src="../assets/images/logo.svg" class="img">
                        Главная страница
                    </span>
                    <mat-icon>navigate_next</mat-icon>
                </div>
                <div class="hint-button"  (click)="toHint('users')">
                    <span class="hint-title">
                        <img src="/assets/images/users.svg" class="img">
                        Справочник
                    </span>
                    <mat-icon>navigate_next</mat-icon>
                </div>
                <div class="hint-button"  (click)="toHint('tasks')">
                    <span class="hint-title">
                        <img src="../assets/images/task-manager.svg" class="img">
                        Задачи</span>
                    <mat-icon>navigate_next</mat-icon>
                </div><!--
                <div class="hint-button"  (click)="toHint('company')">
                    <span class="hint-title">
                        <img src="/assets/images/company.svg" class="img">
                        Компания</span>
                    <mat-icon>navigate_next</mat-icon>
                </div>-->
            </div>
        </div>
    </div>
</div>
