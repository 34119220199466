import {ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Member} from '../../../task-manager/models/member';
import {ConfigService} from '../../../../services/config.service';
import {StorageService} from '../../../../services/storage.service';

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarComponent implements OnInit, OnChanges {
  @Input() public user = null;
  public avatar;
  public text = null;

  constructor(
      private config: ConfigService,
      private cdr: ChangeDetectorRef,
      private global: StorageService
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.user.currentValue) {
        this.user = changes.user.currentValue;
      this.getAvatar(this.user);
    }
  }

  getAvatar(user: Member) {
    if (user) {
      this.avatar = this.config.getUserAvatarByUser(user, 'small');
    } else {
      this.avatar = this.config.photoAvatarUser + '/default/small';
    }

    this.text = '';
    if (this.avatar.search('default') >= 0 || this.avatar.search('undefined') >= 0) {
      this.avatar = null;

      if (this.user.lastName) {
        this.text += this.user.lastName.charAt(0);
      } else if (this.user.last_name) {
        this.text += this.user.last_name.charAt(0);
      }

      if (this.user.firstName) {
        this.text += this.user.firstName.charAt(0);
      } else if (this.user.first_name) {
        this.text += this.user.first_name.charAt(0);
      }

      if (!this.text?.length) {
        const gUser = this.global.getUser(this.user.id);


        if (gUser && gUser.accounts.length && gUser.accounts[0].account) {
          this.text = gUser.accounts[0].account.charAt(0);
        }
      }

      this.text = this.text.toString().toUpperCase();

    }

  }
}
