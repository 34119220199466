import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {
    transform(s: string | null): any {
        if (!s) {
            return '';
        }

        const s2 = s.replace(/\D/g, '');
        const m = s2.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{3})$/);
        const m2 = s2.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);
        if (m) {
            return `+${m[1]} ${m[2]} ${m[3]}-${m[4]}-${m[5]}`;
        }

        if (m2) {
            return `+${m2[1]} ${m2[2]} ${m2[3]}-${m2[4]}-${m2[5]}`;
        }

        return s;
    }

}
