import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {JSON} from 'ta-json';
import {HistoryPhonebook} from '../../../phonebook/models/history-phonebook';
import {Subject} from 'rxjs';
import {ApiService} from '../../../../services/api.service';
import {GlobalNotificationCenterService} from '../../../../services/global-notification-center.service';
import {ConfigService} from '../../../../services/config.service';

@Component({
  selector: 'app-history-phonebook',
  templateUrl: './history-phonebook.component.html',
  styleUrls: ['./history-phonebook.component.scss']
})
export class HistoryPhonebookComponent implements OnInit, OnDestroy, OnChanges {

    public listData: any;
    @Input() id: string;
    @Input() type: string;
    private destroyed = new Subject<void>();

    constructor(
        public api: ApiService,
        public config: ConfigService,
        private notiService: GlobalNotificationCenterService
    ) {
    }

    ngOnInit() {
    }

    getData() {
        this.api.getHistoryPhonebookById(this.id, this.type)
            .pipe(takeUntil(this.destroyed))
            .subscribe(params => {
                this.listData = JSON.deserialize<HistoryPhonebook[]>(params, HistoryPhonebook);
            }, (err) => {
                this.notiService.handleFullError(err);
            });
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.id) {
            this.id = changes.id.currentValue;
            if (this.id) {
                this.getData();
            } else {
                this.listData = [];
            }
        }
    }

    getDateTime(item) {
        const date = item.createdAt;
        const offset = new Date().getTimezoneOffset();

        return new Date(date.getTime() - offset * 60 * 1000);
    }
}
