import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-info-circle',
  templateUrl: './info-circle.component.html',
  styleUrls: ['./info-circle.component.scss']
})
export class InfoCircleComponent {
    @Input() public isAuth = false;

    public blocks = [{
    upTitle: 'Задачи',
      color: 'green',
      title: 'Управление',
      subtitle: 'по проектам и задачам'
  }, {
    upTitle: 'Оргструктура',
      color: 'violet',
      title: 'Построение организационной структуры',
      subtitle: 'и контроль исполнения функций сотрудниками'
  }, {
      upTitle: 'App',
      color: 'emerald',
      title: 'Удобное мобильное приложение на IOS и Android',
  }, {
      upTitle: 'Чаты и уведомления',
      color: 'teal',
      title: 'Простая система связи с сотрудниками',
      subtitle: 'встроенный мессенджер, удобная система уведомлений'
  }, {
    upTitle: 'Календарь',
      color: 'violet',
      title: 'Управление эффективностью встреч',
      subtitle: 'встроенный календарь и протоколы собраний'
  }, {
    upTitle: 'Корпоративная система',
      color: 'blue',
      title: 'Новости компании и база знаний',
  }, {
    upTitle: 'Денежная мотивация',
      color: 'emerald',
      title: 'Мотивация и матрица эффективности',
      subtitle: 'влияние на заработную плату через smart-задачи и показатели'

  }];
}
