<section class="form" [formGroup]="formGroup">
  <h3 class="title">{{ title }}</h3>
  <div class="inputs">
    <label class="label kit _first">
      <span class="label__txt"> Название ссылки </span>
      <input class="input"
        tabindex="-1"
        formControlName="name"
        autocomplete="off"
      />
    </label>
    <label class="label kit">
      <span class="label__txt"> Ссылка </span>
      <input class="input"
        tabindex="-1"
        formControlName="url"
        autocomplete="off"
      />
    </label>
  </div>
  <div class="footer">
    <button mat-flat-button class="button _white _cancel" (click)="close()">
      Отменить
    </button>
    <button mat-flat-button class="button _blue _save" (click)="save()" [disabled]="this.formGroup.controls['url'].value.trim() === ''">
      Сохранить
    </button>
  </div>
</section>
