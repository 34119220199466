import {JsonProperty, JsonType} from 'ta-json';

export class Account {
    @JsonProperty('type')
    @JsonType(String)
    type: string;

    @JsonProperty('account')
    @JsonType(String)
    account: string;

    @JsonProperty('is_verified')
    @JsonType(Boolean)
    isVerified: boolean;

    @JsonProperty('is_notifiable')
    @JsonType(Boolean)
    isNotifiable: boolean;

    @JsonProperty('is_gmail')
    @JsonType(Boolean)
    isGmail: boolean;

    constructor(
        type: string,
        account: string,
        isVerified: boolean,
        isNotifiable: boolean,
        isGmail: boolean
    ) {
        this.type = type;
        this.account = account;
        this.isVerified = isVerified;
        this.isNotifiable = isNotifiable;
        this.isGmail = isGmail;
    }
}
