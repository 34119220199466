import {JSON, JsonElementType, JsonObject, JsonProperty, JsonType} from 'ta-json';
import Status from './status';
import {User, UserShort} from '../../../models/user';
import {File} from '../../../models/file';
import {Board} from './board';
import * as moment from 'moment';
import {Link} from '../../../models/link';
import {TaskView} from './task-view';
import {Tag} from './tag';
import {ColumnShort} from './column-short';
import {ShortUserBoards} from './member';
import {Template} from './template';
import {HistoryRescheduling} from './history-rescheduling';
import {Tracking} from './tracking';

export class Sprint {
    @JsonProperty('id')
    @JsonType(Number)
    id?: number;

    @JsonProperty('name')
    @JsonType(String)
    name?: string;

    @JsonProperty('color')
    @JsonType(String)
    color?: string;

    @JsonProperty('board_id')
    @JsonType(Number)
    board_id?: number;

    @JsonProperty('archived')
    @JsonType(Boolean)
    archived?: boolean;

    @JsonProperty('first_board')
    @JsonType(Boolean)
    firstBoard?: boolean;

}



/**
 * Список статусов
 */
export enum TaskStatuses {
    RESTORE = 'RESTORE',
    DRAFT = 'DRAFT',
    NEW = 'NEW',
    BACKLOG = 'BACKLOG',
    AT_WORK = 'AT_WORK',
    AT_WORK_BACK = 'AT_WORK_BACK',
    TO_ACCEPT = 'TO_ACCEPT',
    REJECTED = 'REJECTED',
    DELETED = 'DELETED',
    DONE = 'DONE',
    DONE_ARCHIVE = 'DONE_ARCHIVE',
    REJECTED_ARCHIVE = 'REJECTED_ARCHIVE',
    PAUSE = 'PAUSE',
    CANCELLATION_APPROVAL = 'CANCELLATION_APPROVAL',
    CHANGE_APPROVAL = 'CHANGE_APPROVAL',
    RESCHEDULING_AGREED = 'RESCHEDULING_AGREED',
    RESCHEDULING_REJECTED = 'RESCHEDULING_REJECTED',
}

/**
 * Список статусов
 */
export enum TaskStatusesId {
    DRAFT= 0,
    NEW = 1,
    BACKLOG = 2,
    AT_WORK= 3,
    TO_ACCEPT = 4,
    DONE = 5,
    REJECTED = 6,
    PAUSE = 7,
    DELETED = 8,
    CANCELLATION_APPROVAL = 9,
    REJECTED_ARCHIVE = 10,
    DONE_ARCHIVE = 11,
    CHANGE_APPROVAL = 12,
    RESCHEDULING_AGREED = 1024,
    RESCHEDULING_REJECTED = 1025,
}

/**
 * Список статусов
 */
export enum ButtonIcons {
    DRAFT = 'DRAFT',
    NEW = 'NEW',
    BACKLOG = 'BACKLOG',
    AT_WORK = 'AT_WORK',
    TO_ACCEPT = 'TO_ACCEPT',
    REJECTED = 'REJECTED',
    DELETED = 'DELETED',
    DONE = 'DONE',
    DONE_ARCHIVE = 'DONE_ARHIVE',
    PAUSE = 'PAUSE'
}

export const ASSESSMENT_TYPE = [
    {type: 'sp', title: 'SP (Сторипоинты)'},
    {type: 'day', title: 'День'},
    {type: 'hour', title: 'Часы, минуты'},
    {type: 'p', title: 'Балл'}
];

/** Статусы, для которых значок "важно" не отображать */
export const taskNotImportantStatuses = [
    TaskStatuses.REJECTED,
    TaskStatuses.DONE
];

/**
 * Маппинг статусов
 */

export const taskStatusesActualMapping = Object.freeze([
    {
        value: TaskStatuses.DRAFT,
        name: 'Черновик',
        icon: null,
        actionId: null
    },
    {
        value: TaskStatuses.NEW,
        name: 'Сделать',
        icon: 'format_list_bulleted',
        actionId: 1
    },
    {
        value: TaskStatuses.BACKLOG,
        name: 'Сделать',
        icon: 'format_list_bulleted',
        actionId: 2
    },
    {
        value: TaskStatuses.AT_WORK,
        name: 'В работе',
        icon: 'play_arrow',
        actionId: 3
    },
    {
        value: TaskStatuses.TO_ACCEPT,
        name: 'Принять',
        icon: 'done',
        actionId: 4
    },
    {
        value: TaskStatuses.DONE,
        name: 'Сделана',
        icon: 'done_all',
        actionId: 5
    },
    {
        value: TaskStatuses.REJECTED,
        name: 'Отменена',
        icon: 'close',
        actionId: 6
    },
    {
        value: TaskStatuses.PAUSE,
        name: 'Приостановлена',
        icon: 'pause',
        actionId: 7
    },
    {
        value: TaskStatuses.DELETED,
        name: 'Удалена',
        icon: 'close',
        actionId: 8
    },
    {
        value: TaskStatuses.CANCELLATION_APPROVAL,
        name: 'В архиве',
        icon: 'close',
        actionId: 9
    },
    {
        value: TaskStatuses.REJECTED_ARCHIVE,
        name: 'Отменена',
        icon: 'close',
        actionId: 10
    },
    {
        value: TaskStatuses.DONE_ARCHIVE,
        name: 'В архиве',
        icon: 'archive',
        actionId: 11
    },
    {
        value: TaskStatuses.CHANGE_APPROVAL,
        name: 'Согласовать',
        icon: 'done',
        actionId: 12
    },
    {
        value: TaskStatuses.RESCHEDULING_AGREED,
        name: 'Согласовать перенос',
        icon: 'done',
        actionId: 1024
    },
    {
        value: TaskStatuses.RESCHEDULING_REJECTED,
        name: 'Отменить перенос',
        icon: 'done',
        actionId: 1025
    },
]);

export const taskStatusesMapping = Object.freeze([
    {
        value: TaskStatuses.DRAFT,
        name: 'Черновик',
        icon: null,
        actionId: null
    },
    {
        value: TaskStatuses.PAUSE,
        name: 'Приостановлена',
        icon: 'pause',
        actionId: 7
    },
    {
        value: TaskStatuses.NEW,
        name: 'Сделать',
        icon: 'format_list_bulleted',
        actionId: 1
    },
    {
        value: TaskStatuses.BACKLOG,
        name: 'В очереди',
        icon: 'format_list_bulleted',
        actionId: 2
    },
    {
        value: TaskStatuses.AT_WORK,
        name: 'В работе',
        icon: 'play_arrow',
        actionId: 3
    },
    {
        value: TaskStatuses.TO_ACCEPT,
        name: 'Принять',
        icon: 'done',
        actionId: 5
    },
    {
        value: TaskStatuses.REJECTED,
        name: 'Отменена',
        icon: 'close',
        actionId: 6
    },
    {
        value: TaskStatuses.DELETED,
        name: 'Удалена',
        icon: 'close',
        actionId: 8
    },
    {
        value: TaskStatuses.DONE,
        name: 'Выполнена',
        icon: 'done_all',
        actionId: 4
    },
    {
        value: TaskStatuses.REJECTED_ARCHIVE,
        name: 'Отменена',
        icon: 'close',
        actionId: 10
    },
    {
        value: TaskStatuses.DONE_ARCHIVE,
        name: 'В архиве',
        icon: 'done_all',
        actionId: 11
    },
    {
        value: TaskStatuses.CANCELLATION_APPROVAL,
        name: 'В архиве',
        icon: 'close',
        actionId: 9
    },
    {
        value: TaskStatuses.CHANGE_APPROVAL,
        name: 'Согласовать',
        icon: 'done',
        actionId: 12
    },
]);

@JsonObject()
export class ShortTask {
    /** Идентификатор */
    @JsonProperty('id')
    @JsonType(Number)
    id?: number;

    @JsonProperty('vid')
    @JsonType(Number)
    vid?: number;

    @JsonProperty('title')
    @JsonType(String)
    title?: string;

    @JsonProperty('flag_importance')
    @JsonType(Boolean)
    flagImportance: boolean;

    @JsonProperty('importance')
    @JsonType(Number)
    importance = 0;

    @JsonProperty('status')
    @JsonElementType(Status)
    status?: Status;

    @JsonProperty('status_last')
    @JsonElementType(Status)
    status_last?: Status;

    @JsonProperty('availability')
    @JsonType(Boolean)
    availability?: boolean;

    /** Активная колонка **/
    @JsonProperty('column')
    @JsonElementType(ColumnShort)
    column: ColumnShort = null;

    @JsonProperty('on_current_board')
    @JsonType(Boolean)
    onCurrentBoard: boolean;

    @JsonProperty('responsible')
    @JsonElementType(ShortUserBoards)
    responsible: ShortUserBoards = null;

    @JsonProperty('members_approver')
    @JsonElementType(ShortUserBoards)
    approvers: ShortUserBoards[] = [];

    @JsonProperty('members_performer')
    @JsonElementType(ShortUserBoards)
    performers: ShortUserBoards[] = [];

    @JsonProperty('members_observer')
    @JsonElementType(ShortUserBoards)
    observers: ShortUserBoards[] = [];

    constructor(
        id?: number,
        title: string = '',
        flagImportance: boolean = false,
        status?: Status,
        availability: boolean = true,
        onCurrentBoard: boolean = true,
        approvers: UserShort[] = [],
        performers: UserShort[] = [],
        observers: UserShort[] = [],
    ) {
        this.id = id;
        this.title = title;
        this.flagImportance = flagImportance;
        this.status = status;
        this.availability = availability;
        this.onCurrentBoard = onCurrentBoard;
        this.approvers = approvers;
        this.performers = performers;
        this.observers = observers;
    }

    get isTaskClose() {
        if (!this.status) {
            return false;
        }
        return this.status.id === TaskStatusesId.REJECTED ||
            this.status.id === TaskStatusesId.REJECTED_ARCHIVE ||
            this.status.id === TaskStatusesId.DONE_ARCHIVE ||
            this.status.id === TaskStatusesId.DONE;
    }

    get isTaskDone() {
        if (!this.status) {
            return false;
        }
        return +this.status.id == +TaskStatusesId.DONE_ARCHIVE ||
            +this.status.id == +TaskStatusesId.DONE;
    }

    get isTaskDeleted() {
        if (!this.status) {
            return false;
        }
        return this.status.id === TaskStatusesId.DELETED;
    }

    get isTaskArchive() {
        if (!this.status) {
            return false;
        }
        return this.status.id === TaskStatusesId.DONE_ARCHIVE || this.status.id === TaskStatusesId.REJECTED_ARCHIVE;
    }

    get isTaskRejected() {
        if (!this.status) {
            return false;
        }
        return this.status.id === TaskStatusesId.REJECTED ||
            this.status.id === TaskStatusesId.CANCELLATION_APPROVAL ||
            this.status.id === TaskStatusesId.REJECTED_ARCHIVE;
    }

    get isTaskRejectedArchive() {
        if (!this.status) {
            return false;
        }
        return this.status.id === TaskStatusesId.REJECTED_ARCHIVE;
    }

    getStatusIcon() {
        const status = taskStatusesMapping.find(el => el.actionId === this.status?.id);
        return status?.icon;
    }

    getActualStatusIcon() {
        const status = taskStatusesActualMapping.find(el => el.actionId === this.status?.id);
        return status?.icon;
    }

    hasSvgIcon() {
        const status = taskStatusesMapping.find(el => el.actionId === this.status?.id);
        return status?.icon === 'format_list_bulleted';
    }

    membership(userId: number) {
        return this.onCurrentBoard ? true : (
            this.performers && this.performers?.some(el => +el.id === userId) ||
            this.observers && this.observers?.some(el => +el.id === userId) ||
            this.approvers && this.approvers?.some(el => +el.id === userId)
        );
    }
}

@JsonObject()
export class Task extends ShortTask {
    /** Идентификатор */
    @JsonProperty('id')
    @JsonType(Number)
    id?: number;

    /** Идентификатор */
    @JsonProperty('author')
    @JsonElementType(User)
    author?: User;
    /** Идентификатор */
    @JsonProperty('author_id')
    @JsonType(Number)
    authorId?: number;

    @JsonProperty('description')
    @JsonType(String)
    description: string;

    @JsonProperty('dod')
    @JsonType(String)
    dod: string;

    @JsonProperty('actual_result')
    @JsonType(String)
    actual_result: string;

    @JsonProperty('date_expiration')
    @JsonType(Date)
    dateExpiration: Date;

    @JsonProperty('date_creation')
    @JsonType(Date)
    dateCreation: Date;

    @JsonProperty('date_finish')
    @JsonType(Date)
    dateFinish: Date;

    @JsonProperty('date_rescheduling')
    @JsonType(Date)
    dateRescheduling: Date;

    @JsonProperty('date_start')
    @JsonType(Date)
    dateStart: Date;

    @JsonProperty('date_actual_start')
    @JsonType(Date)
    date_actual_start: Date;

    @JsonProperty('unread_messages')
    @JsonType(Number)
    messages: number;

    @JsonProperty('matrix_id')
    @JsonType(Number)
    matrixId: number;

    @JsonProperty('flag_new')
    @JsonType(Boolean)
    isNew: boolean;

    @JsonProperty('files')
    @JsonElementType(File)
    files: File[] = [];

    @JsonProperty('links')
    @JsonElementType(Link)
    links: Link[] = [];

    @JsonProperty('history_rescheduling')
    @JsonElementType(HistoryRescheduling)
    history_rescheduling: HistoryRescheduling = null;

    @JsonProperty('group')
    @JsonElementType(Tag)
    group: Tag;

    @JsonProperty('boards')
    @JsonElementType(Board)
    boards: Board[] = [];

    @JsonProperty('board_columns')
    @JsonElementType(ColumnShort)
    boardColumns: ColumnShort[] = [];

    @JsonProperty('parent')
    @JsonElementType(ShortTask)
    parent: ShortTask;

    @JsonProperty('children')
    @JsonElementType(Task)
    children: Task[] = [];

    @JsonProperty('ancestors')
    @JsonElementType(ShortTask)
    ancestors: ShortTask[] = [];

    @JsonProperty('descendants')
    @JsonElementType(ShortTask)
    descendants: ShortTask[] = [];

    @JsonProperty('path')
    @JsonType(Array)
    path = [];

    @JsonProperty('view')
    @JsonElementType(TaskView)
    view: TaskView;

    /** Оценка */
    @JsonProperty('assessment')
    @JsonType(Number)
    assessment?: number;

    /** Оценка */
    @JsonProperty('duration')
    @JsonType(Number)
    duration?: number;

    /** Оценка */
    @JsonProperty('assessment_type')
    @JsonType(String)
    assessmentType?: string;

    /** Чеклист */
    @JsonProperty('subtasks')
    @JsonElementType(Task)
    subtasks: Task[] = [];

    @JsonProperty('required_field_date')
    @JsonType(Boolean)
    requiredFieldDate: boolean;

    @JsonProperty('status_change_comment')
    @JsonType(String)
    statusChangeComment: string;

    @JsonProperty('board_column')
    @JsonType(String)
    boardColumn: string;

    @JsonProperty('type')
    @JsonType(String)
    type: string;

    @JsonProperty('hash')
    @JsonType(Number)
    hash: number;

    @JsonProperty('sprint_columns')
    @JsonType(Array)
    sprintColumns = [];

    @JsonProperty('sprint')
    @JsonType(Sprint)
    sprint: Sprint = {};

    @JsonProperty('vid')
    @JsonType(Number)
    vid: number;

    @JsonProperty('visible')
    @JsonType(Boolean)
    visible: boolean;

    @JsonProperty('cover')
    @JsonType(File)
    cover: File = null;

    @JsonProperty('template')
    @JsonType(Template)
    template: Template = null;

    @JsonProperty('tracking')
    @JsonType(Tracking)
    tracking: Tracking[] = null;

    @JsonProperty('is_sprint_auto_date')
    @JsonType(Boolean)
    isSprintAutoDate: boolean;

    error = false;

    constructor(
        author?: User,
        authorId?: number,
        description: string = '',
        dod: string = '',
        actual_result: string = '',
        dateExpiration: Date = null,
        dateCreation: Date = new Date(),
        messages: number = 0,
        isNew: boolean = false,
        files: File[] = [],
        links: Link[] = [],
        approvers: UserShort[] = [],
        performers: UserShort[] = [],
        observers: UserShort[] = [],
        boards: Board[] = [],
        group: Tag = null,
        boardColumns: ColumnShort[] = [],
        parent: ShortTask = null,
        children: Task[] = [],
        path = [],
        assessment?: number,
        duration?: number,
        assessmentType: string = 'sp',
        subtasks: Task[] = [],
        view: TaskView = new TaskView(),
        requiredFieldDate: boolean = false,
        statusChangeComment?: string,
        boardColumn?: string,
        type?: string,
        sprintColumns?,
        sprint?: Sprint,
        onCurrentBoard: boolean = true,
        template?: Template
    ) {
        super();
        this.author = author;
        this.authorId = authorId;
        this.description = description;
        this.dod = dod;
        this.actual_result = actual_result;
        this.dateExpiration = dateExpiration;
        this.dateCreation = dateCreation;
        this.messages = messages;
        this.isNew = isNew;
        this.files = files;
        this.links = links;
        this.approvers = approvers;
        this.performers = performers;
        this.observers = observers;
        this.boards = boards;
        this.group = group;
        this.boardColumns = boardColumns;
        this.parent = parent;
        this.children = children;
        this.path = path;
        this.assessment = assessment;
        this.duration = duration;
        this.assessmentType = assessmentType;
        this.subtasks = subtasks;
        this.view = view;
        this.requiredFieldDate = requiredFieldDate;
        this.statusChangeComment = statusChangeComment;
        this.boardColumn = boardColumn;
        this.type = type;
        this.sprintColumns = sprintColumns;
        this.sprint = sprint;
        this.onCurrentBoard = onCurrentBoard;
        this.template = template;
    }

    get activeUsers() {
        if (this.responsible) {
            this.performers = this.performers?.sort((a, b) => ((a.id === this.responsible.id) === (b.id === this.responsible.id)) ? 0 :
                    (a.id === this.responsible.id) ? -1 : 1);
            return this.performers ? this.performers : [];
            // return [this.responsible, ...this.performers]
        }
        return this.performers;
    }

    get taskFromPast() {
        const today = new Date();
        today.setDate(today.getDate() - 1);
        today.setHours(0,0,0);
        const complete = new Date( this.dateExpiration);
        complete.setHours(0,0,0);
        return this.dateExpiration && (complete < today);
    }

    get taskCurrentDay() {
        const today = new Date();
        const complete = new Date(this.dateExpiration);
        return complete.getDate() === today.getDate() &&
            complete.getMonth() === today.getMonth() &&
            complete.getFullYear() === today.getFullYear();
    }

    get taskFromTomorrow() {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        const complete = new Date(this.dateExpiration);
        return complete.getDate() === tomorrow.getDate() &&
            complete.getMonth() === tomorrow.getMonth() &&
            complete.getFullYear() === tomorrow.getFullYear();
    }

    get taskFromThisWeek() {
        const today = moment();
        const endOdWeek = today.endOf('week').add(1, 'days');
        const complete = new Date(this.dateExpiration);
        return this.taskFromFuture && moment(complete) < endOdWeek;
    }

    get taskFromFuture() {
        const today = new Date();
        const complete = new Date(this.dateExpiration);
        return complete > today;
    }

    get isTaskExpired() {
        const today = new Date();
        today.setDate(today.getDate() - 1);
        today.setHours(0,0,0);
        const complete = new Date(this.dateExpiration);
        complete.setHours(0,0,0);

        return this.dateExpiration && complete < today &&
            !(this.status.type === TaskStatuses.DONE ||
                this.status.type === TaskStatuses.DONE_ARCHIVE ||
                this.status.type === TaskStatuses.REJECTED ||
                this.status.type === TaskStatuses.REJECTED_ARCHIVE);
    }

    // условие для дизейбла всех кнопок в хереде задачн
    get isTaskComplete() {
        return !(this.type === 'sprint') && (this.status.type === TaskStatuses.REJECTED_ARCHIVE ||
            this.status.type === TaskStatuses.DONE_ARCHIVE ||
            this.status.type === TaskStatuses.DELETED);
    }

    getActualStatusIcon() {
        const status = taskStatusesActualMapping.find(el => el.actionId === this.status?.id);
        return status?.icon;
    }
}

export function getMissedTasks(task) {
    const today = new Date();
    today.setDate(today.getDate() - 1);
    today.setHours(0,0,0);

    return task.template.schedule.filter(task => {
        const date = new Date( task.date);
        date.setHours(0,0,0);
        return !task.status && date < today;
    })
}

export function getStatusName(task: Task, userId = null) {
    // Если отменена и апрувер должен согласовать
    if (task.status.id === 9 && userId && task.approvers && task.approvers.length && !task.approvers.some(el => el.id === userId)) {
        return 'На согласовании';
    }
    // Если отменена и ты апрувер должен согласовать
    if (task.status.id === 9 && userId && task.approvers && task.approvers.length && task.approvers.some(el => el.id === userId)) {
        return 'Согласовать';
    }
    // Если выполнена и апрувер должен согласовать
    if (task.status.id === 4 && userId && (!task.approvers || (task.approvers && !task.approvers.some(el => el.id === userId)))) {
        return 'На согласовании';
    }

    return task.status.name;
}

export function isParamBooleanIsset(param) {
    if (typeof(param) === 'boolean') {
        return param;
    } else {
        return true;
    }
}

export function getStatusNameWithColumns(task, userId = null) {
    if (task.type == 'template') {
        return getStatusName(task, userId);
    }
    if (
        task.status.type === TaskStatuses.PAUSE
        || task.status.type === TaskStatuses.TO_ACCEPT
        || task.status.type === TaskStatuses.CHANGE_APPROVAL
        || task.status.type === TaskStatuses.CANCELLATION_APPROVAL
        || this?.isStatusColumnSwap
        || this?.isSprint
    ) {
        return getStatusName(task, userId);
    } else {
        let name = task.column.tag ? task.column.tag : task.column.name;
        if (name && name.length > 9) {
            name = name.slice(0, 8) + '...';
        }
        return name;
    }
}

export function generateDataToCopyTask(task, board = null, userId, isFileNeeded = false, boardsService) {
    const formData = {
        id: null,
        title: task.title ? task.title : '',
        description: task.description ? task.description : '',
        dod: task.dod ? task.dod : null,
        date_expiration: null,
        flag_importance: false,
        assessment: null,
        assessment_type: task.assessmentType,
        status: {id: 1},
        links: [],
        files: [],
        group: task.group ? task.group : null,
        parent: null,
        boards: task.boards ? task.boards : null,
        subtasks: [],
        members_observer: board.id > 0 ? [{user_id: userId}] : [],
        members_performer: board.id < 0 ? [{user_id: userId}] : [],
        members_approver: []
    };

    if (task.subtasks && task.subtasks.length) {
        formData.subtasks = task.subtasks.map(el => {
            let newItem = {title: el.title, type: 'checkbox'};

            if (el.responsible) {
                newItem['members_performer'] = [{user_id: el.responsible.id}];
                newItem['responsible'] = {user_id: el.responsible.id}
            }

            return newItem;
        });
    }


    // Добавляем правильные метки к скопированной задаче
    const currentTaskBoard = task.boards.find(el => el.id === board.id);
    if (currentTaskBoard) {
        formData['boards'] = [currentTaskBoard];
    } else {
        const boardWithoutTags = board;
        boardWithoutTags.tags = [];
        formData['boards'] = [boardWithoutTags];
    }

    if (isFileNeeded) {
        if (task.files) {
            const files = JSON.serialize(task.files);
            // Мы шлем файлы без ID а на бэке содатся новые файлы с новыми ID
            for (let i = 0; i < files.length; i++) {
                files[i].id = null;
            }
            formData.files = files;
        }
        formData.links = task.links ? task.links : [];
    }

    if (board && board.sharedBoard && task.type !== 'template') {
        const baseApprovers = boardsService.getBoardBaseApprovers(board).map(el => ({user_id: el.id}));
        const basePerformers = boardsService.getBoardBasePerformers(board).map(el => ({user_id: el.id}));
        const baseObservers = boardsService.getBoardBaseObservers(board).map(el => ({user_id: el.id}));
        const baseTags = boardsService.getBoardBaseTags(board);

        if (board.id > 0 &&
            !baseObservers.find(el => el.user_id === userId) &&
            !basePerformers.find(el => el.user_id === userId) &&
            !baseApprovers.find(el => el.user_id === userId)) {
            baseObservers.push({user_id: userId});
        }

        if (board.id < 0 && !basePerformers.find(el => el.user_id === userId)) {
            basePerformers.push({user_id: userId});
        }

        // Скроем чтоб копировать и доски и метки и группы
        //
        // const baseBoard = board;
        // baseBoard.tags = baseTags;
        //
        // formData['boards'] = [baseBoard];
        formData['members_approver'] = baseApprovers;
        formData['members_observer'] = baseObservers;
        formData['members_performer'] = basePerformers;
    }

    if (task.type == 'template') {
        console.log(task);
        formData.members_observer = task.observers ? task.observers.map(el => ({user_id: el.id})) : [];
        formData.members_performer = task.performers ? task.performers.map(el => ({user_id: el.id})) : [];
        formData.members_approver = task.approvers ? task.approvers.map(el => ({user_id: el.id})) : [];
        formData['members_responsible'] = task.responsible ? {user_id: task.responsible.id} : null;
        formData['boards'] = task.boards.filter(b => !b.template_board);
        formData['template'] = task.template;
        formData['type'] = 'task';
        formData['parent'] = task.parent;
    }

    if (task.importance) {
        formData['importance'] = task.importance;
    }

    return formData;
}


@JsonObject()
export class NoticeableList {
    /** Идентификатор */
    @JsonProperty('id')
    @JsonType(Number)
    id?: number;

    @JsonProperty('name')
    @JsonType(String)
    name?: string;

    @JsonProperty('tasks')
    @JsonElementType(Task)
    tasks?: Task[] = [];

    constructor(
        id?: number,
        name: string = '',
        tasks: Task[] = [],
    ) {
        this.id = id;
        this.name = name;
        tasks = tasks;
    }
}
