import {BeforeDeserialized, JsonElementType, JsonObject, JsonProperty, JsonType} from 'ta-json';

export type ORG_UNIT_TYPE = 'Company' | 'Unit' | 'Member';

@JsonObject()
class Unit {
    /** Наименование организационной структуры. */
    @JsonProperty()
    @JsonType(String)
    name: string;

    /** Логотип. */
    @JsonProperty()
    @JsonType(String)
    logo: string | null;

}

@JsonObject()
class UserShort {
    /** Id пользователя */
    @JsonProperty()
    @JsonType(Number)
    userId: number;

    /** Роль (должность) */
    @JsonProperty()
    @JsonType(String)
    position: string | null;
}

/** Сокращенное представление кругов */
export class OrgUnitShort {

    /** Уникальный идентификатор организационной структуры. */
    @JsonProperty()
    @JsonType(String)
    id: string;

    @JsonProperty()
    @JsonType(String)
    type: ORG_UNIT_TYPE;

    @JsonProperty()
    @JsonElementType(Unit)
    unit?: Unit;

    @JsonProperty()
    @JsonElementType(UserShort)
    user?: UserShort;

    @JsonProperty()
    @JsonElementType(OrgUnitShort)
    subordinates: OrgUnitShort[];

    @JsonProperty()
    @JsonType(Boolean)
    isActive: boolean;

    /** Id организации */
    orgId: string;

    /** Имя */
    @JsonProperty()
    @JsonType(String)
    name: string;

    /** Id родителя */
    parentId?: string;

    constructor(id?: string, name?: string, isActive?: boolean) {
        this.id = id;
        this.name = name;
        this.isActive = isActive;
    }

    @BeforeDeserialized()
    public setDefaults() {
        this.isActive = true;
    }
}
