<h1 class="verification-title">
    Подтверждение телефона
    <mat-icon class="icon" (click)="close()">close</mat-icon>
</h1>

<!--<div *ngIf="isSended">-->
<div class="verification-content">
    <div class="description">
        На Ваш номер {{ data.phone }} отправлена СМС.
        Введите код из СМС для подтверждения.
    </div>
    <div class="cipher-input-block kit">
        <input name="input" #input="ngModel" minlength="4" maxlength="4" class="input"
               pattern="[0-9]{4}" [ngClass]="{'_error': cipher.length === 4 && input.invalid}"
               type="text" required [(ngModel)]="cipher"
               placeholder="Введите код"/>
        <app-button title="Подтвердить" [buttonType]="'FILED'" [disabled]="input.invalid" (click)="onCipherCheckClick()"></app-button>
    </div>
    <div class="send-again-block _error" *ngIf="cipher.length === 4 && input.invalid">
        Код проверки должен содержать 4 цифры
    </div>

    <div class="send-again-block" *ngIf="timer > 0">
        Запросить повторно через 0:{{timer.toString().padStart(2, '0')}}
    </div>
    <div class="send-again-block kit" *ngIf="timer === 0">
        <a href="#" class="refresh" (click)="onSendAgainClick($event)">
            <mat-icon class="icon refresh__icon">sync</mat-icon>
            <span class="refresh__text">Повторить</span>
        </a>
    </div>
</div>
