import {JsonElementType, JsonProperty, JsonType} from 'ta-json';
import {Rating} from '../../shared/models/rating';
import {FunctionInfo} from './functions/function-info';
import {PositionFolderShort} from './position';

export class PositionShortSlot {
    @JsonProperty('id')
    @JsonType(Number)
    id: number = null;

    @JsonProperty('title')
    @JsonType(String)
    title: string = null;

    @JsonProperty('description')
    @JsonType(String)
    description: string = null;

    @JsonProperty('destiny')
    @JsonType(String)
    destiny: string = null;

    @JsonProperty('rating')
    @JsonElementType(Rating)
    rating: Rating = null;

    @JsonProperty('functions')
    @JsonElementType(FunctionInfo)
    functions: FunctionInfo[] = null;

    @JsonProperty('group')
    @JsonElementType(PositionFolderShort)
    group: PositionFolderShort = null;

    @JsonProperty('group_id')
    @JsonElementType(Number)
    group_id = 0;

    constructor(
        id?: number,
        title?: string,
        description?: string,
        destiny?: string,
        functions: FunctionInfo[] = []
    ) {
        this.id = id;
        this.title = title;
        this.description = description;
        this.destiny = destiny;
        this.functions = functions;
    }
}
