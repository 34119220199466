import {BehaviorSubject, Subject} from 'rxjs';

import {Injectable} from '@angular/core';

import {User, UserShort} from '../models/user';

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    public apps$ = new BehaviorSubject(null);
    public users$ = new BehaviorSubject<User[]>(null);
    updateUsers$ = new Subject();

    constructor() {}

    getUser(id: number): User {
        const val = this.users$.getValue();
        let u = val?.find((u) => u.id === id);

        if (!u) {
            u = new User(id);
            u.lastName = 'Неизвестный';
            u.firstName = 'пользователь';
        }

        // test coverage of func visually!
        //u.lastName = 'TEST';
        //u.firstName = 'MOCK';

        //console.log(val, id);

        return u;
    }

    getUserShort(id: number): UserShort {
        return this.getUser(id)?.getUserShort();
    }
}
