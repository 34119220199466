<div class="inn">
    <input autoFocus="true" class="hidden-but-active" />

    <div *ngIf="selectedCompany && !isEditingInn" class="input inn__select inn-option"
         (click)="onSelectInnClick($event)" [matTooltip]="formatName(selectedCompany)">
        <div class="mat-option-text inn-option">
            <div class="option__name">{{formatName(selectedCompany)}}</div>
            <div class="option__inn">{{formatInn(selectedCompany)}}</div>
            <div class="inn__select-arrow-down">
                <mat-icon>arrow_drop_down</mat-icon>
            </div>
        </div>
    </div>

    <input  [ngClass]="{'_visible': !selectedCompany || isEditingInn, '_error': hasError()}"
            class="input inn__input"
            [placeholder]="placeholder"
            [disabled]="isDisabled"
            [matAutocomplete]="auto"
            #innInput
            (keyup)="onInnKeyUp(innInput.value)"
            (paste)="onInnKeyUp(innInput.value)"
            (focusout)="onInnBlur($event)"
            maxlength="13"
            minlength="10"
            [mask]="'\d*'"
    />
    <mat-error class="error" *ngIf="hasError()">Поле не может быть пустым или содержать меньше 10 символов</mat-error>

</div>

<mat-autocomplete #auto="matAutocomplete" (optionSelected)="onCompanySelected($event)"
                  [displayWith]="displayFn()" autoFocus="false" autoActiveFirstOption class="inn__select"
>
    <mat-option class="inn-option" disabled *ngIf="optionsStatus === statuses.LOADING">Загрузка...</mat-option>
    <mat-option class="inn-option" disabled *ngIf="options.length === 0 && innInput.value.length >= 10 && optionsStatus === statuses.LOADED">Ничего не найдено</mat-option>
    <mat-option class="inn-option" disabled *ngIf="innInput.value.length < 10 && requireInn">Введите ИНН полностью</mat-option>
    <mat-option *ngFor="let option of options" [value]="option" class="inn-option">
        <span class="option__name">{{formatName(option)}}</span>
        <span class="option__inn">ИНН {{formatInn(option)}}</span>
    </mat-option>
</mat-autocomplete>
