<div class="history">
    <ul class="history-list" *ngIf="listData">
        <li class="history-item" *ngFor="let item of listData;">
            <header class="history-header" [ngClass]="{'hide': index > 0 && item.userId == listData[index-1].userId}">
                <img class="history-avatar" [src]="config.getUserAvatarByUser(item.user, 'small')">
                <div class="header-txt">
                    <span class="history-name">{{ item.user.shortName }}</span>
                    <span class="history-date">{{ getDateTime(item) | date:'dd.MM.yyyy HH:mm' }}</span>
                </div>
            </header>
            <div class="history-body">
                <span class="txt" [innerHtml]="item.event"></span>
            </div>
        </li>
    </ul>
</div>
