import {JsonElementType, JsonObject, JsonProperty, JsonType, OnDeserialized} from 'ta-json';
import {areOneDay} from '../utils/commons';
import {File} from './file';

@JsonObject()
export class ChatLink  {
    @JsonProperty('link_type')
    @JsonType(String)
    link_type: string;

    @JsonProperty('entity_id')
    @JsonType(Number)
    entity_id: number;
}

@JsonObject()
export class ChatMessage {
    // TODO add msg_uid
    @JsonProperty()
    @JsonType(String)
    id?: string;

    @JsonProperty('entity')
    @JsonType(String)
    entity?: string;

    @JsonProperty('msg_uid')
    @JsonType(Number)
    msgUid?: number;

    @JsonProperty('user_id')
    @JsonType(Number)
    userId: number;

    @JsonProperty('user_photo')
    @JsonType(String)
    userPhoto: string;

    @JsonProperty('photo')
    @JsonType(String)
    photo: string;

    @JsonProperty('first_name')
    @JsonType(String)
    firstName: string;

    @JsonProperty('last_name')
    @JsonType(String)
    lastName: string;

    @JsonProperty('middle_name')
    @JsonType(String)
    middleName: string;

    @JsonProperty('created_at')
    @JsonType(Date)
    createdAt?: Date = null;

    @JsonProperty()
    @JsonType(String)
    message: string;

    @JsonProperty()
    @JsonType(Boolean)
    edited: boolean;

    @JsonProperty('is_read')
    @JsonType(Boolean)
    isRead: boolean = false;

    showName = true;
    showAvatar = true;
    showDate = false;
    isSent = false;

    @JsonProperty('links')
    @JsonElementType(ChatLink)
    links?: ChatLink[] = [];

    @JsonProperty('attachments')
    @JsonElementType(File)
    attachments?: File[] = [];

    @JsonProperty('fileUploading')
    @JsonElementType(Boolean)
    fileUploading?: boolean;

    @JsonProperty('reply_to')
    @JsonElementType(ChatMessage)
    reply_to?: ChatMessage;

    @JsonProperty('offset')
    @JsonElementType(Number)
    offset?: number;

    @JsonProperty('limit')
    @JsonElementType(Number)
    limit?: number;


    constructor(
        entity: string,
        id: string,
        userId: number,
        userPhoto: string,
        firstName: string,
        lastName: string,
        middleName: string,
        createdAt: Date,
        message: string,
        edited: boolean
    ) {
        this.entity = entity;
        this.id = id;
        this.userId = userId;
        this.userPhoto = userPhoto;
        this.firstName = firstName;
        this.lastName = lastName;
        this.middleName = middleName;
        this.createdAt = createdAt;
        this.message = message;
        this.edited = edited;
    }

    /** Сокращение имени */
    get userShortName() {
        let short = this.lastName;
        if (this.firstName) {
            short += ' ' + this.firstName.slice(0, 1) + '.';
            if (this.middleName) {
                short += ' ' + this.middleName.slice(0, 1) + '.';
            }
        }
        return short;
    }


    /**
     * Преобразует единичное сообщение для удобства отображения
     *
     * @param nextMsg - предыдущее сообщение
     * @param chatMsg - текущее сообщение
     * Возвращает: обновленное предыдущее сообщение
     */
    static transformMsg(nextMsg: ChatMessage, chatMsg: ChatMessage, previousMsg?: ChatMessage) {
        chatMsg.showDate = true;
        chatMsg.showAvatar = true;

        if (!previousMsg) {
            chatMsg.showName = true;
            chatMsg.showDate = true;
        } else {
            if (areOneDay(chatMsg.createdAt, previousMsg.createdAt)) {
                chatMsg.showDate = false;
                chatMsg.showName = false;
                if (chatMsg.userId !== previousMsg.userId) {
                    chatMsg.showName = true;
                }
            } else {
                chatMsg.showDate = true;
                chatMsg.showName = true;
            }
        }

        if (!nextMsg) {
            chatMsg.showAvatar = true;
            if (previousMsg) {
                if (+chatMsg.userId == previousMsg.userId) {
                    previousMsg.showAvatar = false;
                }
            }
        } else {
            if (areOneDay(chatMsg.createdAt, nextMsg.createdAt)) {
                if (+chatMsg.userId == +nextMsg.userId) {
                    chatMsg.showAvatar = false;
                    // nextMsg.showAvatar = true;
                }
            } else {
                // В новом дне покажем аватарку
                chatMsg.showAvatar = true;
            }
        }
    }

    /**
     * Преобразует сообщения от сервера для удобства отображения
     *
     * @param chatData - все сообщения
     */
    static transformChatData(chatData: ChatMessage[]) {
        for (let i = 0; i < chatData.length; i++) {
            const previousMsg = i + 1 < chatData.length ? chatData[i + 1] : null;
            const chatMsg = chatData[i];
            const nextMsg = i - 1 < chatData.length ? chatData[i - 1] : null;

            this.transformMsg(nextMsg, chatMsg, previousMsg);
        }
    }

    /**
     * Добавляет одно сообщение к пулу сообщений с преобразованием
     *
     * @params msg
     * @params chatData
     */
    static addNewMsg(msg: ChatMessage, chatData: ChatMessage[]) {
        let nextMsg;
        if (chatData && chatData.length) {
            nextMsg = chatData[0];
        } else {
            nextMsg = null;
        }

        this.transformMsg(null, msg,nextMsg);

        // новые вначале списка, старые в конце
        chatData.unshift(msg);

    }


    @OnDeserialized()
    onDeserialized() {
        this.createdAt = new Date(this.createdAt);
    }

}
