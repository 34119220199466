<div class="learning-group-add-anchor" tourAnchor="learningTagAddDialog"></div>
<section class="dialog">
    <header class="dialog__header">
        <h3 class="dialog__title">{{ title }}</h3>
        <mat-icon class="close learningTagAddClose" (click)="close()">close</mat-icon>
    </header>

    <form class="dialog__content" *ngIf="isLoaded" [formGroup]="formGroup">
        <div class="name form kit">
            <input matInput
                   maxlength="255"
                   class="input" [ngClass]="{'_error': formGroup.invalid && this.formGroup.controls['name'].errors && this.formGroup.controls['name'].errors['maxlength']}"
                   formControlName="name"
                   [placeholder]="'Название'"
                   autocomplete="off">
        </div>
        <mat-error class="error" *ngIf="formGroup.invalid && this.formGroup.controls['name'].errors && this.formGroup.controls['name'].errors['maxlength']">{{ config.FORM_INVALID_100 }}</mat-error>

        <div class="colors">
            <label class="color-label" *ngFor="let item of colors">
                <input type="radio" class="color-input" name="color" (change)="colorChange($event)" [value]="item.name">
                <span [ngStyle]="{'background' : item.background }" class="color-emul">
                    <mat-icon [ngClass]="{'hide' : item.name !== selected}"
                              [ngStyle]="{'color' : item.color}">done</mat-icon>
                </span>
            </label>
        </div>
    </form>

    <footer class="dialog__footer">
        <button type="reset" mat-flat-button class="button _transparent" (click)="close()">ОТМЕНА</button>
        <button type="button" [disabled]="isButtonsDisabled"
                mat-flat-button
                class="button _link learningTagAddDialog"
                (click)="submit()">ГОТОВО</button>
    </footer>

    <app-spinner class="loader" [isSpinnerOn]="true" *ngIf="!isLoaded"></app-spinner>
</section>
<div *ngIf="isLearningOpen" class="learning-background-overlay"></div>
