import {JsonElementType, JsonProperty, JsonType} from 'ta-json';
import {UserSubdivision} from './user-subdivision';
import {Rating} from '../../shared/models/rating';
import {FunctionInfo} from './functions/function-info';
import {Slot} from './slot';
import {FunctionQuestion} from './functions/function-question';

export class Subdivision {
    @JsonProperty('id')
    @JsonType(Number)
    id: number = null;

    @JsonProperty('name')
    @JsonType(String)
    name = '';

    @JsonProperty('description')
    @JsonType(String)
    description = '';

    @JsonProperty('destiny')
    @JsonType(String)
    destiny = '';

    @JsonProperty('final_output')
    @JsonType(String)
    final_output = '';

    @JsonProperty('unit_id')
    @JsonType(Number)
    unit_id = 0;

    @JsonProperty('image')
    @JsonType(String)
    image: string = null;

    @JsonProperty('path')
    @JsonType(Array)
    path = [];

    @JsonProperty('parent')
    @JsonElementType(Subdivision)
    parent: Subdivision = null;

    @JsonProperty('users')
    @JsonElementType(UserSubdivision)
    users: UserSubdivision[] = [];

    @JsonProperty('slots')
    @JsonElementType(Slot)
    slots: Slot[] = [];

    @JsonProperty('is_archive')
    @JsonType(Boolean)
    isArchive = false;

    @JsonProperty('is_finance_struct')
    @JsonType(Boolean)
    is_finance_struct = false;

    @JsonProperty('rating')
    @JsonElementType(Rating)
    rating: Rating = null;

    @JsonProperty('children')
    @JsonElementType(Subdivision)
    children: Subdivision[] = [];

    @JsonProperty('functions')
    @JsonElementType(FunctionInfo)
    functions: FunctionInfo[] = [];

    @JsonProperty('kpi')
    @JsonElementType(FunctionInfo)
    kpi: FunctionInfo[] = [];

    @JsonProperty('chart_data')
    @JsonType(Array)
    chart_data = [];

    @JsonProperty('questions')
    @JsonElementType(FunctionQuestion)
    questions: FunctionQuestion[];

    constructor(id?: number, name?: string) {
        this.id = id;
        this.name = name;
    }

    makeTreeNode(list) {
        const map = {};
        const roots = [];

        if (list.length === 1) {
            return list;
        }

        for (let i = 0; i < list.length; i += 1) {
            map[list[i].id] = i;
            list[i]['children'] = [];
        }

        for (let i = 0; i < list.length; i += 1) {
            const node = list[i];
            const parent = node['parent'];
            const parentId = parent?.id;
            if (parentId !== node.id && list[map[parentId]]) {
                list[map[parentId]]['children'] ?
                    list[map[parentId]]['children'].push(node) :
                    list[map[parentId]]['children'] = [node];
            } else {
                roots.push(node);
            }
        }

        return roots;
    }
}
