import {JsonProperty, JsonType} from 'ta-json';

export enum StatusType {
    BIRTHDAY = 'birthday',
    VACATION = 'vacation',
    BUSINESS_TRIP = 'business_trip',
    REMOTE_WORK = 'remote_work',
    SICK_LEAVE = 'sick_leave',
    DECREE = 'decree',
}

/**
 * Маппинг статусов
 */
export const statusMapping = Object.freeze([
    // {
    //     type: StatusType.BIRTHDAY,
    //     name: 'День рождения',
    //     icon: 'radius_cake'
    // },
    {
        type: StatusType.VACATION,
        name: 'Отпуск',
        icon: 'radius_vacation'
    },
    {
        type: StatusType.BUSINESS_TRIP,
        name: 'Командировка',
        icon: 'radius_business_trip'
    },
    {
        type: StatusType.REMOTE_WORK,
        name: 'Удаленная работа',
        icon: 'radius_home'
    },
    {
        type: StatusType.SICK_LEAVE,
        name: 'Больничный',
        icon: 'radius_hospital'
    },
    {
        type: StatusType.DECREE,
        name: 'В декрете',
        icon: 'radius_pregnant'
    },


]);

export class Status {
    @JsonProperty('type')
    @JsonType(String)
    type: StatusType;

    @JsonProperty('date_end')
    @JsonType(Date)
    dateEnd: Date;

    @JsonProperty('date_start')
    @JsonType(Date)
    dateStart: Date;

    constructor(
        type: StatusType,
        dateEnd: Date = null,
        dateStart: Date = null
    ) {
        this.type = type;
        this.dateEnd = dateEnd;
        this.dateStart = dateStart;
    }

    getStatusIcon(statusType) {
        const status = statusMapping.find(el => el.type.toUpperCase() === statusType.toUpperCase());
        return status ? status.icon : null;
    }
}
