import {JsonProperty, JsonType} from 'ta-json';
import {Task} from './task';


export class Paginator {
    @JsonProperty('has_more')
    @JsonType(Boolean)
    hasMore = false;

    @JsonProperty('offset')
    @JsonType(Number)
    offset = 0;

    @JsonProperty('total_count')
    @JsonType(Number)
    totalCount = 0;

    @JsonProperty('items_count')
    @JsonType(Number)
    itemsCount = 0;
}

export class MatchedFields {
    @JsonProperty('actual_result')
    @JsonType(String)
    actualResult?: string;

    @JsonProperty('description')
    @JsonType(String)
    description?: string;

    @JsonProperty('dod')
    @JsonType(String)
    dod?: string;
}

export class TaskMatch extends Task {
    @JsonProperty('matched_fields')
    @JsonType(MatchedFields)
    matchedFields?: MatchedFields;
}

export class SearchResult {
    @JsonProperty('items')
    @JsonType(TaskMatch)
    items: TaskMatch[];

    @JsonProperty('pagination')
    @JsonType(Paginator)
    pagination: Paginator;
}

export class SearchResponse {
    @JsonProperty('current_board')
    @JsonType(SearchResult)
    currentBoard?: SearchResult;

    @JsonProperty('other_boards')
    @JsonType(SearchResult)
    otherBoards?: SearchResult;

    @JsonProperty('templates')
    @JsonType(SearchResult)
    templates?: SearchResult;
}
