import {JSON, JsonObject, JsonProperty, JsonType, OnDeserialized} from 'ta-json';
import {PaymentApplicationForNotifications, SmartContractForNotifications, TaskForNotifications} from './notificationCenter';
import {MONTHS, numberWithoutZerros} from '../utils/commons';
import {Task} from '../modules/task-manager/models/task';
import {Meet} from '../modules/calendar/models/meet';
import {User, UserShort} from './user';
import {UsersMotivation} from '../modules/phonebook/models/motivations/users-motivation';
import {FunctionShort} from '../modules/phonebook/models/functions/function-short';
import {Matrix} from '../modules/phonebook/models/matrix/matrix';
import {ChatMessage} from './chat';

/**
 * Типы смарт-контрактов.
 */
export enum SmartContractType {
    /** Купля-продажа. */
    SALE = 'SALE',
    /** Инвестиция. */
    INVESTMENT = 'INVESTMENT',
    /** Услуга. */
    SERVICE = 'SERVICE',
    /** Соглашения. */
    AGREEMENTS = 'AGREEMENTS',
    /** Трудовой. */
    EMPLOYMENTS = 'EMPLOYMENTS',
    /** Неизвестный тип контракта */
    UNKNOWN = 'UNKNOWN',
}

export function getChatRoomsScType(smartContractType: SmartContractType) {
    let scType = '';
    switch (smartContractType) {
        case SmartContractType.SERVICE:
            scType = 'на услуги';
            break;
        case SmartContractType.INVESTMENT:
            scType = 'на инвестиции';
            break;
        case SmartContractType.AGREEMENTS:
            scType = 'по соглашению';
            break;
        case SmartContractType.EMPLOYMENTS:
            scType = '(трудовой)';
            break;
        case SmartContractType.UNKNOWN:
        default:
            scType = 'неизвестного типа';
            break;
    }
    return scType;
}

@JsonObject()
export class ChatRoomsItem {
    @JsonProperty('chat_id')
    @JsonType(Number)
    chat_id: number;

    @JsonProperty('unread_messages')
    @JsonType(Number)
    unreadMessages: number;

    @JsonProperty('last_msg_date')
    @JsonType(Date)
    lastMsgDate: Date;

    @JsonProperty('last_msg_full')
    @JsonType(ChatMessage)
    lastMsgFull: ChatMessage;

    @JsonProperty('last_message')
    @JsonType(String)
    lastMsg: string;

    @JsonProperty('last_msg_full')
    @JsonType(ChatMessage)
    last_msg_full: ChatMessage;

    @JsonProperty('last_msg_tags')
    @JsonType(UserShort)
    last_msg_tags: UserShort[];

    @JsonProperty('from_user')
    @JsonType(UserShort)
    fromUser: UserShort = null;

    @JsonProperty('object_id')
    @JsonType(String)
    objectId: string;

    @JsonProperty('object_type')
    @JsonType(String)
    objectType: 'smart_contract' | 'payment_application' |
        'bug_tracker' | 'task_manager' | 'calendar' |
        'motivation_without_user' | 'motivation_with_user' | 'function' | 'matrix' | 'user_to_user';

    @JsonProperty('object')
    @JsonType(Object)
    object: any;

    @JsonProperty('user')
    @JsonType(UserShort)
    user: UserShort;

    @JsonProperty('user_full')
    @JsonType(User)
    userFull: User;

    subdivisionId: string;
    subdivisionName: string;

    @JsonType(Boolean)
    isIndividual = false;

    @JsonProperty('object_name')
    @JsonType(String)
    objectName: string;

    @JsonProperty('object_type_view')
    @JsonType(String)
    objectTypeView: string;

    @JsonProperty('reg_number')
    @JsonType(String)
    regNumber: string;

    @JsonProperty('is_read')
    @JsonType(Boolean)
    is_read: boolean;

    @JsonProperty('tagged')
    @JsonType(Boolean)
    tagged: boolean;

    @JsonProperty('notify')
    @JsonType(Boolean)
    notify: boolean;

    @OnDeserialized()
    onDeserialized() {
        if (!this.object) {
            return null;
        }
        switch (this.objectType) {
            case 'smart_contract':
                if (!this.object) {
                    this.object = new SmartContractForNotifications();
                    this.regNumber = '';
                    this.objectTypeView = 'Смарт-контракт удален!';
                    this.objectName = 'Смарт-контракт удален!';
                    this.subdivisionId = '';
                    this.subdivisionName = '';
                    this.isIndividual = false;
                } else {
                    this.object = JSON.deserialize<SmartContractForNotifications>(this.object, SmartContractForNotifications);
                    this.regNumber = this.object.registrationNumber;
                    this.objectTypeView = 'Смарт-контракт ' + getChatRoomsScType(this.object.type) + ' №' + numberWithoutZerros(this.regNumber);
                    this.objectName = this.object.name;
                }
                // Контрагента определим, когда будут данные по моим кругам
                break;
            case 'payment_application':
                if (!this.object) {
                    this.object = new PaymentApplicationForNotifications();
                    this.regNumber = '';
                    this.objectTypeView = 'Заявка на оплату';
                    this.objectName = 'Заявка на оплату удалена!';
                    this.subdivisionId = '';
                    this.subdivisionName = '';
                    this.isIndividual = false;
                } else {
                    this.object = JSON.deserialize<PaymentApplicationForNotifications>(this.object, PaymentApplicationForNotifications);
                    this.regNumber = this.object.registrationNumber ? this.object.registrationNumber.toString() : '';
                    this.objectTypeView = 'Заявка на оплату' + ' №' + numberWithoutZerros(this.regNumber);
                    if (this.object.subdivision) {
                        this.subdivisionId = this.object.subdivision.id;
                            this.subdivisionName = this.object.subdivision.name;
                    } else {
                        this.subdivisionId = '';
                        this.subdivisionName = '';
                    }
                    this.isIndividual = false;
                    this.objectName = this.object.name;
                }
                break;

            case 'bug_tracker':
                if (!this.object) {
                    this.object = new TaskForNotifications();
                    this.regNumber = '';
                    this.objectTypeView = 'Реаном';
                    this.objectName = 'Задача удалена';
                    this.subdivisionId = '';
                    this.subdivisionName = '';
                    this.isIndividual = false;
                } else {
                    this.object = JSON.deserialize<TaskForNotifications>(this.object, TaskForNotifications);
                    this.regNumber = this.object.number ? this.object.number.toString() : '';
                    this.objectTypeView = 'Задача Реаном - ' + this.object.name + ' №' + numberWithoutZerros(this.regNumber);
                    this.objectName = this.object.name;
                    this.subdivisionId = this.object.subdivision.productId;
                    this.subdivisionName = this.object.subdivision.name;
                    this.isIndividual = false;
                }
                break;

            case 'calendar':
                if (!this.object) {
                    this.object = new TaskForNotifications();
                    this.regNumber = '';
                    this.objectTypeView = 'Встреча';
                    this.objectName = 'Встреча удалена';
                    this.subdivisionId = '';
                    this.subdivisionName = 'Встречи';
                    this.isIndividual = false;
                } else {
                    this.object = JSON.deserialize<Meet>(this.object, Meet);
                    this.regNumber = this.objectId;
                    this.objectTypeView = this.object.vid ? 'Встреча №' + this.object.vid + ' ' + this.object.title + ' ' :
                        'Встреча №' + this.objectId + ' ' + this.object.title + ' ';
                    this.objectName = this.object.title;
                    this.subdivisionName = 'Встречи';
                }
                break;

            case 'task_manager':
                if (!this.object) {
                    this.object = new TaskForNotifications();
                    const type = this.object.type && this.object.type === 'template' ? 'Шаблон' : 'Задача';
                    this.regNumber = '';
                    this.objectTypeView = type;
                    this.objectName = this.object.type && this.object.type === 'template' ? 'Шаблон удален' : 'Задача удалена';
                    this.subdivisionId = '';
                    this.subdivisionName = 'Задачи';
                    this.isIndividual = false;
                } else {
                    this.object = JSON.deserialize<Task>(this.object, Task);
                    const type = (this.object.type && this.object.type === 'template') ?
                        'Шаблон' :
                        this.object.vid ?
                            'Задача №' + this.object.vid :
                            'Задача №' + this.objectId + ' ';
                    this.regNumber = this.objectId;
                    this.objectTypeView = type;
                    this.objectName = this.object.title;
                    this.subdivisionName = 'Задачи';
                }
                break;

            case 'function':
                if (!this.object) {
                    this.object = new FunctionShort();
                    this.regNumber = '';
                    this.objectTypeView = 'Функция';
                    this.objectName = 'Функция удалена';
                    this.subdivisionId = '';
                    this.subdivisionName = 'Функции';
                    this.isIndividual = false;
                } else {
                    this.object = JSON.deserialize<FunctionShort>(this.object, FunctionShort);
                    this.regNumber = this.objectId;
                    this.objectTypeView = 'Функция';
                    this.objectName = this.object.title;
                    this.subdivisionName = 'Функция';
                }
                break;

            case 'matrix':
                    if (this.object.start) {
                        this.object = JSON.deserialize<Matrix>(this.object, Matrix);
                        this.regNumber = this.objectId;
                        this.objectTypeView = 'Матрица ' + MONTHS[this.object.start.getMonth()] + ' ' + this.object.start.getFullYear();
                        this.objectName = !this.objectName ? this.object.user.name : this.objectName;
                    }
                break;

            case 'motivation_without_user':
                if (!this.object) {
                    this.object = new UsersMotivation();
                    this.regNumber = '';
                    this.objectTypeView = 'Мотивация';
                    this.objectName = 'Мотивация удалена';
                    this.subdivisionId = '';
                    this.subdivisionName = 'Мотивации';
                    this.isIndividual = false;
                } else {
                    this.object = JSON.deserialize<UsersMotivation>(this.object, UsersMotivation);
                    this.user = this.user ? this.user : this.object['user'];
                    this.regNumber = this.objectId;
                    this.objectTypeView = 'Мотивация (чат с согласователями)';
                    this.objectName = this.user ? 'Мотивация сотрудника ' + this.user.shortName : '(Удален)';
                    this.subdivisionName = 'Мотивации';
                }
                break;

            case 'motivation_with_user':
                if (!this.object) {
                    this.object = new UsersMotivation();
                    this.regNumber = '';
                    this.objectTypeView = 'Мотивация';
                    this.objectName = 'Мотивация удалена';
                    this.subdivisionId = '';
                    this.subdivisionName = 'Мотивации';
                    this.isIndividual = false;
                } else {
                    this.object = JSON.deserialize<UsersMotivation>(this.object, UsersMotivation);
                    this.user = this.user ? this.user : this.object['user'];
                    this.regNumber = this.objectId;
                    this.objectTypeView = 'Мотивация (чат с сотрудником)';
                    this.objectName = this.user ? 'Мотивация сотрудника ' + this.user.shortName : '(Удален)';
                    this.subdivisionName = 'Мотивации';
                }
                break;

            case 'user_to_user':
                if (!this.object) {
                    this.object = new UsersMotivation();
                    this.regNumber = '';
                    this.objectTypeView = 'Чат с сотрудником';
                    this.objectName = 'сотрудник удален';
                    this.subdivisionId = '';
                    this.subdivisionName = 'Сотрудник';
                    this.isIndividual = false;
                } else {
                    this.userFull = JSON.deserialize<User>(this.object, User);
                    this.regNumber = this.objectId;
                    this.objectName = this.object ? this.userFull.shortName : '(Удален)';
                    this.subdivisionName = 'Чат';
                }
                break;

        }
    }

    getObjectDescription() {
        return this.objectName;
    }

    static sortChatRoomItems(a: ChatRoomsItem, b: ChatRoomsItem) {
        return b.lastMsgDate.getTime() - a.lastMsgDate.getTime();
    }

}
