// Модели для центра уведомлений

import {
    DateConverter,
    IPropertyConverter,
    JsonConverter,
    JsonElementType,
    JsonObject,
    JsonProperty,
    JsonType,
    OnDeserialized
} from 'ta-json';
import {numberWithoutZerros} from '../utils/commons';
import {User} from './user';


class CustomDateFormatter extends DateConverter implements IPropertyConverter {
    public serialize(property: Date): string {
        return property.toISOString();
    }
}


/**
 * Типы сторон смарт-контракта.
 */
export enum SmartContractSideType {
    /** Поставщик. */
    SUPPLIER = 'SUPPLIER',
    /** Получатель. */
    RECEIVER = 'RECEIVER',
    /** Представитель обеих сторон */
    BOTH = 'BOTH',
    /** Третья сторона - не имеет отношения к контракту. */
    THIRD_PARTY = 'THIRD_PARTY',
}

///////////////////////////////////  СМАРТ-КОНТРАКТЫ //////////////////////////////////////////
/**
 * Категории смарт-контрактов для отображения.
 */
export enum SmartContractStatuses {
    /** Черновик. */
    DRAFT = 1,
    /** На обсуждении. */
    ON_DISCUSSION,
    /** На подписании. */
    ON_SIGNING,
    /** Подписать. */
    TO_SIGN,
    /** Действует. */
    IN_PROGRESS,
    /** Расторжение. */
    TERMINATING,
    /** Приостановление */
    POSTPONING,
    /** Приостановлен. */
    POSTPONED,
    /** Расторгнут. */
    TERMINATED,
    /** Исполнен. */
    COMPLETE,
    /** Перезаключен. */
    RENEGOTIATED,
    /** ??? */
    UNKNOWN = 127
}

// TODO будет ли в пустых местах уведомление?
const smartContractStatusesMapping = {
    [SmartContractStatuses.DRAFT]: {
        title: 'Новый'
    },
    [SmartContractStatuses.ON_DISCUSSION]: {
        title: ''
    },
    [SmartContractStatuses.ON_SIGNING]: {
        title: 'Подписан'
    },
    [SmartContractStatuses.TO_SIGN]: {
        title: 'Подписан'
    },
    [SmartContractStatuses.IN_PROGRESS]: {
        title: ''
    },
    [SmartContractStatuses.TERMINATING]: {
        title: ''
    },
    [SmartContractStatuses.POSTPONING]: {
        title: ''
    },
    [SmartContractStatuses.POSTPONED]: {
        title: 'Отложен'
    },
    [SmartContractStatuses.TERMINATED]: {
        title: 'Расторгнут'
    },
    [SmartContractStatuses.COMPLETE]: {
        title: 'Исполнен'
    },
    [SmartContractStatuses.RENEGOTIATED]: {
        title: 'Перезаключен'
    },
    [SmartContractStatuses.UNKNOWN]: {
        title: ''
    },
};

/**
 * Совсем сокращенная модель круга для уведомлений
 */
@JsonObject()
export class OrgUnitShortForNotifications {

    /** Уникальный идентификатор организационной структуры. */
    @JsonProperty()
    @JsonType(String)
    id: string;
    /** Наименование организационной структуры. */
    @JsonProperty()
    @JsonType(String)
    name: string;

    constructor(id?, name?) {
        this.id = id;
        this.name = name;
    }
}

/**
 * Сокращенная модель таблицы услуг для смарт-контракта
 */
@JsonObject()
export class SmartContractProvidedServicesForNotifications {
    /** Количество */
    @JsonProperty()
    @JsonType(Number)
    quantity: number;
    /** Цена за единицу */
    @JsonProperty()
    @JsonType(Number)
    price: number;
}

/**
 * Типы смарт-контрактов.
 */
export enum SmartContractType {
    /** Купля-продажа. */
    SALE = 'SALE',
    /** Инвестиция. */
    INVESTMENT = 'INVESTMENT',
    /** Услуга. */
    SERVICE = 'SERVICE',
    /** Неизвестный тип контракта */
    UNKNOWN = 'UNKNOWN',
}

/**
 * Модель краткого представления пользователя
 */
@JsonObject()
export class UserShort {
    /** Уникальный идентификатор */
    @JsonProperty()
    @JsonType(String)
    id: string;
    /** Имя */
    @JsonProperty('first_name')
    @JsonType(String)
    firstName: string;
    /** Отчество, если есть */
    @JsonProperty('middle_name')
    @JsonType(String)
    middleName?: string;
    /** Фамилия */
    @JsonProperty('last_name')
    @JsonType(String)
    lastName: string;

    constructor(id?: any,
                firstName?: string,
                middleName?: string,
                lastName?: string) {
        this.id = id;
        this.firstName = firstName;
        this.middleName = middleName;
        this.lastName = lastName;
    }

    get shortName() {
        let shortName = this.lastName;
        if (this.firstName) {
            shortName += ' ' + this.firstName;
        }
        return shortName;
    }

    get initialName() {
        let short = this.lastName;
        if (this.firstName) {
            short += ' ' + this.firstName.slice(0, 1) + '.';
            if (this.middleName) {
                short += ' ' + this.middleName.slice(0, 1) + '.';
            }
        }
        return short;
    }

    get name() {
        let fullName = this.lastName;
        if (this.firstName) {
            fullName += ' ' + this.firstName;
            if (this.middleName) {
                fullName += ' ' + this.middleName;
            }
        }
        return fullName;
    }
}


/**
 * Модель стороны смарт-контракта
 */
@JsonObject()
export class SmartContractSide {
    /** Организация */
    @JsonProperty()
    @JsonElementType(OrgUnitShortForNotifications)
    org: OrgUnitShortForNotifications;
    /** Подразделение (круг) */
    @JsonProperty()
    @JsonElementType(OrgUnitShortForNotifications)
    subdivision: OrgUnitShortForNotifications;
    /** Ответственный за смарт-контракт */
    @JsonProperty('responsible_person')
    @JsonElementType(UserShort)
    responsiblePerson: UserShort;
    /** Является ли сторона физическим лицом */
    @JsonProperty('is_individual')
    @JsonType(Boolean)
    isIndividual: boolean;
}

/**
 * Сокращенная модель смарт-контракта для уведомлений.
 */
@JsonObject()
export class SmartContractForNotifications {

    /** Уникальный идентификатор смарт-контракта. */
    @JsonProperty()
    @JsonType(String)
    id: string;
    /** Наименование смарт-контракта. */
    @JsonProperty()
    @JsonType(String)
    name: string;
    /** Регистрационный номер смарт-контракта в системе документооборота. */
    @JsonProperty('registration_number')
    @JsonType(String)
    registrationNumber: string;
    /** Тип смарт-контракта. */
    @JsonProperty()
    @JsonType(String)
    type: SmartContractType;
    /** Дата создания СК. */
    @JsonProperty('created_at')
    @JsonType(Date)
    createdAt: Date;
    /** Сторона-поставщик. */
    @JsonProperty()
    @JsonElementType(SmartContractSide)
    supplier?: SmartContractSide;
    /** Сторона-получатель. */
    @JsonProperty()
    @JsonElementType(SmartContractSide)
    receiver?: SmartContractSide;
    /** Список товаров и услуг, являющихся Предметом Договора по смарт-контракту. */
    @JsonProperty('provided_services')
    @JsonElementType(SmartContractProvidedServicesForNotifications)
    providedServices: SmartContractProvidedServicesForNotifications[];
    /** Статус смарт-контракта */
    @JsonProperty()
    @JsonType(String)
    status: SmartContractStatuses;
    /** Дополнительное рассчитываемое поле. Сторона смарт-контракта */
    @JsonProperty('side_type')
    @JsonType(String)
    sideType: SmartContractSideType;
    /** Дополнительное рассчитываемое поле. Сумма контракта */
    total: number;

    /** Возвращает номер документа без лидирующих нулей */
    get shortNumber() {
        return numberWithoutZerros(this.registrationNumber);
    }

    public static scSideName(side: SmartContractSide) {
        if (!side) {
            return '';
        }
        if (side.isIndividual) {
            return side.responsiblePerson ? side.responsiblePerson.initialName : '<не выбран>';
        } else {
            return side.subdivision ? side.subdivision.name : '<не выбран>';
        }
    }

    @OnDeserialized()
    onDeserialized() {
        if (!this.providedServices) {
            this.providedServices = [];
        }
        if (!this.total) {
            this.total = 0;
        }
        this.total = this.providedServices.reduce((f, l) => (f + l.price * l.quantity), 0);
    }

    whoIs(orgUnitIds) {
        let isSupplier = false;
        let isReceiver = false;
        if (this.supplier &&
            this.supplier.subdivision &&
            orgUnitIds &&
            orgUnitIds.some(orgUnitId => this.supplier.subdivision.id === orgUnitId)
        ) {
            isSupplier = true;
        }
        if (this.receiver &&
            this.receiver.subdivision &&
            orgUnitIds &&
            orgUnitIds.some(orgUnitId => this.receiver.subdivision.id === orgUnitId)
        ) {
            isReceiver = true;
        }
        if (isSupplier && isReceiver) {
            // Если одна из сторон - физическое лицо, то приоритет на эту сторону
            if (this.receiver.isIndividual) {
                return SmartContractSideType.RECEIVER;
            }
            if (this.supplier.isIndividual) {
                return SmartContractSideType.SUPPLIER;
            }
            return SmartContractSideType.BOTH;
        }
        if (!isSupplier && !isReceiver) {
            return SmartContractSideType.THIRD_PARTY;
        }
        if (isSupplier && !isReceiver) {
            return SmartContractSideType.SUPPLIER;
        }
        if (!isSupplier && isReceiver) {
            return SmartContractSideType.RECEIVER;
        }
    }

    /**
     * Отдает контрагента
     */
    getCounterparty() {
        switch (this.sideType) {
            case SmartContractSideType.SUPPLIER:
                return this.receiver;
                break;
            case SmartContractSideType.RECEIVER:
                return this.supplier;
                break;
            case SmartContractSideType.BOTH:
                return this.supplier;
                break;
            // что-то пошло не так
            default:
                return this.supplier;
        }
    }

}

///////////////////////////////////  ЗАЯВКИ НА ОПЛАТУ //////////////////////////////////////////

/** Варианты валюты */
export enum Currency {
    /** RUR    810    Российский рубль    Российская Федерация */
    RUB = 643,
    /** CNY    156    Китайский юань    Китай */
    CNY = 156,
    /** EUR    978    ЕВРО    Страны - участницы Европейского Союза */
    EUR = 978,
    /** USD    840    Доллар США    Американское Самоа, Британская территория Индийском океане,
     * Виргинские острова (Британские), Виргинские острова (США), Гаити, Гуам, Малые Тихоокеанские отдаленные острова США,
     * Маршалловы острова, Микронезия, Палау, Панама, Пуэрто - Рико, Северные Марианские острова, Соединенные Штаты Америки,
     * Теркс и Кайкос, острова */
    USD = 840
}

/** Типы заявок на оплату */
export enum PaymentApplicationTypes {
    /** Заявка на оплату без особенностей */
    BASIC = 'BASIC',
    /** Заявка на оплату через логистику */
    LOGISTIC = 'LOGISTIC',
    /** Заявка на оплату по агентской схеме */
    AGENT_SCHEME = 'AGENT_SCHEME',
    /** Заявка в подотчет*/
    IMPREST = 'IMPREST'
}

/** Краткая модель контрагента */
@JsonObject()
export class CounterpartyShort {
    /** Уникальный идентификатор */
    @JsonProperty()
    @JsonType(String)
    id: string;
    /** Наименование */
    @JsonProperty()
    @JsonType(String)
    name: string;
    /** ИНН */
    @JsonProperty()
    @JsonType(String)
    inn: string;
}


/*
switch (status) {
      case 'DRAFT':
          '• ЧЕРНОВИК',

      case 'SEND_TO_PAYMENT':
          '• ОТПР. НА ОПЛАТУ',

      case 'PAYMENT_ACCEPTED':
          '• ПРИН. В ОПЛАТУ',

      case 'EXPLANATIONS_ARE_REQUIRED':
          '• ТРЕБ. ПОЯСНЕНИЯ',

      case 'EXPLANATIONS_FROM_LOGISTIC_ARE_REQUIRED':
          '• ТРЕБ. ПОЯСНЕНИЯ ОТ ЛОГИСТА',

      case 'WAIT_FOR_APPROVAL':
          '• НА СОГЛАСОВАНИИ',

      case 'UPLOADED_TO_1C':
          '• ВЫГРУЖЕНО В 1С',

      case 'PAID':
          '• ОПЛАЧЕН',

      case 'DENIED':
          '• ОТКЛОНЕН',

      case 'SEND_TO_LOGISTIC':
          '• ОТПР. В ЛОГИСТИКУ',

      case 'IN_PROGRESS_LOGISTIC':
          '• В РАБОТЕ У ЛОГИСТА',

      case 'ARCHIVE':
          '• АРХИВ',

      default:
          '• НЕТ СТАТУСА',

* */
/**
 * Список статусов
 */
export enum PaymentApplicationStatuses {
    /** Черновик */
    DRAFT = 'DRAFT',
    /** Отправлено на оплату */
    SEND_TO_PAYMENT = 'SEND_TO_PAYMENT',
    /** Принято в оплату */
    PAYMENT_ACCEPTED = 'PAYMENT_ACCEPTED',
    /** Требуются пояснения */
    EXPLANATIONS_ARE_REQUIRED = 'EXPLANATIONS_ARE_REQUIRED',
    /** Требуются пояснения от логиста */
    EXPLANATIONS_FROM_LOGISTIC_ARE_REQUIRED = 'EXPLANATIONS_FROM_LOGISTIC_ARE_REQUIRED',
    /** На согласовании */
    WAIT_FOR_APPROVAL = 'WAIT_FOR_APPROVAL',
    /** Выгружено в 1С */
    UPLOADED_TO_1C = 'UPLOADED_TO_1C',
    /** Оплачен */
    PAID = 'PAID',
    /** Отклонен */
    DENIED = 'DENIED',
    /** Отправлено в логистику */
    SEND_TO_LOGISTIC = 'SEND_TO_LOGISTIC',
    /** В работе у логиста */
    IN_PROGRESS_LOGISTIC = 'IN_PROGRESS_LOGISTIC',
    /** Архив */
    ARCHIVE = 'ARCHIVE'
}

// TODO будет ли в пустых местах уведомление?
export const PaymentApplicationStatusesMapping = Object.freeze([
    {
        value: PaymentApplicationStatuses.DRAFT,
        class: 'grey',
        name: 'Черновик'
    },
    {
        value: PaymentApplicationStatuses.SEND_TO_PAYMENT,
        class: 'yellow',
        name: 'Отправлено на оплату'
    },
    {
        value: PaymentApplicationStatuses.PAYMENT_ACCEPTED,
        class: 'green',
        name: 'Принято в оплату'
    },
    {
        value: PaymentApplicationStatuses.EXPLANATIONS_ARE_REQUIRED,
        class: 'blue',
        name: 'Требуются пояснения'
    },
    {
        value: PaymentApplicationStatuses.EXPLANATIONS_FROM_LOGISTIC_ARE_REQUIRED,
        class: 'blue',
        name: 'Требуются пояснения от логиста'
    },
    {
        value: PaymentApplicationStatuses.WAIT_FOR_APPROVAL,
        class: 'blue',
        name: 'На согласовании'
    },
    {
        value: PaymentApplicationStatuses.UPLOADED_TO_1C,
        class: 'color1c',
        name: 'Выгружено в 1С'
    },
    {
        value: PaymentApplicationStatuses.PAID,
        class: 'grey',
        name: 'Оплачен'
    },
    {
        value: PaymentApplicationStatuses.DENIED,
        class: 'grey',
        name: 'Отклонен'
    },
    {
        value: PaymentApplicationStatuses.SEND_TO_LOGISTIC,
        class: 'yellow',
        name: 'Отправлено в логистику'
    },
    {
        value: PaymentApplicationStatuses.IN_PROGRESS_LOGISTIC,
        class: 'green',
        name: 'В работе у логиста'
    },
    {
        value: PaymentApplicationStatuses.ARCHIVE,
        class: 'grey',
        name: 'Архив'
    },
]);

/**
 * Сокращенная модель заявки на оплату для уведомлений
 */
@JsonObject()
export class PaymentApplicationForNotifications {
    /** Уникальный идентификатор */
    @JsonProperty()
    @JsonType(String)
    id = '';
    /** Наименование */
    @JsonProperty()
    @JsonType(String)
    name = '';
    /** Номер заявки */
    @JsonProperty('registration_number')
    @JsonType(String)
    registrationNumber: number;
    /** Тип заявки на оплату. */
    @JsonProperty()
    @JsonType(String)
    type: PaymentApplicationTypes = PaymentApplicationTypes.BASIC;
    /** Дата документа */
    @JsonProperty('created_at')
    @JsonConverter(CustomDateFormatter)
    @JsonType(String)
    createdAt: Date = new Date();
    /** Автор документа */
    @JsonProperty()
    @JsonElementType(UserShort)
    author: UserShort;
    /** Компания плательщик */
    @JsonProperty()
    @JsonElementType(OrgUnitShortForNotifications)
    org: OrgUnitShortForNotifications = new OrgUnitShortForNotifications();
    /** Круг плательщик */
    @JsonProperty()
    @JsonElementType(OrgUnitShortForNotifications)
    subdivision: OrgUnitShortForNotifications = new OrgUnitShortForNotifications();
    /** Ответственный */
    @JsonProperty('responsible_person')
    @JsonElementType(UserShort)
    responsiblePerson: UserShort = new UserShort();
    /** Контрагент */
    @JsonProperty()
    @JsonElementType(CounterpartyShort)
    counterparty: CounterpartyShort = new CounterpartyShort();
    /** Сумма */
    @JsonProperty()
    @JsonType(Number)
    sum: number;
    /** Статус */
    @JsonProperty()
    @JsonType(String)
    status: PaymentApplicationStatuses;
}

@JsonObject()
export class Circle {
    @JsonProperty('_id')
    @JsonType(String)
    id?: string;

    @JsonProperty('active')
    @JsonType(Boolean)
    active: boolean;

    @JsonProperty('name')
    @JsonType(String)
    name: string | null = null;

    @JsonProperty('description')
    @JsonType(String)
    description: string | null = null;

    @JsonProperty('product_id')
    @JsonType(String)
    productId: string | null = null;

    constructor(
        id?: string,
        active?: boolean,
        name?: string,
        description?: string,
        productId?: string
    ) {
        this.id = id;
        this.active = active;
        this.name = name;
        this.description = description;
        this.productId = productId;
    }
}


/**
 * Сокращенная модель заявки на оплату для уведомлений
 */
@JsonObject()
export class TaskForNotifications {

    @JsonProperty('_id')
    @JsonType(String)
    id?: string;

    @JsonProperty('vid')
    virtualId?: string;

    @JsonProperty('performer_subdivision')
    @JsonElementType(Circle)
    subdivision: Circle;

    @JsonProperty('registration_number')
    @JsonType(String)
    number?: string;

    @JsonProperty('name')
    @JsonType(String)
    name?: string;

    @JsonProperty('created_at')
    @JsonType(String)
    createdAt?: string;

    @JsonProperty('completion_at')
    @JsonType(String)
    completionAt?: string;

    @JsonProperty('is_important')
    @JsonType(Boolean)
    isImportant?: boolean;

    @JsonProperty('comment')
    @JsonType(String)
    description?: string;

    @JsonProperty('created_by')
    @JsonType(String)
    createdBy?: string;

    @JsonProperty('author')
    @JsonElementType(User)
    author: User;

    @JsonProperty('performer')
    @JsonElementType(User)
    performer: User;

    @JsonProperty('type')
    @JsonType(String)
    type: string;

    @JsonProperty('is_deletable')
    @JsonType(Boolean)
    isDeletable: boolean;
}

/**
 * Сокращенная модель заявки на оплату для уведомлений
 */
@JsonObject()
export class NotificationSettings {
    @JsonProperty('sc_email')
    @JsonType(Boolean)
    scEmail = false;

    @JsonProperty('sc_push')
    @JsonType(Boolean)
    scPush = false;

    @JsonProperty('pa_email')
    @JsonType(Boolean)
    paEmail = false;

    @JsonProperty('pa_push')
    @JsonType(Boolean)
    paPush = false;

    @JsonProperty('chats_push')
    @JsonType(Boolean)
    chatsPush = false;

    constructor(
        scEmail: boolean,
        scPush: boolean,
        paEmail: boolean,
        paPush: boolean,
        chatsPush: boolean,
    ) {
        this.scEmail = scEmail;
        this.scPush = scPush;
        this.paEmail = paEmail;
        this.paPush = paPush;
        this.chatsPush = chatsPush;
    }
}

@JsonObject()
class EventAttributes {
    @JsonProperty()
    @JsonType(String)
    id: string;

    @JsonProperty()
    @JsonType(String)
    vid: string;

    @JsonProperty('registration_number')
    @JsonType(String)
    registrationNumber: string;

    @JsonProperty('created_at')
    @JsonConverter(CustomDateFormatter)
    @JsonType(String)
    createdAt: Date;

    @JsonProperty('receiver')
    @JsonType(String)
    receiver: string;

    @JsonProperty('supplier')
    @JsonType(String)
    supplier: string;

    @JsonProperty('sum')
    @JsonType(Number)
    sum: number;

    @JsonProperty('currency')
    @JsonType(Number)
    currency: number;

    @JsonProperty('payment_type')
    @JsonType(String)
    paymentType: PaymentApplicationTypes;
}

@JsonObject()
export class NotificationCenterItem {
    @JsonProperty('event_id')
    @JsonType(String)
    eventId: string;

    @JsonProperty('event_time')
    @JsonConverter(CustomDateFormatter)
    @JsonType(String)
    notificationDate: Date;

    @JsonProperty('is_new')
    @JsonType(Boolean)
    isNew: boolean;

    @JsonProperty('unread')
    @JsonType(Boolean)
    unread: boolean;

    @JsonProperty()
    @JsonType(String)
    message: string;

    @JsonProperty('type')
    @JsonType(String)
    objectType: string;

    @JsonProperty('icon')
    @JsonType(String)
    authorIcon: string;

    @JsonProperty()
    @JsonElementType(EventAttributes)
    attributes: EventAttributes;

    @JsonProperty('user_id')
    @JsonType(Number)
    user_id: number;

    date: Date;

    /**
     * Группирует по дате
     *
     * @params items
     */
    static transformItems(oldItems: NotificationCenterItem[]) {
        const newItems: { date: Date; items: NotificationCenterItem[] }[] = [];
        oldItems.forEach(item => {
            NotificationCenterItem.addOneItem(newItems, item);
        });
        newItems.sort((a, b) => b.date.getTime() - a.date.getTime());
        return newItems;
    }

    static addOneItem(items: { date: Date; items: NotificationCenterItem[] }[], newItem: NotificationCenterItem) {
        let ind = -1;
        ind = items.findIndex(el => el.date.getTime() === newItem.date.getTime());
        if (ind >= 0) {
            items[ind].items.push(newItem);
            items[ind].items.sort((a, b) => b.notificationDate.getTime() - a.notificationDate.getTime());
        } else {
            items.push({date: newItem.date, items: [newItem]});
        }
    }

    @OnDeserialized()
    onDeserialized() {
        this.date = new Date(this.notificationDate);
        this.date.setHours(0, 0, 0, 0);
        this.unread = this.unread || false; // старые, для которых еще нет этого поля, считаем прочитанными
    }

    getTypeView() {
        switch (this.objectType) {
            case 'contract':
                return 'Смарт-контракт';
            case 'meeting_notify':
                return 'Календарь';
            case 'payment':
                return this.getPaStatusName();
            case 'invoice':
                return 'Документы';
            case 'bug_tracker':
                return 'Реаном';
            case 'task_manager':
                return 'Задачи';
            case 'update_motivation' :
                return 'Согласование мотивации';
            case 'accept_motivation' :
                return 'Согласование мотивации';
            case 'accept_employee_motivation' :
                return 'Согласование мотивации';
            case 'matrix' :
            case 'matrix_employee':
                return 'Матрица';
            default:
                return 'Неизвестный тип документа';
        }
    }

    getPaStatusName() {
        switch (this.attributes.paymentType) {
            case 'BASIC':
                return 'Заявка на оплату';
                break;
            case 'LOGISTIC':
                return 'Заявка на оплату через логистику';
                break;
            case 'AGENT_SCHEME':
                return 'Заявка на оплату по агентской схеме';
                break;
            case 'IMPREST':
                return 'Заявка в подотчет';
                break;
            default:
                return 'Заявка на оплату';
        }
        return 'Заявка на оплату';
    }
}
