import {UserShort} from '../../../models/user';
import {JsonProperty, JsonType} from 'ta-json';

export class UserSubdivision extends UserShort {
    @JsonProperty('role')
    @JsonType(String)
    role: string;

    @JsonProperty('is_active')
    @JsonType(Boolean)
    is_active = true;

    constructor(
        userShort: UserShort,
        role: string,
        is_active: boolean = true
    ){
        super(userShort.id,
            userShort.firstName,
            userShort.middleName,
            userShort.lastName,
            userShort.position,
            userShort.currentPosition,
            userShort.weight,
            userShort.photo);
            this.role = role;
            this.is_active = is_active;
    }
}
