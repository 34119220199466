<div class="slot"
     *ngIf="slot">
    <img class="slot__img"
         *ngIf="slot.user && slot.user.id > 0 && slot.user.photo"
         [src]="getUserAvatar()">

    <span class="slot__img _empty"
         *ngIf="slot.user && slot.user.id > 0 && !slot.user.photo">
        {{slot.user.lastName.slice(0,1)}}{{slot.user.firstName.slice(0,1)}}
    </span>

    <span class="slot__img _empty"
          *ngIf="!slot.user">
        <mat-icon class="icon">person_add</mat-icon>
    </span>

    <span class="role" *ngIf="slot.is_role">
        <mat-icon class="icon" [svgIcon]="'radius_mask'"></mat-icon>
    </span>

    <span class="resp" *ngIf="isResp">
        <mat-icon class="icon responsive" svgIcon="radius_star-border"></mat-icon>
    </span>

    <span class="slot__title txt" *ngIf="!shortView">

        <ng-container *ngIf="slot.user">
            <ng-container *ngIf="isPosition">
                <span class="slot__user-txt"
                      [innerHtml]="(slot.user.shortName ? slot.user.shortName: slot.user['last_name'] +' '+ slot.user['first_name'])  | highlight : searchStr">
                </span>
                <span class="slot__position" *ngIf="slot.user && slot.struct && !shortPosition">
                    <span [innerHtml]="slot.struct.name | highlight : searchStr"></span>
                </span>

                <span class="slot__position" *ngIf="slot.user && slot.struct && shortPosition">
                    <span [innerHtml]="(isPosition ?
                        (slot.position && slot.position.title) ? slot.position.title : positionTitle : slot.struct.name) | highlight : searchStr">
                    </span>
                </span>
            </ng-container>

            <ng-container *ngIf="!isPosition">
                <span class="slot__user-txt"
                      [innerHtml]="slot.position.title | highlight : searchStr"></span>

                <span class="slot__position" *ngIf="slot.user"
                      [innerHtml]="(slot.user.shortName ? slot.user.shortName: slot.user['last_name'] +' '+ slot.user['first_name']) | highlight : searchStr">
                </span>
            </ng-container>


        </ng-container>

        <span *ngIf="!slot.user"
              [innerHtml]="(isPosition ? slot.position ? slot.position.title : positionTitle : slot.struct.name) | highlight : searchStr"></span>
    </span>
</div>

