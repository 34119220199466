import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {

  constructor() { }
    @Input() title = null;
    @Input() textarea = null;
    @Input() confirmTitle = null;
    @Input() cancelTitle = null;
    @Output() confirm = new EventEmitter<any>();
    @Output() cancel = new EventEmitter<boolean>();
    @ViewChild('textInput') textElement: ElementRef;

    public text = null;

  ngOnInit() {
    if (this.textarea) {
        setTimeout(() => {
            this.textElement.nativeElement.focus();
        },0);
    }
  }

  onConfirm() {
    if (this.textarea) {
      if (!this.text) {
        return false;
      }

      this.confirm.emit(this.text.toString());
      return false;
    }

    this.confirm.emit(true);
  }

}
