import {JsonProperty, JsonType} from 'ta-json';

export class Rating {
    @JsonProperty('value')
    @JsonType(Number)
    value: number = null;

    @JsonProperty('direction')
    @JsonType(String)
    direction: string = null;
}
