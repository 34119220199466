import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-stars',
  templateUrl: './stars.component.html',
  styleUrls: ['./stars.component.scss']
})
export class StarsComponent implements OnInit {

  @Input() public data: number = null;
  constructor() { }

  public stars = new Array(5);

  ngOnInit(): void {
  }

}
