import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {take} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class PersonalCardSaverService {
    /** Массив фотографий */
    public canExit$ = new BehaviorSubject<boolean>(false);
    public confimExit$ = new BehaviorSubject<boolean>(false);

    constructor() {
        this.canExit$
            .pipe(take(1))
            .subscribe(val => {
            if (val) {
                this.confimExit$.next(false);
            }
        });
    }
}
