import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'tagify'
})
export class TagifyPipe implements PipeTransform {
  transform(link: string): string {
    return this.tagify(link);
  }

  private tagify(plainText): string {
    let replacedText;
    let replacePattern1;

    // Change tags.
    replacePattern1 = /(\B@\S+)|(\S+)'/g;

    replacedText = plainText.replace(replacePattern1, '<a class="link _tag" >$1</a>');

    return replacedText;
  }
}
