import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {AuthService} from '../../services/auth.service';

/**
 * Route guard that ensures that there is a user admin of his company
 */
@Injectable()
export class IsNpmGuard implements CanActivate, CanActivateChild {

    constructor(
        private readonly auth: AuthService,
        readonly router: Router
    ) {
    }

    /** Validates authentication for a specific route. */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const user = this.auth.auth;

        if (user?.company?.is_platform_owner_company) {
            return true;
        } else {
            this.router.navigate(['404']);
            return false;
        }
    }

    /** Validates authentication for children of a route. */
    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.canActivate(childRoute, state);
    }

}
