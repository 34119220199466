import {Injectable} from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {AngularFireMessaging} from '@angular/fire/compat/messaging';
import {take, takeUntil} from 'rxjs/operators';
import {BehaviorSubject, Subject} from 'rxjs';
import {ApiService} from './api.service';
import {NotifierService} from 'angular-notifier';

@Injectable()
export class MessagingService {

    currentMessage = new BehaviorSubject(null);
    destroyed: Subject<void> = new Subject();
    token: string = null;

    constructor(
        private api: ApiService,
        private notifierService: NotifierService,
        private angularFireDB: AngularFireDatabase,
        private angularFireAuth: AngularFireAuth,
        private angularFireMessaging: AngularFireMessaging) {
        // this.angularFireMessaging.messages.subscribe(
        //     (_messaging) => {
        //         _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        //         _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
        //     }
        // )
    }

    /**
     * update token in firebase database
     *
     * @param userId userId as a key
     * @param token token as a value
     */
    updateToken(userId, token) {
        // we can change this function to request our backend service
        this.angularFireAuth.authState.pipe(take(1)).subscribe(
            () => {
                const data = {};
                data[userId] = token;
                this.angularFireDB.object('fcmTokens/').update(data);
            });
    }

    /**
     * request permission for notification from firebase cloud messaging
     *
     * @param userId userId
     */
    requestPermission(userId) {
        this.angularFireMessaging.requestToken
            .pipe(take(1))
            .subscribe(
            (token) => {
                this.token = token;
                this.sendToken(token);
                this.updateToken(userId, token);
            },
            (err) => {
                console.error('Unable to get permission to notify.', err);
            }
        );
    }

    /**
     * hook method when new notification received in foreground
     */
    receiveMessage() {
        this.angularFireMessaging.messages.subscribe(
            (payload) => {
                this.currentMessage.next(payload);
            });
    }

    /**
     * Отсылаем токен в пупу
     *
     * @param token string
     * @param apiKey string
     */
    sendToken(token) {
        this.api.sendTokenIn(token)
            .pipe(takeUntil(this.destroyed))
            .subscribe(result => {
            }, err => {
                console.error(err);
            });
    }

    getToken() {
        return this.token;
    }
}
