import {Component, Input, OnInit} from '@angular/core';
import {Tag} from '../../../task-manager/models/tag';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {TagsFormComponent} from './tags-form/tags-form.component';
import {JSON} from 'ta-json';

@Component({
    selector: 'app-tags',
    templateUrl: './tags.component.html',
    styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit {

    @Input() public tags: Tag[] = [];
    @Input() public isGroups = false;
    @Input() public disabled = false;
    @Input() public isOpened = false;

    constructor(
        private dialog: MatDialog,
    ) {
    }

    get title() {
        return 'Метки';
    }

    ngOnInit() {
        const opened = localStorage.getItem('taskManagerTagsExpanded');
        this.isOpened = opened ? JSON.parse(opened) : false;
    }

    onExpand(evt) {
        localStorage.setItem('taskManagerTagsExpanded', evt);
        this.isOpened = evt;
    }

    openFormAdd() {
        const data = {
            isGroup: this.isGroups
        };

        const dialogForm = this.dialog.open(TagsFormComponent, {data});
    }

}
