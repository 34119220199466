<app-personal-card-form #form
                        *ngIf="user && isLoaded"
                        [data]="user"
                        [rights]="rights"
                        [isAboutCompany]="isAboutCompany"
                        (formSubmit)="onSubmit($event)"
                        (reset)="reset()"></app-personal-card-form>

<app-spinner *ngIf="!(user  || isLoaded)" [isSpinnerOn]="!user"></app-spinner>

