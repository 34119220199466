<ul class="groups" *ngIf="groups">
    <li class="group _empty" *ngIf="selected && !(collapsed && groupSelected)" (click)="removeSelect(groupSelected)">
        <ng-container *ngIf="!shortView">
            <span class="inner"
                  [ngClass]="{'_selectedItem': !groupSelected}">
                <span class="txt">Без группы</span>
                <mat-icon>done</mat-icon>
            </span>
        </ng-container>

        <ng-container *ngIf="shortView">
            <div class="group">
                <div class="color-emul"
                ></div>
                <span class="txt"> Без группы </span>
                <mat-icon *ngIf="!groupSelected" class="done">done</mat-icon>
            </div>
        </ng-container>
    </li>

    <ng-container *ngFor="let item of groups">
        <li class="group"
            [ngClass]="{'_editable' : editable, '_selected' : selected, '_removable' : removable, '_disabled': disabled || !canEditGroup, '_tag': !item.taskGroup, '_collapsed': collapsed && !isSelected(item)}"
            (click)="click(item)">

            <ng-container *ngIf="shortView">
                <div class="group">
                    <div class="color-emul"
                         [ngStyle]="{'background' : getItemColor(item.color)}"
                    ></div>
                    <span class="txt">{{ item.name }}</span>
                    <mat-icon *ngIf="isSelected(item)" class="done">done</mat-icon>
                </div>
            </ng-container>

            <ng-container *ngIf="!shortView">
                <span class="inner"
                      [ngClass]="{'_selectedItem': isSelected(item)}"
                      [ngStyle]="{'background': getItemColor(item.color)}">
                <span class="txt">{{ item.name }}</span>
                <mat-icon>done</mat-icon>
            </span>
            </ng-container>

            <div class="toolbar" *ngIf="(editable || removable) && !disabled">
                <button type="button" class="button" *ngIf="editable && !disabled" mat-icon-button
                        (click)="edit(item.id)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button type="button" class="button" *ngIf="editable && !disabled" mat-icon-button
                        (click)="isDeleteConfirm = true; groupToDelete = item.id">
                    <mat-icon>close</mat-icon>
                </button>
                <button type="button" class="button" *ngIf="removable && !disabled"
                        mat-icon-button
                        (click)="remove(item)">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </li>
    </ng-container>
</ul>

<article class="group" *ngIf="group"
    [ngClass]="{'_editable' : (removable || editable) && !disabled, '_selected' : selected, '_disabled': disabled}"
    (click)="click(group)">
            <span class="inner"
                  [ngClass]="{'_selectedItem': isSelected(group), '_disabled': disabled}"
                  [ngStyle]="{'background': getItemColorFromBoard(group)}">
                <span class="txt">{{ group.name }}</span>
                <mat-icon>done</mat-icon>
            </span>
    <div class="toolbar" *ngIf="(editable || removable) && !disabled">
        <button type="button" class="button" *ngIf="removable && !disabled" mat-icon-button
                (click)="select()">
            <mat-icon>edit</mat-icon>
        </button>
        <button type="button" class="button" *ngIf="removable && !disabled"
                mat-icon-button
                (click)="removeSelect(group)">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</article>

<ng-container *ngIf="isDeleteConfirm">
    <app-confirm title="Удалить группу?"
                 confirmTitle="Удалить"
                 cancelTitle="Отмена"
                 (confirm)="delete()"
                 (cancel)="isDeleteConfirm = false;">
    </app-confirm>
</ng-container>

