<mat-accordion class="users section" *ngIf="companyMode">
    <mat-expansion-panel class="users-expansion-panel" [expanded]="isExpandedDefault"
                         (opened)="onOpen.emit()" (closed)="onClose.emit()" #usersPanel="matExpansionPanel">
        <mat-expansion-panel-header class="users__header"
                                    [collapsedHeight]="'24px'"
                                    [expandedHeight]="'24px'">
            <h2 class="title">
                {{ title }} <span class="total" *ngIf="users.length">
                ({{ users.length }})</span>
            </h2>
        </mat-expansion-panel-header>

        <section class="section">
            <div class="users__content">
                <ul class="users__list _inner">
                    <ng-container *ngFor="let item of users">
                        <li class="users__list-item _company" *ngIf="item['structs'] && item['structs'].length"
                            [ngClass]="{'_opened': item.id === openedUserId}">
                            <app-users-list-item class="user _company" [userPosition]="item"
                                                 [disabled]="disabled || item['disabled']"
                                                 [companyMode]="companyMode"
                                                 (onUser)="openUser($event)"></app-users-list-item>
                        </li>
                    </ng-container>
                </ul>
            </div>
        </section>

    </mat-expansion-panel>

    <section class="users__content _header" *ngIf="users.length"
             (click)="usersPanel.open()"
             [ngClass]="{'_hide': usersPanel.expanded}">
        <ul class="users__list _header">
            <ng-container *ngFor="let item of users; let i = index">
                <li class="users__list-item _short" *ngIf="i < 5">
                    <app-users-list-item [userSubdivision]="item"
                                         [approvers]=approvers
                                         [approved]="approvers && item.isApproved"
                                         [notReaded]="approvers && item.isNotReaded"
                                         [shortView]="true"></app-users-list-item>
                </li>
            </ng-container>
            <li class="users__list-item _short" *ngIf="users.length > 5">
                <span class="oversize">+{{users.length - 5}}</span>
            </li>
        </ul>
    </section>

</mat-accordion>

<ng-container *ngIf="!companyMode">
    <section class="users _empty" *ngIf="!users.length && !disabled">
        <h2 class="title">
            {{ title }}
        </h2>
        <section class="section">
            <div class="users__content">
                <ul class="users__list _inner">
                    <li class="users__list-item" *ngFor="let item of users"
                        [ngClass]="{'_opened': item.id === openedUserId}">
                        <app-users-list-item [userSubdivision]="item"
                                             [position]="position"
                                             [removable]="!disabled && !item['disabled']"
                                             [disabled]="disabled || item['disabled']"
                                             [approvers]=approvers
                                             [approved]="approvers && item.isApproved"
                                             [notReaded]="approvers && item.isNotReaded"
                                             (onRemove)="removeUser(item)"
                                             (onUser)="openUser($event)"></app-users-list-item>
                    </li>
                    <li class="member users__list-item" *ngIf="!disabled">
                        <button mat-icon-button class="button _add _list" type="button" (click)="openUsersForm($event)">
                            <mat-icon>person_add</mat-icon>
                            <span class="txt">{{ buttonTitle }}</span>
                        </button>
                    </li>
                </ul>
            </div>
        </section>
    </section>

    <section class="users _empty" *ngIf="users.length && !expansive">
        <h2 class="title">
            {{ title }} <span class="total" *ngIf="users.length && !role ==='executive'">
                ({{ users.length  }})</span>
        </h2>

        <section class="section">
            <div class="users__content">
                <ul class="users__list _inner">
                    <li class="users__list-item" *ngFor="let item of users"
                        [ngClass]="{'_opened': item.id === openedUserId}">
                        <app-users-list-item [userSubdivision]="item"
                                             [removable]="!disabled && !item['disabled']"
                                             [disabled]="disabled || item['disabled']"
                                             [position]="position"
                                             [approvers]=approvers
                                             [approved]="approvers && item.isApproved"
                                             [notReaded]="approvers && item.isNotReaded"
                                             (onRemove)="removeUser(item)"
                                             (onUser)="openUser($event)"></app-users-list-item>
                    </li>
                </ul>
            </div>
        </section>

        <button mat-icon-button *ngIf="!disabled && !(role ==='executive' && users.length >= 1)"
                class="button _add _list" type="button" (click)="openUsersForm($event)">
            <mat-icon>person_add</mat-icon>
            <span class="txt">{{ buttonTitle }}</span>
        </button>
    </section>

    <mat-accordion class="users section" *ngIf="(users.length || employee) && expansive">
        <mat-expansion-panel class="users-expansion-panel" [expanded]="isExpandedDefault"
                             (opened)="onOpen.emit()" (closed)="onClose.emit()" #usersPanel="matExpansionPanel">
            <mat-expansion-panel-header class="users__header"
                                        [collapsedHeight]="'24px'"
                                        [expandedHeight]="'24px'">
                <h2 class="title">
                    {{ title }} <span class="total" *ngIf="users.length">
                ({{ users.length }})</span>
                </h2>
            </mat-expansion-panel-header>

            <section class="section">
                <div class="users__content">
                    <ul class="users__list _inner">
                        <li class="users__list-item" *ngFor="let item of users"
                            [ngClass]="{'_opened': item.id === openedUserId}">
                            <app-users-list-item [userSubdivision]="item"
                                                 [removable]="!disabled && !item['disabled']"
                                                 [disabled]="disabled || item['disabled']"
                                                 [position]="position"
                                                 [approvers]=approvers
                                                 [approved]="approvers && item.isApproved"
                                                 [notReaded]="approvers && item.isNotReaded"
                                                 (onRemove)="removeUser(item)"
                                                 (onUser)="openUser($event)"></app-users-list-item>
                        </li>
                        <li class="users__list-item" *ngIf="employee">
                            <app-users-list-item [userSubdivision]="employee"
                                                 [disabled]="true"
                                                 [removable]="false"
                                                 [approvers]=approvers
                                                 [approved]="approvers && employee['isApproved']"
                                                 [notReaded]="approvers && employee['isNotReaded']"
                            ></app-users-list-item>
                        </li>
                        <li class="member users__list-item" *ngIf="!disabled">
                            <button mat-icon-button class="button _add _list" type="button" (click)="openUsersForm($event)">
                                <mat-icon>person_add</mat-icon>
                                <span class="txt">{{ buttonTitle }}</span>
                            </button>
                        </li>
                    </ul>
                </div>
            </section>

        </mat-expansion-panel>

        <section class="users__content _header" *ngIf="(users.length || employee) && !usersPanel.expanded"
                 (click)="usersPanel.open()"
                 [ngClass]="{'_hide': usersPanel.expanded}">
            <ul class="users__list _header">
                <ng-container *ngFor="let item of users; let i = index">
                    <li class="users__list-item _short" *ngIf="i < 5">
                        <app-users-list-item [userSubdivision]="item"
                                             [approvers]=approvers
                                             [approved]="approvers && item.isApproved"
                                             [notReaded]="approvers && item.isNotReaded"
                                             [shortView]="true"></app-users-list-item>
                    </li>
                </ng-container>
                <li class="users__list-item _short" *ngIf="users.length > 5">
                    <span class="oversize">+{{users.length - 5}}</span>
                </li>

                <li class="users__list-item _short _separated" *ngIf="employee">
                    <app-users-list-item [userSubdivision]="employee"
                                         [approvers]=approvers
                                         [approved]="approvers && employee['isApproved']"
                                         [notReaded]="approvers && employee['isNotReaded']"
                                         [shortView]="true"></app-users-list-item>
                </li>
            </ul>
        </section>

    </mat-accordion>

</ng-container>
