<button type="button" class="response">
    <span class="button__header">
        <app-stars [data]="data.points" *ngIf="data.points"></app-stars>
        <span class="txt _small _no-points" *ngIf="!data.points">Без оценки</span>

        <span class="txt _small _past">Прошлый ответ</span>
        <span class="txt _small _anon">Отвечен анонимно</span>
    </span>

    <span class="txt">
        {{ data.title }}
    </span>
</button>
