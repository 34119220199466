<div class="floating-message" >
    <div class="icon-wrap">
        <mat-icon class="icon info-icon" [svgIcon]="'radius_info'"></mat-icon>
    </div>
    <div>
        <ng-content></ng-content>
    </div>
    <div class="spacer"></div>
    <button (click)="onClose()" class="button icon-button" mat-icon-button>
        <mat-icon class="icon">close</mat-icon>
    </button>
</div>
