import {Component, Input, OnInit} from '@angular/core';
import {ConfigBoardsService} from '../../../task-manager/services/config-boards.service';
import {AuthService} from '../../../../services/auth.service';
import {Slot} from '../../../phonebook/models/slot';

@Component({
  selector: 'app-slots-list-item',
  templateUrl: './slots-list-item.component.html',
  styleUrls: ['./slots-list-item.component.scss']
})
export class SlotsListItemComponent implements OnInit {

    // для раздела компаний
    @Input() companyMode = false;
    @Input() public slot: Slot = null;
    @Input() public shortView = false;
    @Input() public isResp = false;

    // Нужна ли должность
    @Input() public isPosition = true;
    @Input() public disabled = false;
    @Input() public searchStr = null;
    @Input() public positionTitle = '';
    @Input() public shortPosition = false;
    public userId = null;

    constructor(
        private config: ConfigBoardsService,
        private auth: AuthService
    ) {
    }

    getUserAvatar() {
        return this.config.getUserAvatarByUser(this.slot.user, 'small');
    }

    ngOnInit() {
        this.userId = this.auth.currentUser$.value.id;
    }
}
