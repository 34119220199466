import {Component, Input, OnInit} from '@angular/core';
import {TasksService} from 'src/app/modules/task-manager/services/tasks.service';

@Component({
  selector: 'app-buttons-kb',
  templateUrl: './buttons-kb.component.html',
  styleUrls: ['./buttons-kb.component.scss']
})
export class ButtonsKBComponent implements OnInit {

  // индексы полей
  @Input() public index: number;
  // Массив файлов для загрузки в базу знаний
  public buttonKBActive = false;
  public buttonConversionGDActive = true;

  constructor(
    private tasksService: TasksService
  ) { }

  ngOnInit(): void {
  }

  saveInKB(index) {
    this.buttonKBActive = !this.buttonKBActive;
    this.buttonKBActive ? this.buttonConversionGDActive = true : this.buttonConversionGDActive = false;
    this.updateButtonsInfo(index);
  }

  transformInGD(index) {
    this.buttonConversionGDActive = !this.buttonConversionGDActive;
    this.updateButtonsInfo(index);
  }

  updateButtonsInfo(index: number) {
    const newElemKB = {index, addKB:true, conversion : true};
    if (this.buttonKBActive && !this.buttonConversionGDActive) {
      newElemKB.conversion = false;
    } else if (!this.buttonKBActive) {
      newElemKB.addKB = false;
      newElemKB.conversion = false;
    }
    this.tasksService.addToKB$.next(newElemKB);
  }
}
