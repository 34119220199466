import {Injectable} from '@angular/core';
import {Tag, tagsMapping} from '../models/tag';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TagsService {

    onTagRemove$ = new Subject<any>();
    onTagSelect$ = new Subject<Tag>();
    onTagCreate$ = new Subject<Tag>();
    openCreateTagForm$ = new Subject();
    onTagUpdate$ = new BehaviorSubject<Tag>(null);
    searchedName$ = new BehaviorSubject<string>('');
    openCreateTagFormMeet$ = new Subject();
    openCreateTagFormDialogOpen$ = new BehaviorSubject<boolean>(false);

    constructor() {
    }

    getTagBackground(colorName) {
        const st = tagsMapping.find(el => el.name === colorName);
        return st ? st.background : '';
    }

    getTagColor(colorName) {
        const st = tagsMapping.find(el => el.name === colorName);
        return st ? st.color : '';
    }
}
