import {Directive, HostListener} from '@angular/core';

@Directive({
    selector: 'input',
})
export class NoScrollInputDirective {

    @HostListener('wheel', ['$event'])
    onWheel(event: Event) {
        const elem = document.activeElement as HTMLElement;
        if (elem && elem['type'] == 'number') {
            event.preventDefault();
            (document.activeElement as HTMLElement).blur();
        }
    }

}
