import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialog as MatDialog,
    MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import {TagsService} from '../../../../task-manager/services/tags.service';
import {Subject} from 'rxjs';
import {Tag} from '../../../../task-manager/models/tag';
import {BoardsService} from '../../../../task-manager/services/boards.service';
import {GroupsFormComponent} from '../groups-form/groups-form.component';
import {takeUntil} from 'rxjs/operators';
import {AuthService} from '../../../../../services/auth.service';
import {SharedService} from '../../../services/shared.service';
import {ApiBoardsService} from '../../../../task-manager/services/api-boards.service';

@Component({
    selector: 'app-group-select',
    templateUrl: './group-select.component.html',
    styleUrls: ['./group-select.component.scss']
})
export class GroupSelectComponent implements OnInit, OnDestroy {
    // Триггер смерти компонента
    private destroyed = new Subject<void>();
    public sorted: Tag[] = [];
    public searchTerm = '';
    public groups: Tag[] = [];
    public groupSelected: Tag = null;
    public canEdit = false;
    public currentBoard = null;
    private boardRole = null;

    constructor(
        public dialogRef: MatDialogRef<any>,
        private dialog: MatDialog,
        private tagsService: TagsService,
        private boardsService: BoardsService,
        private sharedService: SharedService,
        private apiGroup: ApiBoardsService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private auth: AuthService,
    ) {
        this.groups = data.groups;
        this.groupSelected = data.groupSelected;
        this.sorted = this.groups;
        this.currentBoard =  data.currentBoard;
    }

    get canAdd() {
        return this.boardRole === 'owner' || this.boardRole === 'editor' || this.currentBoard.template_board;
    }

    get isLearningOpen() {
        return this.sharedService.openTaskLearning$.value;
    }

    ngOnInit() {
        this.currentBoard = this.boardsService.boards$.value.find(b => b.id === this.currentBoard.id);
        this.canEdit = this.boardsService.canEditGroup$.getValue();
        this.boardRole = this.currentBoard?.members?.find(m => +m.id === +this.auth.auth.id)?.boardRole;

        this.tagsService.onTagCreate$
            .pipe(takeUntil(this.destroyed))
            .subscribe(group => {
                if (group) {
                    if (!this.groups.some(el => el.id === group.id)) {
                        this.groups.push(group);
                        this.groupSelected = group;
                        this.close(group);
                        //this.groupsService.onGroupSelect$.next(this.groupSelected);
                    }
                }
            });

        this.sharedService.openTaskLearning$
            .pipe(takeUntil(this.destroyed))
            .subscribe(val => {
                if (val && this.groups.length) {

                    this.apiGroup.deleteTag(this.groups[0].id)
                        .pipe(takeUntil(this.destroyed))
                        .subscribe(res => {
                            this.tagsService.onTagRemove$.next({boardId: this.currentBoard?.id, tag: this.groups[0]});
                        }, err => {
                        });
                }
            });
    }

    onSearchChange(search) {
        this.searchTerm = search;
        this.sorted = this.filterValues<Tag>(search, this.groups);
    }

    filterValues<Type>(searchTerm, baseList: Type[]): Type[] {
        let newList;

        if (searchTerm) {
            const terms_str = searchTerm.toLowerCase()
                .split(' ')
                .map(i => i.trim())
                .filter(i => i);
            newList = baseList.filter(
                item => terms_str.every(
                    term => this.testItem<Type>(item, term)
                )
            );
        } else {
            newList = baseList;
        }

        return newList;
    }

    testItem<Type>(item: Type, term: string) {
        return item && (this.testString(item['name'], term)) ;
    }

    testString(value: string, term: string) {
        if (!!value) {
            return value.toString().toLowerCase().includes(term);
        }
        return false;
    }

    openFormAdd() {
        const data = {
            boardId: this.currentBoard ? this.currentBoard.id : null,
        };
        const dialogForm = this.dialog.open(GroupsFormComponent, {
            data: data,
            disableClose: this.sharedService.openTaskLearning$.value
        });
    }

    close($event?) {
        this.groupSelected = $event;
        this.dialogRef.close(this.groupSelected);
    }

    remove() {
        this.dialogRef.close(null);
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }

}
