import {Injectable} from '@angular/core';
import {Tag} from '../models/tag';
import {BehaviorSubject, Subject} from 'rxjs';
import {Group, groupsMapping} from '../models/group';

@Injectable({
    providedIn: 'root'
})
export class GroupsService{

    boardGroups$ = new BehaviorSubject<Group[]>(null);
    onGroupSelect$ = new Subject();
    selectedGroupFilter$ = new BehaviorSubject([]);
    removeGroup$ = new Subject();
    groupsMenuCollapsed$ = new BehaviorSubject(true);
    groupsCounters$ = new BehaviorSubject(null);
    onGroupCreate$ = new Subject<Tag>();
    onGroupRemove$ = new Subject<any>();
    onGroupUpdate$ = new Subject<any>();

    constructor() {
    }

    removeGroup(id) {
        console.log('removeGroup')
        // let groups = this.selectedGroupFilter$.value;
        // if (groups && groups.length) {
        //     groups = groups.filter(el => el !== id);
        //     this.selectedGroupFilter$.next(groups);
        // }
    }

    getGroupBackground(colorName) {
        let st = groupsMapping.find(el => el.name === colorName);
        if (!st) {
st = Object.assign(groupsMapping.find(el => el.name === '_colorDisabled'));
}
        return st.color;
    }

    getGroupById(id) {
        const groups = this.boardGroups$.value;
        if (groups) {
            return groups.find(el => el['id'] === id);
        }
    }
}
