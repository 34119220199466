import {JsonObject, JsonProperty, JsonType} from 'ta-json';

@JsonObject()
export default class Status {
    @JsonProperty('id')
    @JsonType(Number)
    id?: number;

    @JsonProperty('type')
    @JsonType(String)
    type?: string;

    @JsonProperty('name')
    @JsonType(String)
    name?: string;

    @JsonProperty('color')
    @JsonType(String)
    color?: string;

    constructor(
        id?: number,
        type?: string,
        name?: string,
        color?: string,
    ) {
        this.id = id;
        this.type = type;
        this.name = name;
        this.color = color;
    }
}

