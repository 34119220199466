import {JsonObject, JsonProperty, JsonType} from 'ta-json';

@JsonObject()
export class ColumnShort {
    @JsonProperty('id')
    @JsonType(Number)
    id?: number;

    @JsonProperty('color')
    @JsonType(String)
    color?: string;

    @JsonProperty('name')
    @JsonType(String)
    name?: string;

    @JsonProperty('tag')
    @JsonType(String)
    tag?: string;
}
