import {Component, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {OnboardingService} from '../../../../../services/onboarding.service';
import {Subject} from 'rxjs';
import {AuthService} from '../../../../../services/auth.service';

@Component({
  selector: 'app-onboarding-users',
  templateUrl: './onboarding-users.component.html',
  styleUrls: ['./onboarding-users.component.scss'],
})
export class OnboardingUsersComponent implements OnInit, OnDestroy {
  private destroyed = new Subject<void>();
  isVisible = false;
  ONBOARDING_KEY = 'users';

  slides = [];
  isAdmin = false;
  inited = false;

  constructor(
      private onboarding: OnboardingService,
      private auth: AuthService,
  ) { }

  ngOnInit(): void {
    this.onboarding.currentScreen$.next(this.ONBOARDING_KEY);

    this.onboarding.viewedHints$.pipe(takeUntil(this.destroyed))
        .subscribe(val => {
            if (val.hasOwnProperty(this.ONBOARDING_KEY)) {
              this.isVisible = val[this.ONBOARDING_KEY] === false;
            } else {
              this.isVisible = false;
            }
        });

    this.auth.rights$.pipe(takeUntil(this.destroyed))
        .subscribe(val => {
          this.updateRights(val);
          this.setSlides();
        });
  }

  updateRights(rights) {
    this.isAdmin = rights?.isAdministrator;
    this.inited = true;
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  private setSlides() {
    const slidesAdmin = [
      { src: '/assets/images/onboarding/users-1.png',
        src2x: '/assets/images/onboarding/users-1-2x.png',
      },
      { src: '/assets/images/onboarding/users-2.png',
        src2x: '/assets/images/onboarding/users-2-2x.png',
      },
      { src: '/assets/images/onboarding/users-3.png',
        src2x: '/assets/images/onboarding/users-3-2x.png',
      },
      { src: '/assets/images/onboarding/users-4.png',
        src2x: '/assets/images/onboarding/users-4-2x.png',
      },
      { src: '/assets/images/onboarding/users-5.png',
        src2x: '/assets/images/onboarding/users-5-2x.png',
      },
    ];

    const slidesUser = [
      { src: '/assets/images/onboarding/users-u-1.png',
        src2x: '/assets/images/onboarding/users-u-1-2x.png',
      }
    ];

    if (this.isAdmin) {
      this.slides = slidesAdmin;
    } else {
      this.slides = slidesUser;
    }
  }
}
