import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ChatsService} from '../../../../../services/chats.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-chat-audio',
  templateUrl: './chat-audio.component.html',
  styleUrls: ['./chat-audio.component.scss']
})
export class ChatAudioComponent implements OnInit, OnDestroy {
    @Input() public src = null;
    @Input() public type = null;
    @ViewChild('audio', { static: false }) public _audioRef:  ElementRef;
    @ViewChild('slider') slider: ElementRef;
    private destroyed = new Subject<void>();

    constructor(
        private chatService: ChatsService,
    ) {
    }

    ngOnInit() {
        this.chatService.stopAudio$
            .pipe(takeUntil(this.destroyed))
            .subscribe(task => {
                this._audioRef.nativeElement.pause()
            })
    }

  durationCalculate(val) {
    return this.calculateTime(val);
  }

  currentVal() {
    if (this.slider && this.slider.nativeElement) {
      return this.calculateTime(this.slider.nativeElement.value);
    }
    return 0;
  }

  sliderMove(evt) {
      evt.stopPropagation();
      const seekTo = this._audioRef.nativeElement.duration * (this.slider.nativeElement.value / 100);
      this._audioRef.nativeElement.currentTime = seekTo;
  }

  timeUpdate(evt) {
      if (!this.slider || !this.slider.nativeElement) {
          return false;
      }

      const currentTimeValue = this._audioRef.nativeElement.currentTime;
      const durationValue = this._audioRef.nativeElement.duration;

      this.slider.nativeElement.value = (currentTimeValue / durationValue) * 100;
  }

  play(audio) {
      this.chatService.stopAudio$.next(true);

      setTimeout(() => {
          audio.play()
      })

  }

  calculateTime(secs) {
    const minutes = Math.floor(secs / 60);
    const seconds = Math.floor(secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${minutes}:${returnedSeconds}`;
  }


    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }
}
