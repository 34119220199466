import {Injectable} from '@angular/core';
import {GlobalNotificationCenterService} from '../../../services/global-notification-center.service';
import {NotifierService} from 'angular-notifier';

@Injectable({
  providedIn: 'root'
})
export class NotificationCenterService extends GlobalNotificationCenterService{

    constructor(notifierService: NotifierService) {
super(notifierService);
}
}
