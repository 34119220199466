<div class="chat-wrapper">
    <div class="flex info" *ngIf="isCard">
        <span class="_back" (click)="onClose()">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 5.25H2.8725L7.065 1.0575L6 0L0 6L6 12L7.0575 10.9425L2.8725 6.75H12V5.25Z" fill="#7E858E"/>
            </svg>
            В карточку
        </span>

        <span>
            <app-chat-members-menu class="chat-members" type="motivation"></app-chat-members-menu>
        </span>
    </div>
    <div class="flex info _subs" *ngIf="!isCard">
        <span class="tite">
            Мотивация
        </span>

        <mat-icon class="button _icon close-btn" (click)="onClose()">clear</mat-icon>
    </div>

    <app-chat-menu *ngIf="multiple" [isPrivate]="isPrivate" (change)="onChangeChat($event)"></app-chat-menu>

    <div class="subheaders" *ngIf="!multiple">
        <div  class="subheader _vert">
            <div class="subheader-name">{{title}}</div>
            <div class="subheader-date">{{date}}</div>
        </div>
        <div class="subheader">
            <span *ngIf="isPrivate" class="subheader-chat">
                Чат c согласователями
            </span>
            <span *ngIf="!isPrivate"  class="subheader-chat">
                Чат с сотрудником
            </span>
        </div>
    </div>

        <app-chat class="chats" *ngIf="usersMotivationId && isLoaded"
                  [itemId]="usersMotivationId"
                  [entity]="isPrivate ? 'motivation_without_user' : 'motivation_with_user'"
                  [isMembersAddNeed]="false"></app-chat>

</div>
