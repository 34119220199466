import {JsonElementType, JsonProperty, JsonType} from 'ta-json';
import {Task} from './task';
import {PlaningUser} from './planing-user';

export class PlaningBlock {
    @JsonProperty('id')
    @JsonType(Number)
    id?: number;

    @JsonProperty('count')
    @JsonType(Number)
    count?: number;

    @JsonProperty('color')
    @JsonType(String)
    color?: string;

    @JsonProperty('name')
    @JsonType(String)
    name?: string;

    @JsonProperty('description')
    @JsonType(String)
    description?: string;

    @JsonProperty('tag')
    @JsonType(String)
    tag?: string;

    @JsonProperty('hash')
    @JsonType(Number)
    hash?: number;

    @JsonProperty('tasks')
    @JsonElementType(Task)
    tasks: Task[] = [];

    @JsonProperty('users')
    @JsonElementType(PlaningUser)
    users: PlaningUser[] = [];

    @JsonProperty('parent')
    @JsonElementType(PlaningBlock)
    parent?: PlaningBlock = null;

    @JsonProperty('task')
    @JsonElementType(Task)
    task?: Task = null;

    @JsonProperty('task_id')
    @JsonType(Number)
    taskId?: number = null;
}
