import {JsonProperty, JsonType} from 'ta-json';

export class SubdivisionShortSlot {
    @JsonProperty('id')
    @JsonType(Number)
    id: number = null;

    @JsonProperty('name')
    @JsonType(String)
    name = '';

    @JsonProperty('description')
    @JsonType(String)
    description = '';

    @JsonProperty('destiny')
    @JsonType(String)
    destiny = '';

    @JsonProperty('unit_id')
    @JsonType(Number)
    unit_id = 0;

    @JsonProperty('image')
    @JsonType(String)
    image: string = null;

    @JsonProperty('is_archive')
    @JsonType(Boolean)
    is_archive?: boolean;

}
