import {JsonProperty, JsonType} from 'ta-json';

export class PositionShort {
    @JsonProperty('id')
    @JsonType(Number)
    id: number = null;

    @JsonProperty('title')
    @JsonType(String)
    title: string = null;

    @JsonProperty('description')
    @JsonType(String)
    description: string = null;
}
