import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit,} from '@angular/core';
import {Task, TaskStatuses} from '../../../task-manager/models/task';
import {Board} from '../../../task-manager/models/board';
import {UserShort} from '../../../../models/user';
import {ApiBoardsService} from '../../../task-manager/services/api-boards.service';
import {AuthService} from '../../../../services/auth.service';
import {TasksService} from '../../../task-manager/services/tasks.service';
import {BoardsService} from '../../../task-manager/services/boards.service';
import {TagsService} from '../../../task-manager/services/tags.service';
import {NavService} from '../../../task-manager/services/nav.service';
import {Router} from '@angular/router';
import {ConfigBoardsService} from '../../../task-manager/services/config-boards.service';
import {takeUntil} from 'rxjs/operators';
import {combineLatest, Subject} from 'rxjs';
import {ViewSettingsTask} from '../../../task-manager/models/view-settings';
import {finishDateByCount, getRepeatUnit, getRepeatValues, Template} from '../../../task-manager/models/template';
import * as moment from 'moment';

@Component({
    selector: 'app-task-item',
    templateUrl: './task-item.component.html',
    styleUrls: ['./task-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskItemComponent implements OnInit, OnDestroy {
    @Input() public breakpoint = 0;
    @Input() public planing = false;
    @Input() public isKanban = false;
    @Input() public shortView = false;
    @Input() public isDashboard = false;
    @Input() public task: Task = null;
    public searchTerm = '';
    @Input() public disabled = false;
    @Input() archiveOpen = true;
    // настройки внешнего вида задачи
    public viewSettingsTask: ViewSettingsTask = null;
    // Последняя открытая таска
    public openedId = null;
    // Название элемента подзадачи в DOM-дереве
    readonly TAG_NAME = 'APP-SHORT-FORM-ADD';
    // Триггер смерти компонента
    private destroyed = new Subject<void>();
    public tableView: string = null;
    public unwrapTask = false;
    public archiveCondition: boolean = false;
    public isTemplate = false;
    public isSprintVisible = false;

    public IMPORTANCE_TYPE = this.config.IMPORTANCE_TYPE;
    public group = null;
    public mainBoard = null;
    public isDateVisible = null;
    public isDateFinishVisible = null;
    public templateTitle = null;
    public templateSubTitle = null;
    public importanceText = null;
    public importanceIcon = null;

    constructor(
        private api: ApiBoardsService,
        private auth: AuthService,
        private tasksService: TasksService,
        private boardsService: BoardsService,
        private tagsService: TagsService,
        private nav: NavService,
        private router: Router,
        private config: ConfigBoardsService,
        private cdr: ChangeDetectorRef
    ) {
    }

    @Input() set viewTask(param: ViewSettingsTask) {
        this.viewSettingsTask = param;

        this.isDateVisible = this.task.dateExpiration && this.viewSettingsTask.isDateVisible &&
            !this.task.isTaskDone && !this.task.isTaskArchive && !this.task.isTaskClose && !this.isTemplate;

        this.isDateFinishVisible = this.task.dateFinish && this.viewSettingsTask.isDateVisible &&
            (this.task.isTaskDone || this.task.isTaskArchive || this.task.isTaskClose);

        if (this.task.type == 'template' && this.task.template && this.task.template.repeat_every_unit) {
            this.templateTitle = this.getTemplateTitle(this.task.template);
            this.templateSubTitle = this.getTemplateSubtitle(this.task.template);
        }

        this.changeSprintVisibility();

        this.cdr.detectChanges();
    }

    get isMediaQueryMedium() {
        return this.breakpoint > 0;
    }

    get isMediaQuerySmall() {
        return this.breakpoint > 1;
    }


    onFormClick(evt) {
        evt.stopPropagation();
    }

    ngOnInit() {
        this.getBoardGroup();

        this.isTemplate = this.task && this.task.type == 'template';

        if (this.task.importance) {
            this.importanceText = this.config.getImportanceTooltip(this.task.importance);
            this.importanceIcon = this.config.getImportanceById(this.task.importance).icon;
        }

        this.unwrapTask = this.shortView;

        combineLatest(this.boardsService.tableView$, this.tasksService.lastOpenedTask$, this.tasksService.updateChatCnt$)
            .pipe(takeUntil(this.destroyed))
            .subscribe(([tableView, lastOpenedTask, updateChatCnt]) => {
                if (tableView && tableView !== this.tableView) {
                    this.tableView = tableView;
                    this.archiveCondition = this.getArchiveState();
                }

                if (lastOpenedTask && this.openedId !== lastOpenedTask.id) {
                    this.openedId = lastOpenedTask.id;
                    if (this.task.id === this.openedId) {
                        this.task.isNew = lastOpenedTask.isNew;
                    }
                }

                if (updateChatCnt && this.task.id === +updateChatCnt.id) {
                    this.task.messages = updateChatCnt.counter ? this.task.messages + 1 : 0;
                }

                if (tableView && this.viewSettingsTask) {
                    this.changeSprintVisibility();
                }

                this.cdr.detectChanges();
            });

        this.searchTerm = this.tasksService.searchTerm$.value;

        this.tasksService.searchTerm$
            .pipe(takeUntil(this.destroyed))
            .subscribe(query => {
                this.searchTerm = query;
                this.cdr.detectChanges();
            });
    }

    changeSprintVisibility() {
        this.isSprintVisible = !this.isKanban &&
            this.task.type !== 'template' &&
            this.task.sprint && this.viewSettingsTask.isSprintVisible
    }

    // Условие отображения задач с других досок, у которых связь только с архивными задачами с этой доски
    getArchiveState() {
        if (this.tableView === 'hierarchy' && !this.task.onCurrentBoard) {
            if ((this.task.parent?.status.type === TaskStatuses.DONE_ARCHIVE) &&
               (this.task.children.length === 0 ||
                    this.task.children.every(el => el.status.type === TaskStatuses.DONE_ARCHIVE)
                )) {
                    return this.archiveOpen;
                } else {
                return true;
            }
        } else {
            return true;
         }
    }

    isBoardSelected(board: Board) {
        const selected = this.boardsService.boardSelected$.getValue();
        if (selected) {
            return board.id === selected.id;
        } else {
            return false;
        }
    }

    getUserAvatar(user: UserShort) {
        return this.config.getUserAvatarByUser(user, 'small');
    }

    openChat() {
        this.tasksService.chatIsOpened$.next(true);
    }

    getBoardColor(colorName) {
        return this.boardsService.getBoardColor(colorName);
    }

    getTagBackground(colorName) {
        return this.tagsService.getTagBackground(colorName);
    }

    getBoardTags(board: Board) {
        return board.tags.filter(el => !el.taskGroup);
    }

    getBoardGroup() {
        let currentTaskBoard = this.isDashboard ? this.task.boards.find(el => el.is_main) : this.task.boards.find(el => this.isBoardSelected(el));

        if (this.isDashboard && !currentTaskBoard) {
            currentTaskBoard = this.task.boards.find(el => this.isBoardSelected(el));
        }
        if (currentTaskBoard && currentTaskBoard.tags && currentTaskBoard.tags.some(el => el.taskGroup)) {
            if (this.task.onCurrentBoard) {
                this.group = currentTaskBoard.tags.find(el => el.taskGroup);
            }

            this.mainBoard = currentTaskBoard;
            this.cdr.detectChanges();
            return false;
        }

        const mainBoard = this.task.boards.find(b => b.is_main);
        this.group = mainBoard?.tags.find(el => el.taskGroup);
        this.cdr.detectChanges();
    }


    getBoardName(board) {
        return this.boardsService.getBoardName(board, this.auth.currentUser$.getValue());
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
        this.tasksService.updateChatCnt$.next(null);
    }

    getTemplateTitle(template: Template) {
        return getRepeatUnit(template);
    }

    getTemplateSubtitle(template: Template) {
        let fromTxt = getRepeatValues(template);
        if (fromTxt && fromTxt.length) {
            fromTxt = fromTxt + ' | ';
        }
        let untilTxt = 'По ';
        if (template.repeat_until_count) {
            untilTxt = untilTxt + moment(finishDateByCount(template)).format('dd MMM yyyy')
        }

        if (template.repeat_until_date) {
            untilTxt = untilTxt + moment(template.repeat_until_date).format('dd MMM yyyy')
        }

        if (!template.repeat_until_date && !template.repeat_until_count) {
            untilTxt = untilTxt + 'бессрочно'
        }

        return fromTxt + untilTxt;
    }
}
