import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class QuestionsService {

    public cardOpenedId$ = new Subject();
    public cardAnsweredId$ = new Subject();
    public surveyUpdate$ = new Subject();

    constructor() {
    }
}
