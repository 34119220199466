import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-lost',
  templateUrl: './lost.component.html',
  styleUrls: ['./lost.component.scss']
})
export class LostComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
