import {JsonElementType, JsonProperty, JsonType} from 'ta-json';
import {Position} from './position';
import {PositionShort} from './position-short';

export class PositionFolder {
    @JsonProperty('id')
    @JsonType(Number)
    id: number = null;

    @JsonProperty('parent_id')
    @JsonType(Number)
    parent_id: number = null;

    @JsonProperty('group_id')
    @JsonType(Number)
    group_id: number = null;

    @JsonProperty('counter')
    @JsonType(Number)
    counter: number = null;

    @JsonProperty('title')
    @JsonType(String)
    title: string = null;

    @JsonProperty('destiny')
    @JsonType(String)
    destiny: string = null;

    @JsonProperty('path')
    @JsonType(Array)
    path = [];

    @JsonProperty('unit_id')
    @JsonType(Number)
    unit_id: number = null;

    @JsonProperty('positions')
    @JsonElementType(Position)
    positions: Position[] = [];

    @JsonProperty('positions_short')
    @JsonElementType(PositionShort)
    positionsShort: PositionShort[] = [];

    @JsonProperty('children')
    @JsonElementType(PositionFolder)
    children: PositionFolder[] = [];
}
