<div class="members">
      <div class="header">
        <h2 class="title">
          {{ title }} <ng-container *ngIf="tags.length">({{ tags.length }})</ng-container>
        </h2>
      </div>

    <app-tags-list [tags]="tags" [editable]="!disabled"></app-tags-list>

    <button mat-flat-button class="button _transparent" type="button" *ngIf="!disabled" (click)="openFormAdd()">
        <mat-icon class="icon">add</mat-icon>
        Добавить метку
    </button>
</div>




