<section class="dialog">
    <header class="dialog__header">
        <h3 class="dialog__title"></h3>
        <mat-icon class="close" (click)="close()">close</mat-icon>
    </header>

    <div class="content">
        <h3 [innerHtml]="title" class="title"></h3>

        <h4 class="subtitle">Вы можете изучить функционал раздела в <a class="link" [href]="LINK">справочном центре.</a></h4>

        <div class="admins" *ngIf="admins">
            <span class="txt">Для изменения тарифа обратитесь к&nbsp;администратору компании:</span>

            <div class="scroll">
                <app-user-list-item *ngFor="let user of admins" class="admin"
                                    [disabled]="true"
                                    (click)="openChat(user)"
                                    [userSubdivision]="user" [position]="'Администратор'"></app-user-list-item>
            </div>

        </div>

    </div>

</section>
