import {PlaningUserStats, UserShort} from '../../../models/user';
import {JsonElementType, JsonObject, JsonProperty, JsonType} from 'ta-json';

/**
 * Список ролей
 */
export enum BoardRole {
    MEMBER = 'MEMBER',
    OWNER = 'OWNER',
    EDITOR = 'EDITOR'
}

/**
 * Маппинг ролей
 */
export const BoardRoleMap = Object.freeze([
    {
        value: BoardRole.OWNER,
        name: 'Владелец',
        description: 'Редактирование, архивирование, назначение ролей, смена владельца'
    },
    {
        value: BoardRole.EDITOR,
        name: 'Редактор',
        description: 'Редактирование, назначение ролей'
    },
    {
        value: BoardRole.MEMBER,
        name: 'Участник',
        description: 'Просмотр информации о доске, полные права на работу с задачами доски'
    },
]);

@JsonObject()
export class ShortUserBoards extends UserShort {
    @JsonProperty('unread')
    @JsonType(Boolean)
    unread?: boolean;

    @JsonProperty('planing_points')
    @JsonType(Number)
    planing_points: number;

    @JsonProperty('stats')
    @JsonElementType(PlaningUserStats)
    stats: PlaningUserStats = null;

    constructor(
        userShort?: UserShort,
        unread: boolean = true,
        planingPoints = 0,
    ) {
        super(userShort.id, userShort.firstName, userShort.middleName, userShort.lastName, userShort.position,
            userShort.currentPosition, userShort.weight, userShort.photo);
        this.unread = unread;
        this.planing_points = planingPoints;
    }
}

@JsonObject()
export class Member extends ShortUserBoards {
    @JsonProperty('on_board_default')
    @JsonType(String)
    onBoardDefault?: string = null;

    @JsonProperty('board_role')
    @JsonType(String)
    boardRole = 'owner';

    @JsonProperty('on_board')
    @JsonType(Boolean)
    onBoards: boolean;

    @JsonProperty('control_performers')
    @JsonType(String)
    controlPerformers: string[];

    @JsonProperty('is_active_planing')
    @JsonType(Boolean)
    is_active_planing: boolean;

    @JsonProperty('planing_points')
    @JsonType(Number)
    planing_points: number;

    constructor(
        boarsUserShort?: ShortUserBoards,
        onBoardDefault: string = null,
        boardRole: string = 'owner',
        onBoards: boolean = false,
        controlPerformers: string[] = null
    ) {
        super(boarsUserShort, boarsUserShort?.unread);
        this.onBoardDefault = onBoardDefault;
        this.boardRole = boardRole;
        this.onBoards = onBoards;
        this.controlPerformers = controlPerformers;
    }
}
