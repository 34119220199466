import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {ApiMotivationsService} from '../../../phonebook/services/api-motivations.service';
import {GlobalNotificationCenterService} from '../../../../services/global-notification-center.service';

@Component({
    selector: 'app-motivations-chat',
    templateUrl: './motivations-chat.component.html',
    styleUrls: ['./motivations-chat.component.scss']
})
export class MotivationsChatComponent implements OnInit, OnDestroy {

    @Input() public usersMotivationId = null;
    @Input() public multiple = false;
    @Input() public isPrivate = true;
    @Input() public isCard = true;
    @Input() public title = null;
    @Output() public readonly close: EventEmitter<any> = new EventEmitter();
    private destroyed = new Subject<void>();
    public isLoaded = false;
    @Input() date: string;

    constructor(
        private api: ApiMotivationsService,
        private notiService: GlobalNotificationCenterService
    ) {
    }

    ngOnInit(): void {
        this.isLoaded = true;
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }

    onClose() {
        this.close.emit(true);
    }


    onChangeChat(val) {
        this.isLoaded = false;
        this.isPrivate = val;
        setTimeout(() => {
            this.isLoaded = true;
        });
    }
}
