<div class="dialog">
    <div class="header">
        <h3 [innerHtml]="title" class="title"></h3>
        <mat-icon class="close" (click)="close()">close</mat-icon>
        <p *ngIf="subtitle" class="subtitle">{{subtitle}}</p>
    </div>
    <div class="content">
        <p *ngIf="description">{{description}}</p>
        <label class="label kit"
               [matTooltipDisabled]="true"
               [matTooltip]="''"
        >
            <textarea class="input _textarea" *ngIf="!input.isPhone"
                   [ngClass]="{'_error': false}"
                   [placeholder]="input.placeholder"
                   [(ngModel)]="input.value"
                      [maxLength]="input.maxLength ? input.maxLength : 300"></textarea>

            <input class="input" *ngIf="input.isPhone"
                   [ngClass]="{'_error': false}"
                   [placeholder]="input.placeholder"
                   [(ngModel)]="input.value"
                   prefix="+" mask="0 (000) 000 00 000">

            <div *ngIf="input.maxLength" class="max-length">{{input.value.trimEnd().length}} / {{input.maxLength}}</div>
        </label>
    </div>

    <div class="footer" *ngIf="buttons && buttons.length === 2">
        <app-button *ngFor="let button of buttons, let index = index"
                    class="button"
                    [buttonType]="index === 0 ? 'outlined' : 'field'"
                    autoFocus="false"
                    [title]="button.name"
                    (click)="action(button.action)"
                    [disabled]="button.inputRequired && !input.value.trimEnd().length"
        >
        </app-button>
    </div>

    <div class="footer"
         *ngIf="buttons && buttons.length !== 2">
        <button *ngFor="let button of buttons"
                mat-flat-button [autoFocus]="button.autofocus"
                class="button" [ngClass]="button.color"
                (click)="action(button.action)">
            {{button.name}}
        </button>
    </div>
</div>


