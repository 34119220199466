<div class="back-layer" (click)="closeFullScreen()" *ngIf="!isMobile">
    <div class="wnd" (click)="stopPropagation($event)" [ngClass]="{'_small': mainSlider}">
        <button mat-icon-button (click)="backToCommonHint()" class="back-btn">
            <mat-icon>arrow_back</mat-icon>
        </button>
        <button mat-icon-button (click)="closeFullScreen()" class="close-btn">
            <mat-icon>close</mat-icon>
        </button>

        <div class="content">
            <div class="title-wrap-onb">
                <ng-content select="[title]"></ng-content>
            </div>
            <div class="slider-wrap">
                <owl-carousel-o [options]="owlOptions" #owlCarousel>
                    <ng-template carouselSlide *ngFor="let slide of slides; let i = index"
                                 [id]="getSlideId(i)" [width]="mainSlider ? 490 : 800">
                        <div class="pane" [ngClass]="{'_big': !mainSlider}">
                            <img [src]="slide.src"
                                 [srcset]="getSrcSet(slide)"
                                 alt="" />
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</div>
