import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {ConfigService} from './config.service';
import {map} from 'rxjs/operators';
import {JSON} from 'ta-json';
import {NotifierService} from 'angular-notifier';
import {AuthService} from './auth.service';
import {UserInfoService} from './user-info.service';
import {Rights, User} from '../models/user';
import {Application} from '../modules/phonebook/models/application';
import {Subdivision} from '../modules/phonebook/models/subdivision';
import {FunctionInfo} from '../modules/phonebook/models/functions/function-info';
import {History} from '../modules/shared/models/history';
import {HistoryPhonebook} from '../modules/phonebook/models/history-phonebook';
import {FunctionShort} from '../modules/phonebook/models/functions/function-short';
import {Task} from '../modules/task-manager/models/task';

@Injectable({
    providedIn: 'root'
})
export abstract class ApiService {
    /** list of available for this user apps */
    appList$ = new BehaviorSubject([]);
    currentUserAwailableCircles$ = new BehaviorSubject(null);
    onCloseSidebar$ = new Subject();

    constructor(
        readonly http: HttpClient,
        public config: ConfigService,
        public userInfo: UserInfoService,
        public auth: AuthService,
        public notifierService: NotifierService
    ) {
    }


    getShortTaskById(taskId) {
        let virtualIdParam = '?v=true&short=true';

        return this.http.get(
            `${this.config.taskManagerTaskUrl}/${taskId}${virtualIdParam}`,
            this.auth.authorize()
        ).pipe(
            map(res => ({
                    data: JSON.deserialize<Task>(res['data'], Task),
                    rights: JSON.deserialize<Rights>(res['rights'], Rights),
                })
            ));
    }

    getCompanyTariffs(names) {
        const params = 'app_names=' + names.join(' ');

        return this.http.get(
            `${this.config.tariffsService}/api/v1/tariffs/company/users?${params}`,
            this.auth.authorize(),
        ).pipe(
            map(res => res['payload'])
        );
    }

    getCompanySettings() {
        return this.http.get(
            `${this.config.companySettings}`,
            this.auth.authorize(),
        ).pipe(
            map(res => res)
        );
    }

    getFunctions() {
        return this.http.get(
            `${this.config.functions}`,
            this.auth.authorize(),
        ).pipe(
            map(res => ({
                    functions: JSON.deserialize<FunctionShort[]>(res['data'], FunctionShort),
                    rights: JSON.deserialize<Rights>(res['rights'], Rights)
                })
            )
        );
    }

    getUsers(search?) {
        return this.http.get(
            `${this.config.users}?search=${search}`,
            this.auth.authorize(),
        ).pipe(
            map(res => ({
                        users: JSON.deserialize<User[]>(res['payload'], User),
                        rights: JSON.deserialize<Rights>(res['rights'], Rights),
                        count: res['count']
                    })
            )
        );
    }

    getUsersArchive() {
        return this.http.get(
            `${this.config.authService}archive/all/`,
            this.auth.authorize(),
        ).pipe(
            map(res => JSON.deserialize<User[]>(res['payload'], User))
        );
    }

    getHints() {
        return this.http.get(
            `${this.config.userHelpers}`,
            this.auth.authorize(),
        ).pipe(map(res => res['payload'] ? res['payload'].state : null));
    }

    updateHints(data) {
        return this.http.put(
            `${this.config.userHelpers}`,
            data,
            this.auth.authorize(),
        ).pipe(map(res => res['payload']));
    }


    getSubdivisionsLite() {
        return this.http.get(
            `${this.config.structAdminLite}`,
            this.auth.authorize(),
        ).pipe(
            map(res => JSON.deserialize<Subdivision[]>(res['data'], Subdivision))
        );
    }

    getSubdivisions(isArchive = false) {
        return this.http.get(
            `${this.config.structAdmin}?is_archive=${isArchive}`,
            this.auth.authorize(),
        ).pipe(
            map(res => ({
                        subdivision: JSON.deserialize<Subdivision[]>(res['data'], Subdivision),
                        rights: JSON.deserialize<Rights>(res['rights'], Rights)
                    })
            )
        );
    }

    public closeSidebar() {
        this.onCloseSidebar$.next(true);
    }

    public File(file: FormData) {
        return this.http.post(
            `${this.config.fileStorageURL}files/`,
            file,
            this.auth.authorize()
        ).pipe(
            map(res => res['payload'])
        );
    }

    public getBoardsCounters(): Observable<any> {
        return this.http.get(
            `${this.config.getBoardsCounters}`,
            this.auth.authorize()
        ).pipe(map(res => res['payload']));
    }

    public getUsersQuestionsCounters(): Observable<any> {
        return this.http.get(
            `${this.config.platformLearning}`,
            this.auth.authorize()
        ).pipe(map(res => res['payload']));
    }

    public getApplications(): Observable<any> {
        return this.http.get(
            `${this.config.apps}`,
            this.auth.authorize()
        ).pipe(map(res => res['payload']));
    }

    public checkToken(token): Observable<any> {
        return this.http.get(
            `${this.config.invites}/${token}/`
        ).pipe(map(res => res['payload']));
    }

    public checkEmail(email, token): Observable<any> {
        return this.http.get(
            `${this.config.authorizationInvites}?account=${email}&cipher=${token}`
        ).pipe(
            map(res => res['payload'])
        );
    }

    public inviteComplete(data): Observable<any> {
        return this.http.post(
            `${this.config.authorizationInvitesComplete}`,
            data
        ).pipe(
            map(res => res['payload'])
        );
    }

    public sendTokenIn(token): Observable<any> {
        return this.http.post(
            `${this.config.fcm}token/sign-in/`,
            {
                type: 'WEB',
                token,
            },
            this.auth.authorize()
        ).pipe(map(res => res['payload']));
    }

    public sendTokenOut(token): Observable<any> {
        return this.http.post(
            `${this.config.fcm}token/sign-out/`,
            {
                token
            },
            this.auth.authorize()
        ).pipe(map(res => res));
    }


    public getMyCircles(): Observable<any> {
        return this.http.get(
            `${this.config.myCircles}`,
            this.auth.authorize()
        ).pipe(map(res => res['payload']));
    }


    public getRulesByUserId(id, circleId) {
        return this.http.get(
            `${this.config.rulesByUserId + circleId + '/' + id}`,
            this.auth.authorize()
        ).pipe(
            map(res => res['payload'])
        );
    }

    public setRulesByUserId(id, circleId, api_key, params) {
        return this.http.post(
            `${this.config.rulesByUserId + circleId + '/' + id}`,
            {settings: params},
            this.auth.authorize()
        ).pipe(
            map(res => res['payload'])
        );
    }

    public getRulesUsersOutsideCircle(circleId) {
        return this.http.get(
            `${this.config.permissionsBase}external_users/${circleId}`,
            this.auth.authorize()
        ).pipe(
            map(res => res['payload'])
        );
    }

    public rulesAddUserToCircle(circleId, userId) {
        return this.http.post(
            `${this.config.permissionsBase}add_user/${circleId}/${userId}`,
            {},
            this.auth.authorize()
        ).pipe(
            map(res => res['payload'])
        );
    }

    public rulesDeleteUserFromCircle(circleId, userId) {
        return this.http.post(
            `${this.config.permissionsBase}delete_user/${circleId}/${userId}`,
            {},
            this.auth.authorize()
        ).pipe(
            map(res => res['payload'])
        );
    }

    public getSmartContractById(id) {
        return this.http.get(
            `${this.config.smartContracts + id}`,
            this.auth.authorize()
        );
    }

    /**
     * Запрос на получение заявки на оплату и доп данных по ее id
     */
    public getPaymentApplicationById(id: string) {
        return this.http.get(
            `${this.config.paymentRequest}/${id}`,
            this.auth.authorize()
        );
    }

    /**
     * Запрос на получение всех пользователей
     */
    public getAllUsers() {
        return this.http.get(
            `${this.config.usersApiUrl}`,
            this.auth.authorize()
        );
    }

    public getChatAvailableMembers(chatId, type = null) {
        const typeStr = type ? `&type=${type}`: ``;
        return this.http.get(
            `${this.config.chatAvailableMembers}?id=${chatId}${typeStr}`,
            this.auth.authorize()
        ).pipe(
            map(res => JSON.deserialize<User[]>(res['payload'], User))
        );
    }

    public getChatMembers(chatId, type = null) {
        const typeStr = type ? `&type=${type}`: ``;
        return this.http.get(
            `${this.config.chatMembers}?id=${chatId}${typeStr}`,
            this.auth.authorize()
        ).pipe(
            map(res => JSON.deserialize<User[]>(res['payload'], User))
        );
    }

    public addChatMember(chatId, userId, type) {
        return this.http.post(
            `${this.config.addChatMember}`,
            {chat_id: chatId, user_id: userId, chat_type: type},
            this.auth.authorize()
        ).pipe(
            map(res => JSON.deserialize<User[]>(res['payload'], User))
        );
    }

    public removeChatMember(chatId, userId, type) {
        return this.http.post(
            `${this.config.removeChatMember}`,
            {chat_id: chatId, user_id: userId, chat_type: type},
            this.auth.authorize()
        ).pipe(
            map(res => JSON.deserialize<User[]>(res['payload'], User))
        );
    }

    public checkAvailableEmail(email) {
        return this.http.post(
            `${this.config.authorizationInvites}check_account/`,
            {account: email}
        ).pipe(
            map(res => res['payload'])
        );
    }

    public getApplicationsChildren(appId) {
        return this.http.get(
            `${this.config.apps}${appId}/children/`,
            this.auth.authorize()
        ).pipe(
            map(res => JSON.deserialize<Application[]>(res['payload'], Application))
        );
    }

    /**
     * обработчик ошибок
     *
     * @param err String
     */
    public handleFullError(err) {
        this.notifierService.notify('error', err && err.error && err.error.message ? err.error.message : err.message);
    }

    getPermissions(appId): Observable<Application[]> {
        return this.http.get(
            `${this.config.childrenApplications}${appId}/children/`,
            this.auth.authorize()
        ).pipe(
            map(res => JSON.deserialize<Application[]>(res['payload'], Application))
        );
    }

    getFunctionInfoUser(userId) {
        return this.http.get(
            `${this.config.functions}?user_id=${userId}`,
            this.auth.authorize(),
        ).pipe(
            map(res =>  JSON.deserialize<FunctionInfo[]>(res, FunctionInfo))
        );
    }

    getFunctionInfoSubdivision(subdivisionId) {
        return this.http.get(
            `${this.config.functions}?subdivision_id=${subdivisionId}`,
            this.auth.authorize(),
        ).pipe(
            map(res =>  JSON.deserialize<FunctionInfo[]>(res, FunctionInfo))
        );
    }

    getHistoryPhonebookById(id: string, type: string): Observable<HistoryPhonebook[]> {
        let url = `${this.config.motivations}users_motivation/${id}/history/`;
        if (type && type === 'matrix') {
            url = `${this.config.matrix}/${id}/history`;
        }
        return this.http.get(
            url,
            this.auth.authorize()
        ).pipe(
            map(res => res['payload'])
        );
    }

    getHistoryById(id: string, type: string): Observable<History[]> {
        return this.http.get(
            `${this.config.history}/${type}/${id}`,
            this.auth.authorize()
        ).pipe(
            map(res => res['payload'])
        );
    }

    /* Запрос профиля компании*/
    public getCompaniesList() {
        return this.http.get(
            `${this.config.companyProfile}`,
            this.auth.authorize()
        ).pipe(
            map(res => res['payload'])
        );
    }

    // загрузка картинки
    public uploadFile(file: FormData) {
        return this.http.post(
            `${this.config.fileStorageURL}files/`,
            file,
            this.auth.authorize()
        ).pipe(
            map(res => res['payload'])
        );
    }

    // загрузка картинки
    public uploadFileLoader(file: FormData) {
        let  options = this.auth.authorize();
        options['reportProgress'] =  true;
        options['observe'] = 'events';
        return this.http.post(
            `${this.config.fileStorageURL}files/`,
            file,
            options
        )
    }

    public getFile(entity_id: string, fileId: string, fileName: string): Observable<any> {
        const httpHeaders = this.auth.authorize();
        httpHeaders['responseType'] = 'arraybuffer';
        return this.http.get(
            `${this.config.fileStorageURL}files/${entity_id}/${fileName}`,
            httpHeaders
        );
    }

    setTariffMsgViewed(type?) {
        return this.http.post(
            `${this.config.tariffsService}/api/v1/tariffs/notify_read`,
            {
                type,
            },
            this.auth.authorize()
        ).pipe(
            map(res => res['payload'])
        );
    }

        getApplicationsChildrenByName(appName: string) {
        return this.http.get(
            `${this.config.apps}/by_name/${appName}/children/`,
            this.auth.authorize()
        ).pipe(
            map(res => JSON.deserialize<Application[]>(res['payload'], Application))
        );
    }

    public updateChatUsers(data): Observable<any> {
        return  this.http.put(
            `${this.config.chatRoomUsers}`,
            data,
            this.auth.authorize()
        ).pipe(map(res => res['payload']));
    }

    public changeChatNotify(data): Observable<any> {
        return this.http.put(
            `${this.config.chatRoomNotification}`,
            data,
            this.auth.authorize()
        ).pipe(map(res => res['payload']));
    }
}
