<mat-tree [dataSource]="dataSource" #tree
          *ngIf="!searchStr || !searchStr.length"
          [treeControl]="treeControl">
    <mat-tree-node *matTreeNodeDef="let node"
                   matTreeNodePadding
                   matTreeNodePaddingIndent="15">
        <div class="head">
            <button mat-icon-button disabled></button>
            <app-subdivision-list-item
                    class="subdivision"
                    [rating]="false"
                    [selectable]="true"
                    (click)="onSubdivisionClick(node, $event)"
                    [ngClass]="{'_root': node.id < 0, '_selected': isSelected(node)}"
                    [data]="node">
            </app-subdivision-list-item>
        </div>

    </mat-tree-node>

    <mat-tree-node *matTreeNodeDef="let node; when: hasChild"
                   matTreeNodePadding
                   matTreeNodePaddingIndent="15">
            <div class="head" [ngClass]="{'_expanded': treeControl.isExpanded(node)}" *ngIf="node" matTreeNodeToggle>
                <button mat-icon-button
                        [attr.aria-label]="'Toggle ' + node.name">
                    <mat-icon class="mat-icon-rtl-mirror">
                        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                    </mat-icon>
                </button>
                <app-subdivision-list-item
                        class="subdivision"
                        [rating]="false"
                        [selectable]="true"
                        (click)="onSubdivisionClick(node, $event)"
                        [ngClass]="{'_root': node.id < 0, '_selected': isSelected(node)}"
                        [data]="node">
                </app-subdivision-list-item>
            </div>
    </mat-tree-node>
</mat-tree>

<ng-container *ngIf="searchStr && searchStr.length">
    <app-subdivision-list-item
            *ngFor="let item of sortedSubdivisions, let index = index"
            class="subdivision _root"
            [rating]="false"
            [searchStr]="searchStr"
            [selectable]="true"
            (click)="onSubdivisionClick(item, $event)"
            [ngClass]="{'_selected': isSelected(item)}"
            [data]="item">
    </app-subdivision-list-item>
</ng-container>
