import {JsonProperty, JsonType} from 'ta-json';
import {UserShort} from '../../../../models/user';
import {MotivationsPositionsCoordinators} from './motivations-positions-coordinators';
import {MotivationsCustomField} from './motivations-custom-field';

export class MotivationsSettingsCompany {
    @JsonProperty('id')
    @JsonType(Number)
    id: number;

    @JsonProperty('custom_fields')
    @JsonType(MotivationsCustomField)
    customFields: MotivationsCustomField[] = [];

    @JsonProperty('positions_coordinators')
    @JsonType(MotivationsPositionsCoordinators)
    positionsCoordinators: MotivationsPositionsCoordinators[] = [];

    @JsonProperty('coordinators')
    @JsonType(UserShort)
    coordinators: UserShort[] = [];

    @JsonProperty('executives')
    @JsonType(UserShort)
    executives?: UserShort[] = [];

    @JsonProperty('subdivision_id')
    @JsonType(Number)
    subdivisionId: number = null;

    constructor(subdivisionId: number = null) {
        this.subdivisionId = subdivisionId;
    }
}
