import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '../../../services/config.service';
import {AuthService} from '../../../services/auth.service';
import {map} from 'rxjs/operators';
import {JSON} from 'ta-json';
import {Account} from 'src/app/models/account';

@Injectable({
  providedIn: 'root'
})
export class ApiCabinetService {

  constructor(
      private http: HttpClient,
      public config: ConfigService,
      public auth: AuthService
  ) { }

  /* Выбор почты для уведомления*/
  public setNotifiableEmail(data) {
    return this.http.put(
        `${this.config.setNotificationEmail}`,
        data,
        this.auth.authorize()
    ).pipe(map(res => JSON.deserialize<Account>(res['payload'], Account)));
  }

  /* Запрос профиля компании*/
  public getCompanyProfile(companyId) {
    return this.http.get(
      `${this.config.companyProfile}${companyId}/`,
      this.auth.authorize()
  ).pipe(
      map(res => res['payload'])
  );
  }

  /* Обновление профиля компании*/
  public updateCompanyProfile(data, companyId) {
    return this.http.put(
      `${this.config.companyProfile}${companyId}/`,
      data,
      this.auth.authorize()
  ).pipe(
      map(res => res['payload'])
  );
  }
}
