import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {GroupsService} from '../../../task-manager/services/groups.service';
import {Tag} from '../../../task-manager/models/tag';
import {BoardsService} from '../../../task-manager/services/boards.service';
import {Board} from '../../../task-manager/models/board';

@Component({
    selector: 'app-small-group',
    templateUrl: './small-group.component.html',
    styleUrls: ['./small-group.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmallGroupComponent implements OnInit, OnChanges {

    @Input() public group: Tag = null;
    @Input() public board: Board = null;
    @Input() public colorOnly = false;
    public color = null;

    public groupBackground = null;

    constructor(
        private groupsService: GroupsService,
        private boardsService: BoardsService,
        private cdr: ChangeDetectorRef
    ) {
    }

    getGroupBackground() {
        if (!this.board) {
            this.board = this.boardsService.boardSelected$.value;

            if (!this.board) {
                return false;
            }
        }
        // Если на текущей доску есть метка с таким же названием используем ее цвет
        const boardGroups = this.board.tags.filter(el => el.taskGroup);
        const group = boardGroups.find(el => el.name === this.group.name);
        this.color = this.groupsService.getGroupBackground(group ? group.color : this.group.color);
    }

    ngOnInit(): void {
        this.groupBackground = this.getGroupBackground();
        this.cdr.detectChanges();

        this.boardsService.boardSelected$.subscribe(res => {
            this.groupBackground = this.getGroupBackground();
            this.cdr.detectChanges();
        })
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.group && changes.group.currentValue) {
            this.getGroupBackground();
            this.cdr.detectChanges();
        }
    }

}
