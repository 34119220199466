<div class="member" *ngIf="!userSubdivision">
    <img class="member__img"
         src="/assets/images/nobody.svg">

    <span class="member__title txt">
        <span [innerHTML]="'Неопознанный пингвин'  | highlight : searchStr"></span>

         <span class="member__position" *ngIf="position && positionIsStr">
            <span [innerHTML]="position | highlight : searchStr"></span>
        </span>
    </span>
</div>

<div class="member"
     *ngIf="userSubdivision" (click)="onUserClick($event)"
     [ngClass]="{'_active': removable || addable, '_small': small, '_disabled': disabled}">

    <app-user-avatar [user]="userSubdivision" class="member__img"
                     *ngIf="userSubdivision.id > 0"
                     [matTooltip]="disabled ? null : approved ? 'Согласовал: ' + userSubdivision.shortName : userSubdivision.shortName"></app-user-avatar>

    <mat-icon *ngIf='responsible' class="responsible" svgIcon="radius_star-border"></mat-icon>

    <span class="member__title txt" *ngIf="!shortView">
        <ng-container *ngIf="fullName">
            <span [innerHTML]="userSubdivision.name  | highlight : searchStr"></span>
        </ng-container>

        <ng-container *ngIf="!fullName">
            <span [innerHTML]="(userSubdivision.shortName ? userSubdivision.shortName:
                userSubdivision['last_name'] +' '+ userSubdivision['first_name']) | highlight : searchStr"></span>
        </ng-container>

        <span class="member__position" *ngIf="userSubdivision.position && !position">
            <span [innerHTML]="userSubdivision.position | highlight : searchStr"></span>
        </span>

        <span class="member__position" *ngIf="userSubdivision.currentPosition && !position && !userSubdivision.position">
            <span [innerHTML]="userSubdivision.currentPosition?.title | highlight : searchStr"></span>
        </span>

        <span class="member__position" *ngIf="position && positionIsStr">
            <span [innerHTML]="position | highlight : searchStr"></span>
        </span>
    </span>

    <div class="close" [matTooltip]="userSubdivision.shortName">
        <mat-icon class="icon">close</mat-icon>
    </div>
</div>
